import dayjs from 'dayjs'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

export { dayjs }
