import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import ObjectList from '@/domains/items/components/ObjectList'
import { getSizeOfSectionInner } from '@/domains/items/components/ObjectList/ObjectList'
import { LeftArrow } from '@/style/components/Arrow/Arrow'
import { UploadedImage } from '@/style/components/Image'
import Text from '@/style/components/LegacyText'
import ScrollReveal from '@/style/components/ScrollReveal'
import { SectionInner, SectionWrapper } from '@/style/components/Section'
import { CategoryPage } from '@/types/gql/graphql'
import { CategoryItem } from './CategoryItem'
import { OtherOption } from './OtherOption'

type ObjectSelectorItem = Pick<
  CategoryPage,
  | '_id'
  | 'path'
  | 'categoryLabel'
  | 'categoryImage'
  | 'maxLoanToValue'
  | 'isSpecialPage'
  | 'customMaxLoanToValue'
>

interface Props {
  title: string
  objects: Array<ObjectSelectorItem>
  allowBack?: boolean
  otherOption?: boolean
  onClick?: (object?: ObjectSelectorItem) => void
  onClickBack?: () => void
}

export const CategoryList: FunctionComponent<Props> = ({
  title,
  allowBack,
  onClickBack,
  objects,
  otherOption,
  onClick,
}) => {
  return (
    <WrapperWithBG sizing="large" backgroundColor="buttonBorder">
      <BackIcon hidden={!allowBack} onClick={onClickBack} />
      <ScrollReveal delay={0.25}>
        <SectionInner textAlign="center" sizing="wide">
          <Text.md weight="semibold" as="h2" transform="uppercase">
            {title}
          </Text.md>
        </SectionInner>
      </ScrollReveal>
      <SectionInner
        sizing={getSizeOfSectionInner(objects.length + (otherOption ? 1 : 0))}
      >
        <ObjectList numberOfItems={objects.length + (otherOption ? 1 : 0)}>
          {objects.map((object) => (
            <CategoryItem
              key={object._id}
              title={object.categoryLabel}
              Image={
                object.categoryImage ? (
                  <UploadedImage
                    data={object.categoryImage}
                    subset={['product_small', 'product_thumbnail']}
                    sizes={{
                      md: 'product_small',
                    }}
                    primarySize="product_thumbnail"
                  />
                ) : undefined
              }
              onClick={() => onClick?.(object)}
            />
          ))}
          {otherOption && <OtherOption onClick={() => onClick?.()} />}
        </ObjectList>
      </SectionInner>
    </WrapperWithBG>
  )
}

const WrapperWithBG = styled(SectionWrapper)`
  position: relative;
  z-index: 1;
  height: 100%;
  overflow: auto;
`

const BackIcon = styled(LeftArrow)<{
  hidden?: boolean
}>`
  ${(props) =>
    props.hidden &&
    `
    display: none !important;
  `}
  position: absolute;
  left: 1rem;
  top: 1rem;
  font-size: 1rem;
  color: black;
  cursor: pointer;
`
