import styled from 'styled-components'
import Text from '@/style/components/LegacyText'

const InputErrorMessageStyled = styled(Text.sm)`
  display: block;
  text-align: left;
  color: #f44336;
  padding: 0 20px;
  margin-top: 8px;
`
export const InputErrorMessage: React.FC<{
  message: string
}> = (props) => {
  return (
    <InputErrorMessageStyled data-info="error">
      {props.message}
    </InputErrorMessageStyled>
  )
}
