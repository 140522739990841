import { wrap } from '@popmotion/popcorn'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { motion } from 'framer-motion'
import { useState } from 'react'
import styled, { css } from 'styled-components'
import useInterval from '@/helpers/useInterval'
import { ButtonWithIcon } from '@/style/components/Button'
import Text from '@/style/components/LegacyText'
import { media } from '@/style/helpers'
import { ReviewsStoryblok } from '@/types/storyblok-component-types'
import { AnimatePresence } from '@/utils/animatePresence'
import StarsRating from './StarsRating'
import QuoteIcon from './assets/quote_ico.svg'

export default function Reviews({ blok }: { blok: ReviewsStoryblok }) {
  const [{ page, direction }, setPagination] = useState({
    page: 0,
    direction: 0,
  })

  const itemsCount = blok.items.length
  const slideIndex = wrap(0, itemsCount, page)

  const paginate = (newDirection: number) => {
    setPagination({ page: page + newDirection, direction: newDirection })
  }

  useInterval(() => paginate(1), 10000)

  return (
    <Container {...storyblokEditable(blok)}>
      <CardWrapper>
        <Card
          leftColor={blok.left_color?.color}
          rightColor={blok.right_color?.color}
        >
          <QuoteIcon className="qi-left" />
          <QuoteIcon className="qi-right" />
          <Rating>
            <Text.xxl weight="bold">{blok.rating.replace('.', ',')}</Text.xxl>
            <StarsRating rating={+blok.rating} />
            <Text.sm color="white">{blok.subcaption}</Text.sm>
          </Rating>
          <CenteredRow>
            <QuoteIcon />
          </CenteredRow>
          <div style={{ marginTop: '0.8rem', marginBottom: '0.5rem' }}>
            <AnimatePresence initial={true} custom={direction} exitBeforeEnter>
              <ItemsWrapper
                key={page}
                custom={direction}
                variants={{
                  enter: () => {
                    return {
                      opacity: 0,
                    }
                  },
                  center: {
                    opacity: 1,
                  },
                  exit: () => {
                    return {
                      opacity: 0,
                    }
                  },
                }}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: 'spring', stiffness: 300, damping: 200 },
                  opacity: { duration: 0.2 },
                }}
              >
                {itemsCount > 0 && (
                  <StoryblokComponent
                    blok={blok.items[slideIndex]}
                    key={blok.items[slideIndex]._uid}
                  />
                )}
              </ItemsWrapper>
            </AnimatePresence>
          </div>
          <NavWrapper>
            <ButtonWithIcon
              icon="chevronLeft"
              appearance="white"
              onClick={() => paginate(1)}
            />
            <ButtonWithIcon
              icon="chevronRight"
              appearance="white"
              onClick={() => paginate(-1)}
            />
          </NavWrapper>
        </Card>
      </CardWrapper>
    </Container>
  )
}

const NavWrapper = styled.div`
  text-align: center;
  scale: 0.8;
`

const Rating = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
`

const Container = styled.div`
  width: 100%;

  position: relative;
  b {
    font-weight: 600;
  }
`

const CardWrapper = styled.div`
  position: relative;
`

const Card = styled.div<{
  leftColor: string | undefined
  rightColor: string | undefined
}>`
  ${({ leftColor, rightColor }) => css`
    background: linear-gradient(99deg, ${leftColor} 1.31%, ${rightColor} 100%);
  `}
  border-radius: ${({ theme }) => theme.radius.large}px;
  color: ${({ theme }) => theme.colors.white};
  padding: 2rem 2rem 1rem;
  overflow: hidden;
  position: relative;

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 75%;
    clip-path: ellipse(90% 100% at 50% 0%);

    ${media.sm} {
      height: 72%;
    }
  }

  .qi-left,
  .qi-right {
    display: none;
  }

  ${media.md} {
    .qi-left,
    .qi-right {
      display: block;
      position: absolute;
      pointer-events: none;
      top: 15%;
      left: -32%;
      width: 70%;
      height: 70%;
      opacity: 0.1;
    }

    .qi-right {
      left: auto;
      right: -32%;
    }
  }
`

const ItemsWrapper = styled(motion.div)`
  height: 19rem;

  ${media.xs} {
    height: 18rem;
  }

  ${media.sm} {
    height: 14rem;
  }
`

const CenteredRow = styled.div`
  display: flex;
  justify-content: center;
`
