import {
  UploadFile,
  UploadFileFormat,
  UploadFileFormats,
} from '@/types/gql/graphql'

export function buildSrcSet(
  uploadFile: UploadFile,
  options: { sizes?: Array<keyof Omit<UploadFileFormats, '__typename'>> } = {},
) {
  let formatsObj: Omit<UploadFileFormats, '__typename'> | undefined

  if (uploadFile.formats) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, ..._formatsObj } = uploadFile.formats // still needed when using customScalars link???

    formatsObj = _formatsObj
  }

  if (options.sizes && formatsObj) {
    Object.keys(formatsObj).forEach((key) => {
      const typedKey = key as keyof typeof formatsObj

      if (options.sizes !== undefined && !options.sizes.includes(typedKey)) {
        if (formatsObj) {
          // typescript doesn't recognize formatsObj cannot be undefined here
          delete formatsObj[typedKey]
        }
      }
    })
  }

  const formats: Omit<UploadFileFormat, '__typename'>[] = formatsObj
    ? (Object.values(formatsObj).filter(
        (format) => !!format,
      ) as UploadFileFormat[])
    : []

  formats.push(uploadFile) // add original image also

  const sortedFormats = formats.sort((a, b) => a.width - b.width)
  const srcSet = sortedFormats
    .map((format) => `${format.url} ${format.width}w`)
    .join(', ')

  return srcSet
}
