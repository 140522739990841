import { Field, FieldValidator, FormikProps } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import styled from 'styled-components'
import { setLocale } from 'yup'
import { getTranslatedValidationError } from '@/utils/error'
import { InputErrorMessage } from './InputErrorMessage'
import { MuiTextField } from './MuiTextField'

setLocale({
  mixed: {
    default: 'common:errors.wrong_format',
    required: 'common:errors.is_required',
  },
  number: {},
  string: {
    email: 'common:errors.must_be_valid_email',
    min: (params) => {
      return { key: 'common:errors.too_short', values: { min: params.min } }
    },
  },
})

const SmartInputStyle = styled.div<{ marginBottom?: string }>`
  width: 100%;
  margin-bottom: ${(props) => props.marginBottom ?? '10px'};
`

export const SmartTextInput: React.FC<{
  id?: string
  type: string
  name: string
  placeholder: string
  marginBottom?: string
  formikProps: FormikProps<any>
  showValidationError?: boolean
  multiline?: boolean
  rows?: number
  validate?: FieldValidator
  className?: string
  defaultEmptyValue?: boolean
  disabled?: boolean
}> = ({
  id,
  type,
  name,
  placeholder,
  marginBottom,
  formikProps,
  showValidationError = true,
  multiline,
  rows,
  validate,
  className,
  defaultEmptyValue,
  disabled,
}) => {
  const { t } = useTranslation()
  const field = formikProps.getFieldMeta(name)

  let error
  if (
    field.error &&
    (field.touched || formikProps.submitCount > 0) &&
    showValidationError
  ) {
    error = field.error
  }

  return (
    <SmartInputStyle marginBottom={marginBottom} className={className}>
      <Field
        id={id}
        type={type === 'money' ? 'number' : type}
        name={name}
        label={placeholder}
        error={error != undefined}
        as={MuiTextField}
        multiline={multiline}
        minRows={rows}
        validate={validate}
        value={field.value ?? (defaultEmptyValue ? '' : field.value)}
        disabled={disabled}
      />
      {error && (
        <InputErrorMessage message={getTranslatedValidationError(error, t)} />
      )}
    </SmartInputStyle>
  )
}
