import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import styled from 'styled-components'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import ScrollReveal from '@/style/components/ScrollReveal'
import { media } from '@/style/helpers'
import { AdvantagesGridStoryblok } from '@/types/storyblok-component-types'

export default function AdvantagesGrid({
  blok,
}: {
  blok: AdvantagesGridStoryblok
}) {
  return (
    <Container {...storyblokEditable(blok)}>
      <ScrollReveal animation="fadeIn" initial="hidden" className="wrapper">
        <Wrapper>
          <RichTextParagraphStyles>
            <StoryblokRichText document={blok.title} />
          </RichTextParagraphStyles>
        </Wrapper>
      </ScrollReveal>
      <ScrollReveal animation="fadeIn" initial="hidden" className="wrapper">
        <ItemWrapper>
          {blok.items.map((item) => (
            <StoryblokComponent blok={item} key={item._uid} />
          ))}
        </ItemWrapper>
      </ScrollReveal>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  position: relative;

  b {
    font-weight: 600;
  }

  p {
    letter-spacing: 0em;
    line-height: 1.4;
  }

  .wrapper {
    display: flex;
    justify-content: center;
  }
`

const Wrapper = styled.div`
  padding-bottom: 46px;

  ${media.md} {
    padding-bottom: 100px;
  }
`

const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  gap: 30px 20px;

  ${media.md} {
    gap: 50px 40px;
  }
`
