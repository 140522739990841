import { ProductLegacy } from '@/types/gql/graphql'

type ProductSelectorProduct = Pick<
  ProductLegacy,
  'title' | '_id' | 'slug' | 'picture' | 'maxLoanToValue'
> & { isDummyProduct?: boolean }

export function createDummyProducts(numberOfDummyProducts: number) {
  const products: ProductSelectorProduct[] = Array<ProductSelectorProduct>(
    numberOfDummyProducts,
  )

  for (let i = 0; i < numberOfDummyProducts; i++) {
    products[i] = {
      title: 'Hallo',
      _id: i.toString(),
      slug: '',
      maxLoanToValue: 0,
      isDummyProduct: true,
    }
  }

  return products
}
