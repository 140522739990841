import Big from 'big.js'
import * as intl from 'intl'
import { round } from 'lodash'
import { Dispatch, SetStateAction, useState } from 'react'
import {
  TransportFormState,
  TransportFormStateStorebox,
} from '@/app/common/common.state'
import {
  Company,
  EAutomaticPaymentType,
  EManualPaymentType,
  EQuestionValueMode,
  ETransportMode,
  IAddress,
  ItemAnswerArgs,
  ItemCategory,
  ItemQuestion,
} from '@/types/gql/graphql'
import { dayjs } from '@/utils/time'
import { formatLocalisedDate } from './date'

interface Categories {
  name: string
}

type CategoryValues = {
  [key: string]: number | CategoryValues
} & { default?: number }

export function getSingleLineAddress(address: IAddress) {
  if (!address) {
    return ''
  }

  const secondAddressParts: string[] = []

  if (address.stairway) {
    secondAddressParts.push(`Stiege ${address.stairway}`)
  }
  if (address.floor) {
    secondAddressParts.push(`Stock ${address.floor}`)
  }
  if (address.door) {
    secondAddressParts.push(`Tür ${address.door}`)
  }

  const secondAddressLine =
    secondAddressParts && secondAddressParts.length > 0
      ? ` (${secondAddressParts.join(', ')})`
      : ''

  return `${address.street} ${address.houseNumber}${secondAddressLine}, ${address.zipCode} ${address.city}`
}

export function getPremiumShipmentDays(locale?: string) {
  const now = dayjs()
  const endDate = now.add(10, 'day')
  const days: { value: string; label: string }[] = []

  for (let date = now; date.isBefore(endDate); date = date.add(1, 'day')) {
    if (![0, 6].includes(date.day()))
      days.push({
        value: date.format('DD.MM.YYYY'),
        label: formatLocalisedDate(
          date,
          {
            day: '2-digit',
            weekday: 'short',
            year: 'numeric',
            month: '2-digit',
          },
          locale,
        ),
      })
  }

  return days
}

export function getPremiumShipmentHours(day: Date | string | undefined) {
  if (!day) return []

  const hours: { value: number; label: string }[] = []
  const today = dayjs()
  const currentHour = today.hour()
  const startHour = dayjs(day, 'DD.MM.YYYY').isAfter(today)
    ? 9
    : currentHour + (currentHour % 2) + 3

  if (startHour < 9 || startHour > 17) return []

  for (let i = startHour; i <= 15; i += 2) {
    hours.push({ value: i, label: `${i}:00 - ${i + 2}:00` })
  }

  return hours
}

export function printLocalFloat(
  number: number,
  fractionDigits = 2,
  options?: Intl.NumberFormatOptions,
) {
  let formattedNumber = round(number, fractionDigits).toLocaleString(
    // navigator.language,
    'de-DE',
    {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
      ...options,
    },
  )

  try {
    formattedNumber = intl
      .NumberFormat(navigator.language, {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
        ...options,
      })
      .format(number)
  } catch (error) {
    // in browser intl does not work
  }
  return formattedNumber
}

export function printLocalAmount({
  number,
  fractionDigits = 2,
  removeSpaces = false,
}: {
  number: number
  fractionDigits?: number
  removeSpaces?: boolean
}) {
  const defaultCurrencyOptions: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'EUR',
  }
  const fixedNumber = new Big(number).toFixed(fractionDigits)

  let formattedNumber = parseFloat(fixedNumber).toLocaleString('de-DE', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })

  try {
    formattedNumber = new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
      ...defaultCurrencyOptions,
    }).format(number)
  } catch (error) {
    // In browser, Intl might not work
    return `${formattedNumber} €`
  }

  return removeSpaces ? formattedNumber.replace(/\s/g, '') : formattedNumber
}

export function isItemDysfunctional(params: {
  answers: ItemAnswerArgs[]
  itemQuestions: Omit<ItemQuestion, 'validFrom' | 'validTo'>[]
  categories: Pick<ItemCategory, 'name'>[]
}) {
  const { answers, itemQuestions, categories } = params

  let isDysfunctional = false

  if (answers) {
    answers.forEach((answer) => {
      const itemQuestion = itemQuestions.find(
        (q) => q._id === answer.questionId,
      )

      if (itemQuestion?.predictionTag) return

      if (
        itemQuestion?.valueMode === EQuestionValueMode.Percentage &&
        itemQuestion.singleChoiceOptions &&
        answer.selectedOptionIndex
      ) {
        const selectedOption =
          itemQuestion.singleChoiceOptions[answer.selectedOptionIndex]
        const value = getCategoryValue({
          categoryValues: selectedOption.categoryValues,
          categories: categories.map((category) => {
            return { name: category.name }
          }),
        })

        if (value === 0) {
          isDysfunctional = true
        }
      }
    })
  }

  return isDysfunctional
}

/**
 * Returns the loan to value factor for a categories constellation from the loan to values tree object.
 *
 * @param tree
 * @param categoriesList
 */
function getCategoryValueFromTree(
  tree: CategoryValues,
  categoriesList: Categories[],
): number | null {
  const newCategories = [...categoriesList]

  const category = newCategories.shift()

  if (!category) {
    return null
  }

  if (typeof tree[category.name] === 'object') {
    return getCategoryValueFromTree(
      tree[category.name] as CategoryValues,
      newCategories,
    )
  } else if (typeof tree[category.name] === 'number') {
    return tree[category.name] as number
  } else if ('default' in tree) {
    return tree.default ?? 0
  }

  return null
}

/**
 * Returns the category value for the specified item categories.
 *
 * @param {*} params
 */
function getCategoryValue(params: {
  categoryValues: CategoryValues
  categories: Categories[]
}) {
  const { categoryValues, categories } = params

  const categoryValue = getCategoryValueFromTree(categoryValues, categories)

  return categoryValue as number | null
}

const omitDeepArrayWalk = (
  arr: any[],
  key: string,
  keepOriginalObjFunc?: (obj: any, key: string) => boolean,
): any => {
  return arr.map((val) => {
    if (Array.isArray(val))
      return omitDeepArrayWalk(val, key, keepOriginalObjFunc)
    else if (typeof val === 'object')
      return omitDeep(val, key, keepOriginalObjFunc)
    return val
  })
}

export const omitDeep = (
  obj: any,
  key: string,
  keepOriginalObjFunc?: (obj: any, key: string) => boolean,
) => {
  const keys = Object.keys(obj)

  if (
    typeof keepOriginalObjFunc === 'function' &&
    keepOriginalObjFunc(obj, key)
  ) {
    return obj
  }

  const newObj: any = {}

  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i]
      if (val instanceof Date) newObj[i] = val
      else if (Array.isArray(val))
        newObj[i] = omitDeepArrayWalk(val, key, keepOriginalObjFunc)
      else if (typeof val === 'object' && val !== null)
        newObj[i] = omitDeep(val, key, keepOriginalObjFunc)
      else newObj[i] = val
    }
  })

  return newObj
}

export function dayDiffNow(date: Date) {
  const nowUtc = dayjs()
  const dateUtc = dayjs(date)

  const diff = nowUtc.diff(dateUtc, 'day')

  return diff
}

export function buildStoreboxTransportData(
  transportForm: Required<TransportFormStateStorebox>,
) {
  return {
    transportMode: ETransportMode.Storebox,
    storeboxData: {
      address: transportForm.address,
      slot: {
        start: transportForm.slot?.start ?? '',
        end: transportForm.slot?.end ?? '',
      },
    },
  }
}

export function buildTransportFromForm(
  transportData: Required<TransportFormState>,
) {
  switch (transportData.transportMode) {
    case ETransportMode.StandardShipment: {
      return {
        transportMode: ETransportMode.StandardShipment,
        standardShippingData: {
          address: transportData.address,
        },
      }
    }
    case ETransportMode.Shop: {
      return {
        transportMode: ETransportMode.Shop,
      }
    }
    case ETransportMode.PremiumShipment: {
      return {
        transportMode: ETransportMode.PremiumShipment,
        premiumShippingData: {
          address: transportData.address,
          date: dayjs(transportData.transportDate.date, 'DD.MM.YYYY').toDate(),
          fromHourUTC: Number(transportData.transportDate.hour),
          toHourUTC: Number(transportData.transportDate.hour) + 2,
        },
      }
    }
  }
}

export const getPaybackInformation = (
  paymentType: EAutomaticPaymentType | EManualPaymentType,
  companyData?: Pick<Company, 'iban' | 'paypalEmail'>,
) => {
  switch (paymentType) {
    case EManualPaymentType.Bank:
      return companyData && companyData.iban ? companyData.iban : 'none'
    case EManualPaymentType.Paypal:
      return companyData && companyData.paypalEmail
        ? companyData.paypalEmail
        : 'none'
    default:
      return 'none'
  }
}

export function getStringBetween(value: string, start: string, end: string) {
  return value.substring(
    value.indexOf(start) + start.length,
    value.indexOf(end),
  )
}

export function isManualPaymentType(
  paymentType: EManualPaymentType | EAutomaticPaymentType,
): paymentType is EManualPaymentType {
  const manualPaymentTypes = Object.values(EManualPaymentType)
  return (
    manualPaymentTypes as (
      | (typeof manualPaymentTypes)[number]
      | EAutomaticPaymentType
    )[]
  ).includes(paymentType)
}

export function useLocalStorage<S>(
  key: string,
  initialValue?: S,
): [S, Dispatch<SetStateAction<S>>] {
  const [storedValue, setStoredValue] = useState<S>(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }

    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.log(error)
      return initialValue
    }
  })
  const setValue: Dispatch<SetStateAction<S>> = (
    value: S | SetStateAction<S>,
  ) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      console.log(error)
    }
  }

  return [storedValue, setValue]
}
