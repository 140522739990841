import styled from 'styled-components'
import Text from '@/style/components/LegacyText'
import { media } from '@/style/helpers'
import { hoverShadowBox } from '@/style/partials'

// TODO: remove bottom 1 rem once bis zu prices are added
export const Wrapper = styled.a`
  display: block;
  position: relative;
  width: calc(50% - 0.6rem);
  margin: 0.3rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radius.normal}px;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  color: inherit;
  ${hoverShadowBox}

  ${media.sm} {
    width: calc(33.3333% - 1rem);
    margin: 0.5rem;
    padding: 0.8rem 0.8rem 0.8rem 0.8rem;
  }

  ${media.md} {
    width: calc(20% - 1.5rem);
    margin: 0.75rem;
  }

  h3 {
    margin: 0;
  }

  p {
    margin: 1rem 0;
  }
`

export const ImageWrapper = styled(Text.p).attrs({ as: 'div' })``
