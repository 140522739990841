import Link from 'next/link'
import { ParsedUrlQueryInput } from 'querystring'
import React, { FunctionComponent, useMemo } from 'react'
import TextTruncate from 'react-text-truncate'
import styled from 'styled-components'
import { UrlObject } from 'url'
import useResponsive_CAN_CAUSE_CLS from '@/helpers/useResponsive'
import Text from '@/style/components/LegacyText'
import { media } from '@/style/helpers'
import { ImageWrapper, Wrapper } from './ObjectListItemStyles'

interface ObjectListItemProps {
  title: string
  Image?: JSX.Element // TODO: change
  href: UrlObject
  badge?: string
  ribbon?: string | false
  onClick?: () => void
}

const Ribbon = styled(Text.ms)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: 1rem;
  padding: 0.3rem 0.75rem 0.2rem;
  line-height: 1em;
  left: 0;
`

const ObjectListItemTitle = styled(Text.ms)`
  font-size: 0.8rem;

  ${media.xs} {
    font-size: 0.875rem !important;
  }

  ${media.sm} {
    font-size: 1rem !important;
  }

  ${media.lg} {
    font-size: 1.125rem !important;
  }
`

// TODO: Add ProductJsonLd from next-seo.

const ObjectListItem: FunctionComponent<ObjectListItemProps> = ({
  title,
  Image,
  href,
  ribbon,
  onClick,
}) => {
  const { activeBreakpoints } = useResponsive_CAN_CAUSE_CLS()

  const truncateLines = activeBreakpoints.includes('md')
    ? 4
    : activeBreakpoints.includes('xs')
      ? 5
      : 7
  const carProps = useMemo(() => {
    const categorySlug = (href.query as ParsedUrlQueryInput).categorySlug

    if (categorySlug === 'car')
      return { href: '/car', query: undefined, hash: undefined }

    if (categorySlug === 'motorrad')
      return { href: '/motorrad', query: undefined, hash: undefined }

    return {}
  }, [href.query])

  return (
    <Link href={href} {...carProps} passHref legacyBehavior>
      <Wrapper
        onClick={() => {
          if (typeof onClick === 'function') {
            onClick()
          }
        }}
      >
        <ImageWrapper>{Image}</ImageWrapper>
        <ObjectListItemTitle
          as="h3"
          weight="semibold"
          style={{ wordBreak: 'break-word' }}
        >
          {/* {_text} */}
          <TextTruncate
            line={truncateLines}
            element="span"
            truncateText="…"
            text={title}
          />
        </ObjectListItemTitle>
        {ribbon && <Ribbon weight="medium">{ribbon}</Ribbon>}
        <div style={{ marginTop: '1.3rem' }} />
      </Wrapper>
    </Link>
  )
}

export default ObjectListItem
