import styled, { css } from 'styled-components'
import { mediaFromTo } from '@/style/helpers/media'
import Button from './UnstyledButton'

interface ButtonGroupProps {
  size?: number
  children: React.ReactNode | React.ReactNode[]
}

const ButtonGroup = styled.div<ButtonGroupProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  ${({ size }) =>
    size &&
    size > 2 &&
    css`
      flex-direction: column;

      ${Button} {
        &:not(:first-child) {
          border-top-left-radius: ${({ theme }) => theme.radius.normal}px;
          border-top-right-radius: ${({ theme }) => theme.radius.normal}px;
          border-top-width: 1px;
        }

        &:not(:last-child) {
          border-bottom-left-radius: ${({ theme }) => theme.radius.normal}px;
          border-bottom-right-radius: ${({ theme }) => theme.radius.normal}px;
          border-bottom-width: 1px;
        }
      }
    `}

  ${Button} {
    flex: 1;

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left-width: 1px;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 1px;
    }

    ${mediaFromTo('xs', 'md')} {
      &:first-child:nth-last-child(n + 3),
      &:first-child:nth-last-child(n + 3) ~ button {
        width: 100% !important;
        flex: 100%;
        border-radius: 0;
        border-left-width: 2px;
        border-right-width: 2px;
        border-top-width: 1px;
        border-bottom-width: 1px;

        &:first-child {
          border-top-left-radius: ${({ theme }) => theme.radius.normal}px;
          border-top-right-radius: ${({ theme }) => theme.radius.normal}px;
          border-top-width: 2px;
        }

        &:last-child {
          border-bottom-left-radius: ${({ theme }) => theme.radius.normal}px;
          border-bottom-right-radius: ${({ theme }) => theme.radius.normal}px;
          border-bottom-width: 2px;
        }
      }
    }
  }
`

export default ButtonGroup
