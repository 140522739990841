import styled from 'styled-components'
import Text from '@/style/components/LegacyText'
import { media } from '@/style/helpers'

const Wrapper = styled.div`
  margin: 3rem 2rem;
  width: 60vw;

  ${media.sm} {
    width: 50vw;
  }
  ${media.md} {
    width: 40vw;
  }
  ${media.lg} {
    width: 30vw;
  }
`

type InformationModalProps = {
  message: string | JSX.Element
}

export const InformationDialog: React.FC<InformationModalProps> = (props) => {
  return (
    <Wrapper>
      <Text.md>{props.message}</Text.md>
    </Wrapper>
  )
}
