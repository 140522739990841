import styled, { css } from 'styled-components'
import { media } from '@/style/helpers'

interface ItemProps {
  textAlign?: 'left' | 'center' | 'right'
  sizing?: 'normal' | 'wide' | 'narrow' | 'full' | 'near-wide'
  spacingBefore?: boolean
}

const SectionInner = styled.section.attrs<ItemProps>((props) => ({
  textAlign: props.textAlign,
  sizing: props.sizing,
  spacingBefore: props.spacingBefore || false,
}))<ItemProps>`
  max-width: ${({ sizing }) =>
    sizing === 'wide'
      ? '1500px'
      : sizing === 'near-wide'
        ? '1200px'
        : sizing === 'narrow'
          ? '800px'
          : sizing === 'full'
            ? '100%'
            : '1000px'};
  margin: 0 auto;
  position: relative;
  padding: 0 1.5rem;

  ${media.xs} {
    padding: 0 2.5rem;
  }

  ${({ sizing }) =>
    sizing === 'full' &&
    css`
      padding: 0 1rem !important;
    `}

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `}

  ${({ spacingBefore }) =>
    spacingBefore &&
    css`
      margin-top: 2rem;
    `}
`

export default SectionInner
