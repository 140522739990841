import React, { FunctionComponent, useEffect, useState } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import useWindowDimensions_CAN_CAUSE_CLS from '@/helpers/useWindowDimensions'
import Text from '@/style/components/LegacyText'
import { media } from '@/style/helpers'

interface ConfiguratorHeadlineProps {
  image: JSX.Element
  categoryHeadline: string
  productHeadline: string
  productSubhead?: string
}

const CenteredRow = styled(Row)`
  align-items: center;

  h2 {
    margin-bottom: 0.5rem;
  }

  svg,
  img {
    width: 80%;
    height: auto;
    margin: 0 auto;
  }
`

const MobileWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &::before {
    position: absolute;
    display: block;
    content: '';
    bottom: -3rem;
    left: -1.5rem;
    right: -1.5rem;
    height: 15rem;
    background-color: ${({ theme }) => theme.colors.buttonBorder};
    z-index: -1;
    border-top-left-radius: ${({ theme }) => theme.radius.semiLarge}px;
    border-top-right-radius: ${({ theme }) => theme.radius.semiLarge}px;

    ${media.xs} {
      left: -2.5rem;
      right: -2.5rem;
      border-top-left-radius: ${({ theme }) => theme.radius.large}px;
      border-top-right-radius: ${({ theme }) => theme.radius.large}px;
    }

    ${media.md} {
      display: none;
    }
  }

  > *:not(:last-child) {
    z-index: 1;
    margin-bottom: 1.5rem;
  }

  img,
  svg {
    height: auto;
    width: 50vw;
    max-width: 15rem;
  }
`

const ConfiguratorHeadline: FunctionComponent<ConfiguratorHeadlineProps> = ({
  image,
  categoryHeadline,
  productHeadline,
  productSubhead,
}) => {
  const [layout, setLayout] = useState('desktop')
  const { activeBreakpoints } = useWindowDimensions_CAN_CAUSE_CLS()

  useEffect(() => {
    if (activeBreakpoints.includes('md')) {
      setLayout('desktop')
    } else {
      setLayout('mobile')
    }
  }, [activeBreakpoints])

  if (layout === 'desktop') {
    return (
      <CenteredRow>
        <Col xs={12} md={5}>
          {image}
        </Col>
        <Col xs={12} md={7}>
          <Text.md as="h2" weight="semibold" transform="uppercase">
            {categoryHeadline}
          </Text.md>
          <Text.xl weight="bold" transform="uppercase">
            <Text.modify color="primary">{productHeadline}</Text.modify>
            <br />
            {productSubhead}
          </Text.xl>
        </Col>
      </CenteredRow>
    )
  }

  return (
    <MobileWrapper>
      <Text.md as="h2" weight="semibold" transform="uppercase">
        {categoryHeadline}
      </Text.md>
      {image}
      <Text.xxl weight="bold" transform="uppercase">
        <Text.modify color="primary">{productHeadline}</Text.modify>
        <br />
        {productSubhead}
      </Text.xxl>
    </MobileWrapper>
  )
}

export default ConfiguratorHeadline
