import React, { FunctionComponent } from 'react'
import LazyLoad from 'react-lazyload'
import { Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import { media } from '@/style/helpers'
import { AssetStoryblok } from '@/types/storyblok-component-types'

interface ImageProps {
  image: AssetStoryblok
}

const StepImageNormal: FunctionComponent<ImageProps> = ({ image }) => {
  const Image = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  `

  return (
    <ImageWrapper>
      <LazyLoad>
        <Image>
          <StoryblokImage
            image={image}
            sizes="(max-width: 1199px) 80vw, 100vw"
            priority
          />
        </Image>
      </LazyLoad>
    </ImageWrapper>
  )
}

const ImageWrapper = styled(Row)`
  width: 100%;
  text-align: left;

  background-color: ${({ theme }) => theme.colors.primary};
  clip-path: circle(50% at 50% 50%);
  overflow: hidden;
  position: relative;
  float: center;

  &::after {
    content: '';
    display: block;
    padding-top: 100%;
  }

  margin: 1rem auto;
  width: 65%;

  ${media.sm} {
    text-align: left;

    float: left;
    margin-left: 0rem;
    margin-right: 0rem;

    width: 90%;
  }

  ${media.md} {
    float: left;
    margin-left: -3rem;
    margin-right: 0rem;

    width: 100%;
  }
`

export default StepImageNormal
