import { createContext } from 'react'

const ActiveExperimentsContext = createContext<string[] | undefined>(undefined)

type Props = {
  children: React.ReactNode
  activeExperiments?: string[]
}

// This is just here because we think it might be useful in the future.
// It's not currently used anywhere. Make sure that when you start using it,
// ensure that `useContext(ActiveExperimentsContext)` is not undefined, e.g.
// because we use it on a page without `getServerSideProps`.
// TODO: Remove this comment when we start using this context
export function ActiveExperimentsProvider({
  activeExperiments,
  children,
}: Props) {
  return (
    <ActiveExperimentsContext.Provider value={activeExperiments}>
      {children}
    </ActiveExperimentsContext.Provider>
  )
}
