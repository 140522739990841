import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import StickyBox from 'react-sticky-box'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import LoadingOverlay from '@/app/common/components/LoadingOverlay/LoadingOverlay'
import { defaultMinimumItemValue } from '@/app/common/constants'
import { useQuery } from '@/app/common/graphql/hooks'
import { buildDealCalculationVariables } from '@/domains/checkout/checkout.service'
import useCompany from '@/helpers/useCompany'
import useWindowDimensions_CAN_CAUSE_CLS from '@/helpers/useWindowDimensions'
import { Button } from '@/style/components/Button'
import LegacyText from '@/style/components/LegacyText'
import { SectionInner } from '@/style/components/Section'
import Text from '@/style/components/Text'
import { media } from '@/style/helpers'
import { SelectedLegacyProduct } from '@/types'
import {
  DealCalculationDocument,
  DealCalculationQuery,
  DealCalculationQueryVariables,
  EDealType,
  GetParentCategoriesDocument,
  GetParentCategoriesQuery,
  GetParentCategoriesQueryVariables,
  ItemAnswer,
  ItemQuestion,
} from '@/types/gql/graphql'
import { isItemDysfunctional, printLocalFloat } from '@/utils/misc'

interface ConfiguratorSummaryProps {
  product: SelectedLegacyProduct
  itemQuestions: Omit<ItemQuestion, 'validFrom' | 'validTo'>[] | undefined
  itemAnswers: ItemAnswer[]
  checkoutDate: Date | undefined
  proceedToCheckout: () => Promise<void>
  skipItemCalculation?: boolean
}

const ConfiguratorSummary: FunctionComponent<ConfiguratorSummaryProps> = ({
  product,
  itemQuestions,
  itemAnswers,
  checkoutDate,
  proceedToCheckout,
  skipItemCalculation,
}) => {
  const { t } = useTranslation()
  const { activeBreakpoints } = useWindowDimensions_CAN_CAUSE_CLS()
  const [isDysfunctional, setDysfunctional] = useState(false)

  const company = useCompany()

  const dealCalculationResult = useQuery<
    DealCalculationQuery,
    DealCalculationQueryVariables
  >(DealCalculationDocument, {
    variables: buildDealCalculationVariables({
      companyId: company?._id,
      dealType: EDealType.Pawn,
      date: checkoutDate,
      items: [
        {
          answers: itemAnswers,
          algoliaReference: product.objectID,
          itemCategoryId: product.itemCategoryId,
          material: product.material,
          isInvestmentGold: product.isTaxFree,
        },
      ],
      durationInDays: company?.configuration.minimumPawnDuration,
    }),
    skip:
      !company ||
      !company?.configuration.minimumPawnDuration ||
      isDysfunctional ||
      !itemAnswers ||
      itemAnswers.length === 0 ||
      skipItemCalculation,
  })

  const getParentCategoriesResult = useQuery<
    GetParentCategoriesQuery,
    GetParentCategoriesQueryVariables
  >(GetParentCategoriesDocument, {
    variables: {
      itemCategoryId: product.itemCategoryId,
    },
    skip: !product.itemCategoryId,
    enableCaching: true,
  })

  const itemValue =
    dealCalculationResult?.data?.dealCalculation?.dealValuesEntry?.payoutAmount

  const isUnderMinimumItemValue = useMemo(() => {
    if (!itemValue) {
      return false
    }

    return (
      itemValue <
      (product?.itemCategory?.recursiveMinimumItemValue ??
        defaultMinimumItemValue)
    )
  }, [itemValue, product])

  const parentCategories = getParentCategoriesResult.data?.getParentCategories

  useEffect(() => {
    if (
      itemAnswers &&
      itemQuestions &&
      itemAnswers.length === itemQuestions.length
    ) {
      if (
        isItemDysfunctional({
          answers: itemAnswers,
          categories: parentCategories ?? [],
          itemQuestions: itemQuestions,
        })
      ) {
        setDysfunctional(true)
      } else {
        setDysfunctional(false)
      }
    }
  }, [itemAnswers, itemQuestions, parentCategories, setDysfunctional])

  const displayedValue = (itemValue: number | undefined) => {
    if (itemValue === undefined) return ''

    return isUnderMinimumItemValue ? '- ' : printLocalFloat(itemValue, 0)
  }

  const isDisabled =
    isDysfunctional || itemValue === 0 || isUnderMinimumItemValue

  return (
    <StickyBox offsetTop={activeBreakpoints.includes('md') ? 142 : 99}>
      <SummaryOuterWrapper sizing="narrow">
        <SummaryWrapper>
          {dealCalculationResult.isFetching && (
            <LoadingOverlay fullScreen={false} />
          )}

          <TextWrapper>
            <Text.h6 noMargin>
              {isDysfunctional
                ? t('items:only_functioning_items_accepted')
                : itemValue === 0
                  ? t('items:no_payout_possible')
                  : t('items:summary.headline')}
            </Text.h6>
          </TextWrapper>

          <PriceContainer>
            <Row>
              {activeBreakpoints.includes('md') ? (
                <Col xs={12}>
                  <SummaryTotal weight="bold">
                    {displayedValue(itemValue)}
                  </SummaryTotal>
                  <SummarySymbol>€</SummarySymbol>
                </Col>
              ) : (
                <Col xs={12}>
                  <SummaryTotal2 weight="bold">
                    {displayedValue(itemValue)}
                  </SummaryTotal2>
                  <SummarySymbol>€</SummarySymbol>
                </Col>
              )}
            </Row>
            {isUnderMinimumItemValue && (
              <ErrorDescription>
                {t('items:under_minimum_item_value')}
              </ErrorDescription>
            )}
          </PriceContainer>

          <PriceContainer>
            <SubmitButton
              id={`ITEM_PAGE_${product.slug}_NEXT_BUTTON`}
              primary={!isDisabled}
              full
              disabled={isDisabled}
              onClick={proceedToCheckout}
            >
              {t('items:summary.button_label')}
            </SubmitButton>
            {!isDisabled && (
              <LegacyText.p align="center">
                <LegacyText.sm color="light">
                  {t('items:summary.button_description')}
                </LegacyText.sm>
              </LegacyText.p>
            )}
          </PriceContainer>
        </SummaryWrapper>
      </SummaryOuterWrapper>
    </StickyBox>
  )
}

const SubmitButton = styled(Button)`
  :disabled {
    opacity: 0.2;
  }
  font-size: 0.875rem;
  ${media.md} {
    font-size: 1rem;
  }
`

const SummaryOuterWrapper = styled(SectionInner)`
  position: relative;

  > * {
    z-index: 1;
    position: relative;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 2rem;
    right: 0;
    z-index: -1;
    background-color: ${({ theme }) => theme.colors.buttonBorder};
    border-bottom-left-radius: ${({ theme }) => theme.radius.semiLarge}px;
    border-bottom-right-radius: ${({ theme }) => theme.radius.semiLarge}px;

    ${media.xs} {
      border-bottom-left-radius: ${({ theme }) => theme.radius.large}px;
      border-bottom-right-radius: ${({ theme }) => theme.radius.large}px;
    }
  }

  ${media.md} {
    padding: 0 !important;

    &::after {
      display: none;
    }
  }
`

const TextWrapper = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0px;
`

const SummaryWrapper = styled.aside`
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
  padding: 1rem 0;
  border-radius: ${({ theme }) => theme.radius.normal}px;
  background-color: ${({ theme }) => theme.colors.white};

  ${media.md} {
    max-width: 25rem;
    margin: 3rem 2rem 2rem;
  }
`

const SummarySymbol = styled.div`
  font-size: 2rem;
  font-weight: 700;
  display: inline-block;
  ${media.md} {
    font-size: 2.375rem;
  }
`

const PriceContainer = styled.div`
  padding: 0 2rem;

  ${Row} {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0.5rem;
      right: 0.5rem;
    }
  }

  ${Col} {
    position: relative;
    text-align: center;

    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        display: block;
        right: 0.5rem;
        bottom: 0.5rem;
        height: 2rem;
        border-right: 1px solid ${({ theme }) => theme.colors.border};
      }
    }
  }

  p {
    margin: 0.5em 0;
    line-height: 1.2;
  }

  button {
    margin-top: 1.5rem;
  }
`

const ErrorDescription = styled.section`
  font-size: 0.8rem;
  text-align: left;
  padding: 0 0.3rem;
  font-weight: 500;
  color: red;
  ${media.md} {
    padding: 0 1rem;
  }
`

const SummaryTotal = styled(LegacyText.xxxl)`
  font-size: 3.25;
  ${media.md} {
    font-size: 3.125rem;
  }
`
const SummaryTotal2 = styled(LegacyText.x4l)`
  font-size: 3.25;
  ${media.md} {
    font-size: 3.125rem;
  }
`

export default ConfiguratorSummary
