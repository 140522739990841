import {
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
  FormikConfig,
  FormikErrors,
  FormikState,
  FormikTouched,
  FormikValues,
  useFormik,
} from 'formik'
import { useEffect } from 'react'
import { SetterOrUpdater } from 'recoil'
import { useDebouncedCallback } from '@/helpers/useDebouncedCallback'

const useForm = <Values extends FormikValues = FormikValues>(
  formikConfig: UseFormConfig<Values>,
) => {
  const formikProps = useFormik({
    ...formikConfig,
    onSubmit: formikConfig.onSubmit,
    initialValues: {
      ...formikConfig.initialState,
      ...(formikConfig?.syncWithRecoil
        ? (formikConfig?.syncWithRecoil[0] as any)
        : {}),
    },
  })

  const onChangeDebounced = useDebouncedCallback((values: Values) => {
    if (formikConfig.onChangeDebounced) {
      formikConfig.onChangeDebounced.onChange(values)
    }
  }, formikConfig.onChangeDebounced?.delay ?? 0)

  /* const onChangeDebounced = formikConfig.onChangeDebounced 
    ? debounce((values: Values) => {
        if(formikConfig.onChangeDebounced) {
          formikConfig.onChangeDebounced.onChange(values)
        }
      }, formikConfig.onChangeDebounced.delay)
    : null */

  /* useEffect(() => {
    if (formikConfig.syncWithRecoil) {
      formikProps.setValues({ ...formikConfig.initialState, ...formikConfig.syncWithRecoil[0] })
    } else if (formikConfig.initialState) {
      formikProps.setValues(formikConfig.initialState)
    }
  }, [formikConfig.initialState]) */

  useEffect(() => {
    if (formikConfig.onChange) formikConfig.onChange(formikProps.values)

    if (onChangeDebounced) {
      onChangeDebounced[0](formikProps.values)
    }

    if (formikConfig.syncWithRecoil) {
      formikConfig.syncWithRecoil[1]((old) => ({
        ...old,
        ...formikProps.values,
      }))
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikProps.values])

  return formikProps
}

export default useForm

interface UseFormConfig<Values>
  extends Omit<FormikConfig<Values>, 'initialValues'> {
  onChange?: (values: Values) => void
  onChangeDebounced?: {
    delay: number
    onChange: (values: Values) => void
  }
  syncWithRecoil?: [Values, SetterOrUpdater<Values>]
  initialState?: Values
}

export type UseFormReturnType<Values> = {
  initialValues: Values
  initialErrors: FormikErrors<unknown>
  initialTouched: FormikTouched<unknown>
  initialStatus: any
  handleBlur: (eventOrString: any) => void | ((e: any) => void)
  handleChange: (
    eventOrPath: string | React.ChangeEvent<any>,
  ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void)
  handleReset: (e: any) => void
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  resetForm: (nextState?: Partial<FormikState<Values>> | undefined) => void
  setErrors: (errors: FormikErrors<Values>) => void
  setFormikState: (
    stateOrCb:
      | FormikState<Values>
      | ((state: FormikState<Values>) => FormikState<Values>),
  ) => void
  setFieldTouched: (
    field: string,
    touched?: boolean,
    shouldValidate?: boolean | undefined,
  ) => any
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => any
  setFieldError: (field: string, value: string | undefined) => void
  setStatus: (status: any) => void
  setSubmitting: (isSubmitting: boolean) => void
  setTouched: (
    touched: FormikTouched<Values>,
    shouldValidate?: boolean | undefined,
  ) => any
  setValues: (values: Values, shouldValidate?: boolean | undefined) => any
  submitForm: () => Promise<void | undefined>
  validateForm: (values?: Values) => Promise<FormikErrors<Values>>
  validateField: (name: string) => Promise<void> | Promise<string | undefined>
  isValid: boolean
  dirty: boolean
  unregisterField: (name: string) => void
  registerField: (name: string, { validate }: any) => void
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>
  getFieldMeta: (name: string) => FieldMetaProps<any>
  getFieldHelpers: (name: string) => FieldHelperProps<any>
  validateOnBlur: boolean
  validateOnChange: boolean
  validateOnMount: boolean
  values: Values
  errors: FormikErrors<Values>
  touched: FormikTouched<Values>
  isSubmitting: boolean
  isValidating: boolean
  status?: any
  submitCount: number
}
