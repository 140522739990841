import styled from 'styled-components'
import { media } from '@/style/helpers'
import { FactsItemStoryblok } from '@/types/storyblok-component-types'

export default function FactsItem({ blok }: { blok: FactsItemStoryblok }) {
  return (
    <Wrapper>
      <Heading>{blok.heading}</Heading>
      <Text>{blok.text}</Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem auto;

  ${media.sm} {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 2rem auto;
  }
`

const Heading = styled.h2`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
`

const Text = styled.h5`
  font-size: 23px;
  line-height: 32px;
  text-align: center;
`
