import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Pagination } from '@/app/common/components'
import { media } from '@/style/helpers'
import { CardsGridStoryblok } from '@/types/storyblok-component-types'

interface CardsGridProps {
  blok: CardsGridStoryblok
  sectionAnchorId?: string
}

export default function CardsGrid({ blok, sectionAnchorId }: CardsGridProps) {
  const [page, setPage] = useState(1)
  const router = useRouter()

  const hasContactLink = !!(blok.contact_link?.id || blok.contact_link?.url)

  const totalItems = blok.items.length + (hasContactLink ? 1 : 0)

  const maximumItemsPerPage = Number.parseInt(blok.maximum_products_per_page)
  const itemsPerRow = getItemsPerRow(totalItems)

  const totalPages = Math.max(Math.ceil(totalItems / maximumItemsPerPage), 1)

  const items = useMemo(() => {
    if (totalPages === 1) return blok.items

    const start = (page - 1) * maximumItemsPerPage
    const end = start + maximumItemsPerPage

    return blok.items.slice(start, end)
  }, [blok?.items, totalPages, page, maximumItemsPerPage])

  const handleChangePage = (v: number) => {
    setPage(v)
    router.push({ hash: `#${sectionAnchorId}` })
  }

  const { t } = useTranslation()
  return (
    <Container {...storyblokEditable(blok)}>
      <Caption>{blok.caption}</Caption>
      <Wrapper
        numberItemsPerRow={itemsPerRow}
        display={totalItems < itemsPerRow ? 'flex' : 'grid'}
      >
        {items?.map((r) => {
          return (
            <StoryblokComponent
              smallerImage={blok.smaller_image}
              blok={r}
              key={r._uid}
            />
          )
        })}

        {hasContactLink && page === totalPages && (
          <StoryblokComponent
            blok={{
              _uid: `${blok}_nothing_found_blok`,
              component: 'cards-grid-item',
            }}
            nothingFound={{
              link: blok.contact_link,
              caption: t('common:contact_us') + '!',
              description: t('common:found_nothing_appropriate') + '?',
            }}
          />
        )}
      </Wrapper>

      {totalPages > 1 && (
        <Pagination
          variant="outlined"
          shape="rounded"
          siblingCount={0}
          count={totalPages}
          page={page}
          onChange={(_, v) => handleChangePage(v)}
        />
      )}
    </Container>
  )
}

const getItemsPerRow = (num: number) => (num > 8 || num === 5 ? 5 : 4)

const Container = styled.div`
  width: 100%;
`

const Wrapper = styled.div<{ numberItemsPerRow: number; display: string }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 0.5rem;
  margin-top: 3rem;

  > a,
  > div {
    margin: 0;
    width: 100%;
  }

  ${media.md} {
    display: ${({ display }) => display || 'grid'};
    ${({ display, numberItemsPerRow }) =>
      display === 'flex' &&
      `
        justify-content: center;
        & > div {
          max-width: ${100 / numberItemsPerRow}%
        }
      `}
    grid-template-columns: repeat(${({ numberItemsPerRow }) =>
      numberItemsPerRow}, minmax(0, 1fr));
    grid-gap: 1.5rem;
  }
`

const Caption = styled.h2`
  text-align: center;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
  letter-spacing: 0em;

  ${media.xs} {
    font-size: 1rem;
  }
`
