import { storyblokEditable } from '@storyblok/react'
import React, { FunctionComponent } from 'react'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import { EVehicleCategory } from '@/types'
import { CarConfiguratorStoryblok } from '@/types/storyblok-component-types'
import VehicleDealBooking from './VehicleDealBooking'

const CarConfigurator: FunctionComponent<{
  blok: CarConfiguratorStoryblok
}> = ({ blok }) => {
  return (
    <VehicleDealBooking
      {...storyblokEditable(blok)}
      id={blok.id}
      vehicleCategory={
        blok.vehicle_type === 'motor'
          ? EVehicleCategory.MOTORCYCLE
          : EVehicleCategory.CAR
      }
      onlyCustomRequest={blok.vehicle_type === 'motor'}
      documentLinks={{
        termsOfConditionsLink: getStoryBlokLink(blok.terms_of_conditions) ?? '',
        privacyPolicyLink: getStoryBlokLink(blok.privacy_policy),
        withdrawalForm: getStoryBlokLink(blok.withdrawal_form) ?? '',
        whatsappLink: getStoryBlokLink(blok.whatsapp_link),
        feeDocumentLink: getStoryBlokLink(blok.fee_document_link),
      }}
    />
  )
}

export default CarConfigurator
