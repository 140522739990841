import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { media } from '@/style/helpers'

interface ObjectListProps {
  hideElements?: boolean
  numberOfItems?: number
  children: React.ReactNode
}

const ObjectListWrapper = styled.div<{
  hideElements?: boolean
  numberItemsPerRow: number
}>`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 0.5rem;
  margin-bottom: 3rem;

  > a,
  > div {
    margin: 0;
    width: 100%;
  }

  ${media.md} {
    grid-template-columns: repeat(
      ${({ numberItemsPerRow }) => numberItemsPerRow},
      minmax(0, 1fr)
    );
    grid-gap: 1.5rem;
  }

  a {
    visibility: ${({ hideElements }) => (hideElements ? 'hidden' : 'visible')};
  }
`

export const getNumberItemsPerRow = (num?: number) => {
  if (!num || num > 8 || num === 5) return 5
  return 4
}

export const getSizeOfSectionInner = (num?: number) => {
  const numberItemsPerRow = getNumberItemsPerRow(num)
  return numberItemsPerRow === 5 ? 'wide' : 'near-wide'
}

const ObjectList: FunctionComponent<ObjectListProps> = ({
  children,
  hideElements,
  numberOfItems,
}) => {
  const numberItemsPerRow = getNumberItemsPerRow(numberOfItems)

  return (
    <ObjectListWrapper
      numberItemsPerRow={numberItemsPerRow}
      hideElements={hideElements}
    >
      {children}
    </ObjectListWrapper>
  )
}

export default ObjectList
