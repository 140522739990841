import { StoryblokComponent } from '@storyblok/react'
import styled from 'styled-components'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { media } from '@/style/helpers'
import { AboutPageStoryblok } from '@/types/storyblok-component-types'
import AboutPress from './AboutPress/AboutPress'
import Introduction from './Introduction/Introduction'
import MileStone from './MileStone/MileStone'
import Partners from './Partners/Partners'
import TeamMembers from './TeamMembers/TeamMembers'

interface AboutPageProps {
  blok: AboutPageStoryblok
}

export default function AboutPage({ blok }: AboutPageProps) {
  return (
    <>
      <MainWrapper>
        <Introduction
          topDesktopImage={blok.top_desktop_image}
          topMobileImage={blok.top_mobile_image}
          caption={blok.caption!}
        />

        <MileStone milestones={blok.milestones ?? []} />

        <TeamMembers
          caption={blok.members_caption}
          profileCards={blok.members_profile_cards}
        />

        <TeamMembers
          caption={blok.investors_caption}
          profileCards={blok.investors_profile_cards}
        />

        <CommitmentCaptionWrapper>
          <TextWrapper>
            <RichTextParagraphStyles>
              <StoryblokRichText document={blok.commitment_caption} />
            </RichTextParagraphStyles>
          </TextWrapper>
        </CommitmentCaptionWrapper>

        <Partners
          caption={blok.partners_caption}
          partnerCards={blok.partners_cards}
        />

        <AboutPress
          caption={blok.material_caption}
          items={blok.material_items}
        />
      </MainWrapper>
      {blok.shops_map?.map((shops_map) => (
        <StoryblokComponent blok={shops_map} key={shops_map._uid} />
      ))}
    </>
  )
}

const MainWrapper = styled.div`
  width: 100%;
  max-width: 1441px;
  margin: 0 auto;
  text-align: center;
`

const CommitmentCaptionWrapper = styled.div`
  margin: auto;
  text-align: center;
  align-content: center;

  ${media.sm} {
    margin: auto 5rem;
  }

  ${media.md} {
    margin: auto 7.5rem;
  }
`

const TextWrapper = styled.div`
  text-align: center;
  margin: auto;
  width: 75%;
  max-width: 22rem;
  margin-bottom: 4rem;

  ${media.xs} {
    max-width: 42rem;
  }

  ${media.md} {
    margin-bottom: 6rem;
  }
`
