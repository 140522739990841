import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { LoadingOverlay } from '@/app/common/components/LoadingOverlay'
import { media } from '@/style/helpers'
import { KnpDebt, SelectedItemsType } from '@/types'
import {
  DealValuesEntry,
  EDealType,
  TransportData,
  UsedFeeDefinition,
} from '@/types/gql/graphql'
import { PayoutDealValues } from './PayoutDealValues'

const OuterWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;

  > * {
    z-index: 1;
    position: relative;
  }
`

const Wrapper = styled.aside`
  ${media.md} {
    max-width: 25rem;
  }
`

interface DealValuesTableProps {
  dealValuesEntry?: DealValuesEntry | undefined
  appliedUsedFeeDefinitions?: UsedFeeDefinition[] | undefined
  transportData?: TransportData
  loading?: boolean
  dealType: EDealType
  dealItems?: Omit<SelectedItemsType, 'answers' | 'itemCategory'>[]
  customItemTitles?: string[]
  knpDebt?: KnpDebt | undefined
  showKnp?: boolean | undefined
}

export const StickyDealValues: FunctionComponent<DealValuesTableProps> = ({
  appliedUsedFeeDefinitions,
  dealValuesEntry,
  transportData,
  loading,
  dealType,
  dealItems,
  customItemTitles,
  knpDebt,
  showKnp,
}) => {
  return (
    <>
      <OuterWrapper>
        <Wrapper>
          {loading && <LoadingOverlay />}
          <PayoutDealValues
            dealValuesEntry={dealValuesEntry}
            appliedUsedFeeDefinitions={appliedUsedFeeDefinitions}
            transportData={transportData}
            dealType={dealType}
            customItemTitles={customItemTitles}
            dealItems={dealItems}
            knpDebt={knpDebt}
            showKnp={showKnp}
          />
        </Wrapper>
      </OuterWrapper>
    </>
  )
}
