import MoneySvg from '../../../app/common/assets/ill_money.svg'
import { Container } from '@material-ui/core'
import StickyBox from 'react-sticky-box'
import { Col, Row } from 'react-styled-flexboxgrid'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'
import { ClientOnly } from '@/app/common/components/ClientOnly'
import { StickyDealValues } from '@/app/common/components/DealValues'
import { CommissionDealValues } from '@/app/common/components/DealValues/CommissionValues'
import { selectedItemsState } from '@/domains/checkout/checkout.state'
import useResponsive_CAN_CAUSE_CLS from '@/helpers/useResponsive'
import { media } from '@/style/helpers'
import { SelectedItemsType } from '@/types'
import {
  DealValuesEntry,
  EDealType,
  TransportData,
  UsedFeeDefinition,
} from '@/types/gql/graphql'

const ContainerStyled = styled(Container)`
  @media screen and (min-width: 1440px) {
    max-width: 1440px !important;
  }
`

const RowStyled = styled(Row)`
  & > div {
    padding-top: 2rem;
    padding-bottom: 1rem;

    ${media.sm} {
      padding-bottom: 2rem;
    }

    ${media.md} {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }
`

const LeftCol = styled(Col)`
  ${media.sm} {
    display: flex;
    justify-content: center;
  }

  ${media.md} {
    flex-basis: auto;
    flex: 1;
    padding-left: 3rem;
    padding-right: 3rem;
    justify-content: flex-start;
  }

  ${media.lg} {
    flex-basis: 60%;
  }
`

const RightCol = styled(Col)`
  ${media.md} {
    flex-basis: 40%;
    padding-left: 3rem;
    padding-right: 3rem;
    display: flex;
    justify-content: center;
    border-left: 2px dashed #e5e7eb;
  }
`

const StickyBoxStyled = styled(StickyBox)`
  display: none;
  z-index: 1;

  ${media.md} {
    display: block;
  }
`

const MoneyWrapper = styled.div`
  margin-top: 10rem;
  width: 28rem;
  position: absolute;
  right: 0;
  overflow: hidden;
  z-index: 0;
  display: none;

  ${media.md} {
    display: block;
  }
`

const MoneySvgStyled = styled(MoneySvg)`
  margin-top: 0;
  width: 28rem;
  right: 0;
  transform: translate(50%);
  position: relative;
`

export const CustomDealFormBaseLayout: React.FC<{
  content: JSX.Element
  dealValuesEntry?: DealValuesEntry | undefined
  appliedUsedFeeDefinitions?: UsedFeeDefinition[]
  parentFeeInfoTable?: string
  loading?: boolean
  transportData?: TransportData
  dealType: EDealType
  displayMoneySvg?: boolean
  customItemTitles?: string[]
  ssr?: boolean
  className?: string
  isCommissionSale: boolean
}> = ({
  content,
  dealValuesEntry,
  appliedUsedFeeDefinitions,
  loading,
  transportData,
  dealType,
  displayMoneySvg,
  customItemTitles,
  ssr,
  className,
  isCommissionSale,
}) => {
  const { activeBreakpoints } = useResponsive_CAN_CAUSE_CLS()
  const selectedItems: SelectedItemsType[] = useRecoilValue(selectedItemsState)

  return (
    <ClientOnly ssr={ssr}>
      <ContainerStyled>
        <RowStyled className={className}>
          <LeftCol xs={12}>{content}</LeftCol>
          <RightCol xs={12}>
            <StickyBoxStyled
              offsetTop={activeBreakpoints.includes('md') ? 179 : 99}
            >
              {!isCommissionSale ? (
                <StickyDealValues
                  dealType={dealType}
                  dealValuesEntry={dealValuesEntry}
                  appliedUsedFeeDefinitions={appliedUsedFeeDefinitions}
                  loading={loading}
                  transportData={transportData}
                  dealItems={selectedItems}
                  customItemTitles={customItemTitles}
                />
              ) : (
                <CommissionDealValues />
              )}
            </StickyBoxStyled>

            {displayMoneySvg && (
              <MoneyWrapper>
                <MoneySvgStyled />
              </MoneyWrapper>
            )}
          </RightCol>
        </RowStyled>
      </ContainerStyled>
    </ClientOnly>
  )
}
