import { FormQuestions } from '../AdditionQuestions/FormQuestions'
import { CategorySelection } from '../CategorySelection'
import { Typography } from '@material-ui/core'
import { FormikProps } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import { Fragment, useMemo } from 'react'
import { SmartSelectInput } from '@/app/common/components/Form'
import Uploader from '@/app/common/components/UppyUploader'
import { ItemCategory } from '@/types/gql/graphql'
import { printLocalAmount } from '@/utils/misc'
import {
  CustomDealItemPreview,
  CustomDealItemWrapper,
  DeleteButton,
  EditButton,
  ItemHeading,
  ItemName,
  ItemNo,
  ItemPayoutAmount,
  No,
  SaveButton,
} from './styles'

interface Props {
  item: any
  formikProps: FormikProps<any>
  index: number
  topLevelCategories: Pick<ItemCategory, 'name' | '_id'>[]
  onRemoveItem: () => void
  onSaveItem: () => void
  numberOfItems: number
  isEdited: boolean
  isNewItem: boolean
  isError: boolean
  onEditItemIndex: (index: number) => void
  allowOpenCategorySelection?: boolean
  isCommissionSale: boolean
  onIsCommissionSaleChange: (isCommissionSale: boolean) => void
}

export const CustomDealItem = ({
  item,
  topLevelCategories,
  index,
  onRemoveItem,
  onSaveItem,
  formikProps,
  numberOfItems,
  isEdited,
  isNewItem,
  isError,
  onEditItemIndex,
  allowOpenCategorySelection,
  isCommissionSale,
  onIsCommissionSaleChange,
}: Props) => {
  const { t } = useTranslation()

  const isMoreThanOneItem = numberOfItems > 1

  const categoryOptions = useMemo(
    () =>
      topLevelCategories.map((category) => ({
        value: category._id,
        label: category.name,
      })),
    [topLevelCategories],
  )

  const onChangeCategory = (id?: string) => {
    formikProps.setFieldValue(`items.[${index}].itemCategoryId`, id)
  }

  const showPreview = !isEdited && isMoreThanOneItem

  return (
    <Fragment>
      <CustomDealItemPreview isError={isError} show={showPreview}>
        <ItemHeading>
          <div>
            <No>{index + 1}.</No>
            <ItemName>{item.title}</ItemName>
          </div>
          <ItemPayoutAmount>
            {printLocalAmount({
              number: item.payoutAmount ?? 0,
              removeSpaces: true,
            })}
          </ItemPayoutAmount>
        </ItemHeading>
        <EditButton onClick={() => onEditItemIndex(index)}>
          {t('customDeal:edit_item')}
        </EditButton>
      </CustomDealItemPreview>
      <CustomDealItemWrapper show={!showPreview}>
        {isMoreThanOneItem && (
          <ItemHeading>
            <ItemNo>{t('customDeal:item_no', { no: index + 1 })}</ItemNo>
            <DeleteButton onClick={onRemoveItem}>
              <span>{t('customDeal:remove_item')}</span>
            </DeleteButton>
          </ItemHeading>
        )}
        {allowOpenCategorySelection ? (
          <CategorySelection
            itemCategoryId={item.itemCategoryId}
            onChange={onChangeCategory}
            input={
              <SmartSelectInput
                id={`CUSTOM_DEAL_ITEM_${index}_CATEGORY_SELECTION`}
                formikProps={formikProps}
                name={`items.${index}.itemCategoryId`}
                label={t('common:category')}
                options={categoryOptions}
                marginBottom="1rem"
              />
            }
          />
        ) : (
          <SmartSelectInput
            id={`CUSTOM_DEAL_ITEM_${index}_CATEGORY_SELECTION`}
            formikProps={formikProps}
            name={`items.${index}.itemCategoryId`}
            label={t('common:category')}
            options={categoryOptions}
            marginBottom="1rem"
          />
        )}
        <FormQuestions
          isCommissionSale={isCommissionSale}
          onIsCommissionSaleChange={onIsCommissionSaleChange}
          formikProps={formikProps}
          index={index}
          categoryId={item.itemCategoryId}
          formAnswers={item.formAnswers}
        />
        <Uploader
          id={`CUSTOM_DEAL_ITEM_${index}_UPLOAD_INPUT`}
          label={
            <Typography style={{ fontWeight: 500, marginLeft: 10 }}>
              {t('customDeal:upload_photos')}
            </Typography>
          }
          formikProps={formikProps}
          name={`items.${index}.uploadedFiles`}
        />
        {isMoreThanOneItem && (
          <SaveButton type="button" onClick={onSaveItem} full>
            {t(isNewItem ? 'customDeal:add_item' : 'customDeal:update_item')}
          </SaveButton>
        )}
      </CustomDealItemWrapper>
    </Fragment>
  )
}
