import DefaultLanguageIcon from '../../assets/languageSelector/vector.svg'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { hideFrom } from '@/style/helpers/media'
import { Language } from './languages'

interface SmallDevicesLanguageSelectorProps {
  selectedLanguage: Language | undefined
  handleLocaleChange: (locale: string) => void
  languages: Language[] | undefined
  isInFooter?: boolean
  footerContentColor: string | undefined
  footerBackgroundColor?: string | undefined
}

const SmallDevicesLanguageSelector = ({
  selectedLanguage,
  handleLocaleChange,
  languages,
  isInFooter,
  footerContentColor,
  footerBackgroundColor,
}: SmallDevicesLanguageSelectorProps) => {
  const { locale } = useRouter()

  return (
    <Container $isFooter={isInFooter}>
      <Select
        id="language-select"
        value={locale}
        onChange={(e) => handleLocaleChange(e.target.value)}
      >
        {languages?.map((lang) => (
          <option key={lang.locale} value={lang.locale}>
            {lang.name}
          </option>
        ))}
      </Select>
      <Label
        htmlFor="language-select"
        $color={footerContentColor}
        $backgroundColor={footerBackgroundColor}
        $isFooter={isInFooter}
      >
        {isInFooter ? (
          <>
            <DefaultLanguageIcon className="footer-icon" />
            {selectedLanguage?.name}
          </>
        ) : (
          <IconWrapper>
            {selectedLanguage && (
              <selectedLanguage.Flag width={24} height={24} />
            )}
          </IconWrapper>
        )}
      </Label>
    </Container>
  )
}

const Container = styled.div<{ $isFooter?: boolean }>`
  ${hideFrom('md')}
  position: relative;
  width: ${({ $isFooter }) => ($isFooter ? 'auto' : '50px')};
  height: ${({ $isFooter }) => ($isFooter ? 'auto' : '100%')};
  margin-left: ${({ $isFooter }) => ($isFooter ? '1.5rem' : '0')};

  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`

const Select = styled.select`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`
const IconWrapper = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  overflow: hidden;
  border-radius: 6.25rem;
  display: inline-block;
`
const Label = styled.label<{
  $color: string | undefined
  $backgroundColor: string | undefined
  $isFooter?: boolean
}>`
  display: flex;
  align-items: center;
  color: ${({ $color }) => ($color ? $color : '#ffffff')};
  column-gap: 0.5rem;
  cursor: pointer;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 6.25rem;
  cusrsor: pointer;

  .footer-icon {
    fill: ${({ $color }) => ($color ? $color : '#ffffff')};
  }

  ${({ $isFooter, $backgroundColor, $color }) =>
    $isFooter &&
    `
    &:hover {
      background: ${$color};
      color: ${$backgroundColor};
    }

    &:hover .footer-icon {
      fill: ${$backgroundColor};
    }

    &:active {
    background: ${$color};
      color: ${$backgroundColor};
    }

    &:active .footer-icon {
      fill: ${$backgroundColor};
    }
  `}
`

export default SmallDevicesLanguageSelector
