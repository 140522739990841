import { Container } from '@material-ui/core'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import Text from '@/style/components/LegacyText'
import { media } from '@/style/helpers'
import { CustomDealFormStoryblok } from '@/types/storyblok-component-types'

interface Props {
  blok: Pick<
    CustomDealFormStoryblok,
    'background_color' | 'title' | 'sub_title' | 'product_image'
  >
}

export const CustomDealHero = ({ blok }: Props) => {
  return (
    <>
      <Wrapper style={{ backgroundColor: blok.background_color?.color }}>
        <ContainerStyled>
          <RowStyled>
            <LeftCol xs={12}>
              <Title>
                <RichTextParagraphStyles>
                  <StoryblokRichText document={blok.title} />
                </RichTextParagraphStyles>
              </Title>
            </LeftCol>
            <RightCol xs={12}>
              {blok.product_image?.id && (
                <ImageBox>
                  <ImageWrapper>
                    <StoryblokImage
                      image={blok.product_image}
                      width="250"
                      height={250}
                      objectFit="contain"
                    />
                  </ImageWrapper>
                </ImageBox>
              )}
            </RightCol>
          </RowStyled>
        </ContainerStyled>
      </Wrapper>
      <TextContainerStyled>
        <Row>
          <LeftCol xs={12}>
            <SubTitle>
              <RichTextParagraphStyles>
                <StoryblokRichText document={blok.sub_title} />
              </RichTextParagraphStyles>
            </SubTitle>
          </LeftCol>
          <RightCol xs={12} className="has-border">
            <Placeholder />
          </RightCol>
        </Row>
      </TextContainerStyled>
    </>
  )
}

const ContainerStyled = styled(Container)`
  padding-top: 3rem;
  padding-bottom: 3rem;
  position: relative;
  min-height: 200px;

  ${media.md} {
    min-height: 150px;
    height: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;

    > div {
      height: 100%;
    }
  }

  @media screen and (min-width: 1440px) {
    max-width: 1440px !important;
  }
`

const TextContainerStyled = styled(Container)`
  @media screen and (min-width: 1440px) {
    max-width: 1440px !important;
  }
`

const LeftCol = styled(Col)`
  ${media.md} {
    flex-basis: auto;
    flex: 1;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  ${media.lg} {
    flex-basis: 60%;
  }
`

const RightCol = styled(Col)`
  ${media.md} {
    flex-basis: 40%;
    padding-left: 3rem;
    padding-right: 3rem;

    &.has-border {
      border-left: 2px dashed #e5e7eb;
    }
  }
`

const RowStyled = styled(Row)`
  ${media.md} {
    position: relative;
  }
`

const Placeholder = styled.div`
  margin: 1rem auto 1rem 0;
  min-width: 350px;
`

const Wrapper = styled.div`
  text-align: center;
  border-radius: 0 0 50px 50px;
  margin-bottom: calc(75px + 1rem);

  ${media.md} {
    text-align: left;
    display: flex;
    align-items: flex-end;
    border-radius: 0;
    margin-bottom: 0;
  }
`

const ImageBox = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);

  ${media.md} {
    width: 40%;
    right: 0;
    min-width: calc(350px + 6rem);
    left: initial;
    bottom: -2rem;
    transform: none;
    transform: translate(0, 50%);
  }
`
const ImageWrapper = styled.div`
  height: 150px;
  width: 150px;
  margin: auto;

  ${media.md} {
    border-radius: 50%;
    overflow: hidden;
    padding: 1.5rem;
    background: #e5e7eb;
    border-radius: 50%;
    height: 250px;
    width: 250px;
  }
`

const Title = styled(Text.h1)`
  &,
  h1 {
    color: #ffffff;
    font-size: 20px !important;
    margin-bottom: 0;

    ${media.sm} {
      font-size: 28px !important;
    }

    ${media.md} {
      text-align: left;
      height: 100%;
      display: flex;
      align-items: flex-end;
      max-width: 750px;
      padding: 0;
    }
  }
`

const SubTitle = styled(Text.md)`
  &,
  h2 {
    font-size: 12px !important;
    margin: auto;
    margin-bottom: 1rem;
    text-align: center;
    max-width: 750px;
    font-weight: 400;
    text-transform: none;
    line-height: 1.35;

    ${media.xs} {
      font-size: 14px !important;
    }

    ${media.sm} {
      margin-bottom: 2rem;
    }

    ${media.md} {
      text-align: left;
      padding-top: 2rem;
      margin-bottom: 2rem;
      max-width: 750px;
    }
  }
`
