import AccountIcon from '../../assets/ico_mobile_account.svg'
import LoggedInAccountIcon from '../../assets/ico_mobile_account_logged_in.svg'
import { ModalDialogContext } from '../../context/modalDialogContext'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import Link from 'next/link'
import { useContext } from 'react'
import { customerState } from '@/app/auth/auth.state'
import Login from '@/app/auth/components/Login'
import { HeaderLogo } from '@/app/common/components/Header/Logo/Logo'
import { SearchButton } from '@/app/common/components/Header/SeachButton/SearchButton'
import { useRecoilStateWithSSRFallback } from '@/app/common/recoil'
import MenuButton from './MenuButton'
import { MobileCartButton } from './MobileCartButton/MobileCartButton'
import {
  Center,
  CenteredMobileAction,
  GridItem,
  MobilePrimaryHeaderWrapperV2,
} from './styled'

type Props = {
  menuOpen: boolean
  toggleMenu: () => void
  itemsCount: number
  itemsTotalAmount: string
  isCheckout?: boolean
}

const headerPrefix = 'header'

export const MobilePrimaryHeader = ({
  menuOpen,
  toggleMenu,
  itemsCount,
  itemsTotalAmount,
  isCheckout,
}: Props) => {
  const [customer] = useRecoilStateWithSSRFallback(customerState, undefined)
  const modal = useContext(ModalDialogContext)
  const isLoggedIn = !!customer

  const handleLoginClick = () => {
    modal.open(<Login />, { variant: 'full' })
  }

  return (
    <MobilePrimaryHeaderWrapperV2>
      <GridItem area="logo">
        <Center>
          <MenuButton menuOpen={menuOpen} toggleMenu={toggleMenu} />
          <HeaderLogo className="pl-2 pr-1" />
        </Center>
      </GridItem>

      <GridItem area="cart" hidden={isCheckout}>
        <div className="h-full flex flex-row justify-end items-center gap-0.5">
          <CenteredMobileAction justifyContent="center">
            <SearchButton />
          </CenteredMobileAction>
          {itemsCount > 0 && (
            <CenteredMobileAction justifyContent="center">
              <MobileCartButton
                itemsCount={itemsCount}
                itemsTotalAmount={itemsTotalAmount}
              />
            </CenteredMobileAction>
          )}

          <CenteredMobileAction justifyContent="center">
            {isLoggedIn ? (
              <Link
                id={`${headerPrefix}_profile`}
                href="/profile"
                className="size-10 flex justify-center items-center"
              >
                <LoggedInAccountIcon width={20} height={20} />
              </Link>
            ) : (
              <button
                className="size-10 flex justify-center items-center"
                onClick={handleLoginClick}
              >
                <AccountIcon width={20} height={20} />
              </button>
            )}
          </CenteredMobileAction>

          <LanguageSelector />
        </div>
      </GridItem>
    </MobilePrimaryHeaderWrapperV2>
  )
}
