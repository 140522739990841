import * as Sentry from '@sentry/nextjs'
import {
  keepPreviousData,
  skipToken,
  useMutation as useRQMutation,
  useQuery as useRQQuery,
} from '@tanstack/react-query'
import { equal } from '@wry/equality'
import { CloseCode, createClient } from 'graphql-ws'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  destroyCustomerToken,
  getCustomerToken,
  onCustomerTokenChange,
} from '@/app/auth/auth.state'
import {
  GraphQLCLient,
  GraphQLOperationResult,
  Variables,
} from '@/app/common/graphql/client'
import { useGraphQLClient } from '@/app/common/graphql/context'
import { GraphQLOperationError } from '@/app/common/graphql/error'
import { createQueryKey, isErrorUnauthorized } from '@/app/common/graphql/utils'
import config, { CACHE_MAX_AGE, buildApiUri, buildOriginUri } from '@/config'
import useShowApolloErrorInSnackbar from '@/helpers/useShowApolloErrorInSnackbar'
import { TypedDocumentString } from '@/types/gql/graphql'
import { verifyToken } from '@/utils/jwt'

const isSSR = typeof window === 'undefined'
const HOST = isSSR ? 'CLIENT_SIDE_ONLY' : window.location.host

let connectingSocket: any = null

let wsClient = createWsClient()

let authToken = getCustomerToken() || null

let timedOut: ReturnType<typeof setTimeout>

onCustomerTokenChange((token) => {
  if (connectingSocket) {
    connectingSocket.close(CloseCode.Forbidden, 'Forbidden')
  }
  authToken = token
  wsClient = createWsClient()
})

function createWsClient() {
  return !isSSR
    ? createClient({
        url: buildApiUri(HOST, 'ws(s)', config.graphqlRoute),
        connectionParams: () => {
          return authToken && verifyToken(authToken)
            ? { authorization: authToken }
            : undefined
        },
        keepAlive: 10_000,
        retryAttempts: 30,
        shouldRetry: (event) => {
          // default behavior
          if (
            event &&
            typeof event === 'object' &&
            'code' in event &&
            'reason' in event
          ) {
            return true
          }

          // additionally, also try to reconnect on unclean disconnections. resolves a problem in
          // safari websockets, with the network pipe breaking while the app is in the background
          if (!(event as CloseEvent)?.wasClean) {
            return true
          }
          return false
        },
        on: {
          connected: (socket: any) => {
            connectingSocket = socket
          },
          ping: (received) => {
            if (!received /* sent */) {
              timedOut = setTimeout(() => {
                // a close event `4499: Terminated` is issued to the current WebSocket and an
                // artificial `{ code: 4499, reason: 'Terminated', wasClean: false }` close-event-like
                // object is immediately emitted without waiting for the one coming from `WebSocket.onclose`
                //
                // calling terminate is not considered fatal and a connection retry will occur as expected
                //
                // see: https://github.com/enisdenjo/graphql-ws/discussions/290
                wsClient?.terminate()
              }, 5_000)
            }
          },
          pong: (received) => {
            if (received) {
              clearTimeout(timedOut)
            }
          },
        },
      })
    : null
}

export function createGraphQLClient(host?: string) {
  return new GraphQLCLient({
    url: buildApiUri(host, 'http(s)', config.graphqlRoute),
    headers() {
      const headers: Record<string, string> = {
        origin: buildOriginUri(host),
      }

      if (authToken) {
        headers['authorization'] = authToken
      }
      // add the full referer for easier api error monitoring
      if (!isSSR) {
        headers['X-Referer-Full'] = window.location.href
      }

      return headers
    },
    responseMiddleware(response) {
      if (response.error) {
        if (response.error.graphQLErrors?.some(isErrorUnauthorized)) {
          destroyCustomerToken()
        } else {
          Sentry.captureException(response.error)
        }
      }
    },
  })
}

type QueryOptions<TResult, TVariables extends Variables> = {
  disableDefaultErrorHandling?: boolean
  skip?: boolean
  variables?: TVariables
  onCompleted?: (data: TResult) => void
  onError?: (error: GraphQLOperationError) => void
  /**
   * Enable caching for the query. Unlike `executeQuery`, the default
   * value is `false` to prevent caching of queries that are not
   * supposed to be cached.
   * @default false
   */
  enableCaching?: boolean
  staleTime?: number
  keepPreviousData?: boolean
}

export function useQuery<TResult, TVariables extends Variables>(
  document: TypedDocumentString<TResult, TVariables>,
  options?: QueryOptions<TResult, TVariables>,
) {
  const graphQLClient = useGraphQLClient()
  const prevResultRef = useRef<GraphQLOperationResult<TResult> | undefined>()
  const showErrorInSnackbar = useShowApolloErrorInSnackbar()
  const {
    enableCaching = false,
    staleTime = CACHE_MAX_AGE,
    onError,
    onCompleted,
  } = options ?? {}
  const {
    isFetching,
    data: queryRes,
    refetch,
  } = useRQQuery({
    queryKey: createQueryKey(document, options?.variables),
    placeholderData: options?.keepPreviousData ? keepPreviousData : undefined,
    staleTime: enableCaching ? staleTime : 0,
    queryFn: options?.skip
      ? skipToken
      : async () => {
          return graphQLClient.request<TResult, TVariables>({
            query: document.toString(),
            variables: options?.variables,
          })
        },
  })

  const { data, error } = queryRes ?? {}

  useEffect(() => {
    if (isFetching || !queryRes || equal(prevResultRef.current, queryRes)) {
      return
    }
    prevResultRef.current = queryRes
    if (queryRes.error) {
      onError?.(queryRes.error)
    } else {
      onCompleted?.(queryRes.data)
    }
  }, [isFetching, onCompleted, onError, queryRes])

  useEffect(() => {
    if (!options?.disableDefaultErrorHandling && error) {
      showErrorInSnackbar(error)
    }
  }, [error, options?.disableDefaultErrorHandling, showErrorInSnackbar])

  return useMemo(
    () => ({
      isFetching: options?.skip ? false : isFetching,
      data: options?.skip ? undefined : data,
      error: options?.skip ? undefined : error,
      refetch: async () => {
        const newData = await refetch()
        return newData.data
      },
    }),
    [data, error, isFetching, refetch, options?.skip],
  )
}

type MutationOptions<TResult, TVariables extends Variables> = {
  disableDefaultErrorHandling?: boolean
  onCompleted?: (data: TResult) => void
  variables?: TVariables
  onError?: (error: GraphQLOperationError) => void
}

export function useMutation<TResult, TVariables extends Variables>(
  document: TypedDocumentString<TResult, TVariables>,
  options?: MutationOptions<TResult, TVariables>,
) {
  const graphQLClient = useGraphQLClient()
  const showErrorInSnackbar = useShowApolloErrorInSnackbar()
  const query = document.toString()
  const {
    mutateAsync,
    data: { data, error } = {},
    isPending: loading,
  } = useRQMutation({
    mutationFn: async (
      mutateOptions?: MutationOptions<TResult, TVariables>,
    ) => {
      const mergedOptions = {
        ...options,
        ...mutateOptions,
        variables: {
          ...options?.variables,
          ...mutateOptions?.variables,
        } as TVariables,
      }
      const res = await graphQLClient.request<TResult, TVariables>({
        query,
        variables:
          Object.values(mergedOptions.variables).length > 0
            ? mergedOptions.variables
            : undefined,
      })
      if (res.error) {
        mergedOptions?.onError?.(res.error)
      } else {
        mergedOptions?.onCompleted?.(res.data)
      }
      return res
    },
  })

  if (!options?.disableDefaultErrorHandling && error) {
    showErrorInSnackbar(error)
  }

  const mutate = useCallback(
    async (mutateOptions?: MutationOptions<TResult, TVariables>) => {
      const result = await mutateAsync(mutateOptions)
      if (result.error && !mutateOptions?.onError) {
        throw result.error
      }
      return result
    },
    [mutateAsync],
  )

  const mutationResult = useMemo(
    () => ({
      loading,
      data,
      error,
    }),
    [data, error, loading],
  )
  return [mutate, mutationResult] as const
}

type LazyQueryOptions<TResult, TVariables extends Variables> = Omit<
  QueryOptions<TResult, TVariables>,
  'skip'
>

export function useLazyQuery<TResult, TVariables extends Variables>(
  document: TypedDocumentString<TResult, TVariables>,
  options?: LazyQueryOptions<TResult, TVariables>,
) {
  const [executeArgs, setExecuteArgs] = useState<
    LazyQueryOptions<TResult, TVariables> | undefined
  >()

  const mergedOptions = {
    ...options,
    ...executeArgs,
    variables: {
      ...options?.variables,
      ...executeArgs?.variables,
    } as TVariables,
  }

  const executionStatus = useRef<
    | {
        resolve: (res: GraphQLOperationResult<TResult>) => void
      }
    | undefined
  >()

  const useQueryResult = useQuery(document, {
    ...mergedOptions,
    disableDefaultErrorHandling: true,
    onCompleted: (data) => {
      if (mergedOptions?.onCompleted) {
        mergedOptions.onCompleted(data)
      }
      executionStatus.current?.resolve({ data, error: null })
    },
    onError: (error) => {
      if (mergedOptions?.onError) {
        mergedOptions.onError(error)
      }
      executionStatus.current?.resolve({ data: null, error })
    },
    skip: !executeArgs,
  })

  return useMemo(
    () =>
      [
        (options: LazyQueryOptions<TResult, TVariables>) => {
          setExecuteArgs(options)
          return new Promise<GraphQLOperationResult<TResult>>((resolve) => {
            executionStatus.current = { resolve }
          })
        },
        useQueryResult,
      ] as const,
    [useQueryResult],
  )
}

type SubscriptionOptions<TResult, TVariables extends Variables> = {
  variables?: TVariables
  onData?: (data: TResult) => void
  skip?: boolean
}

export function useSubscription<TResult, TVariables extends Variables>(
  document: TypedDocumentString<TResult, TVariables>,
  options?: SubscriptionOptions<TResult, TVariables>,
) {
  const query = document.toString()
  useEffect(() => {
    if (!wsClient || options?.skip) {
      return
    }
    let shouldBreak = false
    ;(async () => {
      const subscription = wsClient.iterate<TResult>({
        query,
        variables: options?.variables,
      })
      for await (const result of subscription) {
        if (result.data) {
          options?.onData?.(result.data)
        }
        if (result.errors) {
          Sentry.addBreadcrumb({
            message: 'Receiving error via websocket',
            data: result.errors,
          })
        }
        if (shouldBreak) {
          break
        }
      }
      return () => {
        shouldBreak = true
      }
    })()
  }, [options, query])
}
