import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'

interface ItemProps {
  seamless?: boolean
  children: React.ReactNode
}

const AccWrapper = styled.div<ItemProps>`
  margin-top: ${({ seamless }) => (seamless ? '-1rem' : 0)};
`

const Accordeon: FunctionComponent<ItemProps> = ({ children, seamless }) => {
  const [currentActive, setCurrentActive] = useState(-1)

  return (
    <AccWrapper seamless={seamless}>
      {React.Children.map(children, (child, key) =>
        React.cloneElement(child as React.ReactElement<any>, {
          isOpen: currentActive === key,
          toggle: () =>
            currentActive === key
              ? setCurrentActive(-1)
              : setCurrentActive(key),
        }),
      )}
    </AccWrapper>
  )
}

export default Accordeon
