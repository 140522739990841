import React from 'react'
import { GridItem, Head, HeaderSpacer, HeaderSpacerCheckout } from './styled'

type Props = {
  primaryHeader: React.ReactNode
  navMenu?: React.ReactNode
  isCheckout?: boolean
}

export const HeaderLayout = ({ primaryHeader, isCheckout, navMenu }: Props) => {
  return (
    <>
      <Head>
        <GridItem area="primaryHeader" zIndex={101}>
          {primaryHeader}
        </GridItem>
      </Head>
      {navMenu}
      {isCheckout ? (
        <HeaderSpacerCheckout />
      ) : (
        <HeaderSpacer id="header-spacer" />
      )}
    </>
  )
}
