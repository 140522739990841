import styled from 'styled-components'
import TypeScales from './typescales'

interface H3Props {
  noMargin?: boolean
}

export default styled(TypeScales.h3).attrs<H3Props>((props) => ({
  as: 'h3',
  noMargin: props.noMargin || false,
}))<H3Props>`
  text-transform: uppercase;
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : 0.75)}rem;
`
