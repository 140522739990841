import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import React from 'react'
import { FaqReferenceStoryblok } from '@/types/storyblok-component-types'

export default function FAQReference({
  blok,
}: {
  blok: FaqReferenceStoryblok
}) {
  // faqs field is string if the relation is not added to resolve_relations yet in the API call
  return blok.faqs && typeof blok.faqs !== 'string' ? (
    <div {...storyblokEditable(blok)}>
      <StoryblokComponent blok={blok.faqs.content} key={blok._uid} />
    </div>
  ) : null
}
