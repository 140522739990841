import { FormikProps } from 'formik'
import React, { FunctionComponent } from 'react'
import SelectButton from '@/style/components/Form/SelectButton'

type ValueType = string | boolean | number

interface SmartSelectButtonProps {
  id?: string
  headline: string
  subline?: string
  description?: string
  name: string
  options: {
    name: string
    value: ValueType
    description?: string
  }[]
  onChange?: (value: ValueType) => void
  formikProps: FormikProps<any>
}

const SmartSelectButton: FunctionComponent<SmartSelectButtonProps> = (
  props,
) => {
  const { formikProps, name, onChange } = props

  const field = formikProps.getFieldMeta(name)
  const fieldHelpers = formikProps.getFieldHelpers(name)

  return (
    <SelectButton
      {...props}
      value={field.value as any}
      onChange={(value) => {
        fieldHelpers.setValue(value)
        if (onChange) onChange(value)
      }}
    />
  )
}

export default SmartSelectButton
