import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {}

const ImageElement = styled.img`
  width: 100%;
  height: auto;
`

const Image: FunctionComponent<ImageProps> = (props) => (
  <ImageElement {...props} />
)

export default Image
