import { FormControl, InputLabel, Select } from '@material-ui/core'
import { SelectInputProps } from '@material-ui/core/Select/SelectInput'
import { CSSProperties, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

interface StyledSelectProps {
  id?: string
  className?: string
  error?: string
  name: string
  value: string | null
  label: string
  showLabel?: boolean
  options: {
    value: string | number
    label: string
  }[]
  onChange?: SelectInputProps['onChange']
  large?: boolean
  fullWidth?: boolean
  hasNone?: boolean
  disabled?: boolean
  style?: CSSProperties
  children?: React.ReactNode
}

const StyledFormControl = styled(FormControl)<{
  error: boolean
  hiddenLabel: boolean
  large: string
}>`
  .MuiFilledInput-root {
    font-size: ${({ large }) => (large === 'true' ? '1rem' : '0.875rem')};
    font-family: GalanoGrotesque-Medium, sans-serif;
    background-color: transparent;
    height: ${({ hiddenLabel, large }) =>
      hiddenLabel ? (large === 'true' ? '3rem' : '2.5rem') : '3.5rem'};

    border: 2px solid
      ${(props) => (!props.error ? props.theme.colors.border : '#f44336')};
    border-radius: ${({ theme }) => theme.radius.normal}px;

    ${({ large }) =>
      large === 'true' &&
      css`
        font-weight: 600;
      `}

    label {
      font-family: GalanoGrotesque, sans-serif;
    }

    &.Mui-focused {
      background-color: white !important;
    }
  }
  .MuiFilledInput-underline {
    &:after,
    &:before,
    &:hover:before {
      border: none;
    }
  }
  label.Mui-focused {
    color: #666666;
    font-size: 1rem;
  }

  .MuiFilledInput-underline.Mui-disabled:before {
    border-bottom-style: unset;
  }

  ${({ hiddenLabel, large }) =>
    hiddenLabel &&
    css`
      .MuiSelect-root {
        padding-top: ${() => (large === 'true' ? 0.875 : 0.625)}rem;
        padding-bottom: ${() => (large === 'true' ? 0.875 : 0.625)}rem;
      }
    `}
`

const StyledSelect: React.FC<StyledSelectProps> = ({
  className,
  error,
  name,
  label,
  value,
  options,
  onChange,
  showLabel,
  large,
  fullWidth,
  hasNone,
  disabled,
  style,
  ...props
}) => {
  const [id, setId] = useState('')

  useEffect(() => {
    setId(uuidv4())
  }, [])

  return (
    <StyledFormControl
      variant="filled"
      className={className}
      error={!!error}
      hiddenLabel={!showLabel}
      large={large ? 'true' : 'false'}
      fullWidth={fullWidth}
      disabled={disabled}
      style={style}
    >
      {showLabel && <InputLabel htmlFor={id}>{label}</InputLabel>}
      <Select
        native
        name={name}
        value={value}
        onChange={(e) => (onChange ? onChange(e, props.children) : null)}
        inputProps={{
          name,
          id: props.id || id,
        }}
      >
        {hasNone && <option key={undefined} value={undefined} />}
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
    </StyledFormControl>
  )
}

export default StyledSelect
