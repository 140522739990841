import * as Sentry from '@sentry/nextjs'
import {
  MutableSnapshot,
  RecoilState,
  SetterOrUpdater,
  Snapshot,
  useRecoilState,
} from 'recoil'
import { useIsSSR } from '@/helpers/useIsSSR'

export function persistState(snapshot: Snapshot, atoms: RecoilState<any>[]) {
  try {
    if (typeof localStorage !== 'undefined') {
      const persistedRecoilState: Record<string, any> = {}

      for (const atom of atoms) {
        persistedRecoilState[atom.key] = snapshot.getLoadable(atom).contents
      }

      localStorage.setItem('recoilState', JSON.stringify(persistedRecoilState))
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}

export function restoreClientState(atoms: RecoilState<any>[]) {
  return ({ set }: MutableSnapshot) => {
    try {
      if (typeof localStorage !== 'undefined') {
        const _recoilState = localStorage.getItem('recoilState')

        if (typeof _recoilState === 'string') {
          const recoilState = JSON.parse(_recoilState)

          for (const atom of atoms) {
            if (typeof atom.key !== 'undefined') {
              set(atom, recoilState[atom.key])
            }
          }
        }
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }
}

export function useRecoilStateWithSSRFallback<T>(
  recoilState: RecoilState<T>,
  fallback: T,
): [T, SetterOrUpdater<T>] {
  const [state, setState] = useRecoilState(recoilState)
  const isSSR = useIsSSR()
  return [isSSR ? fallback : state, setState]
}
