import Background from '../assets/bkg_logo.svg'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import Text from '@/style/components/LegacyText'
import { ImageWrapper, Wrapper } from './CategoryItem'

interface ObjectListFormItemProps {
  onClick?: () => void
}

export const OtherOption: FunctionComponent<ObjectListFormItemProps> = ({
  onClick,
}) => {
  const { t } = useTranslation()

  return (
    <StyledWrapper onClick={onClick}>
      <StyledBackground />
      <StyledImageWrapper>
        <Text.ms weight="semibold">
          {t('customDeal:found_nothing_appropriate')}
        </Text.ms>
      </StyledImageWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled(Wrapper)`
  background-color: ${({ theme }) => theme.colors.buttonBorder};
  border: 2px solid ${({ theme }) => theme.colors.border};
`

const StyledImageWrapper = styled(ImageWrapper)`
  padding-top: 83%;
  position: relative;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
  }
`

const StyledBackground = styled(Background)`
  position: absolute;
  top: 22.5%;
  left: 25%;
  height: auto;
  width: 50%;
  * {
    fill: ${({ theme }) => theme.colors.border};
  }
`
