import { CarCalculation, CustomVehicleFormData } from '../VehicleDealBooking'
import { Box, Typography } from '@material-ui/core'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ScaleLoader } from 'react-spinners'
import styled from 'styled-components'
import { UseFormReturnType } from '@/app/common/components/Form/useForm'
import { MAX_PAYOUT_AMOUNT_PAWN_CONTINUE_USING } from '@/helpers/constants'
import Text from '@/style/components/Text'
import { TextInput } from '@/style/components/TextInput'
import theme from '@/style/themes/default'
import { printLocalAmount } from '@/utils/misc'

interface PriceBoxProps {
  calculationLoading: boolean
  calculation: CarCalculation | undefined | null
  formikProps: UseFormReturnType<CustomVehicleFormData>
  isCustomRequest: boolean
  noValuation?: boolean | null
  maxPayoutAmount?: number
  onChangeOverridePayoutAmountValuation?: (
    overwrittenPayoutAmount: number,
  ) => void
  isPawnContinueUsing: boolean
}

const MIN_DESIRED_PAYOUT_AMOUNT_VALUE = 1000

const PriceBox: FunctionComponent<PriceBoxProps> = ({
  calculationLoading,
  calculation,
  formikProps,
  noValuation,
  isCustomRequest,
  maxPayoutAmount,
  onChangeOverridePayoutAmountValuation,
  isPawnContinueUsing,
}) => {
  const { payoutAmount } = formikProps.values
  const [showTextAmount, setShowTextAmount] = useState(true)
  const [localPayoutAmount, setLocalPayoutAmount] = useState(
    isPawnContinueUsing
      ? Math.min(payoutAmount ?? 0, MAX_PAYOUT_AMOUNT_PAWN_CONTINUE_USING)
      : payoutAmount ?? 0,
  )
  const priceBoxRef = useRef<HTMLInputElement>({} as HTMLInputElement)
  const { t } = useTranslation()

  const hideCreditRange = isCustomRequest || noValuation

  const handlePriceTextOnFocus = () => {
    setShowTextAmount(false)
    setTimeout(() => {
      priceBoxRef.current?.focus()
    }, 0)
  }

  const handleSetDesiredPayoutAmount = (value: number) => {
    const effectiveValue = isPawnContinueUsing
      ? Math.min(value, MAX_PAYOUT_AMOUNT_PAWN_CONTINUE_USING)
      : value

    setLocalPayoutAmount(effectiveValue)
    onChangeOverridePayoutAmountValuation?.(effectiveValue)
  }

  const handlePriceBoxOnBlur = () => {
    setShowTextAmount(true)
    if (hideCreditRange || !calculation) {
      formikProps.setFieldValue('payoutAmount', localPayoutAmount)
      return
    }

    if (
      isNaN(localPayoutAmount) ||
      localPayoutAmount < MIN_DESIRED_PAYOUT_AMOUNT_VALUE
    ) {
      handleSetDesiredPayoutAmount(MIN_DESIRED_PAYOUT_AMOUNT_VALUE)
    } else if (
      typeof calculation.payoutAmount === 'number' &&
      maxPayoutAmount &&
      localPayoutAmount > maxPayoutAmount
    ) {
      handleSetDesiredPayoutAmount(maxPayoutAmount)
    } else {
      handleSetDesiredPayoutAmount(localPayoutAmount)
    }
  }

  useEffect(() => {
    if (calculation?.payoutAmount) {
      const effectivePayoutAmount = isPawnContinueUsing
        ? Math.min(
            calculation.payoutAmount,
            MAX_PAYOUT_AMOUNT_PAWN_CONTINUE_USING,
          )
        : calculation.payoutAmount

      setLocalPayoutAmount(effectivePayoutAmount)
      formikProps.setFieldValue('payoutAmount', effectivePayoutAmount)
      isPawnContinueUsing &&
        onChangeOverridePayoutAmountValuation?.(effectivePayoutAmount)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculation?.payoutAmount, isPawnContinueUsing])

  const maxLoanAmount = useMemo(() => {
    return isPawnContinueUsing
      ? Math.min(MAX_PAYOUT_AMOUNT_PAWN_CONTINUE_USING, maxPayoutAmount ?? 0)
      : maxPayoutAmount ?? 0
  }, [maxPayoutAmount, isPawnContinueUsing])

  return (
    <>
      {!calculationLoading && (
        <Text.h2
          style={{ fontSize: '1rem', margin: 0, textAlign: 'center' }}
          as="p"
        >
          {hideCreditRange
            ? t('common:your_input_payout')
            : t('common:your_desired_payout')}
        </Text.h2>
      )}
      <Box
        justifyContent="center"
        display="flex"
        flexWrap="nowrap"
        alignItems="center"
      >
        <PriceLoadingWrapper>
          {calculationLoading ? (
            <Box
              textAlign="center"
              height={80}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ScaleLoader color={theme.colors.primary} loading={true} />
            </Box>
          ) : (
            <Box
              height={80}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {!showTextAmount && (
                <PriceTextInput
                  inputRef={priceBoxRef}
                  name="payoutAmount"
                  label=""
                  type="number"
                  id="LOCAL_PAYOUT_AMOUNT_INPUT"
                  value={localPayoutAmount}
                  onChange={(
                    e: React.ChangeEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >,
                  ) => setLocalPayoutAmount(parseInt(e.target.value))}
                  onBlur={handlePriceBoxOnBlur}
                />
              )}
              {showTextAmount &&
                (!localPayoutAmount && hideCreditRange ? (
                  <EnterAmountText
                    display="inline"
                    color="primary"
                    onClick={handlePriceTextOnFocus}
                  >
                    <span>{t('common:enter_amount_here')}</span> €
                  </EnterAmountText>
                ) : (
                  <PriceText
                    display="inline"
                    color="primary"
                    onClick={handlePriceTextOnFocus}
                    id="LOCAL_PAYOUT_AMOUNT"
                  >
                    {printLocalAmount({
                      number: localPayoutAmount,
                      fractionDigits: 0,
                    })}
                  </PriceText>
                ))}
            </Box>
          )}
        </PriceLoadingWrapper>
      </Box>
      {!calculationLoading && !hideCreditRange && calculation && (
        <CreditRangeText as="div" color="light">
          <Trans
            i18nKey="vehicle:pawn.credit_amount_range"
            components={{
              minAmount: (
                <SetCreditText
                  onClick={() =>
                    handleSetDesiredPayoutAmount(
                      MIN_DESIRED_PAYOUT_AMOUNT_VALUE,
                    )
                  }
                >
                  {printLocalAmount({
                    number: MIN_DESIRED_PAYOUT_AMOUNT_VALUE,
                    fractionDigits: 0,
                    removeSpaces: true,
                  })}
                </SetCreditText>
              ),
              maxAmount: (
                <SetCreditText
                  onClick={() => handleSetDesiredPayoutAmount(maxLoanAmount)}
                >
                  {printLocalAmount({
                    number: maxLoanAmount,
                    fractionDigits: 0,
                    removeSpaces: true,
                  })}
                </SetCreditText>
              ),
            }}
          />
        </CreditRangeText>
      )}
      {isPawnContinueUsing &&
        !calculationLoading &&
        (calculation?.payoutAmount ?? 0) >=
          MAX_PAYOUT_AMOUNT_PAWN_CONTINUE_USING && (
          <CreditRangeText as="div" color="light">
            <Trans
              i18nKey="common:maximum_payout_amount_for_continued_use"
              components={{
                bold: <TextBold />,
              }}
              values={{
                amount: printLocalAmount({
                  number: MAX_PAYOUT_AMOUNT_PAWN_CONTINUE_USING,
                  fractionDigits: 0,
                  removeSpaces: true,
                }),
              }}
            />
          </CreditRangeText>
        )}
    </>
  )
}

export default PriceBox

const PriceLoadingWrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  padding: 0.5rem 0;
`

const PriceTextInput = styled(TextInput)`
  .MuiFilledInput-root {
    height: 4rem;
    input {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 3.25rem !important;
      font-weight: 700;
      text-align: center;
      color: ${({ theme }) => theme.colors.primary};
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`

const PriceText = styled(Typography)`
  font-size: 3.25rem !important;
  font-weight: 700 !important;
  position: absolute;
  width: 100%;
  border: 2px solid #e9e9e9;
  border-radius: 5px;
  height: 4rem;
  line-height: 1.08 !important;
`

const EnterAmountText = styled(Typography)`
  display: flex !important;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  font-size: 3.25rem !important;
  font-weight: 700 !important;
  position: absolute;
  width: 100%;
  border: 2px solid #e9e9e9;
  border-radius: 5px;
  height: 4rem;
  line-height: 1.08 !important;
  span {
    font-size: 1.25rem !important;
    font-weight: 500 !important;
    color: ${({ theme }) => theme.colors.gray};
  }
`

const CreditRangeText = styled(Text.ms)`
  margin-top: -5px;
  margin-left: 15px;
  margin-bottom: 10px;
  text-align: center;
`

const SetCreditText = styled(Text.ms)`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const TextBold = styled.span`
  font-weight: 500;
`
