import {
  ModalDialogContext,
  ModalOptions,
} from '../../context/modalDialogContext'
import { LoadingOverlay } from '../LoadingOverlay'
import CloseIcon from '@material-ui/icons/Close'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import React, { ComponentProps, useCallback, useEffect, useState } from 'react'
import Modal from 'react-modal'
import { RemoveScroll } from 'react-remove-scroll'
import styled from 'styled-components'
import AuthDialog, { Wrapper } from '@/app/auth/components/AuthDialog'
import useWindowDimensions_CAN_CAUSE_CLS from '@/helpers/useWindowDimensions'
import { media } from '@/style/helpers'

const customModalStyles = {
  content: {},
  overlay: {
    zIndex: 100,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    overflow: 'auto',
  },
}

const CloseButton = styled.span<{ $variant: 'info' | 'full' }>`
  position: absolute;
  cursor: pointer;
  z-index: 3;
  top: ${(props) => (props.$variant === 'full' ? '-16px' : '-36px')} !important;
  right: 14px;

  ${media.sm} {
    top: ${(props) =>
      props.$variant === 'full' ? '14px' : '-36px'} !important;
  }

  ${media.md} {
    top: ${(props) =>
      props.$variant === 'full' ? '14px' : '-36px'} !important;
  }
`

const TypedModal = Modal as unknown as React.FC<
  ComponentProps<typeof Modal> & { children: React.ReactNode }
>

const ModalStyled = styled(TypedModal)<{
  variant?: 'info' | 'full'
}>`
  outline: none;
  position: absolute;
  top: 50%;
  bottom: auto;
  left: 50%;
  right: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 0;
  background-color: white;
  border-radius: 13px;

  ${media.sm} {
    top: 57%;
  }

  ${media.md} {
    top: 55%;
  }

  ${(props) =>
    props.variant === 'full' &&
    `
      height: 100%;
      width: 100%;
      border-radius: 0;

      ${media.sm} {
        width: auto;
        height: auto;
        border-radius: 13px;
      }
    `}

  &.cookies-modal {
    top: 50%;

    > div > div {
      position: unset;
    }

    @media (min-width: 600px) {
      height: auto;
    }
  }
`

export const ModalDialogProvider = (props: { children: React.ReactNode }) => {
  const [modalDialog, setModalDialog] = useState<{
    isOpen: boolean
    content: JSX.Element
    options?: ModalOptions
  }>({
    isOpen: false,
    content: <></>,
  })

  const { height } = useWindowDimensions_CAN_CAUSE_CLS()
  const [viewportHeight, setViewportHeight] = useState(height)

  useEffect(() => {
    setViewportHeight(height)

    Modal.setAppElement('body')
  }, [height])

  const path = useRouter().asPath

  useEffect(() => {
    closeDialog()
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path])

  const { options } = modalDialog
  const closeDialog = useCallback(() => {
    if (modalDialog.options?.onAfterClose) modalDialog.options.onAfterClose()
    setModalDialog({
      content: <></>,
      isOpen: false,
    })
  }, [modalDialog])

  const hideCloseButton =
    modalDialog.options &&
    modalDialog.options.shouldCloseOnOverlayClick === false &&
    modalDialog.options.shouldCloseOnEsc === false

  const variant = modalDialog.options?.variant ?? 'info'

  const openDialog = useCallback(
    (content: JSX.Element, options?: ModalOptions) => {
      setModalDialog({
        isOpen: true,
        content,
        options,
      })
    },
    [setModalDialog],
  )

  return (
    <ModalDialogContext.Provider
      value={{
        close: closeDialog,
        open: openDialog,
        setLoading: () => {
          setModalDialog({
            isOpen: true,
            content: (
              <Wrapper style={{ position: 'relative' }}>
                <Loading>
                  <LoadingOverlay />
                </Loading>
              </Wrapper>
            ),
            options,
          })
        },
        isOpen: modalDialog.isOpen,
      }}
    >
      <ModalStyled
        isOpen={modalDialog.isOpen}
        onRequestClose={closeDialog}
        shouldCloseOnEsc={modalDialog.options?.shouldCloseOnEsc ?? true}
        shouldCloseOnOverlayClick={
          modalDialog.options?.shouldCloseOnOverlayClick ?? true
        }
        variant={variant}
        style={{
          content: {
            ...customModalStyles.content,
            ...options?.customStyles?.content,
          },
          overlay: {
            ...customModalStyles.overlay,
            ...options?.customStyles?.overlay,
            minHeight: viewportHeight,
          },
        }}
        className={modalDialog.options?.customClass}
        contentLabel="Example Modal"
      >
        <>
          <RemoveScroll {...{ style: { height: '100%', overflow: 'auto' } }}>
            <ModalContent
              fixedMobileFooter={modalDialog.options?.fixedMobileFooter}
              hasWrapper={modalDialog.options?.hasWrapper}
              content={modalDialog.content}
              closeDialog={closeDialog}
              hideCloseButton={hideCloseButton}
              variant={variant}
            />
          </RemoveScroll>
        </>
      </ModalStyled>

      {props.children}
    </ModalDialogContext.Provider>
  )
}

const Loading = styled.div`
  min-height: 10rem;
`

const ModalContent = ({
  hasWrapper,
  content,
  fixedMobileFooter,
  hideCloseButton,
  closeDialog,
  variant,
}: {
  hasWrapper?: boolean
  fixedMobileFooter?: boolean
  content: JSX.Element
  hideCloseButton?: boolean
  closeDialog: () => void
  variant: 'info' | 'full'
}) => {
  const { t } = useTranslation()

  return (
    <ModalContentWrapper $variant={variant}>
      {!hideCloseButton && (
        <CloseButton
          $variant={variant}
          color="secondary"
          onClick={() => {
            closeDialog()
          }}
        >
          <CloseIcon fontSize="medium" />
        </CloseButton>
      )}

      {hasWrapper ? (
        <AuthDialog
          navBar={{
            title: t('common:call_request.arrange_a_call'),
          }}
          fixedMobileFooter={fixedMobileFooter}
          content={content}
        />
      ) : (
        content
      )}
    </ModalContentWrapper>
  )
}

const ModalContentWrapper = styled.div<{ $variant: 'info' | 'full' }>`
  position: relative;
  margin-top: ${(props) => (props.$variant === 'full' ? '110px' : '0')};

  ${media.sm} {
    margin-top: 0;
  }
`
