/* eslint-disable jsx-a11y/anchor-is-valid */
import CheckIcon from '../../../app/common/assets/ico_check.svg'
import useTranslation from 'next-translate/useTranslation'
import { useStoryblokConfig } from '@/app/common/context/storyblokConfigContext'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'

const GeneralModalScreen = () => {
  const { t } = useTranslation()
  const { config } = useStoryblokConfig()

  return (
    <>
      <p className="header">
        {t('common:cookies.settings_description.header')}
      </p>
      <p className="title">{t('common:cookies.settings_description.title')}</p>
      <p className="question">
        {t('common:cookies.settings_description.question')}
      </p>
      <p className="body">
        {t('common:cookies.settings_description.firstBody')}
      </p>
      <p className="desc">
        <div className="check-icon">
          <CheckIcon width={12} height={12} />
        </div>
        {t('common:cookies.settings_description.firstParagraph')}
      </p>
      <p className="desc">
        <div className="check-icon">
          <CheckIcon width={12} height={12} />
        </div>
        {t('common:cookies.settings_description.secondParagraph')}
      </p>
      <p className="desc">
        <div className="check-icon">
          <CheckIcon width={12} height={12} />
        </div>
        {t('common:cookies.settings_description.thirdParagraph')}
      </p>
      <p className="body">
        {t('common:cookies.settings_description.secondBody')}
      </p>
      <p className="body">
        {t('common:cookies.settings_description.thirdBody')}
      </p>
      <div className="link">
        CASHY Austria GmbH -{' '}
        <a href={getStoryBlokLink(config.privacy_policy)} target="_blank">
          {t('common:cookies.protection')}
        </a>
      </div>
      <div className="link">
        CASHY Austria GmbH -{' '}
        <a href={'/impressum'} target="_blank">
          {t('common:cookies.imprint')}
        </a>
      </div>
    </>
  )
}

export default GeneralModalScreen
