import { media } from '../../helpers'
import theme from '../../themes/default'
import styled, { IStyledComponent, css } from 'styled-components'
import { Substitute } from 'styled-components/dist/types'

interface TextProps {
  weight?: 'regular' | 'medium' | 'semibold' | 'bold'
  color?: keyof typeof theme.colors | string
  transform?: 'normal' | 'uppercase'
  fontStyle?: 'normal' | 'italic'
  visibleFrom?: keyof typeof theme.breakpoints
  visibleUntil?: keyof typeof theme.breakpoints
  uppercase?: boolean
}

const sizes = theme.fonts.sizes

export function getFontCSS(size: keyof typeof sizes) {
  return css`
    font-size: ${({ theme }) => theme.fonts.sizes[size].fontSize}rem;
    letter-spacing: ${({ theme }) => theme.fonts.sizes[size].letterSpacing}em;
    line-height: ${({ theme }) => theme.fonts.sizes[size].lineHeight};
    font-weight: ${({ theme }) => theme.fonts.sizes[size].fontWeight};

    ${['xl', 'xxl', 'xxxl', 'x4l'].includes(size) &&
    css`
      font-size: ${({ theme }) => theme.fonts.sizes[size].fontSize * 0.55}rem;

      ${media.xs} {
        font-size: ${({ theme }) => theme.fonts.sizes[size].fontSize * 0.7}rem;
      }

      ${media.sm} {
        font-size: ${({ theme }) => theme.fonts.sizes[size].fontSize * 0.7}rem;
      }

      ${media.md} {
        font-size: ${({ theme }) => theme.fonts.sizes[size].fontSize}rem;
      }
    `}

    ${['md', 'ms'].includes(size) &&
    css`
      font-size: ${({ theme }) => theme.fonts.sizes[size].fontSize * 0.875}rem;

      ${media.xs} {
        font-size: ${({ theme }) => theme.fonts.sizes[size].fontSize}rem;
      }
    `}
  `
}

const SizeStyles = (Object.keys(sizes) as Array<keyof typeof sizes>).reduce(
  (acc, label) => {
    acc[label] = styled.span<TextProps>`
      ${getFontCSS(label)}

      ${(props) =>
        props.uppercase &&
        css`
          text-transform: uppercase;
        `}
      
          ${({ weight }) =>
        weight === 'medium' &&
        css`
          font-weight: 500;
        `}

          ${({ weight }) =>
        weight === 'semibold' &&
        css`
          font-weight: 600;
        `}
      
          ${({ weight }) =>
        weight === 'bold' &&
        css`
          font-weight: 700;
        `}
      
          ${({ theme, color }) =>
        color &&
        css`
          color: ${color in theme.colors
            ? theme.colors[color as keyof typeof theme.colors]
            : color};
        `}

          ${({ transform }) =>
        transform &&
        css`
          text-transform: ${transform};
        `}

          ${({ fontStyle }) =>
        fontStyle &&
        css`
          font-style: ${fontStyle};
        `}

          ${({ visibleFrom }) =>
        visibleFrom &&
        css`
          display: none;

          ${media[visibleFrom]} {
            display: unset;
          }
        `}
      
          ${({ visibleUntil }) =>
        visibleUntil &&
        css`
          ${media[visibleUntil]} {
            display: none;
          }
        `}
    `
    acc[label].displayName = `Text.${label}`

    return acc
  },
  {} as Record<
    keyof typeof sizes,
    IStyledComponent<
      'web',
      Substitute<
        React.DetailedHTMLProps<
          React.HTMLAttributes<HTMLSpanElement>,
          HTMLSpanElement
        >,
        TextProps
      >
    >
  >,
)

export default SizeStyles
