import ImgDate from '../assets/date_bg.svg'
import StepArrow from '../assets/step_arrow.svg'
import { motion } from 'framer-motion'
import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled, { css } from 'styled-components'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import Text from '@/style/components/LegacyText'
import ScrollReveal, {
  ScrollRevealChild,
  delayedVariant,
} from '@/style/components/ScrollReveal'
import { media } from '@/style/helpers'
import {
  AssetStoryblok,
  RichtextStoryblok,
} from '@/types/storyblok-component-types'
import StepImageNormal from './StepImage/StepImageNormal'
import StepImageReverse from './StepImage/StepImageReverse'

interface StepProps {
  reverse?: boolean
  date: string
  text?: RichtextStoryblok
  image?: AssetStoryblok
  showArrow: boolean
}

const Step: FunctionComponent<StepProps> = ({
  reverse,
  date,
  text,
  image,
  showArrow,
}) => {
  const IndexNumber = styled(motion.div)`
    width: 6rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    flex-shrink: 0;
    position: absolute;
    top: 0;
    left: 0;

    * {
      padding-top: 0.1em;
      line-height: 1em;
    }

    margin-left: ${reverse ? '0rem' : '5%'};
    margin-right: ${reverse ? '5%' : '0rem'};

    ${media.xs} {
      margin-left: ${reverse ? '0rem' : '15%'};
      margin-right: ${reverse ? '15%' : '0rem'};
    }

    ${media.sm} {
      position: relative;
      margin-left: 0;
      margin-right: 1rem;
    }

    ${media.md} {
      margin: 0;
      margin-left: 4rem;
      margin-right: 1rem;
    }
  `

  const StepWrapper = styled(Row)`
    align-items: center;
    position: relative;
    margin: 0;

    ${({ reverse }) =>
      reverse &&
      css`
        ${StepArrowImage} {
          right: auto;
          left: 1rem;
          transform: rotate(30deg) rotateY(180deg) scale(0.5);

          ${media.sm} {
            left: 0;
            transform: rotateY(-180deg);
          }
        }

        ${IndexNumber} {
          left: auto;
          right: 0;

          ${media.sm} {
            margin-left: -1rem;
          }

          ${media.md} {
            margin-left: -4rem;
          }
        }

        ${TextWrapper} {
          ${media.sm} {
            margin-left: 1rem;
            width: 18rem;
            max-width: 18rem;
          }
          ${media.md} {
            margin-left: 2rem;
            width: 20rem;
            max-width: 20rem;
          }
        }
      `}
  `

  const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    margin: auto;

    margin-bottom: 3rem;
    width: 80%;

    ${media.xs} {
      width: 80%;
      margin: auto;
      margin-top: 0.7rem;
      margin-bottom: 1.5rem;
    }

    ${media.sm} {
      margin-bottom: 0;
      text-align: left;
      margin-left: -1rem;
      width: 20rem;
      max-width: 20rem;
    }
    ${media.md} {
      margin-left: -3.5rem;
      width: 29rem;
      max-width: 29rem;
    }
  `

  return (
    <ScrollReveal animation="slideUp">
      <StepWrapper reverse={reverse}>
        {showArrow ? (
          <StepArrowImage>
            <StepArrow className="inline" />
          </StepArrowImage>
        ) : null}
        <Col xs={12} sm={6}>
          <ScrollRevealChild animation="scale">
            {reverse ? (
              <>{image?.filename && <StyledStepImageReverse image={image} />}</>
            ) : (
              <>{image?.filename && <StyledStepImageNormal image={image} />}</>
            )}
          </ScrollRevealChild>
        </Col>
        <Col xs={12} sm={6}>
          <TextWrapper>
            <IndexNumber variants={delayedVariant('scale', 0.25)}>
              <ImgDate />
              <IndexNumberLabel color="white" weight="semibold">
                {date}
              </IndexNumberLabel>
            </IndexNumber>
            <ScrollRevealChild delay={0.5}>
              <RichTextParagraphStyles>
                <StoryblokRichText document={text} />
              </RichTextParagraphStyles>
            </ScrollRevealChild>
          </TextWrapper>
        </Col>
      </StepWrapper>
    </ScrollReveal>
  )
}

const StyledStepImageNormal = styled(StepImageNormal)`
  position: relative;
`

const StyledStepImageReverse = styled(StepImageReverse)`
  position: relative;
`

const StepArrowImage = styled.div`
  margin: -3.7rem auto;
  display: block;
  position: absolute;
  right: 1rem;
  top: -1.5rem;
  width: 15%;
  transform: rotate(-30deg) scale(0.5);

  g {
    stroke-width: 6;
    ${media.xs} {
      stroke-width: 5;
    }
    ${media.sm} {
      stroke-width: 3;
    }
  }

  margin-left: 1rem;
  margin-top: -2.5rem;

  ${media.xs} {
    margin-left: 3rem;
    margin-top: -1rem;
  }

  ${media.sm} {
    margin: 0;
    top: 0;
    right: 0;
    position: relative;
    margin: -3rem auto;
    width: 100%;
    transform: rotate(0);
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
  }
`
const IndexNumberLabel = styled(Text.xl)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.25rem;
  ${media.md} {
    font-size: 1.7rem;
  }
`

export default Step
