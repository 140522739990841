import useTranslation from 'next-translate/useTranslation'
import styled from 'styled-components'
import Text from '@/style/components/LegacyText'
import { media } from '@/style/helpers'

export function CommissionDealValues() {
  const { t } = useTranslation()
  return (
    <Container>
      <Headline weight="semibold" as="p" uppercase>
        {t('common:you_get')}
      </Headline>
      <CommissionContainer>
        <CommissionRow>
          <Text.md weight="semibold">{t('common:half_of_the_value')}</Text.md>
          <CapitalizedTextXL weight="bold">
            {t('common:immediately')}
          </CapitalizedTextXL>
        </CommissionRow>
        <Divider />
        <ExplanationRow>
          <Text.ms>{t('common:the_other_half_explanation')}</Text.ms>
        </ExplanationRow>
      </CommissionContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const CommissionContainer = styled.div`
  border-radius: ${({ theme }) => theme.radius.normal}px;
  background: ${(props) => props.theme.colors.buttonBorder};
  padding: 1.15rem;
  padding-bottom: 0.35rem;
  margin-top: 1rem;
  ${media.md} {
    padding: 0.75rem 1.75rem;
  }
`

const CommissionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 0.5rem;
`

const Divider = styled.div`
  height: 1px;
  background-color: #e5e5e6;
  width: 100%;
  margin-block: 0.25rem;
`

const ExplanationRow = styled.div`
  padding-block: 0.5rem;
`

const Headline = styled(Text.ms)`
  text-align: center;
`

const CapitalizedTextXL = styled(Text.xl)`
  text-transform: capitalize;
`
