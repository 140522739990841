import { FunctionComponent } from 'react'
import styled from 'styled-components'

const PreviewModeHint = styled.div`
  position: fixed;
  background: #ffcccc;
  color: red;
  padding: 1rem;
  border: 2px solid red;
  border-radius: 5px;
  z-index: 9999;
  left: 1rem;
  top: calc(100% - 4rem);
`

const Logo: FunctionComponent<{ preview?: boolean; children: any }> = ({
  preview,
  children,
}) => {
  if (!preview) {
    return children
  }

  return (
    <div>
      <PreviewModeHint>Preview Mode</PreviewModeHint>
      {children}
    </div>
  )
}

export default Logo
