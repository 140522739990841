import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import React from 'react'
import styled from 'styled-components'
import { media } from '@/style/helpers'
import { BentoGridStoryblok } from '@/types/storyblok-component-types'

type Props = {
  blok: BentoGridStoryblok
}

export default function BentoGrid({ blok }: Props) {
  return (
    <Wrapper {...storyblokEditable(blok)}>
      {blok.rows?.map((r) => {
        return <StoryblokComponent blok={r} key={r._uid} />
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  ${media.md} {
    gap: 3rem;
  }
`
