import CustomDealSuccessModal from '../CustomDealForm/CustomDealSuccessModal'
import { useCreateCustomDeal } from '../CustomDealForm/useCreateCustomDeal'
import { Box } from '@material-ui/core'
import { Form, Formik } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { phone } from 'phone'
import { FunctionComponent, useContext, useMemo } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import AuthDialog from '@/app/auth/components/AuthDialog'
import { SmartTextInput } from '@/app/common/components/Form'
import { InputPhone } from '@/app/common/components/Form/InputPhone'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import useRegionCode from '@/helpers/useRegionCode'
import { Button } from '@/style/components/Button'
import { ErrorBox } from '@/style/components/ErrorBox/ErrorBox'
import {
  CustomDealContactDataArgs,
  CustomDealCreateArgs,
} from '@/types/gql/graphql'
import Contact from './assets/ico_contact.svg'

interface ContactDataModalProps {
  args: CustomDealCreateArgs
}

const ContactDataModal: FunctionComponent<ContactDataModalProps> = ({
  args,
}) => {
  const { t } = useTranslation()

  const regionCode = useRegionCode()
  const modal = useContext(ModalDialogContext)
  const router = useRouter()
  const { createCustomDeal } = useCreateCustomDeal()

  let gqlErrorPretty: undefined | string

  const normalisedPhoneNumber = useMemo(
    () =>
      (phoneNumber: string = '') => {
        if (!phoneNumber?.startsWith('+')) {
          return phone(phoneNumber, {
            validateMobilePrefix: false,
            country: regionCode,
          })
        }
        return phone(phoneNumber, { validateMobilePrefix: false })
      },
    [regionCode],
  )

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .required()
      .test(
        'phoneValidation',
        t('common:errors.phone_number_invalid'),
        function (phoneNumber: string | undefined | null) {
          return (
            Boolean(phoneNumber) &&
            normalisedPhoneNumber(phoneNumber ?? '').isValid
          )
        },
      ),
    email: Yup.string().email().required(),
  })

  return (
    <AuthDialog
      navBar={{
        title: t('common:contact_data.dialog_title'),
      }}
      fixedMobileFooter
      content={
        <>
          <Box textAlign="center" marginTop="10rem" paddingX="2rem">
            <Box display="flex">
              <Box width="45%" borderTop="1px solid #D6D8D1" />
              <Box width="10%" mt="-0.5rem">
                <Contact />
              </Box>
              <Box width="45%" borderTop="1px solid #D6D8D1" />
            </Box>
            <Box
              fontSize="0.875rem"
              fontWeight="600"
              style={{ textTransform: 'uppercase' }}
              mt="0.5rem"
            >
              {t('common:auth.contact_details')}
            </Box>
            <Box
              lineHeight="1.2rem"
              fontSize="0.857rem"
              color="#666"
              marginY="0.75rem"
            >
              {t('common:contact_data.modal_description')}
            </Box>
          </Box>

          <Formik<Partial<CustomDealContactDataArgs>>
            initialValues={{
              email: '',
              phone: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              createCustomDeal({
                variables: {
                  customDealCreateArgs: {
                    ...args,
                    contactData: {
                      phone: values.phone,
                      email: values.email,
                    },
                  },
                },
              }).then((result) => {
                if (result.data) {
                  modal.open(<CustomDealSuccessModal />, {
                    variant: 'full',
                    onAfterClose: () => {
                      router.push('/')
                    },
                  })
                }
              })
            }}
          >
            {(props) => {
              return (
                <Form>
                  <SmartTextInput
                    formikProps={props}
                    type="email"
                    name="email"
                    placeholder={t('common:email_address')}
                  />

                  <InputPhone<CustomDealContactDataArgs>
                    formikProps={props}
                    name="phone"
                    placeHolder={t('common:phone_number')}
                  />

                  {gqlErrorPretty && <ErrorBox message={gqlErrorPretty} />}
                  <SubmitButton
                    primary
                    type="submit"
                    disabled={props.isSubmitting}
                  >
                    {t('common:send')}
                  </SubmitButton>
                </Form>
              )
            }}
          </Formik>
        </>
      }
    />
  )
}

const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 15px;
  padding: 0.5rem 1.5rem;
  justify-content: start;

  :disabled {
    opacity: 0.2;
  }
`

export default ContactDataModal
