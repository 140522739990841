import { ComponentType } from 'react'
import { ERegionCode } from '@/types/gql/graphql'
import AustrianFlag from './flags/at.svg'
import GermanFlag from './flags/de.svg'
import EnglishFlag from './flags/en.svg'

export interface Language {
  name: string
  locale: string
  Flag: ComponentType<{ width: number; height: number }>
  availableIn: ERegionCode[]
}

const languages: Language[] = [
  {
    name: 'Deutsch',
    locale: 'de',
    Flag: AustrianFlag,
    availableIn: [ERegionCode.At],
  },
  {
    name: 'Deutsch',
    locale: 'de',
    Flag: GermanFlag,
    availableIn: [ERegionCode.De],
  },
  {
    name: 'English',
    locale: 'en',
    Flag: EnglishFlag,
    availableIn: [ERegionCode.At, ERegionCode.De],
  },
]

export default languages
