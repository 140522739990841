const { MissingTranslationError } = require('./sentry.errors')
const Sentry = require('@sentry/nextjs')

module.exports = {
  // update next-seo.config.js accordingly
  locales: ['de', 'en'],
  defaultLocale: 'de',
  loadLocaleFrom: (lang, ns) =>
    import(`./locales/${lang}/${ns}.json`).then((m) => m.default),
  logger: (() => {
    const reportedKeys = new Set()
    return ({ namespace, i18nKey }) => {
      const key = `${namespace}:${i18nKey}`
      if (reportedKeys.has(key)) {
        return
      }
      console.error(`No translation for key '${key}'`)
      Sentry.captureException(
        new MissingTranslationError(`No translation for key '${key}'`),
      )
      reportedKeys.add(key)
    }
  })(),
  pages: {
    '*': ['common', 'customDeal', 'blog', 'about', 'vehicle'],
    '/': ['home'],
    '/pawn': ['home'],
    '/purchase': ['home'],
    '/pawn-delivery': ['home'],
    '/customDeal': ['customDeal', 'checkout'],
    '/playground': ['customDeal', 'checkout'],
    '/blog': ['blog', 'home'],
    'rgx:^/shop': ['home'],
    'rgx:^/test': ['test'],
    'rgx:^/checkout': ['checkout'],
    'rgx:^/monetize': ['items', 'customDeal', 'checkout'],
    'rgx:^/item': ['items'],
    'rgx:^/profile': ['common', 'profile'],
    'rgx:^/blog': ['blog', 'home'],
    'rgx:^/about': ['about', 'home'],
    '/impressum': ['impressum'],
    '/privacyPolicy': ['privacyPolicy'],
    '/consumerProtection': ['consumerProtection'],
    '/about': ['about', 'home'],
    'rgx:^/items': ['checkout'],
    'rgx:^/partners': ['home'],
    'rgx:^/car': ['home', 'vehicle'],
    'rgx:^/car-b2b': ['home', 'vehicle'],
    'rgx:^/motorrad': ['home', 'vehicle'],
    '/404': ['notFound'],
    'rgx:^/generateLabel': ['generateLabel', 'profile'],
    'rgx:^/finalize-account-setup': [
      'common',
      'profile',
      'finalizeAccountSetup',
    ],
    'rgx:^/product': ['product'],
  },
}
