import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import styled from 'styled-components'
import { media } from '@/style/helpers'
import { BentoGridRowStoryblok } from '@/types/storyblok-component-types'

type Props = {
  blok: BentoGridRowStoryblok
}

export default function CardItemRow({ blok }: Props) {
  return (
    <Wrapper {...storyblokEditable(blok)}>
      {blok.items?.map((item) => {
        const fullWidth = blok.items?.length === 1
        return (
          <StoryblokComponent
            blok={item}
            key={item._uid}
            fullWidth={fullWidth}
          />
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: auto;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0;

  ${media.md} {
    gap: 3rem;
    flex-direction: row;
    padding: 0 1.5rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
`
