import AuthDialog from '../AuthDialog/AuthDialog'
import Login from '../Login/Login'
import { SuccessConfirmation } from '../SuccessConfirmation/SuccessConfirmation'
import { Form, Formik } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import { useContext, useState } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { SmartTextInput } from '@/app/common/components/Form'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import { useMutation } from '@/app/common/graphql/hooks'
import { Button } from '@/style/components/Button'
import { ErrorBox } from '@/style/components/ErrorBox/ErrorBox'
import Text from '@/style/components/LegacyText'
import { SectionInner } from '@/style/components/Section'
import { media } from '@/style/helpers'
import {
  ResetCustomerPasswordMailDocument,
  ResetCustomerPasswordMailMutation,
  ResetCustomerPasswordMailMutationVariables,
} from '@/types/gql/graphql'
import { parseApolloErrorPretty } from '@/utils/error'

const SubmitButton = styled(Button)`
  width: 100%;
  padding: 0.5rem 1.5rem;
  justify-content: start;

  :disabled {
    opacity: 0.2;
  }
`

const FormWrapper = styled.div`
  margin-top: 160px;

  ${media.xs} {
    text-align: center;
    margin-top: 195px;
  }

  ${media.sm} {
    margin-top: 100px;
  }

  ${media.md} {
    margin-top: 140px;
  }
`

const PaswordRestDescription = styled(Text.ms)`
  display: inline-block;
  margin-top: 8px;
  margin-bottom: 18px;
`

const loginSchema = Yup.object().shape({
  email: Yup.string().email().min(2).required(),
})

type ForgotPasswordProps = {
  email?: string
  fixedMobileFooter?: boolean
}

const ForgotPassword = (props: ForgotPasswordProps) => {
  const { t } = useTranslation()
  const modalDialog = useContext(ModalDialogContext)
  const [forgotPasswordStep, setForgotPasswordStep] = useState<
    'first' | 'success'
  >('first')
  const [email, setEmail] = useState('')

  const [resetCustomerPassword, gqlRes] = useMutation<
    ResetCustomerPasswordMailMutation,
    ResetCustomerPasswordMailMutationVariables
  >(ResetCustomerPasswordMailDocument, {
    disableDefaultErrorHandling: true,
  })

  let gqlErrorPretty: undefined | string

  if (gqlRes.error) {
    gqlErrorPretty = parseApolloErrorPretty(gqlRes.error, t).message
  }

  return (
    <AuthDialog
      navBar={{
        title: t('common:auth.recover_access'),
        link: {
          description: t('common:auth.password_remembered'),
          text: t('common:auth.login'),
          onClick: () =>
            modalDialog.open(<Login />, {
              variant: 'full',
            }),
        },
        backButton:
          forgotPasswordStep === 'success'
            ? () => setForgotPasswordStep('first')
            : undefined,
      }}
      fixedMobileFooter={props.fixedMobileFooter}
      content={
        <FormWrapper>
          {forgotPasswordStep == 'success' ? (
            <SuccessConfirmation
              title={t('common:auth.password_reseted')}
              description={t('common:auth.password_reseted_description', {
                email: email,
              })}
            />
          ) : (
            <>
              <SectionInner textAlign="center" style={{ marginBottom: 10 }}>
                <Text.md weight="semibold">
                  {t('common:auth.password_reset')}
                </Text.md>
                <PaswordRestDescription>
                  {t('common:auth.password_reset_description')}
                </PaswordRestDescription>
              </SectionInner>
              <Formik
                initialValues={{ email: props.email ?? '' }}
                validationSchema={loginSchema}
                onSubmit={async (values) => {
                  resetCustomerPassword({
                    variables: {
                      email: values.email,
                    },
                  })
                    .then(() => {
                      setEmail(values.email)
                      setForgotPasswordStep('success')
                    })
                    .catch(() => {
                      setEmail(values.email)
                      setForgotPasswordStep('success')
                    })
                }}
              >
                {(props) => {
                  return (
                    <Form>
                      <SmartTextInput
                        formikProps={props}
                        type="email"
                        name="email"
                        placeholder="E-Mail"
                        marginBottom="30px"
                      />
                      <SubmitButton
                        primary
                        type="submit"
                        disabled={props.isSubmitting}
                      >
                        {t('common:auth.send')}
                      </SubmitButton>
                    </Form>
                  )
                }}
              </Formik>
              {gqlErrorPretty && <ErrorBox message={gqlErrorPretty} />}
            </>
          )}
        </FormWrapper>
      }
    />
  )
}

export default ForgotPassword
