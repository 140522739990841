import * as Sentry from '@sentry/nextjs'
import Cookies from 'js-cookie'
import { atom } from 'recoil'
import { Customer } from '@/types/gql/graphql'

export type CustomerLoggedInfo =
  | (Pick<
      Customer,
      | '_id'
      | 'firstname'
      | 'lastname'
      | 'email'
      | 'token'
      | 'iban'
      | 'paypalAddress'
      | 'phone'
    > & {
      hasAlreadyDeals?: boolean
    })
  | undefined

const isSSR = typeof window === 'undefined'
const DOMAIN = !isSSR ? window.location.hostname : undefined

export const customerState = atom<CustomerLoggedInfo>({
  key: 'auth:customer', // unique ID (with respect to other atoms/selectors)
  default: undefined, // default value (aka initial value)
})

export const customerAuthFinishedState = atom({
  key: 'auth:customerAuthFinished',
  default: false,
})

const customerTokenChangeCallbacks: Array<(token: string | null) => void> = []

export function getCustomerToken() {
  return Cookies.get('token')
}

export const onCustomerTokenChange = (
  callback: (token: string | null) => void,
) => {
  customerTokenChangeCallbacks.push(callback)
  return () => {
    const index = customerTokenChangeCallbacks.indexOf(callback)
    if (index !== -1) {
      customerTokenChangeCallbacks.splice(index, 1)
    }
  }
}

function notifyCustomerTokenChange(token: string | null) {
  for (const callback of customerTokenChangeCallbacks) {
    callback(token)
  }
}

export const setCustomerToken = async (token: string) => {
  try {
    const newToken = `Bearer ${token}`
    Cookies.set('token', newToken, { expires: 7, domain: DOMAIN })
    notifyCustomerTokenChange(newToken)
  } catch (error) {
    console.error('Failed to set customer token.', error)
    Sentry.captureException(error)
  }
}

export const destroyCustomerToken = () => {
  try {
    Cookies.remove('token', { domain: DOMAIN })
    notifyCustomerTokenChange(null)
  } catch (error) {
    console.error('Failed to destroy customer token.', error)
    Sentry.captureException(error)
  }
}
