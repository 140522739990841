import { CustomDealFormData } from '../ItemList'
import {
  FormAnswerData,
  PRICE_QUESTION_TITLE_KEY,
  PRODUCT_NAME_QUESTION_TITLE_KEY,
} from '../customDeal.service'
import { FormikProps } from 'formik'
import { find, xor } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useQuery } from '@/app/common/graphql/hooks'
import { useLanguageCode } from '@/helpers/useRegionCode'
import {
  CustomDealFormQuestionInitialAnswerArgs,
  CustomDealQuestionNumberRestriction,
  CustomDealQuestionNumberRestrictionArgs,
  ECustomDealFormQuestionType,
  ENumberFilterConditions,
  GetCustomDealFormByCategoryDocument,
  GetCustomDealFormByCategoryQuery,
  GetCustomDealFormByCategoryQueryVariables,
  TranslatedString,
} from '@/types/gql/graphql'
import { BooleanQuestion } from './BooleanQuestion'
import { TextOptionQuestion } from './TextOptionQuestion'
import { TextQuestion } from './TextQuestion'

interface Props {
  isCommissionSale: boolean
  onIsCommissionSaleChange: (isCommissionSale: boolean) => void
  formikProps: FormikProps<CustomDealFormData>
  index: number
  categoryId: string
  formAnswers: FormAnswerData[]
}

const QuestionMap = {
  [ECustomDealFormQuestionType.TextDefault]: function TextDefaultQuestion(
    props: React.ComponentProps<typeof TextQuestion>,
  ) {
    return <TextQuestion {...props} />
  },
  [ECustomDealFormQuestionType.TextMultiline]: function TextMultilineQuestion(
    props: React.ComponentProps<typeof TextQuestion>,
  ) {
    return <TextQuestion {...props} multiline />
  },
  [ECustomDealFormQuestionType.TextOptions]: TextOptionQuestion,
  [ECustomDealFormQuestionType.NumberDefault]: function NumberDefaultQuestion(
    props: React.ComponentProps<typeof TextQuestion>,
  ) {
    return <TextQuestion {...props} type="number" />
  },
  [ECustomDealFormQuestionType.NumberOptions]: function NumberOptionQuestion(
    props: React.ComponentProps<typeof TextOptionQuestion>,
  ) {
    return <TextOptionQuestion {...props} type="number" />
  },
  [ECustomDealFormQuestionType.FileUpload]: null,
  [ECustomDealFormQuestionType.Boolean]: BooleanQuestion,
}

export const checkValidNumberRestriction = (
  option:
    | CustomDealQuestionNumberRestriction
    | CustomDealQuestionNumberRestrictionArgs,
  answer: number,
): boolean => {
  switch (option.conditionOperator) {
    case ENumberFilterConditions.Equals:
      return answer === option.conditionValue
    case ENumberFilterConditions.GreaterThan:
      return answer > option.conditionValue
    case ENumberFilterConditions.GreaterThanOrEqual:
      return answer >= option.conditionValue
    case ENumberFilterConditions.LessThan:
      return answer < option.conditionValue
    case ENumberFilterConditions.LessThanOrEqual:
      return answer <= option.conditionValue
    case ENumberFilterConditions.NotEquals:
      return answer !== option.conditionValue
    default:
      return true
  }
}

const checkValidNumberOptions = (
  restrictions: (Omit<CustomDealQuestionNumberRestriction, 'errorMessage'> & {
    errorMessage: { text: string }
  })[],
  answer: number,
) => {
  return restrictions
    .map((restriction) => {
      return checkValidNumberRestriction(
        restriction as CustomDealQuestionNumberRestriction,
        answer,
      )
        ? null
        : restriction.errorMessage.text
    })
    .filter(Boolean)
}

export const FormQuestions = ({
  formikProps,
  categoryId,
  formAnswers,
  isCommissionSale,
  onIsCommissionSaleChange,
  index,
}: Props) => {
  const { t } = useTranslation()
  const languageCode = useLanguageCode()
  const { setFieldValue, values, getFieldMeta, setFieldTouched } = formikProps

  const { data } = useQuery<
    GetCustomDealFormByCategoryQuery,
    GetCustomDealFormByCategoryQueryVariables
  >(GetCustomDealFormByCategoryDocument, {
    variables: {
      categoryId,
      languageCode,
    },
    skip: !categoryId || !languageCode,
    // TODO: Send invalidations from backend and enable caching
    enableCaching: false,
  })

  const questions = data?.getCustomDealFormByCategory?.questions

  const isAllowedPurchase = data?.getCustomDealFormByCategory?.isAllowedPurchase

  const isUsedForCommissionSale =
    data?.getCustomDealFormByCategory?.isUsedForCommissionSale

  useEffect(() => {
    if (
      isAllowedPurchase != null &&
      values.items[index].isAllowedPurchase !== isAllowedPurchase
    ) {
      setFieldValue(`items.[${index}].isAllowedPurchase`, isAllowedPurchase)
    }
  }, [setFieldValue, values, index, isAllowedPurchase])

  useEffect(() => {
    if (
      isUsedForCommissionSale != null &&
      isCommissionSale !== isUsedForCommissionSale
    ) {
      onIsCommissionSaleChange(isUsedForCommissionSale)
    }
  }, [isUsedForCommissionSale, onIsCommissionSaleChange, isCommissionSale])

  useEffect(() => {
    if (
      xor(
        formAnswers.map(({ question: { _id } }) => _id),
        questions?.map(({ _id }) => _id) ?? [],
      ).length === 0
    )
      return

    const getDefaultAnswer = (
      question: Exclude<
        GetCustomDealFormByCategoryQuery['getCustomDealFormByCategory'],
        null | undefined
      >['questions'][number],
    ) => {
      const formAnswer = formAnswers.find(
        (formAnswer) => formAnswer.question._id === question._id,
      )

      if (question.__typename === 'TextOptionsCustomDealFormQuestion') {
        const defaultOption = question.options.find((option) => option.default)

        return {
          optionTextAnswer:
            question.initialOptionTextAnswer ??
            (defaultOption
              ? defaultOption.label.text
              : formAnswer?.optionTextAnswer ?? ''),
        }
      }

      if (question.__typename === 'NumberOptionsCustomDealFormQuestion') {
        const defaultOption = find(question.options, { default: true })
        return {
          optionNumberAnswer:
            question.initialOptionNumberAnswer ??
            (defaultOption
              ? parseFloat(defaultOption.label.text)
              : formAnswer?.optionNumberAnswer ?? 0),
        }
      }

      if (question.__typename === 'BooleanCustomDealFormQuestion') {
        return {
          booleanAnswer:
            question.initialBooleanAnswer ?? formAnswer?.booleanAnswer ?? null,
        }
      }

      if (question.__typename === 'TextDefaultCustomDealFormQuestion') {
        return {
          textAnswer:
            question.initialTextAnswer ?? formAnswer?.textAnswer ?? '',
        }
      }

      if (question.__typename === 'TextMultilineCustomDealFormQuestion') {
        return {
          textAnswer:
            question.initialMultilineTextAnswer ?? formAnswer?.textAnswer ?? '',
        }
      }

      if (question.__typename === 'NumberDefaultCustomDealFormQuestion') {
        return {
          numberAnswer:
            question.initialNumberAnswer ?? formAnswer?.numberAnswer ?? null,
        }
      }

      if (question.__typename === 'FileUploadCustomDealFormQuestion') {
        return {}
      }

      throw new Error('Invalid question type')
    }

    const getAllTranslations = (translatedString: TranslatedString) =>
      translatedString.allTranslations

    const newFormAnswers: FormAnswerData[] =
      questions?.map((question) => {
        const isOptionType =
          question.__typename === 'TextOptionsCustomDealFormQuestion' ||
          question.__typename === 'NumberOptionsCustomDealFormQuestion'

        let restrictions: CustomDealQuestionNumberRestrictionArgs[] | null =
          null

        if (question.__typename === 'NumberOptionsCustomDealFormQuestion') {
          restrictions = question.restrictions.map((restriction) => ({
            conditionOperator: restriction.conditionOperator,
            conditionValue: restriction.conditionValue,
            errorMessage: [
              {
                text: restriction.errorMessage.text,
                languageCode,
              },
            ],
          }))
        }

        const initialAnswerArgs: CustomDealFormQuestionInitialAnswerArgs = {}

        switch (question.__typename) {
          case 'BooleanCustomDealFormQuestion':
            initialAnswerArgs.booleanAnswer = question.initialBooleanAnswer
            break
          case 'TextOptionsCustomDealFormQuestion':
            initialAnswerArgs.optionTextAnswer =
              question.initialOptionTextAnswer
            break
          case 'NumberOptionsCustomDealFormQuestion':
            initialAnswerArgs.optionNumberAnswer =
              question.initialOptionNumberAnswer
            break
          case 'TextDefaultCustomDealFormQuestion':
            initialAnswerArgs.textAnswer = question.initialTextAnswer
            break
          case 'TextMultilineCustomDealFormQuestion':
            initialAnswerArgs.textAnswer = question.initialMultilineTextAnswer
            break
          case 'NumberDefaultCustomDealFormQuestion':
            initialAnswerArgs.numberAnswer = question.initialNumberAnswer
            break
          case 'FileUploadCustomDealFormQuestion':
            initialAnswerArgs.textAnswer = question.initialFileUploadAnswer
        }

        const formAnswerQuestion = {
          _id: question._id,
          type: question.type,
          __typename: question.__typename,
          titleKey: question.titleKey,
          optional: question.optional,
          showOnIssuanceCertificate: question.showOnIssuanceCertificate,
          showOnSalesBill: question.showOnSalesBill,

          initialAnswerArgs,
          restrictions,
          allowEnterAnswer: isOptionType
            ? question.allowEnterAnswer
            : undefined,
          label: getAllTranslations(question.label),
          info: getAllTranslations(question.info),
        }

        if (question.__typename === 'TextOptionsCustomDealFormQuestion') {
          return {
            ...getDefaultAnswer(question),
            question: {
              ...formAnswerQuestion,
              options: question.options?.map((option) => ({
                ...option,
                description: option.description
                  ? getAllTranslations(option.description)
                  : undefined,
                label: getAllTranslations(option.label),
              })),
            },
          }
        } else if (
          question.__typename === 'NumberOptionsCustomDealFormQuestion'
        ) {
          return {
            ...getDefaultAnswer(question),
            question: {
              ...formAnswerQuestion,
              options: question.options?.map((option) => ({
                ...option,
                label: getAllTranslations(option.label),
              })),
            },
          }
        }

        return {
          ...getDefaultAnswer(question),
          question: formAnswerQuestion,
        }
      }) ?? []

    setFieldValue(`items.[${index}].formAnswers`, newFormAnswers)
  }, [questions, formAnswers, setFieldValue, index, languageCode])

  useEffect(() => {
    const priceFormAnswer = formAnswers.find(
      (formAnwer) =>
        formAnwer.question.type === ECustomDealFormQuestionType.NumberOptions &&
        formAnwer.question.titleKey === PRICE_QUESTION_TITLE_KEY,
    )

    const amount = priceFormAnswer?.optionNumberAnswer

    const titleFormAnswer = formAnswers.find(
      (formAnswer) =>
        formAnswer.question.titleKey === PRODUCT_NAME_QUESTION_TITLE_KEY,
    )

    if (amount) {
      setTimeout(() => {
        setFieldValue(`items.[${index}].payoutAmount`, amount)
      })
    }

    if (titleFormAnswer) {
      let title: string | boolean | number | undefined | null
      if (
        [
          ECustomDealFormQuestionType.TextOptions,
          ECustomDealFormQuestionType.NumberOptions,
        ].includes(titleFormAnswer.question.type)
      ) {
        if (
          titleFormAnswer.question.type ===
          ECustomDealFormQuestionType.NumberOptions
        ) {
          if (
            titleFormAnswer.optionNumberAnswer !== null &&
            titleFormAnswer.optionNumberAnswer !== undefined
          ) {
            title = titleFormAnswer.optionNumberAnswer.toString()
          }
        }

        if (
          titleFormAnswer.question.type ===
          ECustomDealFormQuestionType.TextOptions
        ) {
          if (
            titleFormAnswer.optionTextAnswer !== null &&
            titleFormAnswer.optionTextAnswer !== undefined
          ) {
            title = titleFormAnswer.optionTextAnswer
          }
        }
      } else if (
        titleFormAnswer.question.type === ECustomDealFormQuestionType.Boolean
      ) {
        title = titleFormAnswer.booleanAnswer
      } else if (
        [
          ECustomDealFormQuestionType.TextDefault,
          ECustomDealFormQuestionType.TextMultiline,
        ].includes(titleFormAnswer.question.type)
      ) {
        title = titleFormAnswer.textAnswer
      } else if (
        titleFormAnswer.question.type ===
        ECustomDealFormQuestionType.NumberDefault
      ) {
        title = titleFormAnswer.numberAnswer
      }

      setTimeout(() => {
        setFieldValue(`items.[${index}].title`, title)
      })
    }
  }, [questions, formAnswers, setFieldValue, index])

  const validateNumberQuestion = (
    restrictions: (Omit<CustomDealQuestionNumberRestriction, 'errorMessage'> & {
      errorMessage: { text: string }
    })[],
    answer: number,
  ) => {
    const errorMessage = checkValidNumberOptions(restrictions, answer)?.[0]

    return errorMessage || undefined
  }

  const validateQuestion = (
    question: Exclude<
      GetCustomDealFormByCategoryQuery['getCustomDealFormByCategory'],
      null | undefined
    >['questions'][number],
    answer: number | string | boolean,
  ) => {
    if (question.optional) return undefined

    if (answer === '' || answer === null) return t('common:errors.is_required')

    if (question.__typename === 'NumberOptionsCustomDealFormQuestion') {
      return validateNumberQuestion(question.restrictions, answer as number)
    }

    return undefined
  }

  const onTouched = useCallback(
    (questionIndex: number) => {
      if (!questions) return
      const previousIndex = questionIndex - 1
      const question = questions[previousIndex]
      if (!question) return
      const fieldName = getFormikFieldName(index, previousIndex, question)
      const formikField = getFieldMeta(fieldName)
      if (!formikField.touched) {
        setTimeout(() => {
          setFieldTouched(fieldName, true, true)
        }, 0)
      }
    },

    [getFieldMeta, index, questions, setFieldTouched],
  )

  return (
    <Wrapper>
      {questions?.map((question, i) => {
        const Question = QuestionMap[question.type]

        const isOptionType =
          question.__typename === 'TextOptionsCustomDealFormQuestion' ||
          question.__typename === 'NumberOptionsCustomDealFormQuestion'

        let options: Array<{
          label: string
          value: string | number
          description?: string
        }> = []

        if (question.__typename === 'TextOptionsCustomDealFormQuestion') {
          options = question.options.map((option) => ({
            default: option.default,
            label: option.label.text,
            description: option.description?.text,
            value: option.label.text,
          }))
        } else if (
          question.__typename === 'NumberOptionsCustomDealFormQuestion'
        ) {
          options = question.options.map((option) => ({
            default: option.default,
            label: option.label.text,
            value: parseFloat(option.label.text),
          }))
        }

        const allowEnterAnswer = isOptionType
          ? question.allowEnterAnswer
          : undefined

        return (
          Question && (
            <Question
              key={question._id}
              itemIndex={index}
              questionIndex={i}
              formikProps={formikProps}
              name={getFormikFieldName(index, i, question)}
              label={question.label.text}
              options={options}
              validate={(answer: number | string | boolean) =>
                validateQuestion(question, answer)
              }
              info={question.info.text}
              allowEnterAnswer={allowEnterAnswer}
              onTouched={onTouched}
            />
          )
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 1.5rem 0;
`

const getFormikFieldName = (
  itemIndex: number,
  formAnswerIndex: number,
  question: Exclude<
    GetCustomDealFormByCategoryQuery['getCustomDealFormByCategory'],
    null | undefined
  >['questions'][number],
) => {
  switch (question.__typename) {
    case 'NumberDefaultCustomDealFormQuestion':
      return `items.[${itemIndex}].formAnswers.[${formAnswerIndex}].numberAnswer`
    case 'TextDefaultCustomDealFormQuestion':
      return `items.[${itemIndex}].formAnswers.[${formAnswerIndex}].textAnswer`
    case 'TextMultilineCustomDealFormQuestion':
      return `items.[${itemIndex}].formAnswers.[${formAnswerIndex}].textMultilineAnswer`
    case 'NumberOptionsCustomDealFormQuestion':
      return `items.[${itemIndex}].formAnswers.[${formAnswerIndex}].optionNumberAnswer`
    case 'TextOptionsCustomDealFormQuestion':
      return `items.[${itemIndex}].formAnswers.[${formAnswerIndex}].optionTextAnswer`
    case 'BooleanCustomDealFormQuestion':
      return `items.[${itemIndex}].formAnswers.[${formAnswerIndex}].booleanAnswer`

    default:
      throw new Error('Question type not supported')
  }
}
