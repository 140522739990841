import styled from 'styled-components'
import Sizes from './sizes'

interface H2Props {
  noMargin?: boolean
}

export default styled(Sizes.xl).attrs<H2Props>((props) => ({
  as: 'h2',
  noMargin: props.noMargin || false,
}))<H2Props>`
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : 0.75)}rem;
`
