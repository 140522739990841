import { ChangeEvent, FunctionComponent } from 'react'
import styled from 'styled-components'

interface RadioButtonProps {
  id?: string
  className?: string
  name: string
  value: any
  disabled?: boolean
  checked?: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const Mark = styled.span`
  display: inline-block;
  position: relative;
  border: 2px solid #dfe1e5;
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.secondary};
    opacity: 0;
    left: 50%;
    top: 50%;
    position: absolute;
    transition: all 110ms;
  }
`

const Radio = styled.input`
  position: absolute;
  visibility: hidden;
  display: none;
  &:checked + ${Mark} {
    border-color: #01ff6c;
    &::after {
      width: 14px;
      height: 14px;
      opacity: 1;
      left: 3px;
      top: 3px;
    }
  }
`

const RadioButton: FunctionComponent<RadioButtonProps> = ({
  id,
  className,
  name,
  value,
  disabled,
  checked,
  onChange,
}) => (
  <div className={className ?? ''}>
    <Radio
      checked={checked}
      disabled={disabled}
      type="radio"
      name={name}
      value={value}
      onChange={onChange}
      id={id}
    />
    <Mark />
  </div>
)

export default RadioButton
