import { StoryblokComponent } from '@storyblok/react'
import styled from 'styled-components'
import { media } from '@/style/helpers'
import { FactsStoryblok } from '@/types/storyblok-component-types'

export default function Facts({ blok }: { blok: FactsStoryblok }) {
  const { items } = blok
  return (
    <Wrapper>
      {items.map((item, index) => {
        if (index === 0) {
          return <StoryblokComponent blok={item} key={index} />
        }

        return (
          <>
            <Divider />
            <StoryblokComponent blok={item} key={index} />
          </>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  ${media.sm} {
    flex-direction: row;
  }
`

const Divider = styled.div`
  margin: 0 auto;
  width: 70%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray};

  ${media.sm} {
    width: 1px;
    height: 100%;
  }
`
