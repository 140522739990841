import React, { FunctionComponent, createContext, useContext } from 'react'
import { ConfigStoryblok } from '@/types/storyblok-component-types'

interface StoryblokConfigProps {
  children: React.ReactNode
  config: ConfigStoryblok
}

const StoryblokConfigProvider: FunctionComponent<StoryblokConfigProps> = ({
  config,
  children,
}) => {
  return (
    <StoryblokConfigContext.Provider
      value={{
        config,
      }}
    >
      <>{children}</>
    </StoryblokConfigContext.Provider>
  )
}

interface StoryblokConfigContextType {
  config: ConfigStoryblok
}

const StoryblokConfigContext = createContext<StoryblokConfigContextType>({
  config: {
    _uid: '',
    component: 'config',
  },
})

export const useStoryblokConfig = () => {
  const context = useContext(StoryblokConfigContext)
  if (!context) throw new Error('Need to be wrapped by StoryblokConfigProvider')

  return context
}

export default StoryblokConfigProvider
