import styled from 'styled-components'
import TypeScales from './typescales'

interface H2Props {
  noMargin?: boolean
}

export default styled(TypeScales.h2).attrs<H2Props>((props) => ({
  as: 'h2',
  noMargin: props.noMargin || false,
}))<H2Props>`
  text-transform: uppercase;
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : 0.75)}rem;
`
