import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@/app/common/graphql/hooks'
import { useSearchParams } from '@/helpers/useSearchParams'
import { SelectedLegacyProduct } from '@/types'
import {
  EQuestionPredictionTag,
  GetProductsLegacyWithPaginationDocument,
  GetProductsLegacyWithPaginationQuery,
  GetProductsLegacyWithPaginationQueryVariables,
  ItemAnswer,
  ItemQuestion,
} from '@/types/gql/graphql'

// TODO: type selectedProduct (algolia types)
export function generateDefaultAnswers(
  selectedProduct: SelectedLegacyProduct,
  itemQuestions:
    | Array<
        Pick<
          ItemQuestion,
          | '_id'
          | 'order'
          | 'questionType'
          | 'valueMode'
          | 'titleKey'
          | 'infoKey'
          | 'predictionTag'
          | 'itemCategoryIds'
        >
      >
    | undefined,
) {
  const weight = selectedProduct.gewicht
  const carat = selectedProduct.karat
  const defaultAnswers: ItemAnswer[] = []

  if (weight && weight > 0) {
    const questionWeight = itemQuestions?.find(
      (question) => question.predictionTag === EQuestionPredictionTag.Weight,
    )

    if (questionWeight) {
      defaultAnswers.push({
        questionId: questionWeight._id,
        rangeValue: weight,
      })
    }
  }

  if (carat && carat > 0) {
    const questionCarat = itemQuestions?.find(
      (question) => question.predictionTag === EQuestionPredictionTag.Alloy,
    )

    if (questionCarat) {
      defaultAnswers.push({
        questionId: questionCarat._id,
        rangeValue: carat,
      })
    }
  }

  return defaultAnswers
}

export function useGetPageQueryParam() {
  const queryParams = useSearchParams()

  const parsedPage =
    typeof queryParams.page === 'string' ? parseInt(queryParams.page) : 1
  const page = isNaN(parsedPage) ? 1 : parsedPage

  return page
}

export function useCategoryPageProducts(
  initialQueryVariables: GetProductsLegacyWithPaginationQueryVariables | null,
) {
  const router = useRouter()
  const page = useGetPageQueryParam() // product pagination is on client-side

  const [variables, setVariables] = useState<
    GetProductsLegacyWithPaginationQueryVariables | undefined
  >(
    initialQueryVariables
      ? {
          ...initialQueryVariables,
        }
      : undefined,
  )

  useEffect(() => {
    setVariables(
      initialQueryVariables
        ? {
            ...initialQueryVariables,
          }
        : undefined,
    )
  }, [initialQueryVariables])

  const hitsPerPage = variables?.opts.take ?? 0

  useEffect(() => {
    setVariables((prev) =>
      prev?.opts
        ? {
            opts: {
              ...prev.opts,
              skip: Math.max(0, page - 1) * hitsPerPage,
            },
          }
        : undefined,
    )
  }, [page, hitsPerPage])

  const productsQueryResult = useQuery<
    GetProductsLegacyWithPaginationQuery,
    GetProductsLegacyWithPaginationQueryVariables
  >(GetProductsLegacyWithPaginationDocument, {
    variables,
    skip: !variables,
    enableCaching: true,
  })

  const productsWithPagination =
    productsQueryResult.data?.getProductsLegacyWithPagination
  const products = productsWithPagination?.products ?? []
  const numberOfProductsPages = Math.ceil(
    (productsWithPagination?.count ?? 0) / hitsPerPage,
  )

  const changePage = useCallback((nextPage: number) => {
    router.push(
      `/monetize/[categorySlug]?page=${nextPage}#chooseItemHeader`,
      `/monetize/${router.query.categorySlug}?page=${nextPage}#chooseItemHeader`,
      { shallow: true },
    )
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    productsQueryResult,
    products,
    numberOfProductsPages,
    queryVariable: variables,
    pageNumber: page,
    changePage,
  }
}
