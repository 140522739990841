import { useMemo } from 'react'
import useWindowDimensions_CAN_CAUSE_CLS from './useWindowDimensions'

/**
 * Before you use this, please consider if you might be able to solve your problem by leveraging media queries.
 * The reason this is explicitly discouraged is because it will cause a layout shift. It has to cause a layout shift
 * because we have to default to rendering the desktop layout on the first client render, because that's what we
 * default to during SSR. If we don't, we most likely end up with hydration issues.
 * As mentioned, media queries to dynamically set display: none; usually solves the problem without a big downside,
 * the only downside being that you will end up with redundant elements in the DOM.
 */
export default function useResponsive_CAN_CAUSE_CLS() {
  const { activeBreakpoints } = useWindowDimensions_CAN_CAUSE_CLS()

  const layout: 'desktop' | 'mobile' = useMemo(
    () => (activeBreakpoints.includes('md') ? 'desktop' : 'mobile'),
    [activeBreakpoints],
  )

  return {
    layout,
    activeBreakpoints,
    isDesktop: layout === 'desktop',
  }
}
