import styled from 'styled-components'
import { media } from '@/style/helpers'

export const Container = styled.div`
  h1 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;

    ${media.md} {
      font-size: 39px;
      line-height: 46px;
    }

    ${media.lg} {
      font-size: 52px;
      line-height: 52px;
    }
  }

  h2 {
    text-transform: uppercase;
    font-size: 22px;
    line-height: 22px;

    ${media.sm} {
      font-size: 22px;
      line-height: 24px;
    }

    ${media.md} {
      font-size: 39px;
      line-height: 46px;
    }

    ${media.lg} {
      font-size: 36px;
      line-height: 40px;
    }
  }

  h3 {
    font-size: 18px;
    line-height: 23px;
    font-weight: 600;
    margin-bottom: 1rem;

    ${media.sm} {
      font-size: 18px;
      line-height: 23px;
    }

    ${media.md} {
      font-size: 33px;
      line-height: 44px;
    }

    ${media.lg} {
      font-size: 32px;
      line-height: 40px;
    }
  }

  h4 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 1rem;

    ${media.sm} {
      font-size: 16x;
      line-height: 20px;
    }

    ${media.md} {
      font-size: 24px;
      line-height: 34px;
    }
  }

  a {
    font-size: 16px;
    color: #000 !important;
    font-weight: 500;
  }

  p {
    font-size: 14px;
    line-height: 18px;

    ${media.sm} {
      font-size: 14px;
      line-height: 18px;
    }

    ${media.md} {
      font-size: 16px;
      line-height: 22px;
    }
  }
`

export const Content = styled.div`
  padding: 0 20px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  ${media.sm} {
    padding: 0 30px;
  }

  ${media.md} {
    width: 1200px;
    padding: 0 30px;
  }
`

export const Grid = styled.div`
  display: flex;
  gap: 40px 60px;
  flex-flow: row wrap;

  ${media.md} {
    gap: 90px 60px;
  }
`

export const SectionTitle = styled.h2`
  margin-top: 2rem;
  margin-bottom: 2rem;

  ${media.md} {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
`
