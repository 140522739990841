import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import styled from 'styled-components'
import { ShopsMapReferenceStoryblok } from '@/types/storyblok-component-types'

export default function ShopsMapReference({
  blok,
}: {
  blok: ShopsMapReferenceStoryblok
}) {
  return (
    <Container {...storyblokEditable(blok)}>
      {blok.shop_map && typeof blok.shop_map !== 'string' && (
        <StoryblokComponent blok={blok.shop_map.content} />
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
`
