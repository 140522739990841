import LinkTranslated from 'next/link'
import styled from 'styled-components'
import { UrlObject } from 'url'

const StyledA = styled.a<{
  centerLinkContent: boolean
  removeTextDecoration?: boolean
}>`
  color: unset;
  ${(props) => (props.removeTextDecoration ? 'text-decoration: none;' : '')}

  ${({ centerLinkContent }) =>
    centerLinkContent === true &&
    `
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

interface LinkProps {
  id?: string
  href: UrlObject | string | null
  withoutATag?: boolean
  shallow?: boolean
  removeTextDecoration?: boolean
  centerLinkContent?: boolean
  anchor?: string
  target?: string
  children: React.ReactNode
  useNextLink?: boolean
}

const Link = ({
  id,
  href,
  withoutATag,
  children,
  removeTextDecoration = true,
  centerLinkContent = false,
  shallow,
  anchor,
  useNextLink = true,
  ...props
}: LinkProps) => {
  if (href === null) {
    return <div {...props}>{children}</div>
  }

  const cloneHref = href + (anchor ? `#${anchor}` : '')

  const anchorProps = {
    id,
    removeTextDecoration,
    centerLinkContent,
    ...props,
  }

  if (!useNextLink) {
    return (
      <StyledA href={cloneHref} {...anchorProps}>
        {children}
      </StyledA>
    )
  }

  return (
    <LinkTranslated href={cloneHref} shallow={shallow} passHref legacyBehavior>
      {withoutATag ? children : <StyledA {...anchorProps}>{children}</StyledA>}
    </LinkTranslated>
  )
}

export default Link
