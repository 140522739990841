import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import styled from 'styled-components'
import { ReviewsReferenceStoryblok } from '@/types/storyblok-component-types'

export default function ReviewsReference({
  blok,
}: {
  blok: ReviewsReferenceStoryblok
}) {
  return typeof blok.reviews !== 'string' && blok.reviews?.content ? (
    <Container {...storyblokEditable(blok)}>
      <StoryblokComponent blok={blok.reviews.content} key={blok._uid} />
    </Container>
  ) : null
}

const Container = styled.div``
