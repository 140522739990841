/* eslint-disable jsx-a11y/anchor-is-valid */
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import useTranslation from 'next-translate/useTranslation'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useStoryblokConfig } from '@/app/common/context/storyblokConfigContext'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import { CookiesAccordionItem, IAccordionItem } from './Accordion'

interface IndividualSelectionsScreenProps {
  setIndividualOptions: (payload: boolean[]) => void
  individualOptions: boolean[]
  onCancel: () => void
}

const IndividualSelectionsScreen = (props: IndividualSelectionsScreenProps) => {
  const { individualOptions, setIndividualOptions } = props
  const { t } = useTranslation('de')
  const [isOpened, setOpened] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
    false,
  ])

  const { config } = useStoryblokConfig()

  const data: IAccordionItem[] = [
    {
      title: t('common:cookies.necessary_cookie.title'),
      description: t('common:cookies.necessary_cookie.description'),
      purpose: t('common:cookies.necessary_cookie.purpose'),
      mutable: false,
    },
    {
      title: t('common:cookies.google_analytics.title'),
      description: t('common:cookies.google_analytics.description'),
      purpose: t('common:cookies.google_analytics.purpose'),
      mutable: true,
    },
    {
      title: t('common:cookies.google_ads.title'),
      description: t('common:cookies.google_ads.description'),
      purpose: t('common:cookies.google_ads.purpose'),
      mutable: true,
    },
    {
      title: t('common:cookies.microsoft_ads.title'),
      description: t('common:cookies.microsoft_ads.description'),
      purpose: t('common:cookies.microsoft_ads.purpose'),
      mutable: true,
    },
    {
      title: t('common:cookies.meta_pixel.title'),
      description: t('common:cookies.meta_pixel.description'),
      purpose: t('common:cookies.meta_pixel.purpose'),
      mutable: true,
    },
    {
      title: t('common:cookies.tiktok_pixel.title'),
      description: t('common:cookies.tiktok_pixel.description'),
      purpose: t('common:cookies.tiktok_pixel.purpose'),
      mutable: true,
    },
  ]

  const toggleOpenByIndex = (index: number) => {
    isOpened[index] = !isOpened[index]
    setOpened([...isOpened])
  }
  const toggleCheckedByIndex = (index: number) => {
    individualOptions[index] = individualOptions[index] ? false : true
    setIndividualOptions([...individualOptions])
  }

  return (
    <>
      <ModalHeader onClick={props.onCancel}>
        <ModalHeaderIcon>
          <ArrowBackIos />
        </ModalHeaderIcon>
        <ModalHeaderText>
          {t('common:cookies.intro_header_granular')}
        </ModalHeaderText>
      </ModalHeader>
      <ModalDescription>
        <p>{t('common:cookies.intro_text')}</p>
        <p>
          {t('common:cookies.more_info')}{' '}
          <a
            href={getStoryBlokLink(config.privacy_policy)}
            target="_blank"
          >{`${t('common:cookies.protection')}`}</a>
          ,{' '}
          <a
            href={'/impressum'}
            target="_blank"
          >{`${t('common:cookies.imprint')}`}</a>
          .
        </p>
      </ModalDescription>
      <AccordionsContainer>
        {data.map((item, index) => (
          <CookiesAccordionItem
            key={index}
            index={index}
            isExpanded={isOpened[index]}
            onChange={() => toggleOpenByIndex(index)}
            data={item}
            toggleChecked={() => toggleCheckedByIndex(index)}
            isChecked={individualOptions[index]}
            mutable={item.mutable}
          />
        ))}
      </AccordionsContainer>
    </>
  )
}

export default IndividualSelectionsScreen

const ModalHeader = styled.div`
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
`

const ModalHeaderIcon = styled.div`
  width: 14px;
  height: 14px;
  margin-right: 0.25rem;

  svg {
    width: 100%;
    height: 100%;
  }
`

const ModalHeaderText = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
`

const ModalDescription = styled.h6`
  font-size: 0.75rem;
  line-height: 0.875rem;

  > p:first-child {
    margin-bottom: 0.5rem;
  }

  a {
    font-weight: 700;
    text-decoration: underline;
    color: #000;
  }
`

const AccordionsContainer = styled.div`
  width: 100%;
  margin-top: 0.75rem;
`
