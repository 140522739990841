import * as Sentry from '@sentry/nextjs'
import { omit } from 'lodash'
import { useRouter } from 'next/router'
import React from 'react'
import { useRecoilState } from 'recoil'
import { redirectToCorrectDomainState } from '@/app/common/common.state'
import { useModalDialogContext } from '@/app/common/context/modalDialogContext'
import { useIsSSR } from '@/helpers/useIsSSR'
import CookiesModal, { CONSENT_MODE_LOCAL_STORAGE_KEY } from './CookiesModal'
import { isCookieConsentMode } from './types'

const forcingVarName = 'changeCookiePreferences'

const getForcingValueFromUrl = (url: string) => {
  return new URLSearchParams(url).get(forcingVarName)
}

export default function CookiesConsent() {
  /**
   * For cookies usage we need to put in
   * useEffect to void hydration error
   */
  const { open: openDialog } = useModalDialogContext()
  const router = useRouter()
  const isSSR = useIsSSR()

  const [redirectToCorrectDomain] = useRecoilState(redirectToCorrectDomainState)

  React.useEffect(() => {
    if (isSSR) return

    const changeCookiePreferences = router.query?.[forcingVarName] as string
    const force =
      (changeCookiePreferences || getForcingValueFromUrl(location.search)) ===
      '1'

    if (
      consentModeSet(force) ||
      redirectToCorrectDomain === null ||
      redirectToCorrectDomain === undefined ||
      router.query.slug?.includes('privacyPolicy') ||
      router.query.slug?.includes('impressum')
    ) {
      return
    }

    openDialog(<CookiesModal consent={getConsentMode()} />, {
      onAfterClose: () => {
        router.push(
          {
            pathname: router.pathname,
            query: omit(router.query, 'changeCookiePreferences'),
          },
          undefined,
          { shallow: true },
        )
      },
      customStyles: {
        content: {
          backgroundColor: 'unset',
        },
        overlay: {
          zIndex: 1000,
        },
      },
      variant: 'full',
      shouldCloseOnEsc: false,
      shouldCloseOnOverlayClick: false,
      customClass: 'cookies-modal',
    })
  }, [redirectToCorrectDomain, openDialog, isSSR, router])

  return <></>
}

const getConsentMode = () => {
  const consentString = localStorage.getItem(CONSENT_MODE_LOCAL_STORAGE_KEY)
  if (!consentString) {
    return
  }

  try {
    const consent = JSON.parse(consentString)

    if (!isCookieConsentMode(consent)) {
      return
    }

    return consent
  } catch (error) {
    Sentry.captureException(error)
  }
}

function consentModeSet(force = false): boolean {
  if (typeof localStorage === 'undefined' || force) {
    return false
  }

  const consent = getConsentMode()
  return !!consent
}
