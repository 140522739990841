import { CarCalculationWithNoValuation } from '../CarCalculationWithNoValuation/CarCalculationWithNoValuation'
import { CarCalculationWithValuation } from '../CarCalculationWithValuation/CarCalculationWithValuation'
import IndicataCarSelection from '../IndicataCarSelection/IndicataCarSelection'
import { AutoDocumentLinks } from '../TermsAndSubmit/TermsAndSubmit'
import {
  CarProperty,
  CustomVehicleFormData,
  OnAfterDealBookedArgs,
  OnBeforeDealBookedArgs,
} from '../VehicleDealBooking'
import { Box } from '@material-ui/core'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useState } from 'react'
import { InputErrorMessage } from '@/app/common/components/Form'
import { UseFormReturnType } from '@/app/common/components/Form/useForm'
import WhatsAppSectionCarPawn from '@/app/common/components/WhatsAppSectionCarPawn/WhatsAppSectionCarPawn'
import useCompany from '@/helpers/useCompany'
import Collapse from '@/style/components/Collapse'
import { TextInput } from '@/style/components/TextInput'
import { EVehicleCategory } from '@/types'
import { ItemAnswerArgs, ItemQuestion } from '@/types/gql/graphql'
import { getTranslatedValidationError } from '@/utils/error'

interface CarRequestProps {
  activeCustomRequestForm: () => void
  formikProps: UseFormReturnType<CustomVehicleFormData>
  pawnTimeOptionsInMonths: number[]
  allAnswersUpdatedArgs: ItemAnswerArgs[]
  baseVehicleCategoryId: EVehicleCategory
  vehicleCategoryId: EVehicleCategory
  carStillUsingQuestion: Omit<ItemQuestion, 'validTo' | 'validFrom'> | undefined
  documentLinks: AutoDocumentLinks
  setIsCustomRequest: (value: boolean) => void
  onBeforeDealBooked?: (args: OnBeforeDealBookedArgs) => void | Promise<void>
  onAfterDealBooked?: (args: OnAfterDealBookedArgs) => void | Promise<void>
}

const CarRequest: FunctionComponent<CarRequestProps> = ({
  activeCustomRequestForm,
  formikProps,
  pawnTimeOptionsInMonths,
  allAnswersUpdatedArgs,
  baseVehicleCategoryId,
  carStillUsingQuestion,
  setIsCustomRequest,
  onBeforeDealBooked,
  onAfterDealBooked,
  vehicleCategoryId,
  documentLinks,
}) => {
  const { t } = useTranslation()
  const { odometer } = formikProps.values
  const [lastStepHref, setLastStepHref] = useState<string>()
  const [carPriceLastFetched, setCarPriceLastFetched] = useState<Date>()
  const [vehicleProperties, setVehicleProperties] = useState<CarProperty[]>([])
  const [isNoValuationCar, setIsNoValuationCar] = useState(false)

  const company = useCompany()

  const isValidOdometer =
    !formikProps.errors.odometer && !!odometer && odometer > 0

  const setCarDataForCustomRequest = (vehicleProperties: CarProperty[]) => {
    if (vehicleProperties && vehicleProperties.length) {
      for (const vh of vehicleProperties) {
        if (vh.name === 'make') {
          formikProps.setFieldValue('make', vh.selected?.name)
        } else if (vh.name === 'model') {
          formikProps.setFieldValue('model', vh.selected?.name)
        } else if (vh.name === 'regdate') {
          formikProps.setFieldValue('regdate', vh.selected?.name)
        } else if (vh.name === 'engine' && vh.selected) {
          const horsePowerNumber = vh.selected?.name.match(
            /([0-9]+)*([PS]{2})/,
          )?.[1] as string
          formikProps.setFieldValue('horsePower', horsePowerNumber || '0')
        }
      }
    }
  }

  const handleSetLastStepHref = (href: string | undefined) => {
    setLastStepHref(href)
    setCarDataForCustomRequest(vehicleProperties)
  }

  return (
    <>
      <IndicataCarSelection
        vehicleProperties={vehicleProperties}
        setLastStepHref={handleSetLastStepHref}
        setVehicleProperties={setVehicleProperties}
        setIsNoValuationCar={setIsNoValuationCar}
        setIsCustomRequest={setIsCustomRequest}
      />

      {lastStepHref && (
        <Box>
          <TextInput
            type="number"
            label={t('common:car.odometer')}
            name="odometer"
            id="ODOMETER_INPUT"
            value={formikProps.values.odometer}
            onChange={(e) =>
              formikProps.setFieldValue('odometer', parseInt(e.target.value))
            }
          />
          {formikProps.errors.odometer &&
            !formikProps.errors.odometer?.includes('NaN') && (
              <Box marginBottom="1rem">
                <InputErrorMessage
                  message={getTranslatedValidationError(
                    'common:errors.car_pawn_odometer_too_big',
                    t,
                  )}
                />
              </Box>
            )}
        </Box>
      )}

      <Collapse title={t('common:you_couldnt_find_your_car')}>
        <WhatsAppSectionCarPawn
          activeCustomRequestForm={activeCustomRequestForm}
          whatsappLink={documentLinks.whatsappLink}
        />
      </Collapse>

      {isValidOdometer && !!lastStepHref && !!company && (
        <>
          {isNoValuationCar ? (
            <CarCalculationWithNoValuation
              formikProps={formikProps}
              company={company}
              allAnswersUpdatedArgs={allAnswersUpdatedArgs}
              pawnTimeOptionsInMonths={pawnTimeOptionsInMonths}
              baseVehicleCategoryId={baseVehicleCategoryId}
              vehicleCategoryId={vehicleCategoryId}
              vehicleProperties={vehicleProperties}
              onBeforeDealBooked={onBeforeDealBooked}
              onAfterDealBooked={onAfterDealBooked}
              documentLinks={documentLinks}
            />
          ) : (
            <CarCalculationWithValuation
              formikProps={formikProps}
              company={company}
              allAnswersUpdatedArgs={allAnswersUpdatedArgs}
              pawnTimeOptionsInMonths={pawnTimeOptionsInMonths}
              baseVehicleCategoryId={baseVehicleCategoryId}
              vehicleCategoryId={vehicleCategoryId}
              vehicleProperties={vehicleProperties}
              onBeforeDealBooked={onBeforeDealBooked}
              onAfterDealBooked={onAfterDealBooked}
              setCarPriceLastFetched={setCarPriceLastFetched}
              carStillUsingQuestion={carStillUsingQuestion}
              carPriceLastFetched={carPriceLastFetched}
              lastStepHref={lastStepHref}
              activeCustomRequestForm={activeCustomRequestForm}
              setIsNoValuationCar={setIsNoValuationCar}
              documentLinks={documentLinks}
            />
          )}
        </>
      )}
    </>
  )
}

export default CarRequest
