import styled from 'styled-components'
import { media } from '@/style/helpers'

const QuestionMarkStyled = styled.div`
  background-color: #d2d4d9;
  color: white;
  font-size: 0.82rem;
  font-weight: 500;
  border-radius: 50%;
  height: 1.2rem;
  width: 1.2rem;
  line-height: 1.2rem;
  cursor: pointer;
  margin-left: 0.5rem;
  padding-left: 0.4rem;
  letter-spacing: 0.4rem;

  & div {
    font-size: 0.82rem;
    font-weight: 500;

    ${media.md} {
      font-size: 1rem;
    }
  }
`

const Wrapper = styled.span`
  display: inline-block;
`

interface QuestionMarkProps {
  id?: string
  onClick: () => void
}

export const QuestionMark: React.FC<QuestionMarkProps> = (props) => {
  return (
    <Wrapper>
      <QuestionMarkStyled id={props.id} onClick={props.onClick}>
        <div style={{ marginLeft: -1 }}>?</div>
      </QuestionMarkStyled>
    </Wrapper>
  )
}
