import { InputErrorMessage } from '../Form'
import { Typography } from '@material-ui/core'
import Compressor from '@uppy/compressor'
import Uppy, { UppyFile } from '@uppy/core'
import '@uppy/core/dist/style.css'
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import Tus from '@uppy/tus'
import { FormikProps } from 'formik'
import { compact, uniqueId } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import config, { buildApiUri } from '@/config'
import { Button } from '@/style/components/Button'
import media from '@/style/helpers/media'
import { FileMetadata } from '@/types/gql/graphql'
import { getTranslatedValidationError } from '@/utils/error'
import IconClose from './assets/iconClose.svg'
import UploadIcon from './assets/imageUpload.svg'

type UppyFileObject = UppyFile

interface UploaderProps {
  label: React.ReactNode
  formikProps: FormikProps<any>
  name: string
  id?: string
  compress?: boolean
  multiple?: boolean
}

enum UppyError {
  FILE_DUPLICATION = 'FILE_DUPLICATION',
  EXCEED_MAX_FILE_SIZE = 'EXCEED_MAX_FILE_SIZE',
  FILE_TYPE_UNALLOWED = 'FILE_TYPE_UNALLOWED',
}

const ALLOWED_FILE_TYPES = ['image/*', '.jpg', '.jpeg', '.png', '.gif']
const MAX_FILE_SIZE_MB = 16

export type FileArgs = UppyFileObject | FileMetadata

const Uploader = ({
  label,
  formikProps,
  name,
  id,
  compress = true,
  multiple = true,
}: UploaderProps) => {
  const { t } = useTranslation()

  const [uppyRestrictionError, setUppyRestrictionError] = useState<
    string | null
  >(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const uppy = useMemo(() => {
    if (typeof window === 'undefined') {
      return null
    }

    const instance = new Uppy({
      id: 'uppy-' + uniqueId(),
      autoProceed: true,
      restrictions: {
        maxFileSize: MAX_FILE_SIZE_MB * 10000000,
        allowedFileTypes: ALLOWED_FILE_TYPES,
      },
      locale: {
        strings: {
          noDuplicates: UppyError.FILE_DUPLICATION,
          exceedsSize: UppyError.EXCEED_MAX_FILE_SIZE,
          youCanOnlyUploadFileTypes: UppyError.FILE_TYPE_UNALLOWED,
        },
      },
    })

    if (compress) {
      // for more option check core docs (not uppy docs)
      // https://github.com/fengyuanchen/compressorjs/blob/main/README.md#options
      instance.use(Compressor, {
        quality: 0.75,
        maxWidth: 1920,
        maxHeight: 1920,
      } as any)
    }

    instance
      .use(Tus, {
        endpoint: buildApiUri(window.location.host, 'http(s)', config.tusRoute),
        retryDelays: [0, 1000, 3000, 5000],
      })
      .use(ThumbnailGenerator, {
        id: 'ThumbnailGenerator',
      })

    return instance
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const field = formikProps.getFieldMeta(name)

  const fieldHelpers = formikProps.getFieldHelpers(name)

  const images = field?.value as Array<FileArgs>

  useEffect(() => {
    if (uppyRestrictionError) {
      setTimeout(() => {
        setUppyRestrictionError(null)
      }, 5000)
    }
  }, [uppyRestrictionError])

  const handleUppyError = useCallback((file: File, error: any) => {
    if (!(error instanceof Error)) {
      setUppyRestrictionError(t(`common:errors.unexpected_exception`))
      return
    }

    switch (error.message) {
      case UppyError.FILE_DUPLICATION: {
        setUppyRestrictionError(
          t(`common:errors.uppy_errors.file_duplication`, {
            filename: file.name,
          }),
        )
        return
      }

      case UppyError.EXCEED_MAX_FILE_SIZE: {
        setUppyRestrictionError(
          t(`common:errors.uppy_errors.exceed_max_file_size`, {
            size: MAX_FILE_SIZE_MB,
          }),
        )
        return
      }

      case UppyError.FILE_TYPE_UNALLOWED: {
        setUppyRestrictionError(
          t(`common:errors.uppy_errors.file_type_unallowed`, {
            allowedFileTypes: ALLOWED_FILE_TYPES.join(', '),
          }),
        )
        return
      }

      default: {
        setUppyRestrictionError(t(`common:errors.unexpected_exception`))
        return
      }
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onUppyFileChanges = useCallback(
    (uppyFile?: UppyFileObject) => {
      if (!uppyFile) return

      const newFile = uppy?.getFile(uppyFile.id)
      if (!newFile) {
        return
      }

      if (
        !images.some((image) => isUppyFile(image) && image.id === newFile.id)
      ) {
        fieldHelpers.setValue([...(images ?? []), newFile])
      } else {
        fieldHelpers.setValue(
          images.map((image) => {
            if (isUppyFile(image) && image.id === newFile.id) {
              return newFile
            }

            return image
          }),
        )
      }
    },
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uppy, images],
  )

  const onFileRemoved = useCallback(
    (file: FileArgs, fileIndex: number) => {
      if (isUppyFile(file)) {
        uppy?.removeFile(file.id)
      }

      fieldHelpers.setValue(
        images.filter((image, index) => index !== fileIndex),
      )
    },
    [uppy, fieldHelpers, images],
  )

  const onFilesAdded = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) {
        return
      }
      const files = Array.from(e.target.files)

      for (const file of files) {
        try {
          uppy?.addFile({
            source: 'file input',
            name: file.name,
            type: file.type,
            data: file,
          })
        } catch (error) {
          handleUppyError(file, error as Error)
        }
      }

      ;(e.target.value as any) = null
    },
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uppy],
  )

  useEffect(() => {
    uppy?.on('thumbnail:generated', onUppyFileChanges)
    uppy?.on('upload-success', onUppyFileChanges)
    uppy?.on('upload-progress', onUppyFileChanges)

    return () => {
      uppy?.off('thumbnail:generated', onUppyFileChanges)
      uppy?.off('upload-success', onUppyFileChanges)
      uppy?.off('upload-progress', onUppyFileChanges)
    }
  }, [uppy, onUppyFileChanges])

  const error = field.error && field.touched ? field.error : undefined

  const errorMessages = Array.isArray(error)
    ? compact(error).map((errorData) => errorData.response?.uploadURL)
    : [error]

  return (
    <Fragment>
      <InputWrapper error={error !== undefined}>
        <InputWrapperHeader
          type="button"
          onClick={() => {
            !multiple && images.length > 0 ? null : inputRef?.current?.click()
          }}
        >
          {label}

          <UploadIcon
            style={{
              display: !multiple && images.length > 0 ? 'none' : 'block',
            }}
          />
          <StyledInput
            id={id}
            onChange={onFilesAdded}
            ref={inputRef}
            type="file"
            multiple={multiple}
            accept="image/png, image/jpeg, image/gif"
          />
        </InputWrapperHeader>
        {images.length > 0 && (
          <ImageListWrapper>
            {images.map((image, i) => {
              if (isUppyFile(image)) {
                return (
                  <ImageWrapper key={i}>
                    <ImageStyled
                      src={image.preview}
                      isUploaded={!!image.response?.uploadURL}
                    />
                    <CloseButton onClick={() => onFileRemoved(image, i)}>
                      <IconClose style={{ width: 14, height: 14 }} />
                    </CloseButton>
                    {!image.response?.uploadURL && (
                      <ProgressWrapper>
                        <Typography
                          style={{
                            fontWeight: 600,
                            color: 'white',
                            fontSize: 23,
                          }}
                        >
                          {image.progress?.percentage ?? 0} %
                        </Typography>
                      </ProgressWrapper>
                    )}
                  </ImageWrapper>
                )
              }

              return (
                <ImageWrapper key={i}>
                  <ImageStyled src={image.url} isUploaded />
                  <CloseButton onClick={() => onFileRemoved(image, i)}>
                    <IconClose style={{ width: 14, height: 14 }} />
                  </CloseButton>
                </ImageWrapper>
              )
            })}
          </ImageListWrapper>
        )}
      </InputWrapper>
      {errorMessages.filter(Boolean).map((errorMessage, index) => (
        <InputErrorMessage
          key={index}
          message={getTranslatedValidationError(errorMessage, t)}
        />
      ))}
      {uppyRestrictionError && (
        <InputErrorMessage message={uppyRestrictionError} />
      )}
    </Fragment>
  )
}

const isUppyFile = (file: FileArgs): file is UppyFileObject => 'id' in file

const ImageWrapper = styled.li`
  display: inline-block;
  position: relative;
  margin-right: 10px;
`

const InputWrapper = styled.div<{ error?: boolean }>`
  border: 2px solid
    ${(props) => (!props.error ? props.theme.colors.border : '#f44336')};
  border-radius: ${({ theme }) => theme.radius.normal}px;
`

const InputWrapperHeader = styled(Button)`
  display: flex;
  align-items: center;
  min-height: 2.5rem;
  width: 100%;
  padding: 0;
  border: 0px;
  border-radius: 0px;
  transition: unset;
  justify-content: space-between;
  padding-right: 1.063rem;
  ${media.sm} {
    min-height: 3rem;
  }
`

const ImageStyled = styled.img<{ isUploaded?: boolean }>`
  max-height: 100px;
  max-width: 100%;
  filter: ${(props) =>
    props.isUploaded ? 'brightness(100%)' : 'brightness(50%)'};
`

const StyledInput = styled.input`
  display: none;
`

const CloseButton = styled.span`
  top: 3px;
  right: 3px;
  position: absolute;
  cursor: pointer;
  z-index: 100;
`

const ImageListWrapper = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 10px;
  padding-top: 0px;
`

const ProgressWrapper = styled.div`
  top: 0px;
  right: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Uploader
