import React, { FunctionComponent } from 'react'
import { MdChevronLeft, MdChevronRight, MdTune } from 'react-icons/md'
import styled, { css } from 'styled-components'
import Button, { ButtonProps } from './Button'
import Phone from './icons/phone.svg'

interface ButtonWithIconProps extends React.ComponentPropsWithoutRef<'a'> {
  icon: 'phone' | 'chevronLeft' | 'chevronRight' | 'tune'
  className?: string
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>
  children?: React.ReactNode
}

const StyledButton = styled(Button)<ButtonProps>`
  display: inline-flex;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  margin: 0.25rem;

  ${({ appearance }) =>
    appearance === 'white' &&
    css`
      border-color: ${({ theme }) => theme.colors.white};
      &:hover {
        border-color: black;
      }
    `}

  svg {
    font-size: 2em;
    margin: -0.25em -0.36em;
  }

  svg path {
    transition: fill 0.2s ease;

    ${({ appearance }) =>
      appearance === 'form' &&
      css`
        fill: ${({ theme }) => theme.colors.black};
      `}
  }

  &:hover svg path {
    @media (hover: hover) {
      fill: black;
    }

    @media (hover: none) {
      fill: white;
    }

    ${({ appearance }) =>
      appearance === 'white' &&
      css`
        fill: ${({ theme }) => theme.colors.white};
        @media (hover: hover) {
          fill: white;
        }
      `}
  }

  span {
    display: inline-block;
    margin-left: 0.75em;
    padding-left: 0.75em;
    padding-right: 0.5em;
    border-left: 1px solid ${({ theme }) => theme.colors.buttonBorder};
  }
`

const PhoneIcon = styled(Phone)`
  width: 1em;
  margin: 0 -0.2rem !important;
`

const ChevronLeftIcon = styled(MdChevronLeft)`
  margin-right: -0.3em !important;
`

const ChevronRightIcon = styled(MdChevronRight)`
  margin-left: -0.3em !important;
`

const TuneIcon = styled(MdTune)`
  margin-left: -0.3em !important;
  scale: 0.75;
`

const ButtonWithIcon: FunctionComponent<ButtonWithIconProps & ButtonProps> = ({
  icon,
  className,
  children,
  as,
  href,
  appearance,
  onClick,
  weight,
}) => (
  <StyledButton
    className={className}
    as={as || 'button'}
    href={href}
    appearance={appearance}
    onClick={onClick}
    weight={weight}
  >
    {'phone' === icon && <PhoneIcon />}
    {'chevronLeft' === icon && <ChevronLeftIcon />}
    {'chevronRight' === icon && <ChevronRightIcon />}
    {'tune' === icon && <TuneIcon />}
    {children && <span>{children}</span>}
  </StyledButton>
)

export default ButtonWithIcon
