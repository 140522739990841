import theme from '../../themes/default'
import GlobalStyle from '../GlobalStyle'
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles'
import React from 'react'
import { ThemeProvider } from 'styled-components'

const muiTheme = createTheme({
  palette: {
    primary: {
      main: theme.colors.primary,
    },
  },
  typography: {
    fontFamily: 'GalanoGrotesque, sans-serif',
  },
})

const ThemeWrapper = ({ children }: { children: React.ReactNode }) => (
  <ThemeProvider theme={theme}>
    <MuiThemeProvider theme={muiTheme}>
      <GlobalStyle />
      {children}
    </MuiThemeProvider>
  </ThemeProvider>
)

export default ThemeWrapper
