import { Slider, withStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { round } from 'lodash'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Text from '@/style/components/LegacyText'

const Wrapper = styled.div<{
  hidden: boolean
}>`
  ${(props) =>
    props.hidden &&
    `
  display: none !important;
`}
  margin: 1rem 0;
  background-color: white;
  border: 2px solid ${(props) => props.theme.colors.border};
  border-radius: ${({ theme }) => theme.radius.normal}px;
`

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.75rem 1.25rem;
  text-align: center;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const SliderWrapper = styled.div`
  background-color: white;
  padding: 0.5rem 1.25rem;
  padding-bottom: 0.25rem;
  border-top: 2px solid #e9e9e9;
`

const MiddleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const InputWrapper = styled.div`
  text-align: left;
`

const InputStyled = styled.input`
  -moz-appearance: textfield !important;
  -webkit-appearance: none;
  border: none;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: right;
  width: 51.5%;
  margin-right: 0.2rem;

  :focus {
    outline: none;
  }
`

const MinusIconWrapper = styled.span`
  cursor: pointer;
  border-right: 2px solid ${(props) => props.theme.colors.border};
  padding-right: 0.75rem;
  align-self: baseline;
  transition: color 0.2s ease;

  @media (hover: hover) {
    svg:hover {
      color: rgba(0, 178, 78);
    }
  }

  @media (hover: none) {
    svg:hover {
      color: black;
    }
  }
`

const PlusIconWrapper = styled.span`
  cursor: pointer;
  border-left: 2px solid ${(props) => props.theme.colors.border};
  padding-left: 0.75rem;
  align-self: baseline;
  transition: color 0.2s ease;

  @media (hover: hover) {
    svg:hover {
      color: rgba(0, 178, 78);
    }
  }

  @media (hover: none) {
    svg:hover {
      color: black;
    }
  }
`

const SliderStyled = withStyles({
  root: {
    color: '#01FF6C',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid #000',
    marginTop: -8,
    marginLeft: -12,
    transition: 'border-color .2s ease',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
      borderColor: 'rgba(0,178,78)',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider)

interface SliderInputProps {
  min?: number
  max?: number
  step?: number
  value?: number
  label: string
  slug: string
  unit?: string
  hidden?: boolean
  onChange?: (value: number) => void
  decimalPlaces?: number
}

const SliderInput: React.FC<SliderInputProps> = (props) => {
  const {
    min = 0,
    max = 100,
    step = 1,
    value = 0,
    label,
    onChange,
    decimalPlaces = 0,
    slug,
  } = props

  const [inputValue, setInputValue] = useState<string | undefined>()

  useEffect(() => {
    setInputValue(value.toString())
  }, [value])

  const handleChange = (value: number) => {
    if (value >= min && value <= max && onChange) onChange(value)
  }

  const handleInputValueChange = (value: string) => {
    const parsedValue = round(parseFloat(value), decimalPlaces)

    setInputValue(parsedValue + '')

    if (typeof parsedValue === 'number' && !isNaN(parsedValue)) {
      handleChange(parsedValue)
    }
  }

  return (
    <Wrapper hidden={!!props.hidden}>
      <TopWrapper>
        <MinusIconWrapper
          id={`${slug}_MINUS_ICON`}
          onClick={() => handleChange(round(value - step, decimalPlaces))}
        >
          <RemoveIcon />
        </MinusIconWrapper>
        <MiddleWrapper>
          <Text.sm color="gray">{label}</Text.sm>
          <InputWrapper>
            <InputStyled
              type="number"
              id={`${slug}_TEXT_INPUT`}
              value={inputValue ?? ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleInputValueChange(e.target.value)
              }}
              onBlur={() => {
                setInputValue(value.toString())
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                const invalidChars = ['.', ',', '+', '-']

                if (decimalPlaces === 0) {
                  if (invalidChars.includes(e.key)) e.preventDefault()
                }
              }}
            />
            <Text.ms weight="semibold">{props.unit}</Text.ms>
          </InputWrapper>
        </MiddleWrapper>
        <PlusIconWrapper
          id={`${slug}_PLUS_ICON`}
          onClick={() => handleChange(round(value + step, decimalPlaces))}
        >
          <AddIcon />
        </PlusIconWrapper>
      </TopWrapper>
      <SliderWrapper>
        <SliderStyled
          value={value}
          id={`${slug}_SLIDER_INPUT`}
          onChange={(e, newValue) => handleChange(newValue as number)}
          aria-labelledby="input-slider"
          step={step}
          min={min}
          max={max}
        />
      </SliderWrapper>
    </Wrapper>
  )
}

export default SliderInput
