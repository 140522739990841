import { NextPage } from 'next'
import useTranslation from 'next-translate/useTranslation'
import styled from 'styled-components'
import Text from '@/style/components/LegacyText'
import { media } from '@/style/helpers'

const Wrapper = styled.div`
  text-align: center;
`

const FontTitle = styled(Text.lg)`
  font-size: 20px;
  margin-bottom: 1rem;
  font-weight: 600;

  ${media.md} {
    font-size: 23px;
  }
`

export const FormHeading: NextPage = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <FontTitle>{t('customDeal:other_item')}</FontTitle>
    </Wrapper>
  )
}
