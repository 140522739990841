import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { ConfiguratorGroup } from '@/domains/items/components/ProductConfigurator'
import useRegionCode from '@/helpers/useRegionCode'
import { EVehicleCategory } from '@/types'
import { ERegionCode } from '@/types/gql/graphql'

interface VehiclePawnableQuestionsProps {
  baseVehicleCategoryId: EVehicleCategory
  onChangePawnable: (pawnable: boolean) => void
}

const VehiclePawnableQuestions: FunctionComponent<
  VehiclePawnableQuestionsProps
> = ({ onChangePawnable, baseVehicleCategoryId }) => {
  const { t } = useTranslation()
  const [leasing, setLeasing] = useState(false)
  const [workable, setWorkable] = useState(true)
  // mortaged is only for germany
  const [parkCarAtCashy, setParkCarAtCashy] = useState(true)

  const regionCode = useRegionCode()
  const isGermanyRegion = regionCode === ERegionCode.De

  const vehicleName = useMemo(() => {
    if (
      [EVehicleCategory.CAR, EVehicleCategory.CAR_STORED].includes(
        baseVehicleCategoryId,
      )
    )
      return t('vehicle:types.car')
    return t('vehicle:types.motorcycle')
  }, [baseVehicleCategoryId, t])

  useEffect(() => {
    let pawnable = workable && !leasing
    if (isGermanyRegion) {
      pawnable = pawnable && parkCarAtCashy
    }

    onChangePawnable(pawnable)
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leasing, workable, parkCarAtCashy])

  return (
    <>
      <ConfiguratorGroup
        style={{ margin: '1.25rem 0' }}
        slug={'LEASING'}
        value={leasing}
        onChange={setLeasing}
        headline={t('vehicle:pawn.lease_or_financed_headline', { vehicleName })}
        subline={t('vehicle:pawn.lease_or_financed_subline', { vehicleName })}
        options={[
          {
            name: t('common:yes'),
            value: true,
            color: 'red',
            description: t('vehicle:pawn.we_cant_accept_leased_cars'),
            slug: 'OPTION_YES',
          },
          {
            name: t('common:no'),
            value: false,
            slug: 'OPTION_NO',
          },
        ]}
      />
      <ConfiguratorGroup
        style={{ margin: '1.25rem 0' }}
        value={workable}
        slug="WORKABLE"
        onChange={setWorkable}
        headline={t(`vehicle:pawn.pickerl_headline__${regionCode}`)}
        subline={t(`vehicle:pawn.pickerl_subline__${regionCode}`, {
          vehicleName,
        })}
        options={[
          {
            name: t('common:yes'),
            value: true,
            slug: 'OPTION_YES',
          },
          {
            name: t('common:no'),
            value: false,
            slug: 'OPTION_NO',
            color: 'red',
            description: t('vehicle:pawn.we_cant_accept_no_pickerl'),
          },
        ]}
      />

      {isGermanyRegion && (
        <ConfiguratorGroup
          style={{ margin: '1.25rem 0' }}
          value={parkCarAtCashy}
          slug="PARKCARATCASHY"
          onChange={setParkCarAtCashy}
          headline={t('vehicle:pawn.park_car_at_cashy_headline')}
          subline={t('vehicle:pawn.park_car_at_cashy_subline')}
          options={[
            {
              name: t('common:yes'),
              value: true,
              slug: 'OPTION_YES',
            },
            {
              name: t('common:no'),
              value: false,
              slug: 'OPTION_NO',
              color: 'red',
              description: t('vehicle:pawn.we_cant_accept_not_parked'),
            },
          ]}
        />
      )}
    </>
  )
}
export default VehiclePawnableQuestions
