import CloseIcon from '@material-ui/icons/Close'
import parse from 'html-react-parser'
import DOMPurify from 'isomorphic-dompurify'
import useTranslation from 'next-translate/useTranslation'
import { useSnackbar } from 'notistack'
import styled from 'styled-components'
import { GraphQLOperationError } from '@/app/common/graphql/error'
import { isErrorUnauthorized } from '@/app/common/graphql/utils'
import { parseApolloErrorPretty } from '@/utils/error'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

function useShowApolloErrorInSnackbar() {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  return (error: GraphQLOperationError) => {
    console.error('Showing error to user.', error)
    if (
      !(
        error.graphQLErrors?.length &&
        isErrorUnauthorized(error.graphQLErrors[0])
      )
    ) {
      const apolloErrorPretty = parseApolloErrorPretty(error, t).message
      snackbar.enqueueSnackbar(
        <Wrapper>
          <p>{parse(DOMPurify.sanitize(apolloErrorPretty))}</p>
          <CloseIcon
            onClick={() => snackbar.closeSnackbar()}
            style={{
              marginLeft: 'auto',
              cursor: 'pointer',
            }}
          />
        </Wrapper>,
        {
          style: {
            zIndex: 106,
          },
          variant: 'error',
          persist: true,
          preventDuplicate: true,
          key: apolloErrorPretty,
        },
      )
    }
  }
}

export default useShowApolloErrorInSnackbar
