import { Box } from '@material-ui/core'
import useTranslation from 'next-translate/useTranslation'
import AuthDialog from '@/app/auth/components/AuthDialog'
import Success from './assets/ico_ilustracao.svg'

const CustomDealSuccessModal: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div>
      <AuthDialog
        navBar={{
          title: t('customDeal:successModal.dialog_title'),
        }}
        fixedMobileFooter
        content={
          <Box textAlign="center" marginTop="10rem" paddingX="2rem">
            <Success />
            <Box fontSize="1.25rem" fontWeight="600" marginTop="1.5rem">
              {t('customDeal:successModal.title')}
            </Box>
            <Box marginTop="1.5rem">{t('customDeal:successModal.message')}</Box>
          </Box>
        }
      />
    </div>
  )
}

export default CustomDealSuccessModal
