import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import styled from 'styled-components'
import { CaptionedImagesListStoryblok } from '@/types/storyblok-component-types'

export default function CaptionedImagesList({
  blok,
}: {
  blok: CaptionedImagesListStoryblok
}) {
  return (
    <Container {...storyblokEditable(blok)}>
      {blok.items?.map((item, index) => (
        <StoryblokComponent blok={item} key={item._uid} index={index} />
      )) ?? null}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
`
