import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import styled from 'styled-components'
import { stripExtrasFromSlug } from '@/helpers/getStoryBlokLink'
import useLocalisedDateFormatting from '@/helpers/useLocalisedDateFormatting'
import { media } from '@/style/helpers'
import { PressReleasesStoryblok } from '@/types/storyblok-component-types'
import { dayjs } from '@/utils/time'

export default function PressReleases({
  blok,
}: {
  blok: PressReleasesStoryblok
}) {
  const formatDate = useLocalisedDateFormatting()

  return (
    <Container {...storyblokEditable(blok)}>
      {blok.title && <SectionTitle>{blok.title}</SectionTitle>}

      <div>
        {blok.press_releases?.map(
          (r) =>
            typeof r !== 'string' && (
              <PressReleaseContainer
                key={r.uuid}
                href={stripExtrasFromSlug(r.full_slug)}
              >
                {r.content.release_date && (
                  <ReleaseDate>
                    {formatDate(dayjs(r.content.release_date), 'Date')}
                  </ReleaseDate>
                )}
                {r.content.title && <Title>{r.content.title}</Title>}
                {r.content.excerpt && <Excerpt>{r.content.excerpt}</Excerpt>}
              </PressReleaseContainer>
            ),
        )}
      </div>

      {!!blok.cta_button?.length && (
        <WrapperButton>
          <StoryblokComponent blok={blok.cta_button[0]} />
        </WrapperButton>
      )}
    </Container>
  )
}

const Container = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid #d7d9db;
  }
  padding: 1rem 0;
`

const SectionTitle = styled.h2`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 600;
  ${media.md} {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 2.5rem;
  }
`

const WrapperButton = styled.div`
  margin-top: 1rem;
`

const PressReleaseContainer = styled.a`
  padding: 1rem 0;
  display: block;
  width: 100%;
  text-decoration: none;
  color: #000;
  &:not(:last-child) {
    border-bottom: 1px solid #d7d9db;
  }
`

const Title = styled.h3`
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  line-height: 1.5;
`

const Excerpt = styled.p`
  font-size: 0.875rem;
  line-height: 1.5;
`

const ReleaseDate = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  color: #5016f4;
  margin-bottom: 0.5rem;
`
