import { storyblokEditable } from '@storyblok/react'
import styled from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import StoryblokRichText from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import Text from '@/style/components/LegacyText'
import { media } from '@/style/helpers'
import theme from '@/style/themes/default'
import { AdvantagesGridItemStoryblok } from '@/types/storyblok-component-types'

export default function AdvantagesGridItem({
  blok,
}: {
  blok: AdvantagesGridItemStoryblok
}) {
  return (
    <PointWrapper className={'point-item'} {...storyblokEditable(blok)}>
      {blok.image?.filename && (
        <div className="image-wrapper">
          <StoryblokImage
            sizes={`(max-width: ${theme.breakpoints['md']}px) 57px, 86px`}
            image={blok.image}
            layout="responsive"
          />
        </div>
      )}
      <Text.md as="div" className={'text'}>
        <StoryblokRichText document={blok.text} />
      </Text.md>
    </PointWrapper>
  )
}

const PointWrapper = styled.div`
  min-width: 0;
  flex: 0 0 calc(50% - 10px);
  background-color: #f4f5f9;
  border-radius: 5px;
  position: relative;

  h3 {
    font-weight: 600;
    padding-bottom: 2px;
    line-height: 1.4;
    letter-spacing: 0em;
    font-size: 0.875rem;

    ${media.xs} {
      font-size: 1rem;
    }
  }

  .image-wrapper {
    position: absolute;
    border-radius: 4.75px;
    width: 57px;
    height: 57px;
    left: 10px;
    top: -12px;
  }

  .text {
    padding-top: 54px;
    padding-bottom: 25px;
    padding-left: 10px;
    padding-right: 10px;
  }

  ${media.sm} {
    flex: 0 0 calc(33.33333% - 14px);
  }
  ${media.md} {
    flex: 0 0 calc(25% - 30px);

    .text {
      padding-top: 79px;
      padding-bottom: 50px;
      padding-left: 16px;
      padding-right: 16px;
    }

    .image-wrapper {
      position: absolute;
      border-radius: 7.17px;
      width: 86px;
      height: 86px;
      left: 16px;
      top: -16px;
    }
  }
  ${media.lg} {
    flex: 0 0 calc(25% - 30px);
  }
`
