import { storyblokEditable } from '@storyblok/react'
import React, { useMemo } from 'react'
import { useStoryblokImageLoader } from '@/app/common/components/StoryblokImage/StoryblokImage'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { HeroCardStoryblok } from '@/types/storyblok-component-types'

export default function HeroCard({ blok }: { blok: HeroCardStoryblok }) {
  const mobile_gradient_top = rgbToStr(
    hexToRgb(blok.mobile_gradient_color_top?.color) ?? transparent,
  )
  const mobile_gradient_bottom = rgbToStr(
    hexToRgb(blok.mobile_gradient_color_bottom?.color) ?? transparent,
  )

  const imageLoader = useStoryblokImageLoader({})

  const desktopSrcSet = useMemo(() => {
    const src = blok.hero_image_desktop?.filename

    return desktopScreenWidths
      .map((width) => `${imageLoader({ src, width, quality: 80 })} ${width}w`)
      .join(', ')
  }, [imageLoader, blok.hero_image_desktop?.filename])

  const mobileSrcSet = useMemo(() => {
    const src = blok.hero_image_mobile?.filename

    return mobileScreenWidths
      .map((width) => `${imageLoader({ src, width, quality: 80 })} ${width}w`)
      .join(', ')
  }, [imageLoader, blok.hero_image_mobile?.filename])

  return (
    <div
      className={`w-full mx-auto p-4 lg:p-12 lg:max-w-[1400px]`}
      {...storyblokEditable(blok)}
    >
      {/** Desktop Version*/}
      <div
        className={`hidden lg:block rounded-xl`}
        style={{ height: `${blok.desktop_height}px` }}
      >
        <div
          className={`h-full w-full flex flex-row justify-center items-center text-center px-6 py-16`}
        >
          <RichTextParagraphStyles>
            <StoryblokRichText document={blok.desktop_content} />
          </RichTextParagraphStyles>

          <picture
            className="max-w-[800px] mx-6 rounded-xl bg-cover bg-center"
            key={blok.hero_image_desktop.id}
          >
            <source media="(min-width: 1200px)" srcSet={desktopSrcSet} />
            <img
              className="relative w-full h-full rounded-xl"
              src={imageLoader({
                src: blok.hero_image_desktop?.filename,
                width: 1200,
              })}
              style={{
                maxHeight: `${Number(blok.desktop_height) - 64 * 2}px`,
              }}
              alt={blok.hero_image_desktop.alt}
            />
          </picture>
        </div>
      </div>

      {/** Mobile Version*/}
      <picture
        className="relative lg:hidden z-10"
        key={blok.hero_image_mobile.id}
      >
        <source media="(max-width: 1199px)" srcSet={mobileSrcSet} />
        <div
          className="w-full h-full rounded-xl absolute left-0 top-0 object-top object-cover"
          style={{
            backgroundImage: `linear-gradient(to bottom, ${mobile_gradient_top} 20%, ${mobile_gradient_bottom})`,
            zIndex: -1,
          }}
        />
        <img
          className="w-full h-full rounded-xl absolute left-0 top-0 object-top object-cover"
          src={imageLoader({
            src: blok.hero_image_mobile?.filename,
            width: 750,
          })}
          alt={blok.hero_image_mobile.alt}
          style={{
            zIndex: -2,
          }}
        />
        <div
          className={`w-full h-full flex flex-col ${mobile_alignment[blok.mobile_content_alignment]} items-center text-center px-6 py-16`}
          style={{
            height: `${blok.mobile_height}px`,
          }}
        >
          <RichTextParagraphStyles>
            <StoryblokRichText document={blok.mobile_content} />
          </RichTextParagraphStyles>
        </div>
      </picture>
    </div>
  )
}

const desktopScreenWidths = [1200, 1920]
const mobileScreenWidths = [360, 640, 750, 828, 1080, 1200]

const mobile_alignment: { [key: string]: string } = {
  top: 'justify-start',
  center: 'justify-center',
  bottom: 'justify-end',
}

const transparent = { r: 0, g: 0, b: 0, a: 0 }

function hexToRgb(input?: string) {
  if (!input) return
  input = input.replace('#', '')
  if (input.length != 6) return
  const r = Number('0x' + input.slice(0, 2))
  const g = Number('0x' + input.slice(2, 4))
  const b = Number('0x' + input.slice(4, 6))
  return { r, g, b }
}

function rgbToStr({
  r,
  g,
  b,
  a = 0.2,
}: {
  r: number
  g: number
  b: number
  a?: number
}) {
  return `rgba(${r}, ${g}, ${b}, ${a})`
}
