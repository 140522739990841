export const verifyToken = (token: string | null): Boolean => {
  if (!token) {
    return false
  }

  try {
    const payload = getPayload(token)
    return !!payload?.exp && payload.exp * 1000 > Date.now()
  } catch (e) {
    return false
  }
}

export const getPayload = (token?: string | null) => {
  if (!token) return null

  const splitted = token.split('.')
  if (splitted.length !== 3) return null

  try {
    return JSON.parse(atob(splitted[1]))
  } catch (e) {
    console.error('Failed to parse JWT payload', e)
  }
  return null
}
