import styled from 'styled-components'
import Sizes from './sizes'

interface H2Props {
  noMargin?: boolean
}

export default styled(Sizes.md).attrs<H2Props>((props) => ({
  as: 'h3',
  noMargin: props.noMargin || false,
}))<H2Props>`
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : 0.75)}rem;
`
