import { makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import styled from 'styled-components'

interface SnackbarOptions {
  variant?: 'default' | 'error' | 'success' | 'warning' | 'info'
  autoHideDuration?: number | null
  persist?: boolean
}

type ShowSnackbar = (
  message: string,
  options?: SnackbarOptions,
  link?: string,
) => void

function useShowSnackbar() {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const showSnackbar: ShowSnackbar = useCallback(
    (message, options = {}, link) => {
      const className = options.variant === 'info' ? classes.variantInfo : ''

      enqueueSnackbar(
        <Wrapper>
          <p>{link ? <a href={link}>{message}</a> : message}</p>
          <CloseIcon
            onClick={() => closeSnackbar()}
            style={{
              marginLeft: 'auto',
              cursor: 'pointer',
            }}
          />
        </Wrapper>,
        {
          style: {
            zIndex: 106,
          },
          preventDuplicate: true,
          key: message,
          className: className,
          ...options,
        },
      )
    },
    [enqueueSnackbar, closeSnackbar, classes],
  )

  const showWarning: ShowSnackbar = useCallback(
    (message, options = {}, link) => {
      showSnackbar(message, { ...options, variant: 'warning' }, link)
    },
    [showSnackbar],
  )

  const showError: ShowSnackbar = useCallback(
    (message, options = {}, link) => {
      showSnackbar(
        message,
        { ...options, variant: 'error', persist: true, autoHideDuration: null },
        link,
      )
    },
    [showSnackbar],
  )

  const showSuccess: ShowSnackbar = useCallback(
    (message, options = {}, link) => {
      showSnackbar(
        message,
        { ...options, variant: 'success', autoHideDuration: 7500 },
        link,
      )
    },
    [showSnackbar],
  )

  const showInfo: ShowSnackbar = useCallback(
    (message, options = {}, link) => {
      showSnackbar(message, { ...options, variant: 'info' }, link)
    },
    [showSnackbar],
  )

  return {
    showSnackbar,
    showWarning,
    showError,
    showSuccess,
    showInfo,
  }
}

const useStyles = makeStyles({
  variantInfo: {
    backgroundColor: '#481CE9',
  },
})

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

export default useShowSnackbar
