import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { useQuery } from '@/app/common/graphql/hooks'
import { useCheckCheckoutDate } from '@/domains/checkout/checkout.service'
import { checkoutDateState } from '@/domains/checkout/checkout.state'
import { generateDefaultAnswers } from '@/domains/items/items.service'
import { SelectedLegacyProduct } from '@/types'
import {
  GetItemPageContentDocument,
  GetItemPageContentQuery,
  GetItemPageContentQueryVariables,
} from '@/types/gql/graphql'
import ProductConfigurator from './ProductConfigurator'

interface ProductObjectIDProps {
  initialQueryVariables: GetItemPageContentQueryVariables
  selectedProduct: SelectedLegacyProduct
}

const Item: NextPage<ProductObjectIDProps> = ({
  initialQueryVariables: getItemPageContentQueryVariables,
  selectedProduct,
}) => {
  const router = useRouter()
  const slug = router.query.slug as string

  const [checkoutDate, setCheckoutDate] = useRecoilState(checkoutDateState)

  useCheckCheckoutDate()

  useEffect(() => {
    if (!checkoutDate) {
      setCheckoutDate(date)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  const date = new Date(getItemPageContentQueryVariables?.date)

  const itemPageContent = useQuery<
    GetItemPageContentQuery,
    GetItemPageContentQueryVariables
  >(GetItemPageContentDocument, {
    enableCaching: true,
    variables: {
      ...getItemPageContentQueryVariables,
      date,
    },
  })

  if (!itemPageContent || !itemPageContent.data) {
    return <div>Loading...</div>
  }

  const { itemQuestions } = itemPageContent.data.getItemPageContent

  return (
    <ProductConfigurator
      product={selectedProduct}
      checkoutDate={checkoutDate}
      itemQuestions={itemQuestions}
      immutableAnswers={generateDefaultAnswers(selectedProduct, itemQuestions)}
    />
  )
}

export default Item
