import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import * as tracking from '@/helpers/tracking'
import { UnstyledButton } from '@/style/components/Button'
import Text from '@/style/components/LegacyText'
import media from '@/style/helpers/media'
import BurgerInverted from './burger_inverted.svg'

interface ItemProps {
  menuOpen: boolean
  toggleMenu: Function
}

const MenuButtonWrapper = styled(UnstyledButton)`
  grid-area: menu;
  justify-content: center;
  align-items: center;
  display: flex;
`

const StyledBurger = styled(BurgerInverted)`
  display: none;

  ${media.md} {
    display: inline-block;
    width: ${({ theme }) => (theme.sizes.headerHeight / 2) * 1.2}px;
    height: ${({ theme }) => (theme.sizes.headerHeight / 2) * 1.2}px;
  }
`

const StyledBurgerMobile = styled(BurgerInverted)`
  width: ${({ theme }) => (theme.sizes.headerHeight / 2) * 1.2}px;
  height: ${({ theme }) => (theme.sizes.headerHeight / 2) * 1.2}px;

  ${media.md} {
    display: none;
  }
`

const MenuButton: FunctionComponent<ItemProps> = ({ toggleMenu }) => {
  const { t } = useTranslation()

  return (
    <MenuButtonWrapper
      id="header_open-sidebar"
      onClick={() => {
        toggleMenu()
        tracking.trackEvent('open_navigation')
      }}
    >
      <StyledBurger />
      <StyledBurgerMobile />
      <Text.screenReader as="span">
        {t('common:header.menu_toggle_info')}
      </Text.screenReader>
    </MenuButtonWrapper>
  )
}

export default MenuButton
