import { CarProperty } from '../VehicleDealBooking'
import { Box } from '@material-ui/core'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useState } from 'react'
import { ScaleLoader } from 'react-spinners'
import { useLazyQuery, useQuery } from '@/app/common/graphql/hooks'
import { Select } from '@/style/components/Form'
import Text from '@/style/components/Text'
import theme from '@/style/themes/default'
import {
  CallViviEndpointDocument,
  CallViviEndpointQuery,
  CallViviEndpointQueryVariables,
  GetViviEntryPointDocument,
  GetViviEntryPointQuery,
  GetViviEntryPointQueryVariables,
} from '@/types/gql/graphql'
import { dayjs } from '@/utils/time'

interface IndicataCarSelectionProps {
  vehicleProperties: CarProperty[]
  setVehicleProperties: React.Dispatch<React.SetStateAction<CarProperty[]>>
  setLastStepHref: (lastStepHref: string | undefined) => void
  setIsNoValuationCar: (value: boolean) => void
  setIsCustomRequest: (value: boolean) => void
}

const preRenderedCarOptions = ['make', 'model', 'regdate']

const IndicataCarSelection: FunctionComponent<IndicataCarSelectionProps> = ({
  vehicleProperties,
  setVehicleProperties,
  setLastStepHref,
  setIsNoValuationCar,
  setIsCustomRequest,
}) => {
  const { t } = useTranslation()
  const [questionsLoading, setQuestionsLoading] = useState(false)

  const [callViviEndPoint] = useLazyQuery<
    CallViviEndpointQuery,
    CallViviEndpointQueryVariables
  >(CallViviEndpointDocument)

  useQuery<GetViviEntryPointQuery, GetViviEntryPointQueryVariables>(
    GetViviEntryPointDocument,
    {
      skip: vehicleProperties.length > 0,
      disableDefaultErrorHandling: true,
      onCompleted(data) {
        if (!data || vehicleProperties.length > 0) return
        const nextStep = data.getViviEntryPoint.nextStep
        if (nextStep && nextStep.length > 0) {
          const defaultOption = nextStep.find(
            (option) => option.summary === 'Personenkraftwagen',
          )
          setVehicleProperties([
            {
              name: nextStep[0].rel,
              options: nextStep.map((option) => ({
                name: option.summary,
                href: option.href,
              })),
              selected: defaultOption
                ? { href: defaultOption.href, name: defaultOption.summary }
                : undefined,
            },
          ])

          if (defaultOption) {
            handleOptionSelected(defaultOption.href, 0)
          }
        }
      },
      onError() {
        setIsCustomRequest(true)
      },
    },
  )

  const handleOptionSelected = async (value: string, i: number) => {
    if (i === 0) {
      setIsNoValuationCar(false)
    }

    setLastStepHref(undefined)

    setVehicleProperties((old) => {
      const newVehicleProperties = [...old]
      const carProperty = newVehicleProperties[i]
      carProperty.selected = carProperty.options.find((o) => o.href === value)
      return newVehicleProperties
    })

    if (!value) {
      setVehicleProperties((old) => {
        const newVehicleProperties = [...old]
        newVehicleProperties.splice(i + 1)

        return newVehicleProperties
      })
      return
    }

    setQuestionsLoading(true)

    const res = await callViviEndPoint({
      variables: {
        args: {
          href: value,
        },
      },
    })

    setQuestionsLoading(false)

    const viviRes = res?.data?.callViviEndpoint
    const nextStep = viviRes?.nextStep

    if (!nextStep) return

    if (nextStep.length < 1 && viviRes.lastStepHref) {
      setLastStepHref(viviRes.lastStepHref)
      return
    }

    setVehicleProperties((old) => {
      const newVehicleProperties = [...old]
      newVehicleProperties.splice(i + 1)
      return [
        ...newVehicleProperties,
        {
          name: nextStep[0].rel,
          options: nextStep
            .filter((s) => {
              if (s.rel === 'regdate') {
                const currentYear = dayjs().year()
                return !(currentYear - Number(s.name) > 13)
              }
              return true
            })
            .map((option) => ({
              name: option.summary,
              href: option.href,
            })),
        },
      ]
    })
  }

  return (
    <>
      {vehicleProperties.map((carOption, i) => {
        return (
          <Box key={i}>
            {i === 3 && <Text.spacer>{t('common:car.details')}</Text.spacer>}
            <Box marginBottom="1rem">
              <Select
                id={`${carOption.name}_option`.toUpperCase()}
                hasNone
                fullWidth
                showLabel
                name={carOption.name}
                value={carOption.selected?.href ?? ''}
                label={t(`common:car.${carOption.name}`)}
                options={carOption.options.map((option) => ({
                  label: option.name,
                  value: option.href,
                }))}
                onChange={async (e) => {
                  if (!e.target || typeof e.target.value !== 'string') return
                  handleOptionSelected(e.target.value, i)
                }}
              />
            </Box>
          </Box>
        )
      })}

      {/* Show pre-render car options */}
      {preRenderedCarOptions
        .slice(vehicleProperties.length, 3)
        .filter((p) => !vehicleProperties.find((cp) => cp.name === p))
        .map((carOption) => (
          <Box marginBottom="1rem" key={carOption}>
            <Select
              hasNone
              fullWidth
              showLabel
              value={''}
              name={carOption + 'adfs'}
              disabled={true}
              label={t(`common:car.${carOption}`)}
              options={[]}
            />
          </Box>
        ))}

      {/* Loading section when choose car options */}
      {questionsLoading && (
        <Box textAlign="center">
          <ScaleLoader color={theme.colors.primary} loading={true} />
        </Box>
      )}
    </>
  )
}

export default IndicataCarSelection
