import CartIcon from '../../../assets/ico_mobile_cart.svg'
import Link from 'next/link'
import { cn } from '@/utils/cn'

interface MobileCartButtonProps {
  itemsCount: number
  itemsTotalAmount: string
}

export function MobileCartButton({ itemsCount }: MobileCartButtonProps) {
  if (itemsCount === 0) return null

  return (
    <Link href="/checkout/items">
      <div className="size-10 flex relative mt-[5px]" id="header_cart">
        <div className="size-10 text-black flex items-center justify-center">
          <CartIcon width={20} height={20} />
        </div>
        <span
          className={cn(
            'text-primary font-bold text-xs absolute top-[2px]',
            itemsCount >= 10 ? 'right-[0.70rem]' : 'right-4',
          )}
        >
          {itemsCount}
        </span>
      </div>
    </Link>
  )
}
