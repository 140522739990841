import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Button, ButtonGroup } from '@/style/components/Button'
import Text from '@/style/components/LegacyText'

const Wrapper = styled.section`
  text-align: center;
  margin-top: 1.25rem;

  p {
    margin-top: 1rem;
  }
`

const ButtonGroupStyled = styled(ButtonGroup)`
  margin-top: 0.75rem;
`

const ButtonStyled = styled(Button)``

interface SelectButtonProps {
  id?: string
  headline: string
  subline?: string
  description?: string
  options: {
    name: string
    value: any
    description?: string
  }[]
  onChange?: (value: any) => void
  value: any
}

const SelectButton: FunctionComponent<SelectButtonProps> = ({
  id,
  headline,
  description,
  options,
  value,
  subline,
  onChange,
}) => {
  const selectedValue = options.find((o) => o.value == value)

  return (
    <Wrapper>
      <Text.ms uppercase weight="semibold">
        {headline}
      </Text.ms>

      {subline && (
        <Text.ms
          as="div"
          color="light"
          style={{ marginTop: '0.3rem', marginBottom: '0.5rem' }}
        >
          {subline}
        </Text.ms>
      )}

      <ButtonGroupStyled>
        {options.map((option, index) => (
          <ButtonStyled
            type="button"
            id={id ? `${id}_OPTION_BUTTON_${index}` : undefined}
            key={option.value.toString()}
            appearance={option.value == value ? 'primary-outline' : 'whitebg'}
            onClick={() => {
              if (onChange) {
                onChange(option.value)
              }
            }}
          >
            {option.name}
          </ButtonStyled>
        ))}
      </ButtonGroupStyled>
      {((selectedValue && selectedValue.description) || description) && (
        <Text.ms as="p" color="light">
          {(selectedValue && selectedValue.description) || description}
        </Text.ms>
      )}
    </Wrapper>
  )
}

export default SelectButton
