import { IconButton, InputAdornment, TextField } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import React, { useState } from 'react'
import styled from 'styled-components'
import { media } from '@/style/helpers'

const TextFieldStyled = styled(TextField)<{
  error: boolean
  multiline?: boolean
}>`
  .MuiFilledInput-root {
    background-color: white;
    input {
      font-size: 0.75rem;
    }
    textarea {
      font-size: 0.75rem;
    }

    ${media.sm} {
      textarea {
        font-size: 0.875rem;
      }
    }

    ${(props) =>
      !props.multiline &&
      `
      height: 2.5rem;

      ${media.sm} {
        height: 3rem;
        input {
          font-size: 0.875rem;
        }
      }
    `}

    font-family: GalanoGrotesque-Medium,sans-serif;

    border: 2px solid
      ${(props) => (!props.error ? props.theme.colors.border : '#f44336')};
    border-radius: ${({ theme }) => theme.radius.normal}px;

    &.Mui-focused {
      background-color: white !important;
    }
    &:active,
    &:hover {
      background-color: white;
    }
  }
  .MuiFilledInput-underline {
    &:after,
    &:before,
    &:hover:before {
      border: none;
    }
  }
  label.Mui-focused {
    color: #666666;
  }
  .MuiFilledInput-input {
    ${(props) =>
      !props.multiline &&
      `
      padding: 27px 10px 12px;
    `}
  }
  .MuiFormLabel-root {
    font-size: 0.75rem;
    ${media.sm} {
      font-size: 0.875rem;
    }
  }
  .MuiInputLabel-formControl {
    top: -0.3rem;

    ${media.sm} {
      top: 0;
    }
  }
  .MuiFormLabel-filled {
    top: 0 !important;
  }
  .Mui-focused {
    top: 0 !important;
  }
  .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(12px, 8px) scale(0.75);

    ${media.sm} {
      transform: translate(12px, 10px) scale(0.75);
    }
  }
`

interface IMuiTextField {
  type: string
  name: string
  label: string
  error: boolean
  multiline?: boolean
  rows?: number
  style?: Record<string, any>
  value?: string | null
  onChange?: (value: React.ChangeEvent<HTMLElement>) => void
}

export const MuiTextField: React.FC<IMuiTextField> = (props) => {
  const [showPassword, setShowPassword] = useState(false)

  let type = props.type

  if (type === 'password') {
    type = showPassword ? 'text' : 'password'
  }

  return (
    <TextFieldStyled
      {...props}
      type={type}
      error={props.error}
      variant="filled"
      fullWidth
      rows={props.rows}
      multiline={props.multiline ?? false}
      InputProps={{
        endAdornment:
          props.type == 'password' ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ) : undefined,
        startAdornment:
          props.type === 'money' ? (
            <InputAdornment position="start">€</InputAdornment>
          ) : undefined,
      }}
    />
  )
}
