import { useCallback, useState } from 'react'
import { useLazyQuery } from '@/app/common/graphql/hooks'
import useShowApolloErrorInSnackbar from '@/helpers/useShowApolloErrorInSnackbar'
import {
  Company,
  CustomDealCalculation,
  CustomDealCalculationArgs,
  CustomDealCalculationDocument,
  CustomDealCalculationItem,
  CustomDealCalculationQuery,
  CustomDealCalculationQueryVariables,
  EDealType,
  FormAnswerArgs,
  Scalars,
} from '@/types/gql/graphql'

function buildCustomDealCalculationParams(
  companyId: Scalars['ObjectId']['output'],
  items: Partial<CustomDealCalculationItem>[],
  dealType: EDealType,
  durationInDays?: number,
): {
  variables: {
    customDealCalculationArgs: CustomDealCalculationArgs
  }
  skip: boolean
} {
  return {
    variables: {
      customDealCalculationArgs: {
        isReversedFeeCalculation: true,
        companyId,
        calculationItems: items.map(({ itemCategoryId, payoutAmount }) => ({
          itemCategoryId,
          payoutAmount,
          answers: [],
        })),
        durationInDays: dealType === EDealType.Pawn ? durationInDays ?? 30 : 0,
      },
    },
    skip: !durationInDays,
  }
}

export function useCustomDealCalculation() {
  const showErrorInSnackbar = useShowApolloErrorInSnackbar()
  const [customDealCalculation, setCustomDealCalculation] = useState<
    | {
        pawn?: CustomDealCalculation
        purchase?: CustomDealCalculation
      }
    | undefined
  >(undefined)
  const [queryCustomDealPawnCalculation, calculationQueryPawnRes] =
    useLazyQuery<
      CustomDealCalculationQuery,
      CustomDealCalculationQueryVariables
    >(CustomDealCalculationDocument, {
      onCompleted: (data) => {
        if (data) {
          setCustomDealCalculation((state) => ({
            ...state,
            pawn: data.customDealCalculation,
          }))
        }
      },
      onError: (error) => {
        setCustomDealCalculation((state) => ({
          ...state,
          pawn: undefined,
        }))

        showErrorInSnackbar(error)
      },
    })

  const [queryCustomDealPurchaseCalculation, calculationQueryPurchaseRes] =
    useLazyQuery<
      CustomDealCalculationQuery,
      CustomDealCalculationQueryVariables
    >(CustomDealCalculationDocument, {
      onCompleted: (data) => {
        if (data) {
          setCustomDealCalculation((state) => ({
            ...state,
            purchase: data.customDealCalculation,
          }))
        }
      },
      onError: (error) => {
        setCustomDealCalculation((state) => ({
          ...state,
          pawn: undefined,
        }))

        showErrorInSnackbar(error)
      },
    })

  const queryCustomDealCalculation = useCallback(
    (
      company: Pick<Company, '_id'>,
      items: Partial<CustomDealCalculationItem>[],
      durationInDays: number,
    ) => {
      queryCustomDealPawnCalculation(
        buildCustomDealCalculationParams(
          company._id,
          items,
          EDealType.Pawn,
          durationInDays,
        ),
      )
      queryCustomDealPurchaseCalculation(
        buildCustomDealCalculationParams(
          company._id,
          items,
          EDealType.Purchase,
          durationInDays,
        ),
      )
    },
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return {
    queryCustomDealCalculation,
    customDealCalculation,
    setCustomDealCalculation,
    customDealCalculationLoading:
      calculationQueryPawnRes.isFetching ||
      calculationQueryPurchaseRes.isFetching,
  }
}

export const PRICE_QUESTION_TITLE_KEY = 'PRICE'
export const PRODUCT_NAME_QUESTION_TITLE_KEY = 'PRODUCT_NAME'

export type FormAnswerData = FormAnswerArgs
