import React, { useState } from 'react'
import styled from 'styled-components'
import { LoadingOverlay } from '@/app/common/components/LoadingOverlay'
import { useLazyQuery, useQuery } from '@/app/common/graphql/hooks'
import { media } from '@/style/helpers'
import {
  CategoryPage,
  GetCategoryChildPagesContentDocument,
  GetCategoryChildPagesContentQuery,
  GetCategoryChildPagesContentQueryVariables,
  GetItemCategoriesDocument,
  GetItemCategoriesQuery,
  GetItemCategoriesQueryVariables,
} from '@/types/gql/graphql'
import { CategoryList } from './CategoryList'

interface Props {
  onSelect?: (itemCategoryId?: string) => void
  includeDrafts?: boolean
  initialPath?: string
}

export const CategorySelectionPopup = ({
  initialPath = 'anderen-gegenstand-verkaufen',
  onSelect,
  includeDrafts,
}: Props) => {
  const [path, setPath] = useState(initialPath)
  const [historyStacks, setHistoryStacks] = useState<string[]>([])

  const queryResult = useQuery<
    GetCategoryChildPagesContentQuery,
    GetCategoryChildPagesContentQueryVariables
  >(GetCategoryChildPagesContentDocument, {
    variables: {
      path,
      includeDrafts: includeDrafts ?? false,
    },
    onCompleted: (data) => {
      if (data.getCategoryPageContent.page.isLeaveCategory) {
        onSelectCategoryPageId(data.getCategoryPageContent.page._id)
      }
    },
  })

  const [getItemCategory] = useLazyQuery<
    GetItemCategoriesQuery,
    GetItemCategoriesQueryVariables
  >(GetItemCategoriesDocument, {
    enableCaching: true,
    onCompleted: (data) => {
      onSelect?.(data.getItemCategories?.[0]?._id)
    },
  })

  const onSelectCategoryPageId = (id: string) => {
    getItemCategory({
      variables: {
        opts: {
          filter: {
            categoryPageIds: [id],
          },
        },
      },
    })
  }

  const childPages = queryResult.data?.getCategoryPageContent?.childPages
  const page = queryResult.data?.getCategoryPageContent?.page

  const onClickItem = (
    object?: Pick<CategoryPage, '_id' | 'isSpecialPage' | 'path'>,
  ) => {
    if (!object) {
      // Select other option
      onSelect?.(undefined)
    } else if (object.isSpecialPage || page?.isLeaveCategory) {
      // Select special page
      onSelectCategoryPageId(object._id)
    } else {
      // Select child page
      setHistoryStacks((historyStacks) => [...historyStacks, path])
      setPath(object.path)
    }
  }

  const onClickBack = () => {
    const newHistoryStacks = [...historyStacks]
    const previousPath = newHistoryStacks.pop()
    if (!previousPath) return
    setHistoryStacks(newHistoryStacks)
    setPath(previousPath)
  }

  // Can't select deeper and use the _id from page
  if (page?.isLeaveCategory) return null

  return (
    <Wrapper>
      {queryResult.isFetching || !page || !childPages ? (
        <Loading>
          <LoadingOverlay />
        </Loading>
      ) : (
        <CategoryList
          title={page.selectorHeading}
          objects={childPages}
          allowBack={!!historyStacks.length}
          onClickBack={onClickBack}
          onClick={onClickItem}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;

  ${media.sm} {
    width: 700px;
    height: 650px;
  }

  ${media.md} {
    width: 860px;
    height: 650px;
  }
`

const Loading = styled.div`
  min-height: 10rem;
`
