import { FunctionComponent, useMemo } from 'react'
import { defaultMinimumItemValue } from '@/app/common/constants'
import { useQuery } from '@/app/common/graphql/hooks'
import { buildDealCalculationVariables } from '@/domains/checkout/checkout.service'
import { DesktopConfiguratorSummary } from '@/domains/product/components/ProductConfigurator/ConfiguratorSummary/DesktopConfiguratorSummary'
import { MobileConfiguratorSummary } from '@/domains/product/components/ProductConfigurator/ConfiguratorSummary/MobileConfiguratorSummary'
import useCompany from '@/helpers/useCompany'
import { SelectedProduct } from '@/types'
import {
  DealCalculationDocument,
  DealCalculationQuery,
  DealCalculationQueryVariables,
  EDealType,
  GetParentCategoriesDocument,
  GetParentCategoriesQuery,
  GetParentCategoriesQueryVariables,
  ItemAnswer,
  ItemQuestion,
  ProductVariant,
} from '@/types/gql/graphql'
import { isItemDysfunctional } from '@/utils/misc'

interface ConfiguratorSummaryProps {
  product: SelectedProduct
  itemQuestions: Omit<ItemQuestion, 'validFrom' | 'validTo'>[] | undefined
  itemAnswers: ItemAnswer[]
  checkoutDate: Date | undefined
  onSubmit: () => Promise<void>
  skipItemCalculation?: boolean
  variant?: Pick<ProductVariant, 'id' | 'isPriceValid'> | undefined
}

const ConfiguratorSummary: FunctionComponent<ConfiguratorSummaryProps> = ({
  product,
  itemQuestions,
  itemAnswers,
  checkoutDate,
  onSubmit,
  skipItemCalculation,
  variant,
}) => {
  const company = useCompany()

  const getParentCategoriesResult = useQuery<
    GetParentCategoriesQuery,
    GetParentCategoriesQueryVariables
  >(GetParentCategoriesDocument, {
    variables: {
      itemCategoryId: product.category._id,
    },
    skip: !product.category._id,
    enableCaching: true,
  })

  const parentCategories = useMemo(() => {
    return getParentCategoriesResult.data?.getParentCategories ?? []
  }, [getParentCategoriesResult.data?.getParentCategories])

  const isDysfunctional = useMemo(() => {
    if (
      itemAnswers &&
      itemQuestions &&
      itemAnswers.length === itemQuestions.length
    ) {
      return isItemDysfunctional({
        answers: itemAnswers,
        categories: parentCategories ?? [],
        itemQuestions: itemQuestions,
      })
    }
    return false
  }, [itemAnswers, parentCategories, itemQuestions])

  const dealCalculationResult = useQuery<
    DealCalculationQuery,
    DealCalculationQueryVariables
  >(DealCalculationDocument, {
    variables: buildDealCalculationVariables({
      companyId: company?._id,
      dealType: EDealType.Pawn,
      date: checkoutDate,
      items: [
        {
          answers: itemAnswers,
          variantId: variant?.id,
          itemCategoryId: product.categoryId,
        },
      ],
      durationInDays: company?.configuration.minimumPawnDuration,
    }),
    skip:
      !company ||
      !company?.configuration.minimumPawnDuration ||
      isDysfunctional ||
      skipItemCalculation ||
      !variant?.isPriceValid,
  })

  const itemValue =
    dealCalculationResult?.data?.dealCalculation?.dealValuesEntry?.payoutAmount

  const isUnderMinimumItemValue = useMemo(() => {
    if (!itemValue) {
      return false
    }

    return (
      itemValue <
      (product?.category?.recursiveMinimumItemValue ?? defaultMinimumItemValue)
    )
  }, [itemValue, product])

  const isDisabled = Boolean(
    isDysfunctional ||
      itemValue === 0 ||
      isUnderMinimumItemValue ||
      skipItemCalculation,
  )

  return (
    <>
      <DesktopConfiguratorSummary
        isDisabled={isDisabled}
        isFetching={skipItemCalculation || dealCalculationResult.isFetching}
        itemValue={itemValue}
        isUnderMinimumItemValue={isUnderMinimumItemValue}
        isDysfunctional={isDysfunctional}
        variant={variant}
        onSubmit={onSubmit}
        product={product}
      />
      <MobileConfiguratorSummary
        isDisabled={isDisabled}
        isFetching={skipItemCalculation || dealCalculationResult.isFetching}
        itemValue={itemValue}
        isUnderMinimumItemValue={isUnderMinimumItemValue}
        isDysfunctional={isDysfunctional}
        variant={variant}
        onSubmit={onSubmit}
        product={product}
      />
    </>
  )
}

export default ConfiguratorSummary
