import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Button, ButtonGroup } from '@/style/components/Button'
import Text from '@/style/components/LegacyText'
import { media } from '@/style/helpers'

interface ConfiguratorGroupProps {
  headline: string
  subline?: string
  description?: string
  value?: any
  options: {
    name: string
    value: any
    description?: string
    color?: string
    slug?: string
  }[]
  onChange: (value: any) => void
  style?: React.CSSProperties
  slug?: string
  buttonStyle?: React.CSSProperties
  headlineStyle?: React.CSSProperties
  nestedDescription?: boolean
}

const ConfiguratorGroup: FunctionComponent<ConfiguratorGroupProps> = ({
  headline,
  subline,
  description: descriptionProp,
  value,
  options,
  onChange,
  style = {},
  buttonStyle = {},
  headlineStyle = {},
  nestedDescription = false,
  slug,
}) => {
  const selectedValue = options.find((o) => o.value == value)
  const description =
    (selectedValue && selectedValue.description) ?? descriptionProp

  return (
    <GroupWrapper style={style} id={`${slug}`}>
      <QuestionTitle weight="medium" style={headlineStyle} id={`${slug}_TITLE`}>
        {headline}
      </QuestionTitle>
      {subline ? (
        <Text.ms as="div" color="light" id={`${slug}_SUBLINE`}>
          {subline}
        </Text.ms>
      ) : null}
      <ButtonGroup size={options.length} id={`${slug}_OPTIONS`}>
        {options.map((option) => (
          <Button
            id={`${slug}_${option.slug}`}
            style={buttonStyle}
            type="button"
            key={option.value.toString()}
            appearance={option.value == value ? 'primary-outline' : 'whitebg'}
            onClick={() => {
              onChange(option.value)
            }}
          >
            {option.name}
            {nestedDescription && option.value == value && description && (
              <NestedDescription id={`${slug}_DESCRIPTION`}>
                {description}
              </NestedDescription>
            )}
          </Button>
        ))}
      </ButtonGroup>
      {!nestedDescription && description && (
        <Description
          style={selectedValue?.color ? { color: selectedValue?.color } : {}}
          id={`${slug}_DESCRIPTION`}
        >
          {description}
        </Description>
      )}
    </GroupWrapper>
  )
}

export default ConfiguratorGroup

const GroupWrapper = styled.section`
  text-align: center;
  margin: 3rem 0;

  div {
    padding-bottom: 1rem;
  }
`

const QuestionTitle = styled(Text.h3)`
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0.3rem;

  ${media.xs} {
    font-size: 0.875rem;
  }

  ${media.sm} {
    font-size: 1rem;
  }
`

const Description = styled(Text.ms).attrs({ as: 'p' })`
  margin-top: 1rem;
`

const NestedDescription = styled(Text.ms).attrs({ as: 'p' })`
  margin-top: 0.625rem;
  margin-bottom: -0.625rem;
  margin-left: -1.4rem;
  margin-right: -1.4rem;
  background-color: white;
  padding: 6px 15px;
  border-radius: 3px;
`
