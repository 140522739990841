import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useRecoilState } from 'recoil'
import { useQuery } from '@/app/common/graphql/hooks'
import { useCheckCheckoutDate } from '@/domains/checkout/checkout.service'
import { checkoutDateState } from '@/domains/checkout/checkout.state'
import { SelectedProduct } from '@/types'
import {
  GetProductBySlugDocument,
  GetProductBySlugQuery,
  GetProductBySlugQueryVariables,
  ProductPriceManualPreciousMetalMode,
} from '@/types/gql/graphql'
import {
  EQuestionPredictionTag,
  ItemAnswer,
  ItemQuestion,
} from '@/types/gql/graphql'
import ProductConfigurator from './ProductConfigurator'

interface ProductProps {
  initialQueryVariables: GetProductBySlugQueryVariables
  selectedProduct: SelectedProduct
}

const Product: NextPage<ProductProps> = ({
  initialQueryVariables: getProductbySlugQueryVariables,
  selectedProduct,
}) => {
  const router = useRouter()
  const slug = router.query.slug as string
  const date = new Date(getProductbySlugQueryVariables?.date)

  const [checkoutDate, setCheckoutDate] = useRecoilState(checkoutDateState)

  useCheckCheckoutDate()

  const [prevSlug, setPrevSlug] = useState('')
  if (slug !== prevSlug && !checkoutDate) {
    setPrevSlug(slug)
    setCheckoutDate(date)
  }

  const productContent = useQuery<
    GetProductBySlugQuery,
    GetProductBySlugQueryVariables
  >(GetProductBySlugDocument, {
    enableCaching: true,
    variables: {
      ...getProductbySlugQueryVariables,
    },
  })

  if (!productContent || !productContent.data) {
    return <div>Loading...</div>
  }

  const { itemQuestions, availableProperties, variants } =
    productContent.data.getProductBySlug

  return (
    <ProductConfigurator
      selectedProduct={selectedProduct}
      checkoutDate={checkoutDate}
      itemQuestions={itemQuestions}
      immutableAnswers={generateDefaultAnswers(selectedProduct, itemQuestions)}
      availableProperties={availableProperties}
      variants={variants}
    />
  )
}

const generateDefaultAnswers = (
  selectedProduct: SelectedProduct,
  itemQuestions:
    | Array<
        Pick<
          ItemQuestion,
          | '_id'
          | 'order'
          | 'questionType'
          | 'valueMode'
          | 'titleKey'
          | 'infoKey'
          | 'predictionTag'
          | 'itemCategoryIds'
        >
      >
    | undefined,
) => {
  const price = selectedProduct.price as
    | ProductPriceManualPreciousMetalMode
    | undefined

  const defaultAnswers: ItemAnswer[] = []

  for (const variant of price?.variants ?? []) {
    if (
      variant.materialMetric &&
      typeof variant.materialMetric.alloy === 'number' &&
      typeof variant.materialMetric.weight === 'number'
    ) {
      const { weight, alloy } = variant.materialMetric

      if (weight && weight > 0) {
        const questionWeight = itemQuestions?.find(
          (question) =>
            question.predictionTag === EQuestionPredictionTag.Weight,
        )

        if (questionWeight) {
          defaultAnswers.push({
            questionId: questionWeight._id,
            rangeValue: weight,
          })
        }
      }

      if (alloy && alloy > 0) {
        const questionAlloy = itemQuestions?.find(
          (question) => question.predictionTag === EQuestionPredictionTag.Alloy,
        )

        if (questionAlloy) {
          defaultAnswers.push({
            questionId: questionAlloy._id,
            rangeValue: alloy,
          })
        }
      }
    }
  }

  return defaultAnswers
}

export default Product
