import styled from 'styled-components'
import Text from '@/style/components/LegacyText'
import { media } from '@/style/helpers'

export const H2Header = styled(Text.h2)`
  font-size: 1.375rem;
  font-weight: 700 !important;

  ${media.md} {
    font-size: 2.375rem !important;
  }
`
