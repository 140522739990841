import styled from 'styled-components'
import Text from '@/style/components/LegacyText'
import CheckIconGreen from './small_check_green.svg'
import CheckIconGrey from './small_check_grey.svg'

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;
  width: 100%;
`

const ItemWrapper = styled.div`
  margin-bottom: 10px;
`

const TextStyled = styled(Text.sm)`
  margin-left: 6px;
`

interface ValidationItemsProps {
  items: {
    description: string
    valid: boolean
  }[]
}

const CheckIconGreenStyled = styled(CheckIconGreen)`
  vertical-align: middle;
`

const CheckIconGreyStyled = styled(CheckIconGrey)`
  vertical-align: middle;
`

export const ValidationItems: React.FC<ValidationItemsProps> = (props) => {
  return (
    <Wrapper>
      {props.items.map((validationItem, index) => {
        return (
          <ItemWrapper key={index}>
            {validationItem.valid ? (
              <CheckIconGreenStyled />
            ) : (
              <CheckIconGreyStyled />
            )}
            <TextStyled color={validationItem.valid ? '#31C46F' : 'gray'}>
              {validationItem.description}
            </TextStyled>
          </ItemWrapper>
        )
      })}
    </Wrapper>
  )
}
