import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import { CategorySelectionPopup } from './CategorySelectionPopup'

interface Props {
  onChange?: (itemCategoryId?: string) => void
  includeDrafts?: boolean
  input: ReactElement
  itemCategoryId: string
}

export const CategorySelection = ({
  onChange,
  includeDrafts,
  input,
  itemCategoryId,
}: Props) => {
  const modal = useContext(ModalDialogContext)
  const [otherOption, setOtherOption] = useState(false)

  const onOpenCategorySelection = () => {
    modal.open(
      <CategorySelectionPopup
        onSelect={(_id?: string) => {
          if (_id) {
            onChange?.(_id)
          }
          modal.close()
        }}
        includeDrafts={includeDrafts}
      />,
      {
        onAfterClose: () => {
          setOtherOption(true)
        },
        variant: 'full',
        customStyles: { overlay: { zIndex: 1000 } },
      },
    )
  }

  useEffect(() => {
    if (!itemCategoryId) {
      onOpenCategorySelection()
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemCategoryId])

  return otherOption ? input : null
}
