import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import styled from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import { stripExtrasFromSlug } from '@/helpers/getStoryBlokLink'
import { media } from '@/style/helpers'
import { AssetStoryblok } from '@/types/storyblok-component-types'
import { ReadMore } from './ReadMore'

export const VerticleCard = ({
  title,
  image,
  slug,
}: {
  title?: string
  image?: AssetStoryblok
  slug: string
}) => {
  const { t } = useTranslation()
  return (
    <Container href={stripExtrasFromSlug(slug)}>
      {image?.filename && (
        <StoryblokImage
          sizes={`(max-width: 992px) 94vw, (max-width: 1200px) 28vw, 340px`}
          style={{ borderRadius: 10 }}
          layout="responsive"
          image={image}
        />
      )}
      <h4 style={{ marginTop: 15 }}>{title}</h4>
      <ReadMore text={t('blog:read_article')} />
    </Container>
  )
}

const Container = styled(Link)`
  display: block;
  width: 100%;
  text-decoration: none;

  transition: 0.4s ease-in;

  &:hover {
    transform: scale(1.02);
    transition: 0.4s ease-out;
  }

  ${media.md} {
    flex: 0 0 calc(33.33333% - 40px);
  }
`
