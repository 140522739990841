import { MenuItemGroupStoryblok } from '@/types/storyblok-component-types'
import MenuItemBlok, { MenuItemProps } from './MenuItem'

export default function MenuItemGroup({
  blok,
  openGroup,
  onClick,
}: { blok: MenuItemGroupStoryblok } & Pick<
  MenuItemProps,
  'openGroup' | 'onClick'
>) {
  return (
    <MenuItemBlok
      blok={{
        ...blok,
        component: 'menu-item',
      }}
      isParent={true}
      openGroup={openGroup}
      onClick={onClick}
    />
  )
}
