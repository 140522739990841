import { StoryblokComponent } from '@storyblok/react'
import React from 'react'
import { CardsGridReferenceStoryblok } from '@/types/storyblok-component-types'

export default function CardsGridReference({
  blok,
}: {
  blok: CardsGridReferenceStoryblok
}) {
  return blok.cards_grid && typeof blok.cards_grid !== 'string' ? (
    <StoryblokComponent
      blok={(blok.cards_grid as any).content}
      key={blok._uid}
    />
  ) : null
}
