import React, { FunctionComponent, useEffect, useState } from 'react'
import { ScaleLoader } from 'react-spinners'
import styled from 'styled-components'
import theme from '@/style/themes/default'

const LoaderWrapper = styled.div<{ fullScreen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: ${({ fullScreen }) => (fullScreen ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  z-index: 9999;
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.5);
`

interface LoadingOverlayProps {
  fullScreen?: boolean
  hide?: boolean
  minDuration?: number
}

const LoadingOverlay: FunctionComponent<LoadingOverlayProps> = ({
  fullScreen = true,
  hide,
  minDuration,
}) => {
  const [show, setShow] = useState<boolean>(
    typeof hide === 'undefined' ? true : !hide,
  )

  useEffect(() => {
    if (typeof hide !== 'undefined') {
      if (hide && typeof minDuration === 'number') {
        setTimeout(() => setShow(false), minDuration)
      } else {
        setShow(!hide)
      }
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hide])

  if (show === false) {
    return null
  }
  return (
    <LoaderWrapper fullScreen={fullScreen}>
      <ScaleLoader color={theme.colors.primary} loading={true} />
    </LoaderWrapper>
  )
}

export default LoadingOverlay
