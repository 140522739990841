import { Dayjs } from 'dayjs'
import { useRouter } from 'next/router'
import { CashyDateFormat, formatLocalisedDate } from '@/utils/date'

export default function useLocalisedDateFormatting() {
  const { locale } = useRouter()

  return (date: Dayjs, options: CashyDateFormat | Intl.DateTimeFormatOptions) =>
    formatLocalisedDate(date, options, locale)
}
