import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import styled from 'styled-components'
import { H2Header } from '@/style/components/H2Header'
import { media } from '@/style/helpers'
import { StepsStoryblok } from '@/types/storyblok-component-types'

export default function Steps({ blok }: { blok: StepsStoryblok }) {
  return (
    <Container {...storyblokEditable(blok)}>
      {blok.title && (
        <CaptionWrapper>
          <H2Header>{blok.title}</H2Header>
        </CaptionWrapper>
      )}
      <StepsWrapper>
        {blok.items.map((item, index) => {
          return (
            <StoryblokComponent blok={item} key={item._uid} index={index} />
          )
        })}
        {blok.button?.map((item) => (
          <ButtonWrapper>
            <StoryblokComponent blok={item} />
          </ButtonWrapper>
        ))}
      </StepsWrapper>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  b {
    font-weight: 600;
  }
`

const CaptionWrapper = styled.div`
  position: relative;
  text-align: center;
`

const StepsWrapper = styled(CaptionWrapper)`
  margin-top: 3rem;

  ${media.sm} {
    margin-top: 5rem;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.3rem;

  ${media.md} {
    margin-top: 2rem;
  }
`
