import { FormControl, InputLabel, Select } from '@material-ui/core'
import { FormikProps } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import styled from 'styled-components'
import { media } from '@/style/helpers'
import { getTranslatedValidationError } from '@/utils/error'
import { InputErrorMessage } from './InputErrorMessage'

const Wrapper = styled.div<{ marginBottom?: string }>`
  width: 100%;
  margin-bottom: ${(props) => props.marginBottom ?? '10px'};
`

const SelectInputStyled = styled(FormControl)<{
  error: boolean
  $inputHeight?: string
}>`
  .MuiFilledInput-root {
    font-size: 0.75rem;
    font-family: GalanoGrotesque-Medium, sans-serif;
    background-color: white;
    ${(props) =>
      props.$inputHeight
        ? `
      height: ${props.$inputHeight};
      ${media.sm} {
        font-size: 0.875rem;
      }
    `
        : `
      height: 2.5rem;
      ${media.sm} {
        height: 3rem;
        font-size: 0.875rem;
      }
    `}

    border: 2px solid ${(props) =>
      !props.error ? props.theme.colors.border : '#f44336'};
    border-radius: ${({ theme }) => theme.radius.normal}px;

    label {
      font-family: GalanoGrotesque, sans-serif;
    }

    &.Mui-focused {
      background-color: white !important;
    }
    &:active,
    &:hover {
      background-color: white;
    }
  }
  .MuiFilledInput-underline {
    &:after,
    &:before,
    &:hover:before {
      border: none;
    }
  }
  label.Mui-focused {
    color: #666666;
  }
  .MuiFilledInput-input {
    padding: 27px 11px 12px;
  }
  .MuiFormLabel-root {
    font-size: 0.75rem;
    ${media.sm} {
      font-size: 0.875rem;
    }
  }
  .MuiInputLabel-formControl {
    top: -0.3rem;

    ${media.sm} {
      top: 0;
    }
  }
  .MuiFormLabel-filled {
    top: 0 !important;
  }
  .Mui-focused {
    top: 0 !important;
  }
  .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(12px, 8px) scale(0.75);

    ${media.sm} {
      transform: translate(12px, 10px) scale(0.75);
    }
  }
`

interface SmartSelectInputProps {
  id?: string
  formikProps: FormikProps<any>
  name: string
  label: string
  marginBottom?: string
  inputHeight?: string
  options: {
    value: string | number
    label: string
  }[]
}

export const SmartSelectInput: React.FC<SmartSelectInputProps> = ({
  id,
  options,
  formikProps,
  name,
  inputHeight,
  label,
  marginBottom,
}) => {
  const { t } = useTranslation()

  const field = formikProps.getFieldMeta(name)
  const fieldHelpers = formikProps.getFieldHelpers(name)

  const inputId = id ?? `filled-${name}-native-simple`
  const error =
    field.error && (field.touched || formikProps.submitCount > 0)
      ? field.error
      : undefined

  return (
    <Wrapper marginBottom={marginBottom}>
      <SelectInputStyled
        variant="filled"
        fullWidth
        error={error !== undefined}
        $inputHeight={inputHeight}
      >
        <InputLabel htmlFor={inputId}>{label}</InputLabel>
        <Select
          native
          name={name}
          value={options.length ? field.value : ''}
          onChange={(e) => {
            fieldHelpers.setValue(e.target.value)
          }}
          inputProps={{
            name: name,
            id: inputId,
          }}
        >
          <option aria-label="None" value={undefined} />
          {options.map(({ value, label }) => (
            <option
              id={`${inputId}_${label.toUpperCase()}`}
              key={value}
              value={value}
            >
              {label}
            </option>
          ))}
        </Select>
        {error && (
          <InputErrorMessage message={getTranslatedValidationError(error, t)} />
        )}
      </SelectInputStyled>
    </Wrapper>
  )
}
