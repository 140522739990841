import Link from 'next/link'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { UrlObject } from 'url'
import Text from '@/style/components/LegacyText'
import { UnderlinedLink } from '@/style/components/Link'
import { ImageWrapper, Wrapper } from './ObjectListItemStyles'
import Background from './assets/bkg_logo.svg'

interface ObjectListFormItemProps {
  title: string
  prompt: string
  href: UrlObject | string
}

const StyledWrapper = styled(Wrapper)`
  background-color: ${({ theme }) => theme.colors.buttonBorder};
  border: 2px solid ${({ theme }) => theme.colors.border};
`

const StyledImageWrapper = styled(ImageWrapper)`
  padding-top: 83%;
  position: relative;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
  }
`

const StyledBackground = styled(Background)`
  position: absolute;
  top: 22.5%;
  left: 25%;
  height: auto;
  width: 50%;
  * {
    fill: ${({ theme }) => theme.colors.border};
  }
`

// TODO: Add ProductJsonLd from next-seo.

const ObjectListFormItem: FunctionComponent<ObjectListFormItemProps> = ({
  title,
  href,
  prompt,
}) => {
  return (
    <Link href={href} passHref legacyBehavior>
      <StyledWrapper>
        <StyledBackground />
        <StyledImageWrapper>
          <Text.ms weight="semibold">{title}</Text.ms>
        </StyledImageWrapper>

        <Text.ms as="p">
          <UnderlinedLink weight="semibold" as="span">
            {prompt}
          </UnderlinedLink>
        </Text.ms>
      </StyledWrapper>
    </Link>
  )
}

export default ObjectListFormItem
