import { debounce } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { useCallback, useRef } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'

export const FormikOnChange: React.FC<{
  delay: number
  onChange: (values: any) => void
}> = ({ delay, onChange }) => {
  const { values } = useFormikContext()
  const isFirstRun = useRef(true)

  // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(debounce(onChange, delay), [values])

  useDeepCompareEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false

      return
    }

    debouncedOnChange(values)
  }, [values])

  return null
}
