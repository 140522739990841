import Observer from '@researchgate/react-intersection-observer'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { motion } from 'framer-motion'
import { mean } from 'lodash'
import dynamic from 'next/dynamic'
import { useMemo, useState } from 'react'
import LazyLoad from 'react-lazyload'
import styled from 'styled-components'
import config from '@/config'
import { media } from '@/style/helpers'
import { ShopsMapStoryblok } from '@/types/storyblok-component-types'
import IconMapPin from './assets/icon-map-pin.svg'

const GoogleMapReact = dynamic(() => import('google-map-react'))

export default function ShopsMap({ blok }: { blok: ShopsMapStoryblok }) {
  const [isIntersecting, setIsIntersecting] = useState(false)

  const mapDefaultCenter = useMemo(
    () =>
      blok.shops && blok.shops.length > 0
        ? {
            lat: mean(blok.shops.map((shop) => +shop.latitude)),
            lng: mean(blok.shops.map((shop) => +shop.longitude)),
          }
        : undefined,
    [blok.shops],
  )

  return (
    <Container id="shops-map-block" {...storyblokEditable(blok)}>
      <Observer
        onChange={(e) => setIsIntersecting(e.isIntersecting)}
        threshold={0.33}
      >
        <InnerWrapper>
          <LazyLoad>
            <MapWrapper
              variants={{
                hidden: { scale: 0, opacity: 0, width: '17rem' },
                visible: {
                  scale: 1,
                  opacity: 1,
                  width: '100%',
                  transition: {
                    opacity: { type: 'tween', duration: 0.25 },
                    scale: { type: 'tween', duration: 0.25 },
                    width: { type: 'tween', duration: 0.25, delay: 1.2 },
                    delay: 0.25,
                  },
                },
              }}
              animate={isIntersecting ? 'visible' : 'hidden'}
              initial="visible"
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: config.googleMapsKey,
                }}
                defaultCenter={mapDefaultCenter}
                yesIWantToUseGoogleMapApiInternals
                defaultZoom={+blok.zoom_level || 6}
              >
                {blok.shops?.map((shop) => {
                  return (
                    <IconWrapper
                      key={shop._uid}
                      lat={+shop.latitude}
                      lng={+shop.longitude}
                      variants={{
                        hidden: { opacity: 0, y: -100 },
                        visible: {
                          opacity: 1,
                          y: 0,
                          transition: {
                            opacity: { type: 'tween', duration: 0.25 },
                            y: { type: 'spring', stiffness: 200, velocity: 5 },
                            delay: 0.25,
                          },
                        },
                      }}
                      animate={isIntersecting ? 'visible' : 'hidden'}
                      initial="visible"
                    >
                      <MapIcon />
                    </IconWrapper>
                  )
                })}
              </GoogleMapReact>
            </MapWrapper>
          </LazyLoad>

          <ItemWrapper>
            {blok.shops.map((shop) => (
              <StoryblokComponent blok={shop} key={shop._uid} />
            ))}
          </ItemWrapper>
        </InnerWrapper>
      </Observer>
    </Container>
  )
}

const IconWrapper = (props: any) => (
  <motion.div {...props}>{props.children}</motion.div>
)

const Container = styled.div`
  position: relative;
  width: 100%;

  b {
    font-weight: 600;
  }

  display: flex;
  flex-direction: column;

  p {
    letter-spacing: 0em;
    line-height: 1.4;
  }
`

const InnerWrapper = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
`

const MapWrapper = styled(motion.div)`
  width: 100%;
  height: 17rem;
  border-radius: 1.5rem;
  overflow: hidden;
  margin: 2rem auto;
`

const MapIcon = styled(IconMapPin)`
  position: absolute;
  bottom: -1rem;
  left: 0;
  transform: translateX(-50%) scale(0.5);
`

const ItemWrapper = styled.div`
  ${media.md} {
    border-bottom: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`
