import styled from 'styled-components'
import { media, mediaFromTo } from '@/style/helpers'
import theme from '@/style/themes/default'

export const Head = styled.header<{ secondRowCollapsed?: boolean }>`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  display: grid;
  z-index: 1000;
  grid-template-areas:
    'subHeader'
    'primaryHeader'
    'secondaryHeader';
`

export const GridItem = styled.div<{
  area: string
  hidden?: boolean
  zIndex?: number
}>`
  grid-area: ${(props) => props.area};
  height: inherit;
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  z-index: ${(props) => (props.zIndex === undefined ? '100' : props.zIndex)};
`

export const MobilePrimaryHeaderWrapperV2 = styled.div`
  height: ${(props) => props.theme.sizes.headerHeight}px;
  background-color: white;
  display: grid;
  grid-template-columns: 150px auto;
  grid-template-areas: 'logo cart';
  z-index: 0;

  ${media.md} {
    display: none;
  }
`

export const DesktopPrimaryHeaderWrapper = styled.div`
  display: none;
  ${media.lg} {
    display: grid;
    height: ${(props) => props.theme.sizes.headerHeight}px;
    background-color: white;
    grid-template-columns: 3.5em 11rem auto auto 3rem 4rem 7.5rem;
    grid-template-areas: 'menu logo search categories cart account language';
  }
`

export const TabletPrimaryHeaderWrapper = styled.div<{
  cartHasItems?: boolean
}>`
  display: none;

  ${mediaFromTo('md', 'lg')} {
    display: grid;
    height: ${(props) => props.theme.sizes.headerHeight}px;
    background-color: white;
    grid-template-columns: 3.5em 11rem auto 3rem ${(p) =>
        p.cartHasItems ? '3rem' : ''} 4rem 7.5rem;
    grid-template-areas: 'menu logo . search ${(p) =>
      p.cartHasItems ? 'cart' : ''} account language';
  }
`

export const PrimaryHeaderWrapperCheckout = styled.div`
  display: none;

  ${media.md} {
    display: grid;
    height: ${(props) => props.theme.sizes.headerHeight}px;
    background-color: white;
    grid-template-columns: 3.5em 11rem;
    grid-template-areas: 'menu logo';
  }
`

export const Center = styled.div<{
  leftBorder?: boolean
  rightBorder?: boolean
  justifyContent?: string
  px?: string
}>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent};
  border-left: ${(props) =>
    props.leftBorder && `1px solid ${theme.colors.lightGrey}`};
  border-right: ${(props) =>
    props.rightBorder && `1px solid ${theme.colors.lightGrey}`};
  padding-left: ${(props) => props.px};
  padding-right: ${(props) => props.px};
`

export const ActionItems = styled.div<{
  leftBorder?: boolean
  rightBorder?: boolean
  justifyContent?: string
  px?: string
}>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent};
  border-left: ${(props) =>
    props.leftBorder && `1px solid ${theme.colors.lightGrey}`};
  border-right: ${(props) =>
    props.rightBorder && `1px solid ${theme.colors.lightGrey}`};
  padding-left: ${(props) => props.px};
  padding-right: ${(props) => props.px};
`

export const CenteredMobileAction = styled(Center)<{
  leftBorder?: boolean
  rightBorder?: boolean
  justifyContent?: string
  px?: string
}>`
  position: relative;
  top: -1px;
  flex-direction: column;
`

export const CategoriesContainer = styled.section`
  display: flex;
  grid-area: categories;
  justify-self: end;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 11px;
  padding-bottom: 11px;
  text-wrap: nowrap;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }

  & > .cta-button {
    font-size: 0.9rem !important;
  }
`

export const MainNavigation = styled.span`
  position: relative;
  color: ${(props) => props.theme.colors.black};
  font-size: 0.9rem;
  font-weight: 600;
`

export const HeaderSpacer = styled.div<{
  isCompact?: boolean
  isProfile?: boolean
}>`
  height: ${theme.sizes.headerHeight}px;

  ${media.md} {
    height: ${theme.sizes.headerHeight}px;
  }
`

export const HeaderSpacerCheckout = styled.div`
  height: 66px;
`

export const Dropdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:active {
    cursor: pointer;

    > .dropdown-list {
      display: block;
    }
  }
  position: relative;
`

export const DropdownMenu = styled.div<{
  position: 'left' | 'right'
  $offset?: number
  $minWidth?: number
}>`
  display: none;
  position: absolute;
  min-width: ${({ $minWidth = 180 }) => $minWidth}px;
  top: 100%;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  ${({ position, $offset = -12 }) =>
    position === 'left' &&
    `
      left: ${$offset}px;
  `};

  ${({ position, $offset = -12 }) =>
    position === 'right' &&
    `
      right: ${$offset}px;
  `};

  &:hover {
    display: block;
  }
`

export const DropdownItem = styled.div`
  color: ${(props) => props.theme.colors.black};
  font-size: 0.9rem;
  font-weight: 600;
  padding: 1rem;
  border-radius: 5px;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.primaryHover};
    cursor: pointer;
  }
`

export const IconLabel = styled.div`
  font-size: 0.8rem;
  font-weight: 600;
`
