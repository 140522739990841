import { DealValues, DealValuesTable } from '../DealValuesTable'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'
import { getKnpDebtInfo } from '@/domains/checkout/checkout.service'
import {
  getTotalGrossFeeAmountOfType,
  manipulatedAppliedFeeDefinitions,
} from '@/helpers/feesCalculation'
import useRegionCode from '@/helpers/useRegionCode'
import { KnpDebt, SelectedItemsType } from '@/types'
import {
  DealValuesEntry,
  ECountry,
  EDealType,
  TransportData,
  UsedFeeDefinition,
} from '@/types/gql/graphql'
import { printLocalAmount } from '@/utils/misc'

interface PayoutDealValuesProps {
  dealValuesEntry?: DealValuesEntry | undefined
  appliedUsedFeeDefinitions?: UsedFeeDefinition[] | undefined
  transportData?: TransportData
  dealType: EDealType
  dealItems?: Omit<SelectedItemsType, 'answers' | 'itemCategory'>[]
  customItemTitles?: string[]
  knpDebt?: KnpDebt | undefined
  showKnp?: boolean | undefined
}

export const PayoutDealValues: FunctionComponent<PayoutDealValuesProps> = ({
  appliedUsedFeeDefinitions,
  dealValuesEntry,
  transportData,
  customItemTitles,
  dealType,
  dealItems,
  knpDebt,
  showKnp,
}) => {
  const { t } = useTranslation()
  const regionCode = useRegionCode()
  const empty: DealValues = {
    last: true,
    label: t('common:payout'),
    value: 0,
    hideDecimalPlaces: false,
    hasQuestionMark: false,
  }
  const manipulatedAppliedUsedFeeDefinitions = manipulatedAppliedFeeDefinitions(
    appliedUsedFeeDefinitions,
    regionCode,
    dealItems?.map((c) => c.itemCategoryId),
    Boolean(customItemTitles),
  )

  const payback: DealValues = {
    last: false,
    label:
      dealType === EDealType.Pawn
        ? t('common:paybackAmount')
        : t('common:itemValues'),
    value:
      dealValuesEntry && manipulatedAppliedUsedFeeDefinitions
        ? dealValuesEntry.payoutAmount +
          getTotalGrossFeeAmountOfType(manipulatedAppliedUsedFeeDefinitions)
        : 0,
    hideDecimalPlaces: false,
    hasQuestionMark: false,
  }

  const knpDebtInfo =
    knpDebt?.loadedKnpDebt && dealValuesEntry?.payoutAmount
      ? getKnpDebtInfo({
          payoutAmount: dealValuesEntry?.payoutAmount,
          debtAmount: knpDebt?.loadedKnpDebt?.debtAmount,
        })
      : undefined

  const payoutDebt = knpDebtInfo?.debtPayout ?? 0
  const payoutAmount =
    knpDebtInfo?.cashyPayout ?? dealValuesEntry?.payoutAmount ?? 0

  const debt: DealValues = {
    last: false,
    label: 'Bezahlung offene Schulden bei KNP',
    value: -payoutDebt,
    hideDecimalPlaces: false,
    hasQuestionMark: false,
  }

  const feeValue = manipulatedAppliedUsedFeeDefinitions
    ? getTotalGrossFeeAmountOfType(manipulatedAppliedUsedFeeDefinitions)
    : undefined

  const fees: DealValues | undefined = feeValue
    ? {
        last: false,
        label:
          regionCode === ECountry.De.toString() && dealType === EDealType.Pawn
            ? t('common:fees_for_30_days')
            : dealValuesEntry?.durationInDays
              ? t('common:fees_for_days', {
                  durationInDays: dealValuesEntry.durationInDays.toString(),
                })
              : t('common:fees'),
        value: feeValue,
        hideDecimalPlaces: false,
        hasQuestionMark: true,
      }
    : undefined

  const payout: DealValues = {
    last: true,
    label: t('common:payout'),
    value: payoutAmount,
    hideDecimalPlaces: true,
    hasQuestionMark: false,
  }

  const feeFullContract: DealValues | undefined =
    regionCode === ECountry.De.toString() && dealType === EDealType.Pawn
      ? {
          last: false,
          label: t('common:fees_for_full_contract', {
            fullFees: printLocalAmount({
              number: getTotalGrossFeeAmountOfType(
                appliedUsedFeeDefinitions ?? [],
              ),
            }),
          }),
          value: -1,
          hideDecimalPlaces: false,
          hasQuestionMark: true,
          originalFees: true,
        }
      : undefined

  const dealValues: Array<DealValues | undefined> =
    manipulatedAppliedUsedFeeDefinitions?.length === 0
      ? [empty]
      : knpDebt?.loadedKnpDebt
        ? [payback, debt, fees, payout, feeFullContract]
        : [payback, fees, payout, feeFullContract]

  return (
    <DealValuesTable
      title={`${t(
        dealType === EDealType.Pawn
          ? 'common:you_get_so_much_money_pawn'
          : 'common:you_get_so_much_money_purchase',
      )}:`}
      dealValues={dealValues}
      totalFees={fees?.value}
      appliedUsedFeeDefinitions={appliedUsedFeeDefinitions}
      manipulatedAppliedUsedFeeDefinitions={
        manipulatedAppliedUsedFeeDefinitions
      }
      transportData={transportData}
      dealItems={dealItems}
      customItemTitles={customItemTitles}
      dealValuesEntry={dealValuesEntry}
      showKnp={showKnp}
      dealType={dealType}
    />
  )
}
