import useTranslation from 'next-translate/useTranslation'
import Image from 'next/legacy/image'
import * as React from 'react'
import CashyLogo from '@/app/common/assets/cashyLogo.png'
import { useModalDialogContext } from '@/app/common/context/modalDialogContext'
import {
  AgreeButton,
  ButtonsGroup,
  ButtonsGroupWrapper,
  ContentScreen,
  Logo,
  ModalContainer,
  ModalDescription,
  ModalFirstScreenLayout,
  NecessaryAgreeButton,
  SettingButton,
} from './CookiesAgreementStyles'
import GeneralModalScreen from './GeneralScreen'
import IndividualSelectionsScreen from './InvidualSelectionsScreen'
import { ConsentValue, CookieConsentMode } from './types'

interface CookiesControlState {
  allowGoogleAnalytics: boolean
  allowMetaPixel: boolean
  allowGoogleAds: boolean
  allowMicrosoftAds: boolean
  allowTiktokPixel: boolean
}

export type CookiesModalProps = {
  consent?: CookieConsentMode
}

export default function CookiesModal(props: CookiesModalProps) {
  const { t } = useTranslation()
  const modalDialog = useModalDialogContext()

  const [individualOptions, setIndividualOptions] = React.useState<boolean[]>([
    true,
    props.consent?.ad_storage === ConsentValue.Granted,
    props.consent?.analytics_storage === ConsentValue.Granted,
    props.consent?.meta_pixel === ConsentValue.Granted,
    props.consent?.microsoft_ads === ConsentValue.Granted,
    props.consent?.tiktok_pixel === ConsentValue.Granted,
  ])

  const handleClose = React.useCallback(
    (state: CookiesControlState) => {
      setConsents(state)
      modalDialog.close()
    },
    [modalDialog],
  )
  const [showAgreeIndividual, setShowAgreeIndividual] = React.useState(false)

  const onAgreeAll = () => {
    handleClose({
      allowGoogleAnalytics: true,
      allowGoogleAds: true,
      allowMetaPixel: true,
      allowMicrosoftAds: true,
      allowTiktokPixel: true,
    })
  }

  const onAgreeNecessary = () => {
    handleClose({
      allowGoogleAnalytics: false,
      allowGoogleAds: false,
      allowMetaPixel: false,
      allowMicrosoftAds: false,
      allowTiktokPixel: false,
    })
  }

  const onIndividualAgree = () => {
    if (showAgreeIndividual) {
      handleClose({
        allowGoogleAnalytics: individualOptions[1],
        allowGoogleAds: individualOptions[2],
        allowMicrosoftAds: individualOptions[3],
        allowMetaPixel: individualOptions[4],
        allowTiktokPixel: individualOptions[5],
      })
    } else {
      setShowAgreeIndividual(true)
    }
  }

  return (
    <ModalContainer>
      <ModalFirstScreenLayout>
        <ContentScreen>
          <Logo>
            <Image
              src={CashyLogo}
              alt="check-icon"
              width={40}
              height={40}
              unoptimized
            />
            <span>CASHY</span>
          </Logo>
          <ModalDescription>
            {showAgreeIndividual ? (
              <IndividualSelectionsScreen
                individualOptions={individualOptions}
                setIndividualOptions={setIndividualOptions}
                onCancel={() => setShowAgreeIndividual(false)}
              />
            ) : (
              <GeneralModalScreen />
            )}
          </ModalDescription>
        </ContentScreen>

        <ButtonsGroupWrapper>
          <ButtonsGroup>
            <AgreeButton onClick={onAgreeAll}>
              <p>{t('common:cookies.accept_all')}</p>
            </AgreeButton>
            <NecessaryAgreeButton onClick={onAgreeNecessary}>
              {t('common:cookies.accept_necessary')}
            </NecessaryAgreeButton>
          </ButtonsGroup>
          <SettingButton onClick={onIndividualAgree}>
            {showAgreeIndividual
              ? t('common:cookies.accepted_individual')
              : t('common:cookies.cookies_settings')}
          </SettingButton>
        </ButtonsGroupWrapper>
      </ModalFirstScreenLayout>
    </ModalContainer>
  )
}

function setConsents({
  allowGoogleAnalytics,
  allowGoogleAds,
  allowMetaPixel,
  allowMicrosoftAds,
  allowTiktokPixel,
}: CookiesControlState) {
  const consentMode = {
    ad_storage: consentValueFromBool(allowGoogleAds),
    ad_user_data: consentValueFromBool(allowGoogleAds),
    ad_personalization: consentValueFromBool(allowGoogleAds),
    analytics_storage: consentValueFromBool(allowGoogleAnalytics),
    meta_pixel: consentValueFromBool(allowMetaPixel),
    microsoft_ads: consentValueFromBool(allowMicrosoftAds),
    tiktok_pixel: consentValueFromBool(allowTiktokPixel),
    personalization_storage: ConsentValue.Granted,
    functionality_storage: ConsentValue.Granted,
    security_storage: ConsentValue.Granted,
  }
  localStorage.setItem(
    CONSENT_MODE_LOCAL_STORAGE_KEY,
    JSON.stringify(consentMode),
  )
  // GTAG initialize and updates
  const gtag = (window as any).gtag
  if (!gtag) return

  gtag('consent', 'update', consentMode)
}

export const CONSENT_MODE_LOCAL_STORAGE_KEY = 'consentMode'

function consentValueFromBool(allow: boolean) {
  return allow ? ConsentValue.Granted : ConsentValue.Denied
}
