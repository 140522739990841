import { ISbStoryData } from '@storyblok/react'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import {
  BlogArticleStoryblok,
  BlogLandingPageStoryblok,
} from '@/types/storyblok-component-types'
import { Hero } from './Hero'
import { HorizontalCard } from './HorizontalCard'
import { ReadMore } from './ReadMore'
import { VerticleCard } from './VerticleCard'
import { Container, Content, Grid, SectionTitle } from './styles'

export default function BlogHomePage({
  blok,
  latestArticle,
}: {
  blok: BlogLandingPageStoryblok
  latestArticle: ISbStoryData<BlogArticleStoryblok> | undefined
}) {
  const { t } = useTranslation()
  return (
    <Container>
      <Hero
        leftImage={blok.left_hero_image}
        rightImage={blok.right_hero_image}
        backgroundColor={blok.hero_background_color?.color}
      />
      <Content>
        {latestArticle?.content && (
          <div>
            <SectionTitle>{t('blog:latest_article')}</SectionTitle>
            <HorizontalCard
              title={latestArticle.content.title}
              image={latestArticle.content.image}
              slug={latestArticle.full_slug}
            />
          </div>
        )}
        {blok.popular_posts && blok.popular_posts.length > 0 && (
          <div>
            <SectionTitle>{t('blog:popular_articles')}</SectionTitle>
            <Grid>
              {blok.popular_posts?.map((post, index) => {
                if (typeof post === 'string') {
                  return null
                }

                return (
                  <VerticleCard
                    key={index}
                    title={post.content.title}
                    image={post.content.image}
                    slug={post.full_slug}
                  />
                )
              })}
            </Grid>
          </div>
        )}
      </Content>

      <Link href="/blog/all-articles">
        <ReadMore text={t('blog:all_articles')} isCenter hideArrow />
      </Link>
    </Container>
  )
}
