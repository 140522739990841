import { GraphQLError } from 'graphql'

type GraphQLOperationErrorOptions = {
  graphQLErrors: Array<GraphQLError> | null
  networkError: Error | null
}
export class GraphQLOperationError extends Error {
  name: string
  message: string
  graphQLErrors: Array<GraphQLError> | null
  networkError: Error | null
  cause: Error | null

  constructor({ graphQLErrors, networkError }: GraphQLOperationErrorOptions) {
    const message = generateErrorMessage(graphQLErrors, networkError)
    super(message)
    this.name = 'GraphQLOperationError'
    this.message = message
    this.graphQLErrors = graphQLErrors
    this.networkError = networkError
    this.cause =
      [networkError, ...(graphQLErrors || [])].find((e) => !!e) || null
  }
}

function generateErrorMessage(
  graphQLErrors: Array<GraphQLError> | null,
  networkError: Error | null,
) {
  const errors = [...(graphQLErrors || []), networkError]
  if (!errors.length) {
    return 'Unknown GraphQL error'
  }
  return errors
    .map((err) => (err && err.message) || 'Error message not found.')
    .join('\n')
}
