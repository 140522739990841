import { Box } from '@material-ui/core'
import useTranslation from 'next-translate/useTranslation'
import { useContext } from 'react'
import styled from 'styled-components'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import Text from '@/style/components/LegacyText'

interface LoginPromptProps {
  content: JSX.Element
}

const LoginPrompt = ({ content }: LoginPromptProps) => {
  const { t } = useTranslation()
  const modal = useContext(ModalDialogContext)

  return (
    <PromptWrapper
      onClick={() =>
        modal.open(content, {
          variant: 'full',
        })
      }
    >
      <PromptText>{t('common:auth.account_already')}</PromptText>
      <UnderLinedText>{t('common:auth.login')}</UnderLinedText>
    </PromptWrapper>
  )
}

const PromptWrapper = styled(Box)`
  padding: 1rem;
  background-color: #f2f4f8;
  border-radius: 5px;
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`

const PromptText = styled(Text.md)`
  font-size: 0.75rem;
`

const UnderLinedText = styled(Text.sm)`
  display: inline;
  border-bottom: 0.7px solid black;
  padding-bottom: 1px;
  font-weight: 500;
  margin-left: auto;
  ::after {
    content: ' ';
    display: block;
  }
`

export default LoginPrompt
