import { useImageSizes } from '../Section/Section'
import { storyblokEditable } from '@storyblok/react'
import styled from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { media } from '@/style/helpers'
import { TextWithDesktopImageStoryblok } from '@/types/storyblok-component-types'

export default function TextWithDesktopImage({
  blok,
}: {
  blok: TextWithDesktopImageStoryblok
}) {
  const sizes = useImageSizes(42, ['md', '1px'])

  return (
    <Container {...storyblokEditable(blok)}>
      <CaptionWrapper>
        <StoryblokRichText document={blok.caption} />
      </CaptionWrapper>
      <SpaceWrapper />
      <ImageWrapper>
        {blok.image?.filename && (
          <StoryblokImage image={blok.image} sizes={sizes} />
        )}
      </ImageWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    letter-spacing: 0em;
    line-height: 1.4;
  }

  ${media.md} {
    flex-direction: row;
  }
`

const CaptionWrapper = styled(RichTextParagraphStyles)`
  flex: 1 0 0px;
  text-align: center;
  ${media.md} {
    flex: 5 0 0px;
    text-align: left;
  }

  & > a:last-of-type {
    ${media.md} {
      margin-top: 0.5rem !important;
    }
  }

  & p {
    b {
      font-weight: 600;
    }
  }

  & h1 {
    font-weight: 700 !important;
  }

  & h2 {
    font-weight: 700 !important;
  }
`

const SpaceWrapper = styled.div`
  flex: 0 0 0px;
  display: none;
  ${media.md} {
    display: block;
    flex: 2 0 0px;
  }
`

const ImageWrapper = styled.div`
  flex: 0 0 0px;
  display: none;
  ${media.md} {
    display: block;
    flex: 5 0 0px;
  }
`
