import LogoImage from '../assets/small_logo.svg'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { media } from '@/style/helpers'
import {
  AssetStoryblok,
  RichtextStoryblok,
} from '@/types/storyblok-component-types'

interface IntroductionProps {
  topDesktopImage: AssetStoryblok | undefined
  topMobileImage: AssetStoryblok | undefined
  caption: RichtextStoryblok
}

const Introduction: FunctionComponent<IntroductionProps> = ({
  topDesktopImage,
  topMobileImage,
  caption,
}: IntroductionProps) => {
  return (
    <IntroWrapper>
      <MainImageWrapper>
        <HeaderDesktopImage>
          {topDesktopImage?.filename && (
            <StoryblokImage
              image={topDesktopImage}
              layout="responsive"
              objectFit="fill"
            />
          )}
        </HeaderDesktopImage>

        <HeaderMobileImage>
          {topMobileImage?.filename && (
            <StoryblokImage
              image={topMobileImage}
              layout="responsive"
              objectFit="fill"
            />
          )}
        </HeaderMobileImage>
      </MainImageWrapper>

      <MainTextWrapper>
        <CashyLogo>
          <LogoImage style={{ width: '100%', height: '100%' }} />
        </CashyLogo>

        <DescriptionWrapper>
          <CustomRichTextParagraphStyles>
            <StoryblokRichText document={caption} />
          </CustomRichTextParagraphStyles>
        </DescriptionWrapper>
      </MainTextWrapper>
    </IntroWrapper>
  )
}

export default Introduction

const IntroWrapper = styled.div`
  margin-bottom: 5rem;
  border: 1px solid transparent;

  ${media.sm} {
    margin-top: auto;
    margin-left: 5rem;
    margin-bottom: 5rem;
    margin-right: 3rem;
  }

  ${media.md} {
    margin: auto 0px auto 7.5rem !important;
    margin-bottom: 7rem !important;
  }

  @media screen and (min-width: 1441px) {
    margin: auto 0px auto 7.5rem !important;
    margin-bottom: 7rem !important;
  }
`

const MainImageWrapper = styled.div`
  margin-top: -0.2rem;

  ${media.sm} {
    float: right;
    width: 55vw;
    max-width: 50rem;
    margin-top: -0.2rem;
  }

  ${media.md} {
  }
`

const HeaderDesktopImage = styled.div`
  display: none;

  ${media.sm} {
    display: block;
    width: 90%;
    float: right;
  }
`

const HeaderMobileImage = styled.div`
  display: block;

  ${media.sm} {
    display: none;
  }
`

const MainTextWrapper = styled.div`
  text-align: center;

  ${media.sm} {
    margin-top: 4rem;
    text-align: left;
    width: 30rem;
    position: static;
  }

  ${media.md} {
    margin-top: 10vw;
    width: 65%;
  }
`

const CashyLogo = styled.div`
  margin: auto;
  margin-top: -2rem;
  margin-bottom: 1.5rem;
  width: 3.5rem;
  height: 3.5rem;
  position: sticky;

  ${media.xs} {
    margin-top: -2.6rem;
    margin-bottom: 1.5rem;
  }

  ${media.sm} {
    margin: auto 0;
    margin-bottom: 1.2rem;
  }

  ${media.md} {
    margin-bottom: 1.5rem;
    width: 6.3rem;
    height: 6.3rem;
  }
`

const DescriptionWrapper = styled.div`
  margin: auto 2.3rem;
  margin-bottom: 2rem;

  ${media.xs} {
    margin: auto 2.9rem !important;
    margin-bottom: 2rem;
  }

  ${media.sm} {
    width: 80%;
    max-width: 36rem;
    margin: auto 0 !important;
    margin-bottom: 1.3rem;
  }

  ${media.md} {
    width: 80%;
    margin: auto 0 !important;
    max-width: 50rem;
    margin-bottom: 2.2rem;
  }
`

const CustomRichTextParagraphStyles = styled(RichTextParagraphStyles)`
  & > p {
    font-size: 1rem;
    line-height: 1.3rem;
    text-align: center;
    letter-spacing: 0.1;

    ${media.xs} {
      font-size: 0.9rem !important;
    }

    ${media.sm} {
      font-size: 0.85rem !important;
      line-height: 1.3rem !important;
      text-align: left !important;
    }

    ${media.md} {
      font-size: 0.95rem !important;
      line-height: 1.4rem !important;
      letter-spacing: 0.44px !important;
    }
  }

  & > p:first-of-type {
    margin-top: 0 !important;
  }

  & > p:last-of-type {
    margin-bottom: 2rem;

    ${media.xs} {
      margin-bottom: 2rem;
    }

    ${media.sm} {
      margin-bottom: 1.3rem;
    }

    ${media.md} {
      margin-bottom: 2.2rem;
    }
  }
`
