import styled, { css } from 'styled-components'
import Sizes from './sizes'

interface ParagraphProps {
  align?: 'left' | 'center' | 'right' | 'justify'
}

export default styled(Sizes.md).attrs<ParagraphProps>((props) => ({
  as: 'p',
  align: props.align,
}))<ParagraphProps>`
  margin: 1.25rem 0;

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}
`
