import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
import variants, { delayedVariant } from './variants'

const AnimatedChildDiv = styled(motion.div)`
  position: relative;
`

interface ScrollRevealChildProps {
  animation?: keyof typeof variants
  delay?: number
  className?: string
  children: React.ReactNode
}

const ScrollRevealChild = ({
  children,
  animation,
  delay,
  className,
}: ScrollRevealChildProps) => {
  const variant = delayedVariant(animation || 'slideUp', delay || 0)

  return (
    <AnimatedChildDiv variants={variant} className={className}>
      {children}
    </AnimatedChildDiv>
  )
}

export default ScrollRevealChild
