import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { CSSProperties } from 'react'
import styled from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import { stripExtrasFromSlug } from '@/helpers/getStoryBlokLink'
import { media } from '@/style/helpers'
import { AssetStoryblok } from '@/types/storyblok-component-types'
import { ReadMore } from './ReadMore'

export const HorizontalCard = ({
  title,
  image,
  slug,
  style,
  reversed = false,
  widerText = false,
}: {
  title?: string
  image?: AssetStoryblok
  slug: string
  style?: CSSProperties
  reversed?: boolean
  widerText?: boolean
}) => {
  const { t } = useTranslation()
  return (
    <Container
      style={style ?? {}}
      reversed={reversed}
      href={stripExtrasFromSlug(slug)}
    >
      <CardText $widerText={widerText}>
        <div>
          <h3>{title}</h3>
          <ReadMore text={t('blog:read_article')} />
        </div>
      </CardText>
      <CardImage $widerText={widerText}>
        {image?.filename && (
          <StoryblokImageWrapper
            sizes={`(max-width: 992px) 94vw, (max-width: 1200px) ${
              widerText ? '38vw' : '46vw'
            }, 458px`}
            layout="responsive"
            $reversed={reversed}
            image={image}
          />
        )}
      </CardImage>
    </Container>
  )
}

const Container = styled(Link)<{ reversed: boolean }>`
  border-radius: 10px;
  text-decoration: none;
  display: flex;
  flex-direction: column-reverse;
  transition: 0.4s ease-in;

  &:hover {
    transform: scale(1.02);
    transition: 0.4s ease-out;
  }

  ${media.md} {
    flex-direction: ${(props) => (props.reversed ? 'row-reverse' : 'row')};
  }
  box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.25);
`

const CardText = styled.div<{ $widerText: boolean }>`
  flex: ${(props) => (props.$widerText ? 7 : 6)} 0 0px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
`

const CardImage = styled.div<{ $widerText: boolean }>`
  flex: ${(props) => (props.$widerText ? 5 : 6)} 0 0px;
  margin-bottom: 1rem;

  ${media.md} {
    margin-bottom: 0;
  }
`

const StoryblokImageWrapper = styled(StoryblokImage)<{ $reversed: boolean }>`
  border-radius: 10px 10px 0px 0px;

  ${media.md} {
    border-radius: ${(props) =>
      props.$reversed ? '10px 0px 0px 10px' : '0px 10px 10px 0px'};
  }
`
