import CartButton from '../Header/CartButton'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import { useRouter } from 'next/router'
import { HeaderLogo } from '@/app/common/components/Header/Logo/Logo'
import { SearchButton } from '@/app/common/components/Header/SeachButton/SearchButton'
import AccountButton from './AccountButton'
import MenuButton from './MenuButton'
import {
  Center,
  GridItem,
  PrimaryHeaderWrapperCheckout,
  TabletPrimaryHeaderWrapper,
} from './styled'

type Props = {
  menuOpen: boolean
  toggleMenu: () => void
  itemsCount: number
  itemsTotalAmount: string
}

export const TabletPrimaryHeader = ({
  menuOpen,
  toggleMenu,
  itemsCount,
  itemsTotalAmount,
}: Props) => {
  return (
    <TabletPrimaryHeaderWrapper cartHasItems={itemsCount > 0}>
      <PrimaryHeader
        menuOpen={menuOpen}
        toggleMenu={toggleMenu}
        itemsCount={itemsCount}
        itemsTotalAmount={itemsTotalAmount}
      />
    </TabletPrimaryHeaderWrapper>
  )
}

export const PrimaryHeaderCheckout = ({
  menuOpen,
  toggleMenu,
  itemsCount,
  itemsTotalAmount,
}: Exclude<Props, 'isCheckout'>) => {
  return (
    <PrimaryHeaderWrapperCheckout>
      <PrimaryHeader
        menuOpen={menuOpen}
        toggleMenu={toggleMenu}
        itemsCount={itemsCount}
        itemsTotalAmount={itemsTotalAmount}
      />
    </PrimaryHeaderWrapperCheckout>
  )
}

const PrimaryHeader = ({ menuOpen, toggleMenu, itemsCount }: Props) => {
  const router = useRouter()
  const isCheckout = router.pathname.includes('/checkout')
  const isFinalizeAccountSetup = router.pathname.includes(
    '/finalize-account-setup',
  )

  return (
    <>
      <GridItem area="menu">
        <Center justifyContent="center">
          <MenuButton menuOpen={menuOpen} toggleMenu={toggleMenu} />
        </Center>
      </GridItem>

      <GridItem area="logo">
        <Center>
          <HeaderLogo />
        </Center>
      </GridItem>

      {!isCheckout && !isFinalizeAccountSetup && itemsCount > 0 && (
        <GridItem zIndex={1} area="cart">
          <Center justifyContent="center">
            <CartButton itemsCount={itemsCount} />
          </Center>
        </GridItem>
      )}
      {!isCheckout && (
        <GridItem area="search">
          <Center>
            <SearchButton />
          </Center>
        </GridItem>
      )}
      {!isCheckout && !isFinalizeAccountSetup && <AccountButton />}
      {!isCheckout && (
        <GridItem zIndex={1} area="language">
          <LanguageSelector />
        </GridItem>
      )}
    </>
  )
}
