import Link from 'next/link'
import { ComponentProps } from 'react'
import styled, { css } from 'styled-components'

interface ItemProps extends React.ComponentPropsWithoutRef<'a'> {
  weight?: 'regular' | 'medium' | 'semibold' | 'bold'
  onlyOnHover?: boolean
  fontSize?: string
}

const UnderlinedLink = styled.a<ItemProps>`
  color: inherit;
  text-decoration: underline ${({ theme }) => theme.colors.secondary};
  text-underline-offset: 3px;
  position: relative;
  ${(props) =>
    props.fontSize &&
    `
    font-size: ${props.fontSize};
  `}

  ${({ weight }) =>
    weight === 'medium' &&
    css`
      font-weight: 500;
      border-bottom-width: 2px;
    `}

  ${({ weight }) =>
    weight === 'semibold' &&
    css`
      font-weight: 600;
      border-bottom-width: 2px;
    `}

  ${({ weight }) =>
    weight === 'bold' &&
    css`
      font-weight: 700;
      border-bottom-width: 2px;
    `}

    &:hover::after {
    transform: translateY(-0.1em);
    opacity: 1;
  }
`

UnderlinedLink.defaultProps = {
  weight: 'regular',
  onlyOnHover: false,
}

export default UnderlinedLink

export const UnderlinedNextLink = ({
  children,
  className,
  style,
  target,
  ...props
}: ComponentProps<typeof Link>) => (
  <Link legacyBehavior passHref {...props}>
    <UnderlinedLink className={className} style={style} target={target}>
      {children}
    </UnderlinedLink>
  </Link>
)
