import { useImageSizes } from '../Section/Section'
import { storyblokEditable } from '@storyblok/react'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { media } from '@/style/helpers'
import { ContactCardStoryblok } from '@/types/storyblok-component-types'
import ArrowRight from './assets/arrow_right.svg'

export default function ContactCard({ blok }: { blok: ContactCardStoryblok }) {
  const sizes = useImageSizes(32, ['md', '100vw'])
  const { t } = useTranslation()

  return (
    <Container
      href={blok.link?.url || ''}
      legacyBehavior={false}
      {...storyblokEditable(blok)}
    >
      <ImageWrapper>
        {blok.image?.filename && (
          <StoryblokImage
            image={blok.image}
            sizes={sizes}
            layout="responsive"
          />
        )}
      </ImageWrapper>
      <TextWrapper>
        <RichTextWrapper>
          <StoryblokRichText document={blok.content} />
        </RichTextWrapper>
        <div>
          <ContactLink>
            {t('common:contact_now')}
            <ArrowRight />
          </ContactLink>
        </div>
      </TextWrapper>
    </Container>
  )
}

const Container = styled(Link)`
  display: flex;
  cursor: pointer;
  border-radius: 15px;
  box-shadow: 0px 0px 30px -10px rgba(0, 0, 0, 0.25);
  margin: 28px 0;
  flex-direction: column;
  transition: 0.4s ease-out;
  text-decoration: none;
  color: initial;

  &:hover {
    transform: scale(1.02);
  }

  ${media.md} {
    margin: 28px 16px;
    align-items: center;
    flex-direction: row;
  }
`
const ImageWrapper = styled.div`
  width: 100%;
  max-height: 264px;
  flex-shrink: 0;
  border-radius: 15px 15px 0 0;
  overflow: hidden;

  ${media.md} {
    width: 314px;
    height: 100%;
    border-radius: 15px 0 0 15px;
  }
`

const TextWrapper = styled.div`
  flex-grow: 1;
  padding: 1.85rem 1rem;

  ${media.md} {
    padding: 3.125rem;
  }
`

const ContactLink = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;

  > svg {
    vertical-align: middle;
    margin-left: 0.5rem;
  }
`

const RichTextWrapper = styled(RichTextParagraphStyles)`
  h3 {
    font-size: 1.75rem;
  }
`
