import { storyblokEditable } from '@storyblok/react'
import { useState } from 'react'
import styled from 'styled-components'
import { LoadingOverlay } from '@/app/common/components/LoadingOverlay'
import { GraphQLPrefetchClient } from '@/app/common/graphql/prefetchClient'
import { media } from '@/style/helpers'
import {
  CustomDealCalculation,
  EDealType,
  GetCustomDealFormByCategoryDocument,
  GetCustomDealFormByCategoryQuery,
  GetCustomDealFormByCategoryQueryVariables,
} from '@/types/gql/graphql'
import { CustomDealFormStoryblok } from '@/types/storyblok-component-types'
import { CustomDealFormBaseLayout } from './CustomDealFormBaseLayout'
import { CustomDealHero } from './CustomDealHero'
import { FormHeading } from './FormHeading'
import { ItemList } from './ItemList'

export function prefetchCustomDealForm(
  client: GraphQLPrefetchClient,
  variables: GetCustomDealFormByCategoryQueryVariables,
) {
  return client.executeQuery<
    GetCustomDealFormByCategoryQuery,
    GetCustomDealFormByCategoryQueryVariables
  >({
    document: GetCustomDealFormByCategoryDocument,
    variables,
    // TODO: Send invalidations from backend and enable caching
    staleTime: 0,
  })
}

export default function CustomDealForm({ blok }: CustomDealFormStoryblok) {
  const [isLoading, setIsLoading] = useState(false)
  const [customItemTitles, setCustomItemTitles] = useState<string[]>([])

  const [customDealCalculation, setCustomDealCalculation] = useState<
    | {
        pawn?: CustomDealCalculation
        purchase?: CustomDealCalculation
      }
    | undefined
  >()
  const [dealType, setDealType] = useState(EDealType.Pawn)
  const [isSimplifiedPawnDeal, setIsSimplifiedPawnDeal] = useState(
    dealType === EDealType.Pawn,
  )
  const [isCommissionSale, setIsCommissionSale] = useState(false)

  if (isSimplifiedPawnDeal && dealType !== EDealType.Pawn) {
    setIsSimplifiedPawnDeal(false)
  }

  const dealCalculation =
    dealType === EDealType.Pawn
      ? customDealCalculation?.pawn
      : customDealCalculation?.purchase

  return (
    <Container {...storyblokEditable(blok)}>
      {isLoading && <LoadingOverlay />}

      <CustomDealHero blok={blok} />

      <StyledCustomDealFormBaseLayout
        dealType={dealType}
        dealValuesEntry={dealCalculation?.dealValuesEntry}
        appliedUsedFeeDefinitions={dealCalculation?.appliedUsedFeeDefinitions}
        displayMoneySvg
        customItemTitles={customItemTitles}
        isCommissionSale={isCommissionSale}
        ssr
        content={
          <div>
            <FormHeading />
            <ItemList
              isSimplifiedPawnDeal={isSimplifiedPawnDeal}
              initialCategoryId={blok.category_id}
              onCustomDealCalculated={setCustomDealCalculation}
              onIsLoadingChange={setIsLoading}
              onCustomItemTitlesChange={setCustomItemTitles}
              dealType={dealType}
              onDealTypeChange={setDealType}
              dealCalculation={dealCalculation}
              isCommissionSale={isCommissionSale}
              onIsCommissionSaleChange={setIsCommissionSale}
            />
          </div>
        }
      />
    </Container>
  )
}

const Container = styled.div``

const StyledCustomDealFormBaseLayout = styled(CustomDealFormBaseLayout)`
  aside {
    ${media.md} {
      min-width: 350px;
    }
  }
`
