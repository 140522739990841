import { useRouter } from 'next/router'
import queryString from 'query-string'

// query only works with SSR in next, need to parse asPath myself
export function useSearchParams() {
  const router = useRouter()

  const splits = router.asPath.split('?')

  if (splits.length === 1) {
    // no ? found in url
    return {}
  } else {
    const search = `?${splits[1]}`

    const params = queryString.parse(search)

    return params
  }
}
