const variants = {
  slideUp: {
    visible: {
      opacity: 1,
      top: 0,
      transition: {
        when: 'beforeChildren',
        ease: 'easeInOut',
      },
    },
    hidden: {
      opacity: 0,
      top: 20,
    },
  },
  slideDown: {
    visible: {
      opacity: 1,
      top: 0,
      transition: {
        when: 'beforeChildren',
        ease: 'easeInOut',
      },
    },
    hidden: {
      opacity: 0,
      top: -50,
    },
  },
  fadeIn: {
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        ease: 'easeInOut',
      },
    },
    hidden: {
      opacity: 0,
    },
  },
  none: {
    visible: { opacity: 1, transition: {} },
    hidden: { opacity: 1, transition: {} },
  },
  scale: {
    visible: {
      opacity: 1,
      scale: 1.00001,
      transition: {
        when: 'beforeChildren',
        ease: 'easeInOut',
      },
    },
    hidden: {
      opacity: 0,
      scale: 0.4,
    },
  },
}

export default variants

export const delayedVariant = (
  variantName: keyof typeof variants,
  delay: number,
) => {
  const variant = variants[variantName]
  return Object.assign({}, variant, {
    visible: Object.assign({}, variant.visible, {
      transition: Object.assign({}, variant.visible.transition, {
        delay,
      }),
    }),
  })
}
