import { IconButton, InputAdornment, TextField } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { FormikProps } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import { ChangeEvent, useState } from 'react'
import styled from 'styled-components'
import { setLocale } from 'yup'
import { media } from '@/style/helpers'
import { getTranslatedValidationError } from '@/utils/error'
import { InputErrorMessage } from './InputErrorMessage'

setLocale({
  mixed: {
    default: 'common:errors.wrong_format',
    required: 'common:errors.is_required',
  },
  number: {},
  string: {
    email: 'common:errors.must_be_valid_email',
    min: (params) => {
      return { key: 'common:errors.too_short', values: { min: params.min } }
    },
  },
})

const SmartInputStyle = styled.div<{ marginBottom?: string }>`
  width: 100%;
  margin-bottom: ${(props) => props.marginBottom ?? '10px'};
`

const TextFieldStyled = styled(TextField)<{
  error: boolean
  multiline?: boolean
}>`
  .MuiFilledInput-root {
    background-color: white;
    input {
      font-size: 0.75rem;
    }
    textarea {
      font-size: 0.75rem;
    }

    ${media.sm} {
      textarea {
        font-size: 0.875rem;
      }
    }

    ${(props) =>
      !props.multiline &&
      `
      height: 3.3rem;

      ${media.sm} {
        input {
          font-size: 0.875rem;
        }
      }
    `}

    font-family: GalanoGrotesque-Medium,sans-serif;

    border: 2px solid
      ${(props) => (!props.error ? props.theme.colors.border : '#f44336')};
    border-radius: ${({ theme }) => theme.radius.normal}px;

    &.Mui-focused {
      background-color: white !important;
    }
    &:active,
    &:hover {
      background-color: white;
    }
    input {
      font-size: 0.875rem;
    }
  }
  .MuiFilledInput-underline {
    &:after,
    &:before,
    &:hover:before {
      border: none;
    }
  }
  label.Mui-focused {
    color: #666666;
  }

  .MuiFilledInput-input {
    ${(props) => !props.multiline && `padding: 27px 10px 12px;`}
  }

  .MuiFormLabel-root {
    font-size: 0.75rem;
    ${media.sm} {
      font-size: 0.875rem;
    }
  }
  .MuiInputLabel-formControl {
    top: -0.3rem;

    ${media.sm} {
      top: 0;
    }
  }
  .MuiFormLabel-filled {
    top: 0 !important;
  }
  .Mui-focused {
    top: 0 !important;
  }
  .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(12px, 8px) scale(0.75);

    ${media.sm} {
      transform: translate(12px, 10px) scale(0.75);
    }
  }
`

interface TextInputProps {
  id?: string
  type: string
  name: string
  label: string
  error: boolean
  multiline?: boolean
  rows?: number
  onChange?: (e: ChangeEvent<any>) => void
  onBlur?: (e: any) => void
  value?: string
  disabled?: boolean
}

const TextInput: React.FC<TextInputProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false)

  let type = props.type

  if (type === 'password') {
    type = showPassword ? 'text' : 'password'
  }

  return (
    <TextFieldStyled
      {...props}
      type={type}
      value={props.value || ''}
      error={props.error}
      variant="filled"
      fullWidth
      rows={props.rows}
      multiline={props.multiline ?? false}
      InputProps={{
        inputProps: props.type == 'number' ? { min: 0 } : undefined,
        endAdornment:
          props.type == 'password' ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ) : undefined,
        startAdornment:
          props.type === 'money' ? (
            <InputAdornment position="start">€</InputAdornment>
          ) : undefined,
      }}
    />
  )
}

const SmartTextInput: React.FC<{
  id?: string
  type: string
  name: string
  placeholder: string
  marginBottom?: string
  formikProps: FormikProps<any>
  showValidationError?: boolean
  multiline?: boolean
  rows?: number
  disabled?: boolean
  onBlur?: () => void
}> = ({
  id,
  type,
  name,
  placeholder,
  marginBottom,
  formikProps,
  showValidationError = true,
  multiline,
  rows,
  ...props
}) => {
  const { t } = useTranslation()
  const field = formikProps.getFieldMeta(name)

  let error
  if (field.error && field.touched && showValidationError) {
    error = field.error
  }

  return (
    <SmartInputStyle marginBottom={marginBottom}>
      <TextInput
        id={id}
        type={type === 'money' ? 'number' : type}
        name={name}
        value={(field.value as string) ?? ''}
        onChange={formikProps.handleChange}
        label={placeholder}
        error={error != undefined}
        multiline={multiline}
        rows={rows}
        onBlur={props.onBlur}
        disabled={props.disabled}
      />
      {error && (
        <InputErrorMessage message={getTranslatedValidationError(error, t)} />
      )}
    </SmartInputStyle>
  )
}

export default SmartTextInput
