import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import styled from 'styled-components'
import { StepsReferenceStoryblok } from '@/types/storyblok-component-types'

export default function StepsReference({
  blok,
}: {
  blok: StepsReferenceStoryblok
}) {
  return typeof blok.steps !== 'string' && blok.steps?.content ? (
    <Container {...storyblokEditable(blok)}>
      <StoryblokComponent blok={blok.steps.content} key={blok._uid} />
    </Container>
  ) : null
}

const Container = styled.div``
