import { useImageSizes } from '../Section/Section'
import { storyblokEditable } from '@storyblok/react'
import React from 'react'
import styled from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { media } from '@/style/helpers'
import { CaptionedImagesListItemStoryblok } from '@/types/storyblok-component-types'

export default function CaptionedImagesListItem({
  blok,
  index,
}: {
  blok: CaptionedImagesListItemStoryblok
  index: number
}) {
  const sizes = useImageSizes(44, ['sm', '100vw'])
  const imagePosition = index % 2 === 0 ? 'right' : 'left'

  return (
    <Container $imagePosition={imagePosition} {...storyblokEditable(blok)}>
      {blok.image.filename && (
        <ImageWrapper>
          <div style={{ width: '100%' }}>
            <StoryblokImage image={blok.image} sizes={sizes} />
          </div>
        </ImageWrapper>
      )}
      <TextWrapper>
        <RichTextWrapper>
          <StoryblokRichText document={blok.caption} />
        </RichTextWrapper>
      </TextWrapper>
    </Container>
  )
}

const ImageWrapper = styled.div`
  flex: 1 0 0px;
  padding: 0;
  display: flex;
  justify-content: center;

  ${media.sm} {
    flex: 6 0 0px;
    margin-left: 2rem;
    margin-right: 2rem;
  }
`

const TextWrapper = styled.div`
  flex: 1 0 0px;
  text-align: center;
  margin-top: 2rem;

  ${media.xs} {
    margin-top: 3rem;
  }

  ${media.sm} {
    flex: 6 0 0px;
    text-align: left;
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
`

const Container = styled.div<{
  $imagePosition: 'left' | 'right' | ''
}>`
  b {
    font-weight: 700 !important;
  }

  display: flex;
  flex-direction: column !important;
  margin-bottom: 3rem;

  ${media.sm} {
    margin-bottom: 7rem;
    flex-direction: ${(props) =>
      props.$imagePosition === 'left' ? 'row' : 'row-reverse'} !important;
  }

  ${media.md} {
    margin-bottom: 10rem;
  }
`

const RichTextWrapper = styled(RichTextParagraphStyles)`
  ul {
    list-style-type: disc;
    padding-left: 32px;

    li::marker {
      color: rgb(1, 255, 108) !important;
    }
  }

  & p {
    font-size: 1rem !important;
  }

  & > p:last-of-type {
    margin-bottom: 0 !important;
  }
`
