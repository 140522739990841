import styled from 'styled-components'
import { Button } from '@/style/components/Button'

export const CustomDealItemWrapper = styled.div<{
  show: boolean
}>`
  margin: 2rem 0;
  display: ${({ show }) => (show ? 'block' : 'none')};
`

export const SaveButton = styled(Button)`
  margin: 1rem 0;
  padding: 6px 14px;
  border: 2px solid #000000;
`

export const CustomDealItemPreview = styled.div<{
  isError: boolean
  show: boolean
}>`
  background-color: #f2f4f8;
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: 1px solid ${({ isError }) => (isError ? 'red' : 'transparent')};
  display: ${({ show }) => (show ? 'block' : 'none')};
`

export const No = styled.span`
  margin-right: 0.25rem;
  color: #898989;
`

export const ItemName = styled.span``

export const ItemPayoutAmount = styled.span``

export const ItemHeading = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-weight: bold;
`

export const EditButton = styled.div`
  text-decoration: underline;
  font-size: 0.875rem;
  cursor: pointer;
  text-align: left;
`

export const DeleteButton = styled.div`
  font-weight: normal;
  font-size: 0.875rem;
  cursor: pointer;
  span {
    text-decoration: underline;
  }
`

export const ItemNo = styled.div`
  margin-left: 1rem;
`
