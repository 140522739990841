import IconSearch from '../assets/icon_search.svg'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'

export function SearchBarButton() {
  const { t } = useTranslation()

  return (
    <Link href="/search" className="flex w-[300px]">
      <div className="flex flex-grow items-center border border-r-0 border-gray-200 rounded-l-md bg-white py-2 px-4 text-xs text-gray-600 cursor-text">
        {t('common:header.find_your_item_short')}
      </div>
      <div className="flex items-center justify-center w-11 h-11 bg-black rounded-r-md">
        <IconSearch className="size-5 text-white" />
      </div>
    </Link>
  )
}
