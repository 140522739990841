import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import React from 'react'
import { CaptionedImageReferenceStoryblok } from '@/types/storyblok-component-types'

export default function CaptionedImageReference({
  blok,
}: {
  blok: CaptionedImageReferenceStoryblok
}) {
  return blok.item && typeof blok.item !== 'string' ? (
    <StoryblokComponent
      {...storyblokEditable(blok)}
      blok={(blok.item as any).content}
      key={blok._uid}
    />
  ) : null
}
