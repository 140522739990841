import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import useTranslation from 'next-translate/useTranslation'
import styled from 'styled-components'
import { CustomerLoggedInfo } from '@/app/auth/auth.state'
import { Link } from '@/app/common/components/Link'
import { UnstyledButton } from '@/style/components/Button'

type Props = {
  user?: CustomerLoggedInfo
  showBackButton?: boolean
  back?: () => void
  onLogin?: () => void
  onRegister?: () => void
  toggleMenu?: () => void
}

const MenuHeader = ({
  showBackButton,
  back,
  onLogin,
  onRegister,
  user,
  toggleMenu,
}: Props) => {
  const { t } = useTranslation()
  if (showBackButton) {
    return (
      <BackHeader>
        <UnstyledButton onClick={back}>
          <BackIcon fontSize="small" />
          <BackText>{t('common:back')}</BackText>
        </UnstyledButton>
      </BackHeader>
    )
  }

  if (user) {
    return (
      <UserHeader>
        <HeaderText>
          {t('common:hi')} {user?.firstname}
        </HeaderText>

        <ActionDiv onClick={() => toggleMenu && toggleMenu()}>
          <HeaderActionLink primaryAction href="/profile/deals">
            {t('common:account')}
          </HeaderActionLink>
        </ActionDiv>
      </UserHeader>
    )
  }

  return (
    <LoginHeader>
      <HeaderActionButton onClick={onLogin}>
        {t('common:auth.login')}
      </HeaderActionButton>
      <HeaderActionButton primaryAction onClick={onRegister}>
        {t('common:auth.register')}
      </HeaderActionButton>
    </LoginHeader>
  )
}

export default MenuHeader

const HeaderActionButton = styled(UnstyledButton)<{
  primaryAction?: boolean
}>`
  font-weight: 600;
  flex: 0 0 50%;
  color: ${(props) =>
    props.primaryAction ? props.theme.colors.primary : undefined};
`

const LoginHeader = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  & > button:not(:last-child) {
    border-right: 1px solid ${(props) => props.theme.colors.lightGrey};
  }
`

const BackHeader = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 1rem;
`

const BackText = styled.div`
  padding-left: 1rem;
  display: inline-block;
  font-weight: 600;
`

const BackIcon = styled(ArrowBackIcon)`
  position: relative;
  top: 5px;
`

const UserHeader = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 1.25rem 0rem;
  font-weight: 400;
`

const HeaderText = styled.div`
  margin-right: 0.5rem;
`

export const ActionDiv = styled.div``

const HeaderActionLink = styled(Link)<{ primaryAction?: boolean }>`
  display: inline-block;
  text-decoration: underline;
  color: ${(props) =>
    props.primaryAction ? props.theme.colors.primary : undefined};
`
