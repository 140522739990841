import useTranslation from 'next-translate/useTranslation'
import styled from 'styled-components'
import { getTotalGrossFeeAmountOfType } from '@/helpers/feesCalculation'
import useRegionCode from '@/helpers/useRegionCode'
import { media } from '@/style/helpers'
import { SelectedItemsType } from '@/types'
import {
  EBusinessUnit,
  ECountry,
  EDealType,
  UsedFeeDefinition,
} from '@/types/gql/graphql'
import { printLocalFloat } from '@/utils/misc'
import FeeListingDetail from './FeeListingDetail'

const Wrapper = styled.div`
  margin: 3rem 2rem;
  width: 60vw;

  ${media.sm} {
    width: 50vw;
  }
  ${media.md} {
    width: 40vw;
  }
  ${media.lg} {
    width: 30vw;
  }
`

const FeeInfoTable = styled.table`
  width: 100%;
  font-size: 0.8rem;

  tr {
    td {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }

    td:nth-child(2) {
      padding-left: 1rem;
      vertical-align: bottom;
      text-align: right;
    }
    td:nth-child(3) {
      vertical-align: bottom;
    }
  }

  tr:last-child {
    td {
      border-top: 1px solid black;
    }
  }

  ${media.sm} {
    font-size: 1rem;
  }
`

const TableDataBold = styled.td`
  font-weight: 700;
`

const TableDataEmpty = styled.td`
  font-weight: 700;
  border-top: 0 !important;
`
const FeesScheduleInfo = styled.p`
  margin-top: 2rem;
  font-size: 0.8rem;

  ${media.sm} {
    font-size: 1rem;
  }
`

const FeesScheduleLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
`

interface FeeInfoModalProps {
  businessUnit?: EBusinessUnit
  appliedUsedFeeDefinitions?: UsedFeeDefinition[]
  customItemTitles?: string[]
  text?: string
  dealItems?: Omit<SelectedItemsType, 'answers' | 'itemCategory'>[]
  totalFees?: number
  originalFees?: boolean
  dealType?: EDealType
  feeDocumentLink?: string
}

export const FeeInfoModal: React.FC<FeeInfoModalProps> = (props) => {
  const {
    dealItems,
    appliedUsedFeeDefinitions,
    customItemTitles,
    businessUnit,
    originalFees,
    dealType,
    feeDocumentLink,
  } = props
  const { t } = useTranslation()
  const regionCode = useRegionCode()

  const feesTotalAmount =
    appliedUsedFeeDefinitions && dealItems
      ? getTotalGrossFeeAmountOfType(appliedUsedFeeDefinitions)
      : 0

  return (
    <Wrapper>
      {!props.text && (
        <>
          <FeeInfoTable>
            <tbody>
              {appliedUsedFeeDefinitions && dealItems && (
                <FeeListingDetail
                  isCarDeal={businessUnit === EBusinessUnit.Car}
                  appliedUsedFeeDefinitions={appliedUsedFeeDefinitions}
                  dealItems={dealItems}
                  customItemTitles={customItemTitles}
                />
              )}
              {feesTotalAmount > 0 ? (
                <tr>
                  <TableDataBold>
                    {regionCode === ECountry.De.toString() &&
                    dealType === EDealType.Pawn &&
                    businessUnit === EBusinessUnit.General &&
                    !originalFees
                      ? t('common:TOTAL_FEES_FOR_30_DAYS')
                      : t('common:TOTAL_FEES')}
                  </TableDataBold>
                  <TableDataBold>
                    {printLocalFloat(feesTotalAmount)}
                  </TableDataBold>
                  <TableDataBold>€</TableDataBold>
                </tr>
              ) : (
                <tr>
                  <TableDataEmpty>
                    {regionCode === ECountry.De.toString() &&
                    dealType === EDealType.Pawn &&
                    businessUnit === EBusinessUnit.General
                      ? t('common:TOTAL_FEES_FOR_30_DAYS')
                      : t('common:TOTAL_FEES')}
                  </TableDataEmpty>
                  <TableDataEmpty>{printLocalFloat(0)}</TableDataEmpty>
                  <TableDataEmpty>€</TableDataEmpty>
                </tr>
              )}
            </tbody>
          </FeeInfoTable>
          <FeesScheduleInfo>
            {t('common:FEES_SCHEDULE_INFO')}
            <FeesScheduleLink
              href={feeDocumentLink}
              target="blank"
              rel="noopener noreferrer"
            >
              {t('common:fees_schedule')}
            </FeesScheduleLink>
            .
          </FeesScheduleInfo>
        </>
      )}
      {props.text && <>{props.text}</>}
    </Wrapper>
  )
}
