import { Link } from '../Link'
import React, { ReactNode } from 'react'
import {
  LinkCustomAttributes,
  MARK_LINK,
  render,
} from 'storyblok-rich-text-react-renderer'
import styled from 'styled-components'
import CTAButton from '@/blocks/CTAButton/CTAButton'
import SearchBar from '@/blocks/SearchBar/SearchBar'
import Table from '@/blocks/Table/Table'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import { getFontCSS } from '@/style/components/LegacyText/sizes'
import {
  MultilinkStoryblok,
  RichtextStoryblok,
} from '@/types/storyblok-component-types'

interface StoryblokRichTextProps {
  document: RichtextStoryblok | undefined
}

/**
 * Components maping to embed components inside richtext
 */
const components: Record<string, any> = {
  'cta-button': CTAButton,
  'search-bar': SearchBar,
  table: Table,
}

const blokResolvers: {
  [key: string]: (props: any) => JSX.Element | null
} = {}

for (const [name, BlokComponent] of Object.entries(components)) {
  blokResolvers[name] = (props) => (
    <BlokComponent
      {...props}
      blok={{
        ...props,
        component: name,
      }}
    />
  )
}

const markResolvers = {
  [MARK_LINK]: (
    children: ReactNode,
    props: {
      linktype?: string
      href?: string
      target?: string
      anchor?: string
      uuid?: string
      custom?: LinkCustomAttributes
      story?: MultilinkStoryblok['story']
    },
  ) => {
    const {
      linktype,
      href: rawHref,
      anchor,
      target,
      custom = {},
      story,
    } = props

    const href =
      linktype === 'story' ? getStoryBlokLink({ linktype, story }) : rawHref

    return (
      <Link
        href={(linktype === 'email' ? `mailto:${href}` : href) ?? null}
        anchor={anchor}
        target={target}
        removeTextDecoration={false}
        useNextLink={linktype === 'story'}
        {...custom}
      >
        {children}
      </Link>
    )
  },
}

const StoryblokRichText = ({ document }: StoryblokRichTextProps) => {
  return (
    <>
      {render(document, {
        blokResolvers,
        markResolvers,
      })}
    </>
  )
}

export default React.memo(StoryblokRichText)

export const RichTextParagraphStyles = styled.span`
  & h1 {
    ${getFontCSS('xxl')}

    text-transform: uppercase;
    margin-bottom: 0.75rem;
    font-weight: 700;
  }

  & h2 {
    ${getFontCSS('xl')}

    text-transform: uppercase;
    margin-bottom: 0.75rem;
    font-weight: 700;
  }

  & h3 {
    ${getFontCSS('md')}
    font-weight: 600;
  }

  & h4,
  & h5,
  & h6,
  & b {
    font-weight: 600;
  }

  & i {
    font-style: italic;
  }

  & p {
    ${getFontCSS('md')}
  }

  & > p:first-of-type {
    margin-top: 1.25rem;
  }

  & > p:last-of-type {
    margin-bottom: 1.25rem;
  }

  ul {
    list-style-type: disc;
    margin-left: 1em;
  }

  ol {
    list-style-type: decimal;
    margin-left: 1.1em;
  }
`
