import { flatMap, groupBy, sumBy } from 'lodash'
import { EVehicleCategory } from '@/types'
import {
  CalculatedFeeDefinition,
  Deal,
  DealEvent,
  DealValuesEntry,
  EBillingPeriodType,
  ECountry,
  EFeeLevel,
  EFeeType,
  GetDealByIdQuery,
  Scalars,
  UsedFeeDefinition,
} from '@/types/gql/graphql'

interface ItemFee {
  idx: number
  itemTitle: string
  itemFee: number
  feeType: EFeeType
}

interface DetailFee {
  feeType: EFeeType
  itemFees: ItemFee[]
}

interface DealFees {
  feeType: string
  amount: number
}

export function getTotalGrossFeeAmountOfType(
  usedFeeDefinitions: UsedFeeDefinition[],
  feeType?: EFeeType,
) {
  let retValue = 0

  for (const usedFeeDefinition of usedFeeDefinitions) {
    for (const feeDef of usedFeeDefinition.calculatedFeeDefinitions.filter(
      (c) => (feeType ? c.storedFeeDefinition.feeType === feeType : true),
    )) {
      retValue += feeDef.appliedGrossAmount
    }
  }

  return retValue
}

/**
 *
 * @param usedFeeDefinitions
 * @param feeLevel
 * @returns
 */
const getItemFeesByLevel = (
  usedFeeDefinitions: UsedFeeDefinition[],
  feeLevel: EFeeLevel,
) => {
  const itemLevelFeeDefinitions: UsedFeeDefinition[] = usedFeeDefinitions.map(
    (c) => {
      const tempItemLevelFeeDefinition: UsedFeeDefinition = {
        ...c,
        calculatedFeeDefinitions: c.calculatedFeeDefinitions.filter(
          (c) => c.storedFeeDefinition.level === feeLevel,
        ),
      }
      return tempItemLevelFeeDefinition
    },
  )

  return itemLevelFeeDefinitions
}

// get item fee types

// get product title & total fees for each item
export const getItemsAndFees = (
  usedFeeDefinitions: UsedFeeDefinition[],
  dealItems: Array<{ title: string }>,
): DetailFee[] => {
  const itemLevelFees = getItemFeesByLevel(usedFeeDefinitions, EFeeLevel.Item)
  const itemFees = flatMap(
    itemLevelFees,
    ({ calculatedFeeDefinitions }, idx): ItemFee[] => {
      // there can be multiple fee definitions for the same type, for these itemFee needs to be summed
      const feeDefinitionsByType = groupBy(
        calculatedFeeDefinitions,
        (feeDef) => feeDef.storedFeeDefinition.feeType,
      )

      return Object.entries(feeDefinitionsByType).map(([feeType, feeDefs]) => ({
        idx,
        itemTitle: dealItems[idx]?.title ?? '',
        feeType: feeType as EFeeType,
        itemFee: sumBy(feeDefs, (feeDef) => feeDef.appliedNetAmount),
      }))
    },
  )

  const itemFeesByType = groupBy(itemFees, (itemFee) => itemFee.feeType)
  return Object.entries(itemFeesByType).map(([feeType, itemFees]) => ({
    feeType: feeType as EFeeType,
    itemFees,
  }))
}

// get fee types and total amout for each
export const getDealLevelFees = (usedFeeDefinitions: UsedFeeDefinition[]) => {
  const dealFees: DealFees[] = []
  const dealLevelFees = getItemFeesByLevel(usedFeeDefinitions, EFeeLevel.Deal)

  dealLevelFees.forEach((itemLevel) => {
    itemLevel.calculatedFeeDefinitions.forEach((feeDef) => {
      dealFees.push({
        feeType: feeDef.storedFeeDefinition.feeType,
        amount: feeDef.appliedNetAmount,
      })
    })
  })

  // merge and sum fees
  const result = Array.from(
    dealFees.reduce(
      (m, { feeType, amount }) =>
        m.set(feeType, (m.get(feeType) || 0) + amount),
      new Map(),
    ),
    ([feeType, amount]: [string, number]) => ({ feeType, amount }),
  )

  return result
}

// get extend fees

export function getDealValueEntry<
  TType extends Exclude<DealEvent['__typename'], undefined>,
>(deal: Pick<Deal, 'events'>, eventType: TType): DealValuesEntry {
  const latesEvent = getLatestEventForStatus(deal, eventType)

  if (latesEvent && 'dealCalculation' in latesEvent) {
    return latesEvent.dealCalculation.dealValuesEntry
  } else {
    if (eventType === 'DealClosedEvent') {
      return getDealValueEntry(deal, 'DealVerifiedEvent')
    } else if (eventType === 'DealVerifiedEvent') {
      return getDealValueEntry(deal, 'DealBookedEvent')
    } else {
      const tempDealValueEntry: DealValuesEntry = {
        calculationPaybackAmount: -1,
        calculationPayoutAmount: -1,
        durationInDays: -1,
        paybackAmount: -1,
        payoutAmount: -1,
        overwrittenPayoutAmount: -1,
        shouldOverwritePayoutAmount: false,
      }
      return tempDealValueEntry
    }
  }
}

export function getLatestEventForStatus<
  TType extends Exclude<DealEvent['__typename'], undefined>,
>(
  deal: Pick<Deal, 'events'> | Pick<GetDealByIdQuery['getDealById'], 'events'>,
  eventType: TType,
): Extract<DealEvent, { __typename?: TType }> | undefined {
  const temp = [...deal.events]
    .reverse()
    .find(
      (v): v is Extract<DealEvent, { __typename?: TType }> =>
        v.__typename === eventType,
    )

  return temp
}

export const manipulatedAppliedFeeDefinitions = (
  appliedUsedFeeDefinitions: UsedFeeDefinition[] | undefined,
  regionCode: string,
  itemCategoryIds: Scalars['ObjectId']['output'][] | undefined,
  isCustomDeal?: boolean,
) => {
  if (
    appliedUsedFeeDefinitions &&
    regionCode === ECountry.De.toString() &&
    ((itemCategoryIds &&
      itemCategoryIds.length > 0 &&
      // Check if one of the itemCategories are also included in EVehicleCategory
      !Boolean(
        Object.values(EVehicleCategory).some(
          (r) => itemCategoryIds.map((c) => c).indexOf(r) >= 0,
        ),
      )) ||
      isCustomDeal)
  ) {
    return appliedUsedFeeDefinitions.map((appliedFeeDefinition) => {
      const newUsedFeeDefinition: UsedFeeDefinition = {
        ...appliedFeeDefinition,
        calculatedFeeDefinitions:
          appliedFeeDefinition.calculatedFeeDefinitions.map(
            (calculatedFeeDefinition) => {
              const devider =
                calculatedFeeDefinition.storedFeeDefinition
                  .billingPeriodType === EBillingPeriodType.OneTime
                  ? 1
                  : 3

              const newCalculatedFeeDefinition: CalculatedFeeDefinition = {
                ...calculatedFeeDefinition,
                appliedGrossAmount:
                  calculatedFeeDefinition.appliedGrossAmount / devider,
                appliedNetAmount:
                  calculatedFeeDefinition.appliedNetAmount / devider,
              }
              return newCalculatedFeeDefinition
            },
          ),
      }
      return newUsedFeeDefinition
    })
  }

  return appliedUsedFeeDefinitions
}
