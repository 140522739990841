import h1 from './h1'
import h2 from './h2'
import h3 from './h3'
import h4 from './h4'
import h5 from './h5'
import h6 from './h6'
import modify from './modify'
import p from './p'
import screenReader from './screenReader'
import Sizes from './sizes'
import spacer from './spacer'
import TypeScales from './typescales'

const components = {
  ...Sizes,
  ...TypeScales,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  screenReader,
  spacer,
  modify,
}

export default components
