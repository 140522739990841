import { css } from 'styled-components'

export default css`
  transition:
    box-shadow 0.2s ease,
    background-color 0.2s ease;

  &:hover {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  }

  &:active {
    transition:
      box-shadow 0.05s ease,
      background-color 0.05s ease;
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
  }
`
