import { LogoJsonLd } from 'next-seo'
import Link from 'next/link'
import React from 'react'
import useRegionCode from '@/helpers/useRegionCode'
import { cn } from '@/utils/cn'
import SEOLogo from './logo.png'
import LogoImage from './logo.svg'
import { regions } from '~/next-seo.config'

type Props = {
  className?: string
}

export function HeaderLogo({ className }: Props) {
  const regionCode = useRegionCode()
  const { baseUrl } = regions[regionCode]

  return (
    <Link className={cn('inline-block pl-4 pr-2', className)} href="/">
      <LogoJsonLd logo={SEOLogo.src} url={baseUrl} />
      <LogoImage />
    </Link>
  )
}
