import { createContext, useContext, useState } from 'react'
import { GraphQLCLient } from '@/app/common/graphql/client'
import { createGraphQLClient } from '@/app/common/graphql/hooks'

const GraphQLClientContext = createContext<GraphQLCLient | null>(null)

export function GraphQLCLientProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [client] = useState(() =>
    createGraphQLClient(
      typeof window === 'undefined' ? 'CLIENT_SIDE_ONLY' : window.location.host,
    ),
  )
  return (
    <GraphQLClientContext.Provider value={client}>
      {children}
    </GraphQLClientContext.Provider>
  )
}

export function useGraphQLClient() {
  const client = useContext(GraphQLClientContext)
  if (!client) {
    throw new Error(
      'useGraphQLClient must be used within a GraphQLClientProvider',
    )
  }
  return client
}
