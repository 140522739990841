import { atom } from 'recoil'
import {
  ETransportMode,
  IAddress,
  IStoreboxPickupAddress,
  RegionData,
} from '@/types/gql/graphql'

export type TransportSelectionDomains = 'checkout' | 'payback'

type TransportFormStateBase = {
  transportMode?: ETransportMode
  address?: IAddress
}

export type TransportFormState = TransportFormStateBase & {
  transportDate?: { date?: Date; hour?: number }
}

export const redirectToCorrectDomainState = atom<boolean | undefined>({
  key: 'common:redirectToCorrectDomain',
  default: undefined,
})

export const availableRegionsState = atom<RegionData | undefined>({
  key: 'common:availableRegions',
  default: undefined,
})

export type TransportFormStateStorebox = TransportFormStateBase & {
  address?: IStoreboxPickupAddress
  slot?: {
    start: Date
    end: Date
  }
}
