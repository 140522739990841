import { wrap } from '@popmotion/popcorn'
import { motion } from 'framer-motion'
import useTranslation from 'next-translate/useTranslation'
import { StaticImageData } from 'next/legacy/image'
import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import useInterval from '@/helpers/useInterval'
import useResponsive_CAN_CAUSE_CLS from '@/helpers/useResponsive'
import { media } from '@/style/helpers'
import {
  AssetStoryblok,
  MultilinkStoryblok,
} from '@/types/storyblok-component-types'
import { AnimatePresence } from '@/utils/animatePresence'
import MediaPartnerItem from './MediaPartner/MediaPartnerItem'

export interface MediaLinksProps {
  image: AssetStoryblok | StaticImageData
  link?: string | MultilinkStoryblok
}

interface Props {
  mediaLinks?: MediaLinksProps[]
}

const timeBetweenSlide = 6000
const imagesPerSlide = 3

const TrustedMediaCarousel: FunctionComponent<Props> = ({
  mediaLinks = [],
}) => {
  const { isDesktop } = useResponsive_CAN_CAUSE_CLS()

  const [[page, direction], setPage] = useState([0, 0])

  const slideCount = mediaLinks.length
  const slideIndex = wrap(0, slideCount, page)

  const paginate = (newDirection: number) => {
    setPage([
      page + newDirection > slideCount - 1 ? 0 : page + newDirection,
      newDirection,
    ])
  }

  useInterval(() => !isDesktop && paginate(imagesPerSlide), timeBetweenSlide)

  const { t } = useTranslation()

  return (
    <Wrapper>
      <TextWrapper>{t('common:as_seen_on')}</TextWrapper>
      <TrustedMediaWrapper>
        <TrustedMediaContainer>
          <SectionSlider>
            <AnimatePresence initial={false} custom={direction} exitBeforeEnter>
              <motion.div
                key={page}
                custom={direction}
                variants={isDesktop ? {} : opacityVariants}
                initial={'enter'}
                animate={isDesktop ? '' : 'center'}
                exit={'exit'}
                transition={{
                  x: { type: 'spring', stiffness: 300, damping: 200 },
                  opacity: { duration: 0.2, easeIn: [0.17, 0.67, 0.83, 0.67] },
                }}
              >
                <MediaPartnerItem
                  slideIndex={slideIndex}
                  mediaLinks={mediaLinks}
                  imagesPerSlide={imagesPerSlide}
                />
              </motion.div>
            </AnimatePresence>
          </SectionSlider>
        </TrustedMediaContainer>
      </TrustedMediaWrapper>
    </Wrapper>
  )
}

export default TrustedMediaCarousel

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: -5px;

  ${media.xs} {
    bottom: 0;
  }
`

const TrustedMediaWrapper = styled.div`
  background: rgba(0, 0, 0, 0.35);
  overflow: hidden;
  width: 100%;
`

const TextWrapper = styled.div`
  width: 100%;
  color: #fff;
  font-size: 0.875rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  padding-bottom: 0.6rem;
  line-height: 1.4;
  margin-top: 2rem;

  ${media.md} {
    margin-top: 4rem;
  }
`

const SectionSlider = styled.div`
  margin: 0 auto;
  position: relative;
  width: 100%;

  ${media.xs} {
    width: 80%;
  }

  ${media.md} {
    z-index: 1;
  }
`

const TrustedMediaContainer = styled.div`
  height: 7rem;
  position: relative;
  display: flex;
  justify-items: center;
  align-items: center;
`

const opacityVariants = {
  enter: {
    opacity: 0,
  },
  center: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
}

export const trustedMediaHeight = {
  upToXs: 112 - 5, // bottom: -5px
  xs: 112,
}
