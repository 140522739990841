import { Dayjs } from 'dayjs'
import { defaultTimezone } from '@/app/common/constants'

const CashyDateFormats = ['Date', 'Time'] as const
export type CashyDateFormat = (typeof CashyDateFormats)[number]

function isCashyDateFormat(input: unknown): input is CashyDateFormat {
  if (
    typeof input !== 'string' ||
    !CashyDateFormats.includes(input as CashyDateFormat)
  ) {
    return false
  }

  return true
}

// Presets for our most used formats. Provides easier use and more consistent date representations
// across the website. This map could be more or less replaced with the inbuild
// style shortcut options "datestyle" and "timestyle", but those are relatively new (early-late
// 2020), with only 93.5% coverage https://caniuse.com/?search=datestyle
const CashyDateFormatsMap: {
  [key in CashyDateFormat]: Intl.DateTimeFormatOptions
} = {
  Date: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
  Time: {
    hour: 'numeric',
    minute: 'numeric',
  },
}

export function formatLocalisedDate(
  date: Dayjs,
  options: CashyDateFormat | Intl.DateTimeFormatOptions,
  locale?: string,
) {
  let finalOptions: Intl.DateTimeFormatOptions
  if (isCashyDateFormat(options)) {
    finalOptions = CashyDateFormatsMap[options]
  } else {
    finalOptions = options
  }

  // use the british date formatting instead of the american default, as we show the british
  // flag in the date selector
  const finalLocale = locale === 'en' ? 'en-GB' : locale

  return new Intl.DateTimeFormat(finalLocale, finalOptions).format(
    date.tz(defaultTimezone).toDate(),
  )
}
