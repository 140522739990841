import useTranslation from 'next-translate/useTranslation'
import { useLayoutEffect } from 'react'
import styled from 'styled-components'
import { useLayoutContext } from '@/app/common/components/Layout/Layout'
import { LoadingOverlay } from '@/app/common/components/LoadingOverlay'
import useResponsive_CAN_CAUSE_CLS from '@/helpers/useResponsive'
import { Button } from '@/style/components/Button'
import Text from '@/style/components/Text'
import { hideFrom } from '@/style/helpers/media'
import { SelectedProduct } from '@/types'
import { ProductVariant } from '@/types/gql/graphql'
import { printLocalFloat } from '@/utils/misc'

interface Props {
  isFetching: boolean
  isUnderMinimumItemValue: boolean
  isDysfunctional: boolean
  variant?: Pick<ProductVariant, 'id' | 'isPriceValid'> | undefined
  onSubmit: () => void
  isDisabled: boolean
  product: SelectedProduct
  itemValue?: number
}

export function MobileConfiguratorSummary({
  isFetching,
  itemValue,
  isUnderMinimumItemValue,
  isDysfunctional,
  variant,
  onSubmit,
  isDisabled,
  product,
}: Props) {
  const { isDesktop } = useResponsive_CAN_CAUSE_CLS()
  const { setWhatsappBubbleExtraOffsetBottom } = useLayoutContext()

  useLayoutEffect(() => {
    if (isDesktop) return
    setWhatsappBubbleExtraOffsetBottom(80)
    return () => {
      setWhatsappBubbleExtraOffsetBottom(0)
    }
  }, [setWhatsappBubbleExtraOffsetBottom, isDesktop])

  return (
    <SummaryContainer>
      {isFetching ? (
        <LoadingOverlay fullScreen={false} />
      ) : variant?.isPriceValid &&
        !isDysfunctional &&
        itemValue != null &&
        !isUnderMinimumItemValue ? (
        <ValidPriceSummary
          itemValue={itemValue}
          isDisabled={isDisabled}
          onSubmit={onSubmit}
          product={product}
        />
      ) : !isDysfunctional && !variant?.isPriceValid ? (
        <OfferOnRequest
          isDisabled={isDisabled}
          onSubmit={onSubmit}
          product={product}
        />
      ) : itemValue != null && isUnderMinimumItemValue ? (
        <UnderMinimumItemValue />
      ) : (
        <NoPayoutPossible />
      )}
    </SummaryContainer>
  )
}

interface ValidPriceSummaryProps {
  itemValue: number
  isDisabled: boolean
  onSubmit: () => void
  product: SelectedProduct
}

function ValidPriceSummary({
  itemValue,
  isDisabled,
  onSubmit,
  product,
}: ValidPriceSummaryProps) {
  const { t } = useTranslation()
  return (
    <FlexRow>
      <FlexColumn>
        <Text.ms uppercase weight="bold">
          {t('product:summary.headline')}
        </Text.ms>
        <Text.xxxl weight="bold">{printLocalFloat(itemValue, 0)}€</Text.xxxl>
      </FlexColumn>
      <div style={{ flex: 1 }}>
        <SubmitButton
          id={`ITEM_PAGE_${product.slug}_NEXT_BUTTON`}
          primary={!isDisabled}
          full
          disabled={isDisabled}
          onClick={onSubmit}
        >
          {t('product:summary.button_label')}
        </SubmitButton>
      </div>
    </FlexRow>
  )
}

interface OfferOnRequestProps {
  isDisabled: boolean
  onSubmit: () => void
  product: SelectedProduct
}

function OfferOnRequest({
  isDisabled,
  onSubmit,
  product,
}: OfferOnRequestProps) {
  const { t } = useTranslation()
  return (
    <FlexRow>
      <FlexColumn style={{ flexShrink: 1 }}>
        <Text.lg weight="bold">
          {t('product:summary.offer_on_request.description')}
        </Text.lg>
      </FlexColumn>
      <div style={{ flexGrow: 1, flexShrink: 0 }}>
        <SubmitButton
          id={`ITEM_PAGE_${product.slug}_NEXT_BUTTON`}
          primary={!isDisabled}
          full
          disabled={isDisabled}
          onClick={onSubmit}
        >
          {t('product:summary.offer_on_request.button_label')}
        </SubmitButton>
      </div>
    </FlexRow>
  )
}

function UnderMinimumItemValue() {
  const { t } = useTranslation()
  return (
    <Text.md weight="semibold">{t('product:under_minimum_item_value')}</Text.md>
  )
}

function NoPayoutPossible() {
  const { t } = useTranslation()
  return (
    <Text.md weight="semibold">
      {t('product:only_functioning_items_accepted')}
    </Text.md>
  )
}

const SummaryContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  padding: 16px;
  background-color: white;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -7px 15px 0px rgba(0, 0, 0, 0.13);
  ${hideFrom('md')}
`

const FlexRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const SubmitButton = styled(Button)`
  :disabled {
    opacity: 0.2;
  }
  font-size: 0.875rem;
  margin: 0;
`
