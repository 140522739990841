import PriceBox from '../PriceBox/PriceBox'
import TermsAndSubmit, {
  AutoDocumentLinks,
} from '../TermsAndSubmit/TermsAndSubmit'
import { CarCalculation, CustomVehicleFormData } from '../VehicleDealBooking'
import Keepdriving from '../assets/ico-keepdriving.svg'
import Handover from '../assets/ico_handhover.svg'
import { Box } from '@material-ui/core'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useContext, useRef, useState } from 'react'
import styled from 'styled-components'
import { UseFormReturnType } from '@/app/common/components/Form/useForm'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import { StorageModeButton } from '@/blocks/CarConfigurator/StorageModeButton'
import { FeeInfoModal } from '@/domains/checkout/components/FeeInfoModal'
import { MAX_PAYOUT_AMOUNT_PAWN_CONTINUE_USING } from '@/helpers/constants'
import { getTotalGrossFeeAmountOfType } from '@/helpers/feesCalculation'
import useRegionCode from '@/helpers/useRegionCode'
import { Select } from '@/style/components/Form'
import Text from '@/style/components/Text'
import { media } from '@/style/helpers'
import { EVehicleCategory } from '@/types'
import { EBusinessUnit, ERegionCode } from '@/types/gql/graphql'
import { printLocalFloat } from '@/utils/misc'

// Styles
const OptionButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${media.md} {
    flex-wrap: unset;
  }
`
const TextBold = styled.span`
  font-weight: 600;
`
const FeesLink = styled.span`
  font-weight: 600;
  text-decoration-line: underline;
  text-decoration-color: #646464;
  &:hover {
    cursor: pointer;
  }
`
// Interfaces
interface VehicleCalculationBoxProps {
  calculation: CarCalculation | undefined | null
  calculationLoading: boolean
  marketValueToSmall: boolean
  isCustomRequest: boolean
  pawnTimeOptionsInMonths: number[]
  baseVehicleCategoryId: EVehicleCategory
  createDealResult: any
  formikProps: UseFormReturnType<CustomVehicleFormData>
  onSubmit: (e: React.MouseEvent<HTMLElement>) => void
  feeDifferenceWhenHandingOver?: number
  noValuation?: boolean | null
  shouldOverwrittenPayoutAmount?: boolean | null
  maxPayoutAmount?: number
  documentLinks: AutoDocumentLinks
  onChangeOverridePayoutAmountValuation?: (
    overwrittenPayoutAmount: number,
  ) => void
}

export const VehicleCalculationBox: FunctionComponent<
  VehicleCalculationBoxProps
> = ({
  calculationLoading,
  marketValueToSmall,
  calculation,
  pawnTimeOptionsInMonths,
  baseVehicleCategoryId,
  createDealResult,
  formikProps,
  onSubmit,
  feeDifferenceWhenHandingOver,
  noValuation,
  isCustomRequest,
  shouldOverwrittenPayoutAmount,
  maxPayoutAmount,
  documentLinks,
  onChangeOverridePayoutAmountValuation,
}) => {
  const { t } = useTranslation()
  const selectedPawnTimeRef = useRef(1)
  const regionCode = useRegionCode()
  const modal = useContext(ModalDialogContext)
  const { pawnTime, pawnContinueUsing } = formikProps.values

  const isCustomPayout =
    !isCustomRequest &&
    !noValuation &&
    calculation &&
    shouldOverwrittenPayoutAmount

  const paybackAmount = isCustomPayout
    ? calculation.desiredCalculation?.dealValuesEntry.paybackAmount
    : calculation?.paybackAmount

  const appliedUsedFeeDefinitions = isCustomPayout
    ? calculation.desiredCalculation?.appliedUsedFeeDefinitions
    : calculation?.appliedUsedFeeDefinitions

  const payoutAmount = calculation?.payoutAmount ?? 0

  const feesCaclulation = isCustomPayout
    ? calculation.desiredCalculation?.appliedUsedFeeDefinitions
      ? getTotalGrossFeeAmountOfType(
          calculation.desiredCalculation.appliedUsedFeeDefinitions,
        )
      : 0
    : calculation?.feesCaclulation

  const isGermanRegion = regionCode === ERegionCode.De

  const [prevContinueUsing, setPrevContinueUsing] = useState(pawnContinueUsing)
  if (prevContinueUsing !== pawnContinueUsing) {
    setPrevContinueUsing(pawnContinueUsing)
    if (pawnContinueUsing) {
      formikProps.setFieldValue('pawnTime', 1)
    } else {
      formikProps.setFieldValue('pawnTime', selectedPawnTimeRef.current)
    }
  }

  const vehicleType = [
    EVehicleCategory.CAR,
    EVehicleCategory.CAR_STORED,
  ].includes(baseVehicleCategoryId)
    ? 'car'
    : 'motorcycle'

  return (
    <>
      <Box
        padding="1.25rem"
        mt="1.5rem"
        boxShadow="0 4px 6px 0 rgba(167, 167, 167, 0.35)"
      >
        <Box>
          {marketValueToSmall ? (
            <Text.spacer style={{ fontSize: '1rem', margin: 0 }} as="p" dashed>
              Leider können wir zu deiner Autokonfiguration keinen Kredit
              anbieten.
            </Text.spacer>
          ) : (
            <>
              {/* show pricing box */}
              <PriceBox
                noValuation={noValuation}
                calculationLoading={calculationLoading}
                calculation={calculation}
                formikProps={formikProps}
                isCustomRequest={isCustomRequest}
                onChangeOverridePayoutAmountValuation={
                  onChangeOverridePayoutAmountValuation
                }
                maxPayoutAmount={maxPayoutAmount}
                isPawnContinueUsing={pawnContinueUsing}
              />

              {/* KEEP_DRIVING_MODE deposit term dropdown */}
              {!pawnContinueUsing && (
                <Select
                  id="LOAN_RUN_TIME"
                  fullWidth
                  showLabel
                  style={{ fontWeight: 'bold' }}
                  value={pawnTime as any}
                  name={'loanRuntime'}
                  label={t('vehicle:pawn.duration')}
                  options={pawnTimeOptionsInMonths.map((runtime) => ({
                    value: runtime,
                    label: t('vehicle:pawn.duration_months', {
                      count: runtime,
                    }),
                  }))}
                  onChange={(e) => {
                    selectedPawnTimeRef.current = Number(e.target.value)
                    formikProps.setFieldValue(
                      'pawnTime',
                      Number(e.target.value),
                    )
                  }}
                />
              )}
              <Text.spacer style={{ height: '1px' }} />

              <OptionButtonWrapper>
                {!isGermanRegion && (
                  <StorageModeButton
                    id="STORAGE_MODE_KEEP_DRIVING"
                    icon={<Keepdriving />}
                    title={t(`vehicle:pawn.keep_driving_${vehicleType}`)}
                    note={t(`vehicle:pawn.park_note_${vehicleType}`)}
                    checked={pawnContinueUsing}
                    onChange={() => {
                      formikProps.setFieldValue('pawnContinueUsing', true)
                      payoutAmount > MAX_PAYOUT_AMOUNT_PAWN_CONTINUE_USING &&
                        onChangeOverridePayoutAmountValuation?.(
                          MAX_PAYOUT_AMOUNT_PAWN_CONTINUE_USING,
                        )
                    }}
                  />
                )}
                <StorageModeButton
                  id="STORAGE_MODE_KEEP_HANOVER"
                  icon={<Handover />}
                  title={t(`vehicle:pawn.park_${vehicleType}`)}
                  note={t(`vehicle:pawn.storage_note_${vehicleType}`)}
                  checked={!pawnContinueUsing}
                  onChange={() => {
                    formikProps.setFieldValue('pawnContinueUsing', false)
                  }}
                  fee={
                    !isGermanRegion &&
                    feeDifferenceWhenHandingOver &&
                    feeDifferenceWhenHandingOver > 0
                      ? feeDifferenceWhenHandingOver
                      : undefined
                  }
                />
              </OptionButtonWrapper>

              <Text.spacer style={{ height: '1px' }} />
              <Box
                lineHeight="1.2rem"
                marginX="1rem"
                style={{ marginBottom: '1rem', marginTop: '1.25rem' }}
                textAlign="center"
                fontSize="0.875rem"
              >
                <Trans
                  i18nKey="vehicle:pawn.fee_information"
                  values={{
                    pawnTime,
                    paybackAmount: printLocalFloat(paybackAmount ?? 0, 0),
                    feeAmount: printLocalFloat(feesCaclulation ?? 0),
                  }}
                  components={{
                    bold: <TextBold />,
                    feeLink: (
                      <FeesLink
                        id="CAR_REQUEST_FEES_LINK"
                        onClick={() =>
                          modal.open(
                            <FeeInfoModal
                              businessUnit={EBusinessUnit.Car}
                              appliedUsedFeeDefinitions={
                                appliedUsedFeeDefinitions
                              }
                              dealItems={calculation?.dealItems}
                              feeDocumentLink={documentLinks.feeDocumentLink}
                            />,
                            {
                              customStyles: {
                                content: {
                                  display: 'inline-block',
                                  maxWidth: '80%',
                                },
                              },
                            },
                          )
                        }
                      />
                    ),
                  }}
                />
              </Box>

              {/* term checkboxes & submit button */}
              <TermsAndSubmit
                calculationLoading={calculationLoading}
                noValuation={noValuation}
                createDealResult={createDealResult}
                formikProps={formikProps}
                onSubmit={onSubmit}
                documentLinks={documentLinks}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  )
}
