import Bulb from '../../assets/bulb.png'
import { Box } from '@material-ui/core'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import styled from 'styled-components'
import AuthDialog from '@/app/auth/components/AuthDialog'
import Text from '@/style/components/LegacyText'

export const DifferentInterestItemModal = () => {
  const { t } = useTranslation()

  return (
    <AuthDialog
      hideProfileIcon
      fixedMobileFooter
      navBar={{
        title: `${t('common:important')}!`,
      }}
      sideIcon={
        <ImageWrapper>
          <img src={Bulb.src} alt="warn-icon" />
        </ImageWrapper>
      }
      content={
        <Box textAlign="center" marginTop="10rem" paddingX="2rem">
          <Box marginTop="1.5rem">
            <Text.lg weight="bold">
              {t('common:errors.different_interest_items')}
            </Text.lg>
          </Box>
        </Box>
      }
    />
  )
}

const ImageWrapper = styled.div`
  text-align: center;

  img {
    width: 100%;
  }
`
