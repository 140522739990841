import { media } from '../../helpers'
import theme from '../../themes/default'
import styled, { css } from 'styled-components'

interface TextModifyProps {
  weight?: 'regular' | 'medium' | 'semibold' | 'bold'
  color?: keyof typeof theme.colors | string
  transform?: 'normal' | 'uppercase'
  fontStyle?: 'normal' | 'italic'
  visibleFrom?: keyof typeof theme.breakpoints
  visibleUntil?: keyof typeof theme.breakpoints
}

export default styled.span.attrs<TextModifyProps>((props) => ({
  weight: props.weight || 'regular',
  color: props.color,
  transform: props.transform,
  fontStyle: props.fontStyle,
}))<TextModifyProps>`
  ${({ weight, theme }) =>
    typeof weight !== 'undefined' &&
    css`
      font-weight: ${theme.fonts.fontWeights[weight]};
    `}

  ${({ theme, color }) =>
    color &&
    css`
      color: ${color in theme.colors
        ? theme.colors[color as keyof typeof theme.colors]
        : color};
    `}

  ${({ transform }) =>
    transform &&
    css`
      text-transform: ${transform};
    `}

  ${({ fontStyle }) =>
    fontStyle &&
    css`
      font-style: ${fontStyle};
    `}

  ${({ visibleFrom }) =>
    visibleFrom &&
    css`
      display: none;

      ${media[visibleFrom]} {
        display: unset;
      }
    `}

  ${({ visibleUntil }) =>
    visibleUntil &&
    css`
      ${media[visibleUntil]} {
        display: none;
      }
    `}
`
