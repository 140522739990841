import styled, { css } from 'styled-components'
import Sizes from './sizes'

interface SpacerProps {
  dashed?: boolean
  children?: string
  as?: string
}

export default styled(Sizes.sm).attrs<SpacerProps>((props) => ({
  dashed: props.dashed || false,
  children: props.children,
  as: props.as,
}))<SpacerProps>`
  display: block;
  position: relative;
  text-align: center;
  margin: 1.5rem 0;
  font-weight: 600;
  text-transform: uppercase;

  &::after,
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% - 0.5px);
    border-bottom: 1px solid ${({ theme }) => theme.colors.spacerColor};

    ${({ dashed, theme }) =>
      dashed &&
      css`
        border-bottom: none;
        height: 2px;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='0' y1='0' x2='100%25' y2='0' fill='none' stroke='${theme.colors.spacerColor.replace(
          '#',
          '%23',
        )}' stroke-width='2' stroke-dasharray='5%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      `}
  }

  ${({ as }) =>
    as === 'h2' &&
    css`
      font-size: 1rem;
    `}

  &::before {
    right: calc(
      50% + ${({ children }) => (children ? children.length / 2 : 0)}em
    );

    ${({ as, children }) =>
      as === 'h2' &&
      css`
        right: calc(50% + ${children ? (children.length / 2) * 0.65 : 0}em);
      `}
  }

  &::after {
    left: calc(
      50% + ${({ children }) => (children ? children.length / 2 : 0)}em
    );

    ${({ as, children }) =>
      as === 'h2' &&
      css`
        left: calc(50% + ${children ? (children.length / 2) * 0.65 : 0}em);
      `}
  }
`
