import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import useWhatsappNumber from '@/helpers/useWhatsappNumber'
import { UnderlinedLink } from '@/style/components/Link'
import { SectionInner } from '@/style/components/Section'
import Text from '@/style/components/Text'

interface WhatsAppSectionCarPawnProps {
  activeCustomRequestForm: any
  whatsappLink?: string
}

const CursorPointer = styled.span`
  cursor: pointer;
`

const WhatsAppSectionCarPawn: FunctionComponent<
  WhatsAppSectionCarPawnProps
> = ({ activeCustomRequestForm, whatsappLink }) => {
  const { t, lang } = useTranslation()
  const whatsappNumber = useWhatsappNumber()

  return (
    <SectionInner textAlign="center" sizing="narrow" spacingBefore>
      <Text.p>
        <Trans
          i18nKey="common:whatsapp.car_pawn_page_cta"
          components={{
            requestLink: (
              <UnderlinedLink href="#" onClick={activeCustomRequestForm}>
                <CursorPointer />
              </UnderlinedLink>
            ),
            whatsappLink: (
              <UnderlinedLink
                href={
                  whatsappLink ??
                  `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${t(
                    'common:whatsapp.initial_message',
                  )}&lang=${lang}`
                }
                target="_blank"
                rel="noreferrer"
              />
            ),
          }}
        />
      </Text.p>
    </SectionInner>
  )
}

export default WhatsAppSectionCarPawn
