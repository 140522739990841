import { FormControl, InputLabel, Select } from '@material-ui/core'
import { FormikProps } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import styled from 'styled-components'
import { getTranslatedValidationError } from '@/utils/error'
import { InputErrorMessage } from './InputErrorMessage'

const Wrapper = styled.div<{ marginBottom?: string }>`
  width: 100%;
  margin-bottom: ${(props) => props.marginBottom ?? '10px'};
`

const SelectInputStyled = styled(FormControl)<{
  error: boolean
}>`
  .MuiFilledInput-root {
    font-size: 0.875rem;
    font-family: GalanoGrotesque-Medium, sans-serif;
    background-color: white;
    height: 3.3rem;

    border: 2px solid
      ${(props) => (!props.error ? props.theme.colors.border : '#f44336')};
    border-radius: ${({ theme }) => theme.radius.normal}px;

    label {
      font-family: GalanoGrotesque, sans-serif;
    }

    &.Mui-focused {
      background-color: white !important;
    }
    &:active,
    &:hover {
      background-color: white;
    }
  }
  .MuiFilledInput-underline {
    &:after,
    &:before,
    &:hover:before {
      border: none;
    }
  }
  label.Mui-focused {
    color: #666666;
    font-size: 1rem;
  }
`

interface SelectInputProps {
  formikProps: FormikProps<any>
  name: string
  label: string
  onChange?: (value: any) => void
  options: {
    value: string | number
    label: string
  }[]
}

export const SelectInput: React.FC<SelectInputProps> = ({
  options,
  formikProps,
  name,
  label,
  onChange,
}) => {
  const { t } = useTranslation()

  const field = formikProps.getFieldMeta(name)

  const error =
    field.error && (field.touched || formikProps.submitCount > 0)
      ? field.error
      : undefined

  return (
    <Wrapper>
      <SelectInputStyled variant="filled" fullWidth error={error !== undefined}>
        <InputLabel htmlFor="filled-age-native-simple">{label}</InputLabel>
        <Select
          native
          name={name}
          value={field.value}
          onChange={(e) => {
            onChange && onChange(e.target.value)
          }}
        >
          <option aria-label="None" value={undefined} />
          {options.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
        {error && (
          <InputErrorMessage message={getTranslatedValidationError(error, t)} />
        )}
      </SelectInputStyled>
    </Wrapper>
  )
}
