import { FunctionComponent } from 'react'
import styled from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { media } from '@/style/helpers'
import {
  AboutPagePartnerStoryblok,
  RichtextStoryblok,
} from '@/types/storyblok-component-types'

interface PartnersProps {
  caption?: RichtextStoryblok
  partnerCards?: AboutPagePartnerStoryblok[]
}

const Partners: FunctionComponent<PartnersProps> = ({
  caption,
  partnerCards,
}) => {
  return (
    <PartnersWrapper>
      <TextWrapper>
        <RichTextParagraphStyles>
          <StoryblokRichText document={caption} />
        </RichTextParagraphStyles>
      </TextWrapper>
      <div>
        {partnerCards?.map((card) => {
          return (
            <PartnerCard key={card._id}>
              <PartnerCardImageWrapper>
                {card.image?.filename && (
                  <PartnerCardImage>
                    <StoryblokImage
                      sizes="(max-width: 767px) 60vw, (max-width: 991px) 35vw, (max-width: 1441px) 23vw, 332px"
                      layout="fill"
                      objectFit="contain"
                      image={card.image}
                    />
                  </PartnerCardImage>
                )}
              </PartnerCardImageWrapper>
              <PartnerDescription>{card.caption}</PartnerDescription>
            </PartnerCard>
          )
        })}
      </div>
    </PartnersWrapper>
  )
}

const PartnersWrapper = styled.div`
  width: 100%;
  margin: auto 0;
  text-align: center;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;

  ${media.xs} {
    margin: auto 0;
    text-align: center;
    margin-bottom: 6rem;
  }

  ${media.sm} {
    margin: auto 0;
    text-align: center;
    margin-bottom: 7rem;
  }

  ${media.md} {
    margin: auto 0;
    text-align: center;
    margin-bottom: 11rem;
  }
`
const TextWrapper = styled.div`
  text-align: center;
  margin: auto;
  width: 75%;
  max-width: 22rem;
  margin-bottom: 2rem;

  ${media.xs} {
    max-width: 42rem;
  }

  ${media.md} {
    margin-bottom: 3rem;
  }
`

const PartnerCard = styled.div`
  background-color: #f2f4f8;
  height: 13rem;
  margin: auto;
  margin-bottom: 0.3rem;
  text-align: center;
  vertical-align: top;

  ${media.xs} {
    background-color: #f2f4f8;
    height: 15rem;
    margin: auto;
    margin-bottom: 0.3rem;
    text-align: center;
    vertical-align: top;
  }

  ${media.sm} {
    float: none;
    background-color: #f2f4f8;
    width: 49.5%;
    float: left;
    height: 15rem;
    margin: 0.115rem;
    text-align: center;
    vertical-align: top;
  }

  ${media.md} {
    float: none;
    background-color: #f2f4f8;
    width: 32.9%;
    height: 15rem;
    float: left;
    margin: 0.125rem;
    text-align: center;
    vertical-align: top;
  }
`

const PartnerCardImage = styled.div`
  width: 60%;
  height: 6rem;
  margin-top: 2rem;
  margin-bottom: 1.3rem;
  position: relative;

  ${media.xs} {
    width: 60%;
    height: 6rem;
    margin-top: 3.5rem;
    margin-bottom: 1.3rem;
  }

  ${media.sm} {
    width: 70%;
    height: 110px;

    margin-top: 2.5rem;
    margin-bottom: 1.3rem;
  }

  ${media.md} {
    width: 70%;
    height: 110px;
    margin-top: 2.5rem;
    margin-bottom: 1.3rem;
  }
`

const PartnerDescription = styled.p`
  margin: auto;
  text-align: center;
  font-size: 0.9rem;
  line-height: 18px;
  text-align: center;
  max-width: 80%;

  ${media.xs} {
    margin: auto;
    text-align: center;
    font-size: 0.9rem;
    line-height: 18px;
    text-align: center;
    max-width: 80%;
  }

  ${media.sm} {
    margin: auto 1.5rem;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    max-width: 80%;
  }

  ${media.md} {
    margin: auto 4rem;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    max-width: 80%;
  }
`

const PartnerCardImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export default Partners
