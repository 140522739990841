import { Box } from '@material-ui/core'
import { storyblokEditable } from '@storyblok/react'
import styled from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { media } from '@/style/helpers'
import theme from '@/style/themes/default'
import { CtaCardStoryblok } from '@/types/storyblok-component-types'

export default function CTACard({ blok }: { blok: CtaCardStoryblok }) {
  return (
    <>
      {blok.background_color?.color ? (
        <Container
          $backgroundColor={blok.background_color.color}
          {...storyblokEditable(blok)}
        >
          <ImageWrapper>
            {blok.image.filename && (
              <StoryblokImage
                sizes={`(max-width: ${theme.breakpoints['md']}px) 60px, 84px`}
                layout="responsive"
                image={blok.image}
              />
            )}
          </ImageWrapper>
          <CaptionWrapper>
            <TitleWrapper>
              <StoryblokRichText document={blok.caption} />
            </TitleWrapper>
          </CaptionWrapper>
        </Container>
      ) : null}
    </>
  )
}

const Container = styled(Box)<{ $backgroundColor: string }>`
  border-radius: 5px;
  background-color: ${(props) => props.$backgroundColor};

  min-height: 100px;
  padding: 12px;
  display: flex;
  justify-content: flex-start;
  b {
    font-weight: 600;
  }

  ${media.md} {
    border-radius: 5px;
    min-height: 100px;
    padding: 19px;
    display: flex;
    flex-direction: row;
  }
`

const CaptionWrapper = styled(Box)`
  margin-left: 11px;

  ${media.md} {
    margin-left: 19px;
  }
`

const ImageWrapper = styled.div`
  width: 60px;
  min-width: 60px;
  height: 60px;

  ${media.md} {
    width: 84px;
    min-width: 84px;
    height: 84px;
  }
`

const TitleWrapper = styled(RichTextParagraphStyles)`
  & > p:first-of-type {
    margin-top: 0 !important;
  }

  & > p:last-of-type {
    margin-bottom: 19px !important;
  }
`
