import { MediaLinksProps } from '../TrustedMediaCarousel'
import { motion } from 'framer-motion'
import Image, { StaticImageData } from 'next/legacy/image'
import React, { FunctionComponent, useMemo } from 'react'
import styled from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import useResponsive_CAN_CAUSE_CLS from '@/helpers/useResponsive'
import {
  AssetStoryblok,
  MultilinkStoryblok,
} from '@/types/storyblok-component-types'

interface MediaPartnerProps {
  mediaLinks: MediaLinksProps[]
  slideIndex: number
  imagesPerSlide: number
}

const getLink = (link?: string | MultilinkStoryblok) => {
  if (!link) return undefined
  if (typeof link === 'string') return link
  return getStoryBlokLink(link)
}

const isAssetStoryBlok = (
  image: AssetStoryblok | StaticImageData,
): image is AssetStoryblok => typeof image === 'object' && 'id' in image

const MediaPartnerItem: FunctionComponent<MediaPartnerProps> = ({
  mediaLinks,
  slideIndex,
  imagesPerSlide,
}) => {
  const { isDesktop } = useResponsive_CAN_CAUSE_CLS()

  const firstSlidePosition = useMemo(
    () => (isDesktop ? 0 : slideIndex === 0 ? slideIndex : imagesPerSlide),
    [isDesktop, slideIndex, imagesPerSlide],
  )
  const lastSlidePosition = useMemo(
    () =>
      isDesktop
        ? mediaLinks.length
        : slideIndex === 0
          ? imagesPerSlide
          : imagesPerSlide + slideIndex,
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDesktop, slideIndex, imagesPerSlide],
  )

  return (
    <MediaPartnerWrapper>
      {mediaLinks
        ?.slice(firstSlidePosition, lastSlidePosition)
        .map((partner, i) => (
          <ImageWrapper key={i}>
            <a
              href={getLink(partner.link)}
              target="_blank"
              rel="noreferrer"
              style={{ display: 'block', width: '4rem', height: 64 }}
            >
              <motion.div
                variants={isDesktop ? {} : scaleVariants}
                initial={'enter'}
                animate={isDesktop ? '' : 'center'}
                exit={'exit'}
                transition={{
                  scale: { duration: 0.4 },
                }}
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                }}
              >
                {isAssetStoryBlok(partner.image) ? (
                  <StoryblokImageContainer>
                    <StoryblokImage
                      image={partner.image}
                      objectFit="contain"
                      width={64}
                    />
                  </StoryblokImageContainer>
                ) : (
                  <Image
                    src={partner.image.src}
                    alt={`media-partner-${i + 1}`}
                    layout="fill"
                    objectFit="contain"
                    sizes="(max-width: 500px) 50vw, (max-width: 1280px) 35vw, 30vw"
                    unoptimized
                  />
                )}
              </motion.div>
            </a>
          </ImageWrapper>
        ))}
    </MediaPartnerWrapper>
  )
}

export default MediaPartnerItem

const MediaPartnerWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  height: 4rem;
  overflow: hidden;

  @media only screen and (max-width: 299px) {
    height: 3rem;
  }
`

const ImageWrapper = styled.div`
  img {
    margin: 0 3.5rem;

    @media only screen and (min-width: 300px) and (max-width: 700px) {
      margin: 0 0.7rem;
    }

    @media only screen and (max-width: 299px) {
      margin: 0 0.2rem;
      max-width: 70%;
      object-fit: scale-down;
    }

    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  }
`
const StoryblokImageContainer = styled.div`
  height: 100%;
  & span {
    height: 100% !important;
  }
`

const scaleVariants = {
  enter: {
    scale: 0.6,
  },
  center: {
    scale: 1,
  },
  exit: {
    scale: 0.6,
  },
}
