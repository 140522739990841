import { useQuery } from '@/app/common/graphql/hooks'
import { GraphQLPrefetchClient } from '@/app/common/graphql/prefetchClient'
import {
  EOrderBy,
  ProductSelectorOtherProductsQueryDocument,
} from '@/types/gql/graphql'
import ProductSelectorProducts, {
  ProductSelectorProductsProps,
} from './ProductSelectorProducts'

interface ProductSelectorOtherProductsProps
  extends Omit<ProductSelectorProductsProps, 'products' | 'totalPages'> {
  categoryIds: string[]
  excludedProductIds: string[]
  renderMissingProductLink: undefined | (() => JSX.Element)
}

export default function ProductSelectorOtherProducts({
  renderMissingProductLink,
  ...props
}: ProductSelectorOtherProductsProps) {
  // TODO: paginate (requires total results count)
  const { data } = useQuery(ProductSelectorOtherProductsQueryDocument, {
    variables: {
      opts: {
        filter: {
          categoryIds: props.categoryIds,
          excludeIds: props.excludedProductIds,
          isPublished: true,
        },
        // TODO: sorting by release date would be better, not available yet (CAS-3668)
        order: {
          name: EOrderBy.Desc,
        },
      },
    },
    enableCaching: true,
  })

  const products = data?.products ?? []
  const cardsCount = renderMissingProductLink
    ? products.length + 1
    : products.length

  return (
    <ProductSelectorProducts
      {...props}
      products={products}
      totalPages={Math.ceil(cardsCount / props.pageSize)}
      renderExtraItem={renderMissingProductLink}
    />
  )
}

interface PrefetchNewProductSelectorOtherProductsArgs {
  categoryIds: string[]
  excludeIds: string[]
}

export function prefetchNewProductSelectorOtherProducts(
  client: GraphQLPrefetchClient,
  args: PrefetchNewProductSelectorOtherProductsArgs,
) {
  return client.executeQuery({
    document: ProductSelectorOtherProductsQueryDocument,
    variables: {
      opts: {
        filter: {
          ...args,
          isPublished: true,
        },
        order: { name: EOrderBy.Desc },
      },
    },
  })
}
