import {
  availableRegionsState,
  redirectToCorrectDomainState,
} from '../../common.state'
import { ModalDialogContext } from '../../context/modalDialogContext'
import { Box, Button, Typography } from '@material-ui/core'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import React, { useCallback, useContext, useEffect } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { useRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import { useQuery } from '@/app/common/graphql/hooks'
import { media } from '@/style/helpers'
import {
  GetAvailableAndCurrentUserRegionsDocument,
  GetAvailableAndCurrentUserRegionsQuery,
  GetAvailableAndCurrentUserRegionsQueryVariables,
  Region,
} from '@/types/gql/graphql'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  ${media.sm} {
    width: 750px;
    height: 200px;
  }

  ${media.md} {
    width: 600px;
    height: 200px;
  }
`

const CountryButton = styled(Button)`
  width: 49%;
  border: solid 1px #e6e6e6 !important;
  text-transform: none !important;
`

const HeaderBox = styled(Box)`
  border-bottom: solid 1px #e6e6e6;
  height: 45px;
`

const BodyBox = styled(Box)`
  padding: 20px 15px 15px 15px;
`

const FooterBox = styled(Box)`
  padding: 35px 15px 15px 15px;
  display: flex;
  justify-content: space-between;
`

const CountryFlagIcon = styled(ReactCountryFlag)`
  font-size: 20px;
  margin-right: 5px;
  margin-top: -3px;
`

const SpanBold = styled.span`
  margin-top: 0.5rem;
  font-weight: 600;
`

interface DomainModelProps {
  currentRegion: Region
  websiteRegion: Region
  onClickStayOnSite: () => void
  onClickRedirect: () => void
}

const DomainModal = (props: DomainModelProps) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <HeaderBox>
        <Box style={{ padding: '10px 15px 15px 15px' }}>
          <Typography>
            <Trans
              i18nKey="common:forwarding_on_domain"
              components={{ SpanBold: <SpanBold /> }}
              values={{
                country: t(`common:${props.websiteRegion.name.toLowerCase()}`),
              }}
            />
          </Typography>
        </Box>
      </HeaderBox>
      <BodyBox>
        <Typography>
          {t('common:visit_our_site', {
            country: t(`common:${props.currentRegion.name.toLowerCase()}`),
          })}
        </Typography>
      </BodyBox>
      <FooterBox>
        <CountryButton onClick={props.onClickRedirect}>
          <span>
            <CountryFlagIcon
              countryCode={props.currentRegion.code}
              svg={true}
            />
          </span>
          {t('common:redirect_to_new_site', {
            regionDomain: props.currentRegion.domain,
          })}
        </CountryButton>
        <CountryButton onClick={props.onClickStayOnSite}>
          <span>
            <CountryFlagIcon
              countryCode={props.websiteRegion.code}
              svg={true}
            />
          </span>
          <Typography variant="inherit">
            {t('common:stay_on_current_site', {
              regionDomain: props.websiteRegion.domain,
            })}
          </Typography>
        </CountryButton>
      </FooterBox>
    </Wrapper>
  )
}

export const Domain = () => {
  const modalDialog = useContext(ModalDialogContext)

  const [redirectToCorrectDomain, setRedirectToCorrectDomain] = useRecoilState(
    redirectToCorrectDomainState,
  )

  const setAvailableRegionsState = useSetRecoilState(availableRegionsState)

  const regionQueryResults = useQuery<
    GetAvailableAndCurrentUserRegionsQuery,
    GetAvailableAndCurrentUserRegionsQueryVariables
  >(GetAvailableAndCurrentUserRegionsDocument, {
    onCompleted: (data) => {
      setAvailableRegionsState(data.getAvailableRegions)
    },
  })

  const onClickRedirect = useCallback(() => {
    const currentDomain =
      regionQueryResults.data?.getAvailableRegions.currentRegion?.domain
    if (window && currentDomain) {
      setRedirectToCorrectDomain(true)
      window.location.hostname = currentDomain
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionQueryResults])

  const onClickStayOnSite = useCallback(() => {
    setRedirectToCorrectDomain(false)
    modalDialog.close()
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionQueryResults])

  useEffect(() => {
    const currentRegion =
      regionQueryResults.data?.getAvailableRegions.currentRegion
    const websiteRegion =
      regionQueryResults.data?.getAvailableRegions.availableRegions.find(
        (region) => region.domain === window.location.hostname,
      )

    if (currentRegion === null && redirectToCorrectDomain === undefined) {
      setRedirectToCorrectDomain(false)
    }

    if (!currentRegion || !websiteRegion) {
      return
    }

    if (
      redirectToCorrectDomain === undefined ||
      redirectToCorrectDomain === true
    ) {
      if (currentRegion.domain !== window.location.hostname) {
        modalDialog.open(
          <DomainModal
            currentRegion={currentRegion}
            websiteRegion={websiteRegion}
            onClickRedirect={onClickRedirect}
            onClickStayOnSite={onClickStayOnSite}
          />,
          {
            variant: 'info',
            onAfterClose: () => {
              setRedirectToCorrectDomain(false)
            },
          },
        )
      } else if (redirectToCorrectDomain === undefined) {
        setRedirectToCorrectDomain(false)
      }
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionQueryResults, redirectToCorrectDomain])

  return null
}
