import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import { useRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import { useLazyQuery } from '@/app/common/graphql/hooks'
import {
  buildDealCalculationVariables,
  useCheckCartInvalid,
} from '@/domains/checkout/checkout.service'
import {
  dealCalculationState,
  selectedItemsState,
} from '@/domains/checkout/checkout.state'
import { DifferentInterestItemModal } from '@/domains/items/components/DifferentInterestItemModal'
import {
  ConfiguratorGroup,
  ConfiguratorHeadline,
  ConfiguratorSummary,
} from '@/domains/items/components/ProductConfigurator'
import * as tracking from '@/helpers/tracking'
import useCompany from '@/helpers/useCompany'
import useShowApolloErrorInSnackbar from '@/helpers/useShowApolloErrorInSnackbar'
import useWindowDimensions_CAN_CAUSE_CLS from '@/helpers/useWindowDimensions'
import SliderInput from '@/style/components/Form/SliderInput'
import { LastFallbackImage } from '@/style/components/Image'
import { UploadedImage } from '@/style/components/Image'
import { SectionInner, SectionWrapper } from '@/style/components/Section'
import { media } from '@/style/helpers'
import { SelectedLegacyProduct } from '@/types'
import {
  DealCalculationDocument,
  DealCalculationQuery,
  DealCalculationQueryVariables,
  EDealType,
  EQuestionType,
  ItemAnswer,
  ItemQuestion,
} from '@/types/gql/graphql'
import bgImgLeft from './assets/bg_left.png'
import bgImgRight from './assets/bg_right.png'

interface ProductConfiguratorProps {
  product: SelectedLegacyProduct
  checkoutDate: Date | undefined
  itemQuestions: Omit<ItemQuestion, 'validFrom' | 'validTo'>[] | undefined
  immutableAnswers: ItemAnswer[]
}

const StyleSectionWrapper = styled(SectionWrapper)`
  position: relative;
  z-index: 1;
  padding-bottom: 0 !important;

  ${SectionInner} {
    overflow: hidden;

    &:not(:first-child) {
      background-color: ${({ theme }) => theme.colors.buttonBorder};

      ${media.md} {
        background-color: transparent;
      }
    }
  }

  ${media.md} {
    padding-bottom: 2.5rem !important;

    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      background-image: url(${bgImgLeft.src});
      width: 30%;
      height: 100%;
      background-position: bottom left;
      background-size: contain;
      background-repeat: no-repeat;
      pointer-events: none;
      z-index: -1;
    }

    &::after {
      left: auto;
      right: 0;
      background-image: url(${bgImgRight.src});
    }
  }
`

const RowStyled = styled(Row)`
  margin-left: 0;
  margin-right: 0;
`

const LeftCol = styled(Col)`
  padding-left: 0;
  padding-right: 0;
`

const RightCol = styled(Col)`
  padding-left: 0;
  padding-right: 0;
`

function buildItemAnswers(
  itemQuestions: Omit<ItemQuestion, 'validFrom' | 'validTo'>[] | undefined,
) {
  const answers = (itemQuestions ?? []).map((question) => {
    return {
      questionId: question._id,
      rangeValue:
        question.questionType === EQuestionType.Range && question.rangeData
          ? question.rangeData.defaultValue
          : undefined,
      selectedOptionIndex:
        question.questionType === EQuestionType.SingleChoice &&
        question.singleChoiceOptions
          ? question.singleChoiceOptions.findIndex(
              (option) => option.isDefaultValue,
            )
          : undefined,
    }
  })

  return answers
}

const ProductConfigurator = ({
  product,
  checkoutDate,
  itemQuestions,
  immutableAnswers,
}: ProductConfiguratorProps): JSX.Element => {
  const { t } = useTranslation()
  const showErrorInSnackbar = useShowApolloErrorInSnackbar()
  const [layout, setLayout] = useState('desktop')
  const [itemAnswers, setItemAnswers] = useState<ItemAnswer[]>([])
  const { activeBreakpoints } = useWindowDimensions_CAN_CAUSE_CLS()
  const [selectedItems, setSelectedItems] = useRecoilState(selectedItemsState)
  const setDealCalculation = useSetRecoilState(dealCalculationState)
  const router = useRouter()
  const [calculationObjectId, setCalculationObjectId] = useState(
    product?.objectID,
  )
  const modal = useContext(ModalDialogContext)

  const isCartInvalid = useCheckCartInvalid()

  const company = useCompany()

  const getSelectedOptionIndex = (
    question: Omit<ItemQuestion, 'validFrom' | 'validTo'>,
  ) => {
    if (itemAnswers && itemAnswers.length > 0) {
      const answeredQuestion = itemAnswers.find(
        (answer) => answer.questionId === question._id,
      )
      if (answeredQuestion) {
        return answeredQuestion.selectedOptionIndex
      }
    }
  }

  const getRangeValue = (
    question: Omit<ItemQuestion, 'validFrom' | 'validTo'>,
  ) => {
    if (itemAnswers && itemAnswers.length > 0) {
      const answeredQuestion = itemAnswers.find(
        (answer) => answer.questionId === question._id,
      )
      if (answeredQuestion) {
        return answeredQuestion.rangeValue
      }
    }
  }

  const getQuestionDefaultValue = (
    question: Omit<ItemQuestion, 'validFrom' | 'validTo'>,
  ) => {
    if (question.questionType === EQuestionType.Range && question.rangeData) {
      return question.rangeData.defaultValue
    } else if (
      question.questionType === EQuestionType.SingleChoice &&
      question.singleChoiceOptions
    ) {
      return question.singleChoiceOptions.findIndex(
        (option) => option.isDefaultValue,
      )
    }
  }

  const [getDealCalculation] = useLazyQuery<
    DealCalculationQuery,
    DealCalculationQueryVariables
  >(DealCalculationDocument)

  const proceedToCheckout = async () => {
    const itemCategory = product.itemCategory

    if (!itemCategory || isCartInvalid) {
      setSelectedItems([]) // This should not happen, but just in case there are some "dirty" data before the migration
    }

    const updatedItems = isCartInvalid ? [] : [...(selectedItems ?? [])]

    updatedItems.push({
      ean: product.ean,
      title: product.title ?? '',
      answers: itemAnswers,
      algoliaReference: product.objectID,
      itemCategoryId: product.itemCategoryId,
      material: product.material,
      picture: product.picture ?? undefined,
      contentCategories: product.contentCategories,
      isInvestmentGold: product.isTaxFree,
      itemCategory,
    })

    if (!company) return

    const queryVariables = buildDealCalculationVariables({
      companyId: company._id,
      dealType: EDealType.Pawn,
      date: checkoutDate,
      items: updatedItems,
      durationInDays: company.configuration.minimumPawnDuration,
    })

    const result = await getDealCalculation({
      variables: queryVariables,
      onError(error) {
        showErrorInSnackbar(error)
        modal.open(<DifferentInterestItemModal />, {
          variant: 'full',
        })
      },
    })

    setSelectedItems(updatedItems)

    if (result.data?.dealCalculation) {
      setDealCalculation(result.data.dealCalculation)
      const { itemsValuesEntries } = result.data.dealCalculation

      tracking.addToCartLegacy([
        {
          ...product,
          actualValue:
            itemsValuesEntries[itemsValuesEntries.length - 1]?.loanToValue,
        },
      ])

      router.push('/checkout/items')
    }
  }

  useEffect(() => {
    if (activeBreakpoints.includes('md')) {
      setLayout('desktop')
    } else {
      setLayout('mobile')
    }
  }, [activeBreakpoints])

  useEffect(() => {
    if (immutableAnswers.length === 0) {
      setItemAnswers(buildItemAnswers(itemQuestions))
    } else {
      const defaultAnswers = buildItemAnswers(itemQuestions)
      const itemAnswers = defaultAnswers.map(
        (answer) =>
          immutableAnswers.find(
            (immutableAnswer) =>
              immutableAnswer.questionId === answer.questionId,
          ) ?? answer,
      )

      setItemAnswers(itemAnswers)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemQuestions])

  useEffect(() => {
    setCalculationObjectId(product?.objectID)
  }, [product?.objectID])

  const skipItemCalculation =
    !itemAnswers ||
    itemAnswers.length === 0 ||
    calculationObjectId !== product?.objectID

  return (
    <RowStyled>
      <LeftCol xs={12} md={8}>
        <StyleSectionWrapper
          backgroundColor={layout === 'desktop' ? 'buttonBorder' : 'white'}
        >
          <SectionInner sizing="narrow">
            <ConfiguratorHeadline
              image={
                product.picture ? (
                  <UploadedImage
                    data={product.picture}
                    subset={['product_small', 'product_thumbnail']}
                    sizes={{
                      md: 'product_small',
                    }}
                    primarySize="product_small"
                  />
                ) : (
                  <LastFallbackImage />
                )
              }
              categoryHeadline={t('items:your_item')}
              productHeadline={product.title ?? ''}
            />
          </SectionInner>
          <SectionInner sizing="narrow">
            {itemQuestions &&
              itemQuestions.map((question) => {
                if (
                  question.questionType === EQuestionType.SingleChoice &&
                  question.singleChoiceOptions
                ) {
                  const isOpticalCondition =
                    question.titleKey === 'QUESTION_OPTICAL_CONDITION'
                  return (
                    <ConfiguratorGroup
                      key={question._id}
                      slug={question?.titleKey || ''}
                      options={Object.keys(question.singleChoiceOptions).map(
                        (key) => {
                          return {
                            value: key as any,
                            name: question.singleChoiceOptions
                              ? t(
                                  `items:item_questions.${
                                    question.singleChoiceOptions[key as any]
                                      .labelKey
                                  }`,
                                )
                              : '',
                            description:
                              question.singleChoiceOptions &&
                              question.singleChoiceOptions[key as any].infoKey
                                ? t(
                                    `items:item_questions.${
                                      question.singleChoiceOptions[key as any]
                                        .infoKey
                                    }`,
                                  )
                                : t(`items:item_questions.${question.infoKey}`),
                            slug:
                              question.singleChoiceOptions?.[key as any]
                                .labelKey || '',
                          }
                        },
                      )}
                      value={
                        getSelectedOptionIndex(question) ??
                        getQuestionDefaultValue(question)
                      }
                      headline={`${t(`items:item_questions.${question.titleKey}`)}:`}
                      onChange={(value) => {
                        const updatedAnswers = [...itemAnswers]
                        const existingAnswerIndex = updatedAnswers.findIndex(
                          (answer) => answer.questionId === question._id,
                        )

                        if (existingAnswerIndex >= 0) {
                          updatedAnswers[existingAnswerIndex] = {
                            ...updatedAnswers[existingAnswerIndex],
                            selectedOptionIndex: parseInt(value),
                          }

                          setItemAnswers(updatedAnswers)
                        } else {
                          updatedAnswers.push({
                            questionId: question._id,
                            selectedOptionIndex: parseInt(value),
                          })
                          setItemAnswers(updatedAnswers)
                        }
                      }}
                      buttonStyle={
                        isOpticalCondition ? { textAlign: 'left' } : {}
                      }
                      nestedDescription={isOpticalCondition}
                    />
                  )
                } else if (
                  question.questionType === EQuestionType.Range &&
                  question.rangeData
                ) {
                  const unit = t(
                    `items:item_questions.${question.rangeData.unitKey}`,
                  )
                  return (
                    <SliderInput
                      hidden={
                        immutableAnswers.find(
                          (answer) => answer.questionId === question._id,
                        ) !== undefined
                      }
                      key={question._id}
                      slug={question?.titleKey ?? question._id}
                      value={
                        getRangeValue(question) ??
                        getQuestionDefaultValue(question)
                      }
                      label={`${t(`items:item_questions.${question.titleKey}`)}:`}
                      min={question.rangeData.minValue}
                      max={question.rangeData.maxValue}
                      unit={unit}
                      step={unit === 'g' ? 0.1 : undefined}
                      decimalPlaces={unit === 'g' ? 3 : 0}
                      onChange={(value) => {
                        const updatedAnswers = [...itemAnswers]
                        const existingAnswerIndex = updatedAnswers.findIndex(
                          (answer) => answer.questionId === question._id,
                        )

                        if (existingAnswerIndex >= 0) {
                          updatedAnswers[existingAnswerIndex] = {
                            ...updatedAnswers[existingAnswerIndex],
                            rangeValue: value,
                          }

                          setItemAnswers(updatedAnswers)
                        } else {
                          updatedAnswers.push({
                            questionId: question._id,
                            rangeValue: value,
                          })
                          setItemAnswers(updatedAnswers)
                        }
                      }}
                    />
                  )
                }
              })}
          </SectionInner>
        </StyleSectionWrapper>
      </LeftCol>
      <RightCol xs={12} md={4}>
        <ConfiguratorSummary
          product={product}
          itemQuestions={itemQuestions}
          itemAnswers={itemAnswers}
          checkoutDate={checkoutDate}
          proceedToCheckout={proceedToCheckout}
          skipItemCalculation={skipItemCalculation}
        />
      </RightCol>
    </RowStyled>
  )
}

export default ProductConfigurator
