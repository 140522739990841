import { storyblokEditable } from '@storyblok/react'
import React from 'react'
import styled from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import StoryblokRichText from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { media } from '@/style/helpers'
import { BentoGridItemStoryblok } from '@/types/storyblok-component-types'

type Props = {
  fullWidth?: boolean // if a row has only one item, it should be full width
}

export default function CardItem({
  blok,
  fullWidth = false,
}: { blok: BentoGridItemStoryblok } & Props) {
  return (
    <Container
      color="white"
      bgColor={blok.backgroundColor?.color}
      fullWidth={fullWidth}
      {...storyblokEditable(blok)}
    >
      <ImageContainer fullWidth={fullWidth}>
        <ImageTransformer>
          {blok.image?.filename && (
            <StoryblokImage
              image={blok.image}
              sizes={'(max-width: 991px) 70vw, (min-width: 1280px) 40vw, 45vw'}
              layout="responsive"
            />
          )}
        </ImageTransformer>
      </ImageContainer>
      <TextContainer fullWidth={fullWidth}>
        <TextWrapper>
          <StoryblokRichText document={blok.content} />
        </TextWrapper>
      </TextContainer>
    </Container>
  )
}

const Container = styled.div<{
  bgColor?: string
  fullWidth: boolean
  color?: string
}>`
  display: flex;
  width: 100%;
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  border-radius: 10px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  color: ${({ color }) => color || 'inherit'};

  ${media.xs} {
    flex-direction: column;
    justify-content: center;
  }

  ${media.md} {
    ${({ fullWidth }) =>
      fullWidth
        ? `
        flex-direction: row;
        align-items: center;
        `
        : `
        flex-direction: column;
        justify-content: space-around;`}
  }
`

const imageColumnWidth = (7 / 12) * 100
const textColumnWidth = (5 / 12) * 100

const ImageContainer = styled.div<{ fullWidth?: boolean }>`
  display: block;
  position: relative;
  flex-basis: 100%;
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;

  ${media.md} {
    flex-basis: ${({ fullWidth }) =>
      fullWidth ? `${imageColumnWidth}%` : 'unset'};
    width: ${({ fullWidth }) => (fullWidth ? `${imageColumnWidth}%` : '100%')};
  }
`

const ImageTransformer = styled.div<{ fullWidth?: boolean }>`
  transform: scale(1.2);
  margin: -10% 0;
  aspect-ratio: 1 / 1;

  & > span {
    aspect-ratio: 1 / 1;
  }

  ${media.xs} {
    margin: -15% 0;
  }
`

const TextContainer = styled.div<{ fullWidth?: boolean }>`
  flex-basis: 100%;
  width: 100%;
  padding: 0 8px;

  ${media.md} {
    flex-basis: ${({ fullWidth }) =>
      fullWidth ? `${textColumnWidth}%` : 'unset'};
    width: ${({ fullWidth }) => (fullWidth ? `${textColumnWidth}%` : '100%')};
    ${({ fullWidth }) =>
      fullWidth &&
      `
      display: flex;
    `}
  }
`

const TextWrapper = styled.div`
  letter-spacing: 0em;
  padding: 1.5rem;
  background-color: transparent;

  p {
    font-size: 0.875rem;
    margin: 0;
    line-height: 1.4;

    ${media.xs} {
      font-size: 1rem;
    }
  }

  span,
  i,
  b,
  sup,
  sub {
    font: revert;
  }

  h2 {
    font-size: 1.375rem;
    margin-bottom: 0.75rem;
    font-weight: 700;
    white-space: pre-line;
    line-height: 1.19;
    letter-spacing: 0.014em;

    ${media.xs} {
      font-size: 1.4rem !important;
    }

    ${media.md} {
      font-size: 2.375rem !important;
    }
  }

  ${media.sm} {
    font-size: 1rem;
  }

  ${media.xs} {
    padding: 1rem 2rem 3rem;
  }

  ${media.lg} {
    padding: 1rem 3rem 5rem 3rem;
  }
`
