import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import Text from '@/style/components/LegacyText'
import InfoIcon from './ico_info.svg'

export const ErrorBoxStyled = styled(Row)`
  margin: 0;
  border: 2px solid ${(props) => props.theme.colors.primary};
  border-radius: 5px;
  margin-top: 1.25rem;
  align-items: stretch;
`
export const ErrorBoxLeft = styled(Col)`
  background-color: ${(props) => props.theme.colors.primary};
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 3rem;
  display: flex !important;
  align-items: center;
  justify-content: center;
`
export const ErrorBoxRight = styled(Col)`
  text-align: center;
  padding: 0.5rem;
  max-width: calc(100% - 3rem);
  width: calc(100% - 3rem);
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ErrorBox: React.FC<{
  message: string
}> = (props) => {
  return (
    <ErrorBoxStyled>
      <ErrorBoxLeft>
        <InfoIcon />
      </ErrorBoxLeft>
      <ErrorBoxRight>
        <Text.ms>{props.message}</Text.ms>
      </ErrorBoxRight>
    </ErrorBoxStyled>
  )
}
