import styled from 'styled-components'
import TypeScales from './typescales'

interface H1Props {
  noMargin?: boolean
}

export default styled(TypeScales.h1).attrs<H1Props>((props) => ({
  as: 'h1',
  noMargin: props.noMargin || false,
}))<H1Props>`
  text-transform: uppercase;
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : 2)}rem;
`
