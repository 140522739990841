import Whatsapp from '../../assets/Button-Float-Whatsapp.svg'
import useTranslation from 'next-translate/useTranslation'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useWhatsappNumber from '@/helpers/useWhatsappNumber'
import { Button } from '@/style/components/Button'
import { media } from '@/style/helpers'

const TRACKING_ID = 'modellauswahl'

type Props = {
  extraOffsetBottom: number
}

const WhatsappBubble = ({ extraOffsetBottom }: Props) => {
  const { t, lang } = useTranslation()
  const [visible, setVisible] = useState(false)
  const whatsappNumber = useWhatsappNumber()

  useEffect(() => {
    if (visible) return

    const element = document.querySelector(
      `[data-section-id=${TRACKING_ID}]`,
    ) as HTMLDivElement

    if (!element) {
      return
    }

    const onScroll = () => {
      const elementTop = element.offsetTop + element.clientHeight
      if (window.scrollY > elementTop) {
        setVisible(true)
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [visible])

  if (!visible) return null

  return (
    <a
      href={`https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${t(
        'common:whatsapp.initial_message',
      )}&lang=${lang}`}
      target="_blank"
      rel="noreferrer"
    >
      <ChatButton $extraOffsetBottom={extraOffsetBottom}>
        <WhatsappStyled />
      </ChatButton>
    </a>
  )
}

export default WhatsappBubble

const ChatButton = styled(Button)<{ $extraOffsetBottom: number }>`
  width: 60px;
  height: 60px;
  bottom: ${({ $extraOffsetBottom }) => 20 + $extraOffsetBottom}px;
  right: 20px;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.2);
  ${media.md} {
    bottom: ${({ $extraOffsetBottom }) => 50 + $extraOffsetBottom}px;
    right: 50px;
  }
  position: fixed;
  background-color: #01e677;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  z-index: 999;
`

const WhatsappStyled = styled(Whatsapp)`
  position: absolute;
  z-index: 999;
  top: 4px;
  left: 4px;
`
