import styled from 'styled-components'

export default styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`
