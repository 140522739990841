import {
  IconButton,
  InputAdornment,
  InputProps,
  TextField as MuiTextField,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useState } from 'react'
import styled from 'styled-components'

const TextInputStyled = styled(MuiTextField)<{
  error?: boolean
  multiline?: boolean
}>`
  .MuiFilledInput-root {
    background-color: white;

    ${(props) =>
      !props.multiline &&
      `
      height: 3.3rem;
    `}
    font-family: GalanoGrotesque-Medium,sans-serif;

    border: 2px solid
      ${(props) => (!props.error ? props.theme.colors.border : '#f44336')};
    border-radius: ${({ theme }) => theme.radius.normal}px;

    &.Mui-focused {
      background-color: white !important;
    }
    &:active,
    &:hover {
      background-color: white;
    }
    input {
      font-size: 0.875rem;
    }
  }
  .MuiFilledInput-underline {
    &:after,
    &:before,
    &:hover:before {
      border: none;
    }
  }
  label.Mui-focused {
    color: #666666;
    font-size: 1rem;
  }
`

interface TextInputProps {
  id?: string
  type: string
  name: string
  label: string
  error?: boolean
  multiline?: boolean
  rows?: number
  value?: string | number
  inputProps?: InputProps['inputProps']
  onChange?: InputProps['onChange']
  onBlur?: InputProps['onBlur']
  inputRef?: React.MutableRefObject<HTMLInputElement>
}

export const TextInput: React.FC<TextInputProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false)

  let type = props.type

  if (type === 'password') {
    type = showPassword ? 'text' : 'password'
  }

  return (
    <TextInputStyled
      {...props}
      type={type}
      error={props.error}
      variant="filled"
      fullWidth
      rows={props.rows}
      multiline={props.multiline ?? false}
      InputProps={{
        endAdornment:
          props.type == 'password' ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ) : undefined,
        startAdornment:
          props.type === 'money' ? (
            <InputAdornment position="start">€</InputAdornment>
          ) : undefined,
      }}
    />
  )
}
