import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import styled from 'styled-components'

export const RightArrow = styled(ArrowForwardIosIcon)`
  color: ${(props) => props.theme.colors.secondary};
  vertical-align: middle;
`

export const LeftArrow = styled(ArrowBackIosIcon)`
  color: ${(props) => props.theme.colors.black};
  vertical-align: middle;
`
