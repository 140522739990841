import { storyblokEditable } from '@storyblok/react'
import React from 'react'
import styled from 'styled-components'
import StoryblokRichText from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { FaqItemStoryblok } from '@/types/storyblok-component-types'
import AccordeonItem from './AccordeonItem'

export default function FAQItem({
  blok,
  ...props
}: {
  blok: FaqItemStoryblok
}) {
  return (
    <AccordeonItem
      {...props}
      title={blok.question}
      key={blok._uid}
      id={blok._uid}
      {...storyblokEditable(blok)}
    >
      <RichtextWrapper>
        <StoryblokRichText document={blok.answer} />
      </RichtextWrapper>
    </AccordeonItem>
  )
}

const RichtextWrapper = styled.div`
  & a {
    color: rgb(80, 22, 244) !important;
  }
`
