import { Box } from '@material-ui/core'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import { InputErrorMessage } from '@/app/common/components/Form'
import SmartTextInput from '@/app/common/components/Form/UseSmartTextInput'
import { UseFormReturnType } from '@/app/common/components/Form/useForm'
import { ConfiguratorGroup } from '@/domains/items/components/ProductConfigurator'
import { Select } from '@/style/components/Form'
import { getTranslatedValidationError } from '@/utils/error'
import { CustomVehicleFormData } from './VehicleDealBooking'

interface CustomVehicleFormRequestProps {
  formikProps: UseFormReturnType<CustomVehicleFormData>
}

const CustomVehicleFormRequest: React.FC<CustomVehicleFormRequestProps> = ({
  formikProps,
}) => {
  const { t } = useTranslation()
  const { values, errors } = formikProps

  // if odometer field has error with maximum value validate
  const odometerError = errors.odometer && !errors.odometer?.includes('NaN')

  return (
    <>
      <SmartTextInput
        id={'CAR_BRAND_INPUT'}
        formikProps={formikProps}
        type="text"
        name="make"
        placeholder={t('common:car.make')}
      />
      <SmartTextInput
        id={'CAR_MODEL_INPUT'}
        formikProps={formikProps}
        type="text"
        name="model"
        placeholder={t('common:car.model')}
      />
      <SmartTextInput
        formikProps={formikProps}
        type="number"
        name="horsePower"
        placeholder="PS"
        id="CAR_HP_INPUT"
      />
      <SmartTextInput
        id="CAR_ODOMETER_INPUT"
        formikProps={formikProps}
        type="number"
        name="odometer"
        placeholder="Kilometerstand"
        showValidationError={false}
      />
      {odometerError && (
        <Box marginBottom={1}>
          <InputErrorMessage
            message={getTranslatedValidationError(errors.odometer, t)}
          />
        </Box>
      )}

      <Select
        hasNone
        fullWidth
        showLabel
        style={{ marginBottom: '10px' }}
        value={values.regdate}
        name="regdate"
        label={t('common:car.regdate')}
        options={(() => {
          const years: number[] = []
          for (let i = new Date().getFullYear(); i >= 1950; i--) {
            years.push(i)
          }
          return years.map((y) => ({ label: y + '', value: y }))
        })()}
        onChange={(e) => formikProps.setFieldValue('regdate', e.target.value)}
      />

      <ConfiguratorGroup
        headline={t('common:car.fuel')}
        options={[
          { name: 'Diesel', value: 'diesel' },
          { name: 'Benzin', value: 'petrol' },
        ]}
        style={{
          marginTop: 0,
          marginBottom: '10px',
        }}
        headlineStyle={{
          fontSize: '12px',
          fontWeight: 500,
          display: 'none',
        }}
        buttonStyle={{ fontSize: '12px', fontWeight: 500 }}
        onChange={(v) => formikProps.setFieldValue('fuel', v)}
        value={values?.fuel}
      />
    </>
  )
}

export default CustomVehicleFormRequest
