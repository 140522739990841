const { CustomError } = require('ts-custom-error')

class MissingTranslationError extends CustomError {
  constructor(message) {
    super(message)
    Object.defineProperty(this, 'name', { value: 'MissingTranslationError' })
  }
}

module.exports = {
  MissingTranslationError,
}
