import useTranslation from 'next-translate/useTranslation'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import {
  getDealLevelFees,
  getItemsAndFees,
  getTotalGrossFeeAmountOfType,
} from '@/helpers/feesCalculation'
import useRegionCode from '@/helpers/useRegionCode'
import { SelectedItemsType } from '@/types'
import { EFeeType, UsedFeeDefinition } from '@/types/gql/graphql'
import { printLocalAmount } from '@/utils/misc'

const FeeInfoTableItemRow = styled.tr`
  color: darkGrey;
  margin-left: 0.33rem;

  td:first-child {
    div {
      border-left: 1px solid darkGrey;
      padding-left: 0.33rem;
      margin-left: 0.33rem;
    }
  }
`

interface FeeListingDetailProps {
  appliedUsedFeeDefinitions: UsedFeeDefinition[]
  dealItems: Omit<SelectedItemsType, 'answers' | 'itemCategory'>[]
  customItemTitles?: string[]
  isCarDeal: boolean
}

const FeeListingDetail: React.FC<FeeListingDetailProps> = ({
  appliedUsedFeeDefinitions,
  dealItems,
  customItemTitles,
  isCarDeal,
}) => {
  const { t } = useTranslation()
  const regionCode = useRegionCode()

  const customDealItems: Array<{ title: string }> | undefined =
    customItemTitles &&
    customItemTitles.map((customItemName) => {
      return { title: customItemName }
    })

  const showItemsAndFees = useMemo(() => {
    const itemFeesData = getItemsAndFees(
      appliedUsedFeeDefinitions,
      customDealItems ? customDealItems : dealItems,
    )

    return itemFeesData.map((item, i) => (
      <React.Fragment key={i}>
        <tr>
          <td>{t(`common:FEE_TYPE_${item.feeType}`)}</td>
          <td>
            {printLocalAmount({
              number: getTotalGrossFeeAmountOfType(
                appliedUsedFeeDefinitions,
                item.feeType,
              ),
            })}
          </td>
        </tr>

        {!isCarDeal &&
          item.itemFees.map((itemFee, idx) => (
            <FeeInfoTableItemRow key={idx}>
              <td>
                <div>{itemFee.itemTitle}</div>
              </td>
              <td>{printLocalAmount({ number: itemFee.itemFee })}</td>
            </FeeInfoTableItemRow>
          ))}
      </React.Fragment>
    ))
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedUsedFeeDefinitions, customItemTitles || dealItems])

  const dealFees = useMemo(
    () => getDealLevelFees(appliedUsedFeeDefinitions),
    [appliedUsedFeeDefinitions],
  )

  const interestFee = dealFees.find((c) => c.feeType === EFeeType.Interest)
  return (
    <>
      {/* if isCarDeal we first show the Interest */}
      {isCarDeal && interestFee ? (
        <tr key={dealFees.indexOf(interestFee)}>
          <td>{t(`common:FEE_TYPE_${interestFee.feeType}`)}</td>
          <td>{printLocalAmount({ number: interestFee.amount })}</td>
        </tr>
      ) : (
        ''
      )}

      {/* get items title & fees */}
      {showItemsAndFees}

      {/* deal related fees */}

      {dealFees
        .filter(
          (c) =>
            (c.feeType !== EFeeType.Interest && isCarDeal) || // If isCarDeal we have shown the Interest earlier!
            !isCarDeal,
        )
        .map((deal, idx) => (
          <tr key={idx}>
            <td>
              {deal.feeType === EFeeType.InitialStaggered
                ? t(`common:FEE_TYPE_${deal.feeType}_${regionCode}`)
                : t(`common:FEE_TYPE_${deal.feeType}`)}
            </td>
            <td>{printLocalAmount({ number: deal.amount })}</td>
          </tr>
        ))}
    </>
  )
}

export default FeeListingDetail
