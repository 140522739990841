import Observer from '@researchgate/react-intersection-observer'
import { motion } from 'framer-motion'
import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import variants, { delayedVariant } from './variants'

const AnimatedDiv = styled(motion.div)`
  position: relative;
`

interface ScrollRevealProps {
  animation?: keyof typeof variants
  initial?: 'visible' | 'hidden'
  delay?: number
  className?: string
  onReveal?: () => void
  onVisibleChange?: (visible: boolean) => void
  children: React.ReactNode
}

const ScrollReveal: FunctionComponent<ScrollRevealProps> = ({
  children,
  animation,
  initial,
  delay,
  className,
  onReveal,
  onVisibleChange,
}) => {
  const [isIntersecting, setIsIntersecting] = useState(false)

  const variant = delayedVariant(animation || 'slideUp', delay || 0)

  return (
    <Observer
      threshold={0}
      onChange={(e) => {
        if (onVisibleChange) {
          onVisibleChange(e.isIntersecting)
        }

        if (!isIntersecting) {
          setIsIntersecting(e.isIntersecting)
          if (onReveal) onReveal()
        }
      }}
    >
      <AnimatedDiv
        variants={variant}
        animate={isIntersecting ? 'visible' : 'hidden'}
        initial={initial || 'visible'}
        className={className}
      >
        {children}
      </AnimatedDiv>
    </Observer>
  )
}

export default ScrollReveal
