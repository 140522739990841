import styled from 'styled-components'
import TypeScales from './typescales'

interface H6Props {
  noMargin?: boolean
}

export default styled(TypeScales.h6).attrs<H6Props>((props) => ({
  as: 'h6',
  noMargin: props.noMargin || false,
}))<H6Props>`
  text-transform: uppercase;
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : 0.75)}rem;
`
