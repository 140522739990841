import { GraphQLError } from 'graphql'
import { Variables } from '@/app/common/graphql/client'
import { TypedDocumentString } from '@/types/gql/graphql'

export const isErrorUnauthorized = (graphQLError: GraphQLError) => {
  if (graphQLError.message.includes('Unauthorized')) {
    return true
  }
  return false
}

export function createQueryKey<TResult, TVariables extends Variables>(
  document: TypedDocumentString<TResult, TVariables>,
  variables?: TVariables,
) {
  const query = document.toString()
  const hashedQuery = hashCode(query)
  return [hashedQuery, convertToSerializable(variables)]
}

function hashCode(input: string) {
  let hash = 0
  for (let i = 0, len = input.length; i < len; i++) {
    const chr = input.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0
  }
  return hash
}

function convertToSerializable<T>(input: T): T {
  return input === undefined ? null : JSON.parse(JSON.stringify(input))
}
