import DownloadIcon from '../assets/ico_download_Blue.svg'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import { media } from '@/style/helpers'
import {
  AboutPageMaterialItemStoryblok,
  RichtextStoryblok,
} from '@/types/storyblok-component-types'

interface AboutPressProps {
  caption?: RichtextStoryblok
  items?: AboutPageMaterialItemStoryblok[]
}

const AboutPress: FunctionComponent<AboutPressProps> = ({ caption, items }) => {
  return (
    <MainWrapper>
      <PresstWrapperDesktop>
        <Content caption={caption} items={items} />
      </PresstWrapperDesktop>

      <PresstWrapperMobile>
        <Content caption={caption} items={items} />
      </PresstWrapperMobile>
    </MainWrapper>
  )
}

export default AboutPress

const Content = ({ caption, items }: AboutPressProps): JSX.Element | null => {
  return (
    <>
      <LeftWrapper>
        <TextWrapper>
          <RichTextParagraphStyles>
            <StoryblokRichText document={caption} />
          </RichTextParagraphStyles>
        </TextWrapper>
      </LeftWrapper>

      <RightWrapper>
        <FilesDiv>
          {items?.map((item) => (
            <div key={item._id}>
              <DownloadLink
                href={getStoryBlokLink(item.link)}
                target="_blank"
                rel="noreferrer"
              >
                <IconWrapper>
                  <DownloadIcon />
                </IconWrapper>
                <FileNameWrapper>
                  <FileItemWrapper>{item.caption}</FileItemWrapper>
                </FileNameWrapper>{' '}
              </DownloadLink>
            </div>
          ))}
        </FilesDiv>
      </RightWrapper>
    </>
  )
}

const MainWrapper = styled.div`
  margin: auto 2rem;
  text-align: center;
  align-content: center;
  margin-bottom: -5rem;

  ${media.xs} {
    margin: auto 4rem;
    text-align: center;
    align-content: center;
    margin-bottom: 4rem;
  }

  ${media.sm} {
    margin: auto 5rem;
    text-align: center;
    align-content: center;
    margin-bottom: 4rem;
  }

  ${media.md} {
    margin: auto 7rem;
    text-align: center;
    align-content: center;
    margin-bottom: 2rem;
  }
`

const PresstWrapperDesktop = styled.div`
  width: 100%;

  display: none;

  ${media.xs} {
    display: none;
  }

  ${media.sm} {
    display: block;
    width: 100%;
    margin: auto;
    text-align: center;
    height: 13rem;
  }

  ${media.md} {
    display: block;

    width: 100%;
    margin: auto;
    text-align: center;
    margin-bottom: 13vw;
    height: 14rem;
  }
`

const PresstWrapperMobile = styled.div`
  text-align: center;
  display: block;
  margin-bottom: 9rem;

  ${media.xs} {
    display: block;
    margin-bottom: 1rem;
  }

  ${media.sm} {
    display: none;
  }

  ${media.md} {
    display: none;
  }
`

const RightWrapper = styled.div`
  float: center;
  width: 100%;

  ${media.sm} {
    width: 50%;
    float: right;
  }

  ${media.md} {
    width: 50%;
    float: right;
  }
`

const LeftWrapper = styled.div`
  width: 100%;
  float: center;

  ${media.sm} {
    width: 48%;
    float: left;
  }

  ${media.md} {
    width: 50%;
    float: left;
  }
`

const TextWrapper = styled.div`
  text-align: left;
  max-width: 23rem;
`

const FilesDiv = styled.div`
  width: 100%;
  float: center;

  ${media.xs} {
    float: center;
    margin-top: 1rem;
  }

  ${media.sm} {
    margin-top: -1.5rem;
  }
`
const FileItemWrapper = styled.div`
  color: #000000;
  font-family: GalanoGrotesque;
  font-size: 0.9rem;
  line-height: 22px;
  text-align: left;
  font-weight: 600;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 2rem;
  padding-left: 3rem;
`

const IconWrapper = styled.div`
  float: left;
  margin: auto 0;
  margin-top: 1.4rem;
  margin-left: -0.5rem;

  ${media.xs} {
    float: left;
    margin-top: 1.4rem;
    margin-left: -0.5rem;
  }

  ${media.sm} {
    margin: 0;
    float: left;
    margin-top: 1.4rem;
  }

  ${media.md} {
    float: left;
    margin-top: 1.4rem;
  }
`
const FileNameWrapper = styled.div`
  float: center;
  width: 100%;
  margin-left: -0.5rem;

  ${media.xs} {
    margin-left: -0.5rem;
  }

  ${media.sm} {
    margin-left: 0rem;
  }
`

const DownloadLink = styled.a`
  color: black;
  text-decoration: none;
  font-weight: 500;
`
