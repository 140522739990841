import {
  AccordionHeader,
  AccordionHeaderTitle,
  AccordionSummaryContainer,
  CookiesAccordion,
  CookiesAccordionDetail,
  CookiesAccordionDetailText,
  CookiesAccordionSummary,
  IOSNonToggle,
  IOSSwitch,
  MinusIcon,
  PlusIcon,
} from './CookiesAgreementStyles'

export interface IAccordionItem {
  title: string
  description: string
  purpose: string
  mutable: boolean
}

interface CookiesAccordionItemProps {
  data: IAccordionItem
  index: number
  isExpanded?: boolean
  onChange: (index: number) => void
  toggleChecked: () => void
  isChecked: boolean
  mutable: boolean
  checkboxRef?: any
}

export const CookiesAccordionItem = ({
  index,
  isExpanded,
  onChange,
  data,
  isChecked,
  mutable,
  toggleChecked,
  checkboxRef,
}: CookiesAccordionItemProps) => {
  return (
    <CookiesAccordion
      expanded={isExpanded}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
      }}
      onChange={() => onChange(index)}
    >
      <CookiesAccordionSummary
        expandIcon={<></>}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <AccordionSummaryContainer>
          <AccordionHeader>
            <div style={{ marginTop: 2 }}>
              {!isExpanded ? <PlusIcon /> : <MinusIcon />}
            </div>
            <AccordionHeaderTitle>{data.title}</AccordionHeaderTitle>
          </AccordionHeader>

          {mutable ? (
            <IOSSwitch
              innerRef={checkboxRef}
              checked={isChecked}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                toggleChecked()
                e.stopPropagation()
              }}
            />
          ) : (
            <IOSNonToggle>Immer Aktiv</IOSNonToggle>
          )}
        </AccordionSummaryContainer>
      </CookiesAccordionSummary>
      <CookiesAccordionDetail>
        <CookiesAccordionDetailText>
          {data.description}
        </CookiesAccordionDetailText>
        <CookiesAccordionDetailText>{data.purpose}</CookiesAccordionDetailText>
      </CookiesAccordionDetail>
    </CookiesAccordion>
  )
}
