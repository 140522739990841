import { media } from '../../helpers'
import theme from '../../themes/default'
import styled, { css } from 'styled-components'

interface ItemProps {
  backgroundColor?: keyof typeof theme.colors | false
  color?: keyof typeof theme.colors
  sizing?: keyof typeof theme.sections
  borderBottom?: keyof typeof theme.colors
}

const SectionWrapper = styled.section.attrs<ItemProps>((props) => ({
  backgroundColor: props.backgroundColor,
  color: props.color,
  borderBottom: props.borderBottom,
  sizing: props.sizing || 'normal',
}))<ItemProps>`
  position: relative;
  b {
    font-weight: 600;
  }

  ${({ theme, color }) =>
    color &&
    css`
      color: ${theme.colors[color]};
    `}

  ${({ theme, borderBottom }) =>
    borderBottom &&
    css`
      border-bottom: 1px solid ${theme.colors[borderBottom]};
    `}

    ${({ theme, backgroundColor, sizing }) =>
    backgroundColor &&
    css`
      background-color: ${theme.colors[backgroundColor]};

      padding: ${sizing && theme.sections[sizing].xs}rem 0;

      ${media.sm} {
        padding: ${sizing && theme.sections[sizing].sm}rem 0;
      }

      ${media.md} {
        padding: ${sizing && theme.sections[sizing].md}rem 0;
      }

      ${media.lg} {
        padding: ${sizing && theme.sections[sizing].lg}rem 0;
      }
    `}

    ${({ theme, backgroundColor, sizing }) =>
    !backgroundColor &&
    css`
      margin: ${sizing && theme.sections[sizing].xs}rem 0;

      ${media.sm} {
        margin: ${sizing && theme.sections[sizing].sm}rem 0;
      }

      ${media.md} {
        margin: ${sizing && theme.sections[sizing].md}rem 0;
      }

      ${media.lg} {
        margin: ${sizing && theme.sections[sizing].lg}rem 0;
      }
    `}
`

export default SectionWrapper
