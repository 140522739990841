import { media } from '../../helpers'
import theme from '../../themes/default'
import styled, { Breakpoints, Font, css } from 'styled-components'
import { IStyledComponent } from 'styled-components'
import { Substitute } from 'styled-components/dist/types'

interface TypeScaleProps {
  weight?: 'regular' | 'medium' | 'semibold' | 'bold'
  color?: keyof typeof theme.colors | string
  transform?: 'normal' | 'uppercase'
  fontStyle?: 'normal' | 'italic'
  visibleFrom?: keyof typeof theme.breakpoints
  visibleUntil?: keyof typeof theme.breakpoints
  uppercase?: boolean
  align?: 'left' | 'center' | 'right' | 'justify'
}

const typescales = theme.fonts.typescales

function pickBreakpointFontProperty<P extends keyof Font>(
  breakpoints: Breakpoints,
  propertyKey: P,
  breakpointKey: keyof Breakpoints,
) {
  const currentBreakpoint = breakpoints[breakpointKey]

  if (currentBreakpoint) {
    const property = currentBreakpoint[propertyKey]

    if (typeof property !== 'undefined') {
      return property
    }
  }

  return undefined
}

function printCSSProperty(propertyName: string, value: any, unit?: string) {
  if (typeof value === 'undefined') {
    return ''
  } else {
    return `${propertyName}: ${value}${unit ?? ''}`
  }
}

function printCSSProperties(
  properties: { propertyName: string; value: any; unit?: string }[],
) {
  const printedProperties = properties.map((prop) =>
    printCSSProperty(prop.propertyName, prop.value, prop.unit),
  )
  const filteredPrintedProperties = printedProperties.filter(
    (printedProperty) => !!printedProperty,
  )
  const cssStr = filteredPrintedProperties.join('; ')

  return cssStr
}

const SizeStyles = (
  Object.keys(typescales) as Array<keyof typeof typescales>
).reduce(
  (acc, label) => {
    const typescale = typescales[label]

    acc[label] = styled.span<TypeScaleProps>`
      font-size: ${typescale.null.fontSize}rem;
      letter-spacing: ${typescale.null.letterSpacing}em;
      line-height: ${typescale.null.lineHeight}em;
      font-weight: ${typescale.null.fontWeight};
      font-style: ${typescale.null.fontStyle};
      ${[
        'fatNumbers',
        'quote',
        'body1',
        'body2',
        'caption',
        'bodySmall',
        'footer',
      ].indexOf(label) > -1 &&
      css`
        ${media.xs} {
          ${printCSSProperties([
            {
              propertyName: 'font-size',
              value: pickBreakpointFontProperty(typescale, 'fontSize', 'xs'),
              unit: 'rem',
            },
            {
              propertyName: 'letter-spacing',
              value: pickBreakpointFontProperty(
                typescale,
                'letterSpacing',
                'xs',
              ),
              unit: 'em',
            },
            {
              propertyName: 'line-height',
              value: pickBreakpointFontProperty(typescale, 'lineHeight', 'xs'),
              unit: 'em',
            },
            {
              propertyName: 'font-weight',
              value: pickBreakpointFontProperty(typescale, 'fontWeight', 'xs'),
            },
          ])}
        }

        ${media.sm} {
          ${printCSSProperties([
            {
              propertyName: 'font-size',
              value: pickBreakpointFontProperty(typescale, 'fontSize', 'sm'),
              unit: 'rem',
            },
            {
              propertyName: 'letter-spacing',
              value: pickBreakpointFontProperty(
                typescale,
                'letterSpacing',
                'sm',
              ),
              unit: 'em',
            },
            {
              propertyName: 'line-height',
              value: pickBreakpointFontProperty(typescale, 'lineHeight', 'sm'),
              unit: 'em',
            },
            {
              propertyName: 'font-weight',
              value: pickBreakpointFontProperty(typescale, 'fontWeight', 'sm'),
            },
          ])}
        }

        ${media.md} {
          ${printCSSProperties([
            {
              propertyName: 'font-size',
              value: pickBreakpointFontProperty(typescale, 'fontSize', 'md'),
              unit: 'rem',
            },
            {
              propertyName: 'letter-spacing',
              value: pickBreakpointFontProperty(
                typescale,
                'letterSpacing',
                'md',
              ),
              unit: 'em',
            },
            {
              propertyName: 'line-height',
              value: pickBreakpointFontProperty(typescale, 'lineHeight', 'md'),
              unit: 'em',
            },
            {
              propertyName: 'font-weight',
              value: pickBreakpointFontProperty(typescale, 'fontWeight', 'md'),
            },
          ])}
        }

        ${media.lg} {
          ${printCSSProperties([
            {
              propertyName: 'font-size',
              value: pickBreakpointFontProperty(typescale, 'fontSize', 'lg'),
              unit: 'rem',
            },
            {
              propertyName: 'letter-spacing',
              value: pickBreakpointFontProperty(
                typescale,
                'letterSpacing',
                'lg',
              ),
              unit: 'em',
            },
            {
              propertyName: 'line-height',
              value: pickBreakpointFontProperty(typescale, 'lineHeight', 'lg'),
              unit: 'em',
            },
            {
              propertyName: 'font-weight',
              value: pickBreakpointFontProperty(typescale, 'fontWeight', 'lg'),
            },
          ])}
        }
      `}

      ${(props) =>
        props.uppercase &&
        `
            text-transform: uppercase;
          `}

          ${(props) =>
        props.align &&
        `
            text-align: ${props.align};
          `}
      
          ${({ weight }) =>
        typeof weight !== 'undefined' &&
        css`
          font-weight: ${theme.fonts.fontWeights[weight]};
        `}
      
          ${({ theme, color }) =>
        color &&
        css`
          color: ${color in theme.colors
            ? theme.colors[color as keyof typeof theme.colors]
            : color};
        `}

          ${({ transform }) =>
        transform &&
        css`
          text-transform: ${transform};
        `}

          ${({ fontStyle }) =>
        fontStyle &&
        css`
          font-style: ${fontStyle};
        `}

          ${({ visibleFrom }) =>
        visibleFrom &&
        css`
          display: none;

          ${media[visibleFrom]} {
            display: unset;
          }
        `}
      
          ${({ visibleUntil }) =>
        visibleUntil &&
        css`
          ${media[visibleUntil]} {
            display: none;
          }
        `}
    `
    acc[label].displayName = `Typescale.${label}`

    return acc
  },
  {} as Record<
    keyof typeof typescales,
    IStyledComponent<
      'web',
      Substitute<
        React.DetailedHTMLProps<
          React.HTMLAttributes<HTMLSpanElement>,
          HTMLSpanElement
        >,
        TypeScaleProps
      >
    >
  >,
)

export default SizeStyles
