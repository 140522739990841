import { Box } from '@material-ui/core'
import React from 'react'

interface SectionHeadProps {
  icon: JSX.Element
  title: string
  description: string
}

const SectionHead: React.FC<SectionHeadProps> = ({
  icon,
  title,
  description,
}) => {
  return (
    <Box mt="2.5rem" mb="1rem">
      <Box display="flex">
        <Box width="45%" borderTop="1px solid #D6D8D1" />
        <Box width="10%" mt="-0.5rem">
          {' '}
          {icon}
        </Box>
        <Box width="45%" borderTop="1px solid #D6D8D1" />
      </Box>
      <Box
        fontSize="0.875rem"
        fontWeight="600"
        style={{ textTransform: 'uppercase' }}
        mt="0.5rem"
      >
        {title}
      </Box>
      <Box fontSize="0.85rem" color="#666" marginY="0.75rem">
        {description}
      </Box>
    </Box>
  )
}

export default SectionHead
