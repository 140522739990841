import { Typography } from '@material-ui/core'
import { FormikProps } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import React, { useEffect, useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import styled from 'styled-components'
import useRegionCode from '@/helpers/useRegionCode'
import { media } from '@/style/helpers'
import { ECountry, ERegionCode } from '@/types/gql/graphql'
import { getTranslatedValidationError } from '@/utils/error'
import { InputErrorMessage } from './InputErrorMessage'
import { MuiTextField } from './MuiTextField'

const CountryFlagIcon = styled(ReactCountryFlag)`
  font-size: 18px;
  margin-right: 5px;
  margin-top: -3px;

  ${media.xs} {
    font-size: 20px;
    margin-right: 5px;
    margin-top: -3px;
  }
`

const PhonePrefixText = styled(Typography)`
  font-size: 0.9rem !important;
  width: 40px;
  font-weight: 600 !important;

  ${media.xs} {
    font-size: 1rem !important;
    font-weight: 600 !important;
    width: 40px;
  }
`

const InputErrorMessageStyled = styled(InputErrorMessage)<{
  customPrefix?: boolean
}>`
  margin-left: ${({ customPrefix }) => (customPrefix ? '75px' : '0px')};
`

const SmartInputStyle = styled.div<{ marginBottom?: string }>`
  width: 100%;
  margin-bottom: ${(props) => props.marginBottom ?? '10px'};
`

const InputPhoneContainer = styled.div`
  display: flex;
  align-items: center;
`

interface InputPhoneProps<T> {
  formikProps: FormikProps<Partial<T>>
  name: string
  placeHolder?: string
}

const CountryPhonePrefixMap: Record<ECountry.At | ECountry.De, string> = {
  [ECountry.At]: '+43',
  [ECountry.De]: '+49',
}

export function InputPhone<T>(props: InputPhoneProps<T>) {
  const { t } = useTranslation()

  const regionCode = useRegionCode()

  const [country, setCountry] = useState<
    ERegionCode.At | ERegionCode.De | null
  >(null)

  const field = props.formikProps.getFieldMeta(props.name)

  let error
  if (field.error && (field.touched || props.formikProps.submitCount > 0)) {
    error = field.error
  }

  useEffect(() => {
    if (regionCode === ERegionCode.At || regionCode === ERegionCode.De) {
      setCountry(regionCode)
    }
  }, [regionCode])

  const onChange = (event: React.ChangeEvent<HTMLElement>) => {
    const newValue = (event as React.ChangeEvent<HTMLInputElement>).target
      .value as string | null

    if (!newValue) {
      props.formikProps.getFieldHelpers(props.name).setValue('')
      setCountry(regionCode)
      return
    }

    if (newValue.startsWith('+')) {
      setCountry(null)
    } else {
      setCountry(regionCode)
    }

    props.formikProps.getFieldHelpers(props.name).setValue(newValue)
  }

  return (
    <SmartInputStyle>
      <InputPhoneContainer>
        {country && (
          <>
            <PhonePrefixText>{CountryPhonePrefixMap[country]}</PhonePrefixText>
            <CountryFlagIcon
              style={{ width: 40 }}
              countryCode={country}
              svg={true}
            />
          </>
        )}

        <div style={{ width: '100%' }}>
          <MuiTextField
            type={'text'}
            name={props.name}
            value={field.value as string | null}
            label={props.placeHolder ?? ''}
            error={error != undefined}
            onChange={onChange}
          />
        </div>
      </InputPhoneContainer>
      {error && (
        <InputErrorMessageStyled
          customPrefix={!country}
          message={getTranslatedValidationError(error, t)}
        />
      )}
    </SmartInputStyle>
  )
}
