import { useStoryblokConfig } from '../../context/storyblokConfigContext'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import Link from '@/app/common/components/Link/Link'
import StoryblokRichText from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import Text from '@/style/components/LegacyText'
import { SectionInner } from '@/style/components/Section'
import { media } from '@/style/helpers'
import FooterLogo from './FooterLogo'
import FaceBookLogo from './assets/FaceBookLogo.svg'
import InstagramLogo from './assets/InstagramLogo.svg'
import LinkedInLogo from './assets/LinkedInLogo.svg'
import SnapchatLogo from './assets/SnapchatLogo.svg'
import TikTopLogo from './assets/TikTopLogo.svg'
import XLogo from './assets/XLogo.svg'
import YouTubeLogo from './assets/YouTubeLogo.svg'

export default function Footer() {
  const router = useRouter()
  const {
    config: {
      footer_columns,
      disclaimer,
      tiktok,
      facebook,
      instagram,
      linkedin,
      youtube,
      snapchat,
      x,
      background_color,
      content_color,
    },
  } = useStoryblokConfig()

  const isFinalizeAccountSetup = router.pathname.includes(
    '/finalize-account-setup',
  )

  if (isFinalizeAccountSetup) {
    return
  }

  return (
    <Container backgroundColor={background_color?.color}>
      <SectionInner sizing="wide">
        <FooterColumns>
          {footer_columns?.map((item) => {
            if (item.component === 'footer-column') {
              return (
                <ColumnsInnerContainer key={item.key}>
                  <ColumnTitle color={content_color?.color}>
                    {item.column_heading}
                  </ColumnTitle>
                  {item.column?.map((col) => {
                    if (col.component === 'footer-text') {
                      return (
                        <RichTextContainer color={content_color?.color}>
                          <StoryblokRichText
                            key={col.key}
                            document={col.content}
                          />
                        </RichTextContainer>
                      )
                    } else if (col.component === 'footer-link') {
                      return (
                        <LinkContainer color={content_color?.color}>
                          <Link href={getStoryBlokLink(col?.footer_link)}>
                            {col.label}
                          </Link>
                        </LinkContainer>
                      )
                    }
                  })}
                </ColumnsInnerContainer>
              )
            }
            return null
          })}
        </FooterColumns>
        <FooterInfo>
          <CopyrightContainer>
            <RowContainer>
              <FooterLogo color={content_color?.color} />
              <LanguageSelector
                isInFooter
                footerContentColor={content_color?.color}
                footerBackgroundColor={background_color?.color}
              />
            </RowContainer>

            <CopyrightText color={content_color?.color}>
              {disclaimer}
            </CopyrightText>
          </CopyrightContainer>

          <SocialMediaWrapper>
            {facebook?.url && (
              <IconWrapper rel="noreferrer" href={facebook.url} target="_blank">
                <FaceBookLogo
                  width="1.5rem"
                  height="1.5rem"
                  fill={content_color?.color}
                />
              </IconWrapper>
            )}
            {linkedin?.url && (
              <IconWrapper rel="noreferrer" href={linkedin.url} target="_blank">
                <LinkedInLogo
                  width="1.5rem"
                  height="1.5rem"
                  fill={content_color?.color}
                />
              </IconWrapper>
            )}
            {instagram?.url && (
              <IconWrapper
                rel="noreferrer"
                href={instagram.url}
                target="_blank"
              >
                <InstagramLogo
                  width="1.5rem"
                  height="1.5rem"
                  fill={content_color?.color}
                />
              </IconWrapper>
            )}
            {tiktok?.url && (
              <IconWrapper rel="noreferrer" href={tiktok.url} target="_blank">
                <TikTopLogo
                  width="1.5rem"
                  height="1.5rem"
                  fill={content_color?.color}
                />
              </IconWrapper>
            )}
            {youtube?.url && (
              <IconWrapper rel="noreferrer" href={youtube.url} target="_blank">
                <YouTubeLogo
                  width="1.5rem"
                  height="1.5rem"
                  fill={content_color?.color}
                />
              </IconWrapper>
            )}
            {snapchat?.url && (
              <IconWrapper rel="noreferrer" href={snapchat.url} target="_blank">
                <SnapchatLogo
                  width="1.5rem"
                  height="1.5rem"
                  fill={content_color?.color}
                />
              </IconWrapper>
            )}
            {x?.url && (
              <IconWrapper rel="noreferrer" href={x.url} target="_blank">
                <XLogo
                  width="1.5rem"
                  height="1.5rem"
                  fill={content_color?.color}
                />
              </IconWrapper>
            )}
          </SocialMediaWrapper>
        </FooterInfo>
      </SectionInner>
    </Container>
  )
}

const Container = styled.footer<{ backgroundColor: string | undefined }>`
  width: 100%;
  position: relative;
  overflow: hidden;

  b {
    font-weight: 600;
  }

  ${(props) =>
    props.backgroundColor && `background-color: ${props.backgroundColor};`}
  padding: 2.5rem 0;
`

const FooterColumns = styled.div`
  display: flex;
  margin-bottom: 2.5rem;
  flex-direction: column;
  ${media.md} {
    flex-direction: row;
  }
  flex-wrap: wrap;
`

const ColumnsInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 1.5rem;
  margin-bottom: 2rem;
  &:last-child {
    margin-right: 0;
  }
  ${media.md} {
    margin-bottom: 0;
  }
`
const ColumnTitle = styled(Text.lg)<{ color: string | undefined }>`
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 0.75rem;
  ${(props) => props.color && `color: ${props.color};`}
`

const LinkContainer = styled.div<{ color: string | undefined }>`
  padding-bottom: 0.5rem;
  & a {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-decoration: none;
    ${(props) => props.color && `color: ${props.color};`}

    &:hover {
      text-decoration: underline;
    }
  }
`

const RichTextContainer = styled.div<{ color: string | undefined }>`
  & p {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    padding-bottom: 0.5rem;
    ${(props) => props.color && `color: ${props.color};`}
  }

  & > p:first-of-type {
    margin-top: 0 !important;
  }

  & > p:last-of-type {
    margin-bottom: 0 !important;
  }

  & a {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-decoration: none;
    padding-bottom: 0.5rem;
    ${(props) => props.color && `color: ${props.color};`}
  }
`

const FooterInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${media.md} {
    flex-direction: row;
  }
`
const IconWrapper = styled.a`
  color: white;
  margin-right: 0.5rem;
  &:last-child {
    margin-right: 0;
  }
`

const SocialMediaWrapper = styled.div`
  display: flex;
  align-items: center;
`

const CopyrightContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 2rem;

  ${media.md} {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0rem;
  }
`
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CopyrightText = styled(Text.sm)<{ color: string | undefined }>`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 0.875rem */
  margin-left: 0rem;
  margin-top: 2rem;
  ${(props) => props.color && `color: ${props.color};`}

  ${media.md} {
    margin-left: 2rem;
    margin-top: 0rem;
  }
`
