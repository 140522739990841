import { useImageSizes, useSectionAnchorId } from '../Section/Section'
import { storyblokEditable } from '@storyblok/react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { ObjectListFormItem } from '@/domains/items/components/ObjectList'
import {
  getNumberItemsPerRow,
  getSizeOfSectionInner,
} from '@/domains/items/components/ObjectList/ObjectList'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import { NewProductSelectorStoryblok } from '@/types/storyblok-component-types'
import ProductSelectorMainProducts from './ProductSelectorMainProducts'
import ProductSelectorOtherProducts from './ProductSelectorOtherProducts'

interface NewProductSelectorProps {
  blok: NewProductSelectorStoryblok
}

export default function NewProductSelector({ blok }: NewProductSelectorProps) {
  const { t } = useTranslation()
  const router = useRouter()
  const showOtherProducts = router.query.other_products === '1'

  const pageIndex =
    typeof router.query.page === 'string'
      ? Math.max(Number.parseInt(router.query.page) - 1, 0)
      : 0
  const pageSize = Number.parseInt(blok.page_size)

  const sectionAnchorId = useSectionAnchorId()

  const handlePageIndexChange = (pageIndex: number) => {
    router.push({
      query: {
        ...router.query,
        page: pageIndex,
      },
      hash: sectionAnchorId, // scroll to top of list
    })
  }

  const imageSizes = useImageSizes(100 / getNumberItemsPerRow(pageSize), [
    'md',
    '38vw',
  ])

  const missingProductLink =
    blok.missing_product_link && getStoryBlokLink(blok.missing_product_link)

  const renderMissingProductLink = missingProductLink
    ? () => (
        <ObjectListFormItem
          title={t('common:found_nothing_appropriate')}
          prompt={t('common:contact_us')}
          href={missingProductLink}
        />
      )
    : undefined

  return (
    <ProductSelectorWrapper
      sizing={getSizeOfSectionInner(pageSize)}
      {...storyblokEditable(blok)}
    >
      {!showOtherProducts ? (
        <ProductSelectorMainProducts
          productIds={blok.products}
          categoryIds={blok.categories ?? []}
          fallbackImage={blok.product_image_fallback}
          imageSizes={imageSizes}
          renderMissingProductLink={renderMissingProductLink}
          pageSize={pageSize}
          pageIndex={pageIndex}
          onChangePageIndex={handlePageIndexChange}
        />
      ) : (
        <ProductSelectorOtherProducts
          categoryIds={blok.categories ?? []}
          excludedProductIds={blok.products}
          fallbackImage={blok.product_image_fallback}
          imageSizes={imageSizes}
          renderMissingProductLink={renderMissingProductLink}
          pageSize={pageSize}
          pageIndex={pageIndex}
          onChangePageIndex={handlePageIndexChange}
        />
      )}
    </ProductSelectorWrapper>
  )
}

const ProductSelectorWrapper = styled.div.attrs<{
  sizing: 'wide' | 'near-wide'
}>((props) => ({
  sizing: props.sizing,
}))<{ sizing: 'wide' | 'near-wide' }>`
  margin: 0 auto;
  position: relative;
  max-width: ${({ sizing }) => (sizing === 'wide' ? undefined : '1200px')};
`
