import { useImageSizes } from '../Section/Section'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { FAQPageJsonLd } from 'next-seo'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { Accordeon } from '@/style/components/Accordeon'
import { media } from '@/style/helpers'
import {
  FaqStoryblok,
  RichtextStoryblok,
} from '@/types/storyblok-component-types'

export default function FAQ({ blok }: { blok: FaqStoryblok }) {
  const sizes = useImageSizes(41, ['md', '1px'])

  const faqSchema = useMemo(() => {
    return blok.items?.map((item) => ({
      questionName: extractText(item.question),
      acceptedAnswerText: extractText(item.answer),
    }))
  }, [blok.items])

  return (
    <>
      <FAQPageJsonLd mainEntity={faqSchema} />
      <Container {...storyblokEditable(blok)}>
        <LeftPanel>
          <RichTextParagraphStyles>
            <StoryblokRichText document={blok.title} />
          </RichTextParagraphStyles>

          {blok.image.filename && (
            <ImageWrapper>
              <StoryblokImage sizes={sizes} image={blok.image} />
            </ImageWrapper>
          )}
        </LeftPanel>

        <RightPanel>
          <Accordeon seamless>
            {blok.items?.map((item) => (
              <StoryblokComponent blok={item} key={item._uid} />
            ))}
          </Accordeon>
        </RightPanel>
      </Container>
    </>
  )
}

const extractText = (richText?: RichtextStoryblok): string => {
  if (!richText || !richText.content) return ''

  const iterateContent = (content: RichtextStoryblok[]): string[] => {
    return content.map((node: RichtextStoryblok): string => {
      if (node.type === 'text' && node.text) {
        return node.text.replaceAll('"', '\\"')
      } else if (node.type === 'list_item' && node.content) {
        return '<li>' + iterateContent(node.content).join('') + '</li>'
      } else if (node.type === 'ordered_list' && node.content) {
        return '<ol>' + iterateContent(node.content).join('') + '</ol>'
      } else if (node.type === 'bullet_list' && node.content) {
        return '<ul>' + iterateContent(node.content).join('') + '</ul>'
      } else if (node.type === 'hard_break') {
        return '<br>'
      } else if (node.content) {
        return iterateContent(node.content).join('')
      }
      return ''
    })
  }

  return iterateContent(richText.content).join('')
}

const ImageWrapper = styled.div`
  display: none;

  ${media.md} {
    display: block;
    max-width: 30rem;
    margin-top: 3rem;
  }
`

const Container = styled.div`
  width: 100%;
  b {
    font-weight: 600;
  }

  display: flex;
  flex-direction: column;

  p {
    letter-spacing: 0em;
    line-height: 1.4;
  }

  ${media.md} {
    flex-direction: row;
  }
`

const LeftPanel = styled.div`
  flex: 1 0 0px;
  ${media.md} {
    flex: 5 0 0px;
  }
`

const RightPanel = styled.div`
  flex: 1 0 0px;
  ${media.md} {
    flex: 7 0 0px;
  }
`
