import * as MaterialPagination from '@material-ui/lab/Pagination'
import { PaginationProps } from '@material-ui/lab/Pagination'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { media } from '@/style/helpers'

const StyledPagination = styled(MaterialPagination.default)`
  margin-top: 3rem;
  display: flex;
  width: 100%;
  justify-content: space-around;

  button {
    border-radius: 5px;
    border: 2px solid #dfe1e5;
    width: 33px;
    height: 33px;
    font-weight: bold;
    margin: 0 0.2rem;

    ${media.xs} {
      width: 37px;
      height: 37px;
    }

    ${media.sm} {
      width: 44px;
      height: 44px;
      margin: 0 0.5rem;
    }
  }
`

export const Pagination: FunctionComponent<PaginationProps> = (props) => {
  if (!props.count || props.count <= 1) {
    return null
  } else {
    return <StyledPagination {...props} />
  }
}
