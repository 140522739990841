import { CustomDealFormData } from '../ItemList'
import InfoIcon from '../assets/ico_info.svg'
import { FieldValidator, FormikProps } from 'formik'
import React, { useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { SmartTextInput } from '@/app/common/components/Form'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import { media } from '@/style/helpers'

interface Props {
  itemIndex: number
  questionIndex: number
  formikProps: FormikProps<CustomDealFormData>
  name: string
  type?: string
  multiline?: boolean
  validate?: FieldValidator
  label: string
  info: string
  onTouched: (questionIndex: number) => void
}

export const TextQuestion = ({
  formikProps,
  itemIndex,
  multiline,
  label,
  name,
  type,
  info,
  questionIndex,
  validate,
  onTouched,
}: Props) => {
  const modal = useContext(ModalDialogContext)
  const field = formikProps.getFieldMeta(name)
  const ref = useRef({ touched: false })

  useEffect(() => {
    if (!ref.current.touched && field.touched) {
      ref.current.touched = true
      onTouched(questionIndex)
    }

    return () => {
      if (!field.touched) {
        // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ref.current.touched = false
      }
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.touched, questionIndex])

  const onClickInfo = () => {
    modal.open(<InfoWrapper>{info}</InfoWrapper>, {
      customStyles: {
        content: {
          display: 'inline-block',
          maxWidth: '80%',
        },
      },
    })
  }

  return (
    <Wrapper>
      <SmartTextInput
        id={`CUSTOM_DEAL_ITEM_${itemIndex}_QUESTION_${questionIndex}_TEXT_INPUT`}
        formikProps={formikProps}
        name={name}
        type={type || 'text'}
        placeholder={label}
        multiline={multiline}
        rows={multiline ? 4 : undefined}
        validate={validate}
        marginBottom="0"
        defaultEmptyValue
      />
      {info && <Info height="24" width="24" onClick={onClickInfo} />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  position: relative;
  margin: 1.5rem 0;

  svg {
    flex-shrink: none;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
  }
`

const InfoWrapper = styled.div`
  padding: 2rem;
  width: 60vw;

  ${media.sm} {
    width: 50vw;
  }
  ${media.md} {
    width: 40vw;
  }
  ${media.lg} {
    width: 30vw;
  }
`

const Info = styled(InfoIcon)`
  position: absolute;
  right: 1rem;

  ${media.md} {
    right: -2rem;
  }
`
