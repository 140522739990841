import { Dialog } from '@material-ui/core'
import { UppyFile } from '@uppy/core'
import {
  FieldArray,
  Form,
  FormikProvider,
  setNestedObjectValues,
  useFormik,
} from 'formik'
import { sumBy, uniq } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'
import { customerState } from '@/app/auth/auth.state'
import { ClientOnly } from '@/app/common/components/ClientOnly'
import { CommissionDealValues } from '@/app/common/components/DealValues/CommissionValues'
import { PayoutDealValues } from '@/app/common/components/DealValues/PayoutDealValues'
import { FormikOnChange, SmartTextInput } from '@/app/common/components/Form'
import WhatsAppSection from '@/app/common/components/WhatsAppSection/WhatsAppSection'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import { useQuery } from '@/app/common/graphql/hooks'
import config, { buildApiUri } from '@/config'
import * as tracking from '@/helpers/tracking'
import useCompany from '@/helpers/useCompany'
import { useDebouncedCallback } from '@/helpers/useDebouncedCallback'
import useRegionCode from '@/helpers/useRegionCode'
import { Button, ButtonGroup } from '@/style/components/Button'
import { ErrorBox } from '@/style/components/ErrorBox/ErrorBox'
import RadioButton from '@/style/components/RadioButton'
import Text from '@/style/components/Text'
import { media } from '@/style/helpers'
import { hoverShadowBox } from '@/style/partials'
import { SelectedItemsType } from '@/types'
import {
  CustomDealCalculation,
  CustomDealContactData,
  CustomDealItemCreateArgs,
  CustomDealQuestionNumberRestrictionArgs,
  DealPawnDurationsDocument,
  DealPawnDurationsQuery,
  DealPawnDurationsQueryVariables,
  ECustomDealFormQuestionType,
  EDealType,
  ERegionCode,
  GetItemCategoriesDocument,
  GetItemCategoriesQuery,
  GetItemCategoriesQueryVariables,
  ItemCategory,
} from '@/types/gql/graphql'
import { checkValidNumberRestriction } from './AdditionQuestions/FormQuestions'
import { CustomDealItem } from './CustomDealItem'
import CustomDealSuccessModal from './CustomDealSuccessModal'
import {
  FormAnswerData,
  PRICE_QUESTION_TITLE_KEY,
  useCustomDealCalculation,
} from './customDeal.service'
import { useCreateCustomDeal } from './useCreateCustomDeal'

interface CustomDealFormProps {
  isCommissionSale: boolean
  onIsCommissionSaleChange: (isCommissionSale: boolean) => void
  initialCategoryId?: string
  onCustomDealCalculated: (
    customDealCalculation?:
      | {
          pawn?: CustomDealCalculation
          purchase?: CustomDealCalculation
        }
      | undefined,
  ) => void
  onIsLoadingChange: (value: boolean) => void
  onCustomItemTitlesChange: (value: string[]) => void
  onDealTypeChange: (value: EDealType) => void
  isSimplifiedPawnDeal: boolean
  dealType: EDealType
  dealCalculation?: CustomDealCalculation
}

export interface CustomDealFormData {
  productName: string // it is used as company_name in case of life insurance pawn
  itemCategoryId: string | undefined
  contactData: CustomDealContactData
  items: CustomDealItemFormData[]
  editedItemIndex: number | null
  deletedItemIndex: number | null
  newItemIndex: number | null
  durationInDays: number
}

interface CustomDealItemFormData extends Partial<CustomDealItemCreateArgs> {
  key: string
  payoutAmount: number
  formAnswers: FormAnswerData[]
  uploadedFiles: Array<UppyFile>
  isAllowedPurchase?: boolean
}

const getDefaultItem = (categoryId?: string): CustomDealItemFormData => ({
  key: uuidv4(),
  title: '',
  itemCategoryId: categoryId,
  payoutAmount: 0,
  uploadedFiles: [],
  formAnswers: [],
})

const getCustomDealItemArgs = (
  items: CustomDealItemFormData[],
): CustomDealItemCreateArgs[] => {
  const tusUrl = buildApiUri(
    window.location.host,
    'http(s)',
    `${config.tusRoute}/uploads/`,
  )
  return items.map((item) => ({
    policeNumber: item.policeNumber ?? '',
    itemCategoryId: item.itemCategoryId ?? '',
    condition: item.condition,
    pawnPayoutAmount: item.payoutAmount ?? 0,
    purchasePayoutAmount: item.payoutAmount ?? 0,
    title: item.title ?? '',
    note: item.note ?? '',
    mediaUploads:
      item.uploadedFiles?.map((file) => ({
        fileId: file.response?.uploadURL?.replace(tusUrl, '') ?? '',
      })) ?? [],
    answers: [],
    formAnswers:
      item.formAnswers?.filter(
        ({ question: { titleKey } }) => titleKey !== PRICE_QUESTION_TITLE_KEY,
      ) ?? [],
  }))
}

const lifeInsuranceCategoryName = 'Lebensversicherung'

const validationSchema = Yup.object().shape({
  contactData: Yup.object().shape({
    email: Yup.string().email().required(),
    phone: Yup.string().required(),
  }),
  items: Yup.array().of(
    Yup.object().shape({
      title: Yup.string(),
      itemCategoryId: Yup.string().required(),
      payoutAmount: Yup.number().required(),
      uploadedFiles: Yup.array(
        Yup.object({
          response: Yup.object({
            uploadURL: Yup.string().required('customDeal:images_uploading'),
          }),
        }),
      ).min(1, 'customDeal:images_field_must_have_at_least_1_items'),
      formAnswers: Yup.array().of(
        Yup.object().shape({
          question: Yup.object().required(),
          numberAnswer: Yup.number().nullable(),
          textAnswer: Yup.string().nullable(),
          textMultilineAnswer: Yup.string().nullable(),
          booleanAnswer: Yup.boolean().nullable(),
          optionNumberAnswer: Yup.number().nullable(),
          optionTextAnswer: Yup.string().nullable(),
        }),
      ),
    }),
  ),
})

export const ItemList: React.FC<CustomDealFormProps> = ({
  isCommissionSale,
  onIsCommissionSaleChange,
  dealType,
  dealCalculation,
  initialCategoryId,
  onCustomDealCalculated,
  onDealTypeChange,
  onCustomItemTitlesChange,
  onIsLoadingChange,
  isSimplifiedPawnDeal,
}) => {
  const { t } = useTranslation()

  const [customer] = useRecoilState(customerState)
  const modal = useContext(ModalDialogContext)
  const router = useRouter()
  const regionCode = useRegionCode()
  const { createCustomDeal } = useCreateCustomDeal()

  const initialItem = useMemo(
    () => getDefaultItem(initialCategoryId),
    [initialCategoryId],
  )

  const [loanDurationError, setLoanDurationError] = useState('')
  const [dirty, setDirty] = useState(false)

  const company = useCompany()

  const itemCategoriesRes = useQuery<
    GetItemCategoriesQuery,
    GetItemCategoriesQueryVariables
  >(GetItemCategoriesDocument, {
    enableCaching: true,
  })

  const itemCategories: Pick<ItemCategory, 'parentId' | 'name' | '_id'>[] =
    itemCategoriesRes.data?.getItemCategories || []

  const {
    queryCustomDealCalculation,
    customDealCalculation,
    setCustomDealCalculation,
    customDealCalculationLoading,
  } = useCustomDealCalculation()

  const pawnPayoutAmount =
    customDealCalculation?.pawn?.dealValuesEntry.payoutAmount
  const purchasePayoutAmount =
    customDealCalculation?.purchase?.dealValuesEntry.payoutAmount

  const topLevelCategories =
    itemCategories?.filter(
      (category) =>
        !category.parentId &&
        category.name !== 'car' &&
        category.name !== 'Motorrad',
    ) ?? []

  const onSubmit = async (values: CustomDealFormData) => {
    if (!company || !pawnPayoutAmount || !purchasePayoutAmount) return

    onIsLoadingChange(true)

    tracking.user(
      {
        email: values.contactData.email || null,
        phone: values.contactData.phone || null,
      },
      regionCode,
    )

    createCustomDeal({
      variables: {
        customDealCreateArgs: {
          companyId: company._id,
          items: getCustomDealItemArgs(values.items),
          contactData: values.contactData,
          customerId: customer?._id,
          dealType,
          durationInDays:
            dealType === EDealType.Pawn ? values.durationInDays : 0,
        },
      },
    })
      .then((result) => {
        if (result.data) {
          tracking.trackEvent('custom_deal_request', {
            transaction_id: result.data.createCustomDeal._id,
            value: sumBy(values.items, (i) => i.payoutAmount),
            type: isCommissionSale
              ? 'commission'
              : dealType === EDealType.Pawn
                ? 'pawn'
                : 'purchase',
          })

          tracking.trackEvent('deal_booked', {
            transaction_id: `booking-${result.data.createCustomDeal._id}`,
          })

          modal.open(<CustomDealSuccessModal />, {
            variant: 'full',
            onAfterClose: () => {
              router.push('/')
            },
          })
        }

        return result
      })
      .finally(() => {
        onIsLoadingChange(false)
      })
  }

  const formik = useFormik<CustomDealFormData>({
    validateOnMount: true,
    initialValues: {
      productName: '',
      itemCategoryId: initialCategoryId ?? '',
      contactData: {
        email: customer?.email ?? '',
        phone: customer?.phone ?? '',
      },
      items: [initialItem],
      editedItemIndex: null,
      deletedItemIndex: null,
      newItemIndex: null,
      durationInDays:
        dealType === EDealType.Pawn
          ? company?.configuration.minimumPawnDuration ?? 30
          : 0,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  })

  const { values, setFieldValue, errors, setTouched, isSubmitting } = formik

  const isAllowedPurchase = values.items.every(
    (item) => !!item.isAllowedPurchase,
  )

  const lifeInsuranceCategoryId = topLevelCategories
    .map((c) => c.name)
    .includes(lifeInsuranceCategoryName)
    ? topLevelCategories
        ?.filter((c) => c.name === lifeInsuranceCategoryName)[0]
        ._id.toString()
    : ''

  const isHavingLifeInsuranceItem = values.items.some(
    (item) => item.itemCategoryId?.toString() === lifeInsuranceCategoryId,
  )

  const purchaseDeal = {
    type: EDealType.Purchase,
    titleTranslationKey: 'common:selling',
    noteTranslationKey: 'common:you_sell_your_item',
  }

  const pawnDeal = {
    type: EDealType.Pawn,
    titleTranslationKey: 'common:pawn',
    noteTranslationKey:
      regionCode === ERegionCode.De
        ? 'common:you_pawn_your_item'
        : 'common:you_pawn_your_item_for_days',
  }

  const onChange = useCallback(
    (values: CustomDealFormData) => {
      const validItems = values.items.filter(
        (item) => item.itemCategoryId && validateCustomItemAmount(item),
      )

      if (validItems.length && company) {
        queryCustomDealCalculation(company, validItems, values.durationInDays)
        onCustomItemTitlesChange(validItems.map((item) => item.title ?? ''))
      } else {
        setCustomDealCalculation(undefined)
        onCustomItemTitlesChange([])
      }
    },
    [
      company,
      setCustomDealCalculation,
      queryCustomDealCalculation,
      onCustomItemTitlesChange,
    ],
  )

  const [onChangeDebounced] = useDebouncedCallback(
    (values: CustomDealFormData) => onChange(values),
    250,
    [onChange],
  )

  useEffect(() => {
    onCustomDealCalculated(customDealCalculation)
  }, [customDealCalculation, onCustomDealCalculated])

  const dealItems: Omit<SelectedItemsType, 'answers' | 'itemCategory'>[] = [
    {
      title: values.productName,
      itemCategoryId: values.itemCategoryId,
    },
  ]

  const { data: pawnDurationsData } = useQuery<
    DealPawnDurationsQuery,
    DealPawnDurationsQueryVariables
  >(DealPawnDurationsDocument, {
    variables: {
      args: {
        itemCategoryIds: uniq(
          values.items.map((item) => item.itemCategoryId).filter(Boolean),
        ),
        companyId: company?._id,
      },
    },
    skip: !company?._id || dealType !== EDealType.Pawn,
    onCompleted: (data) => {
      if (
        data?.dealPawnDurations &&
        !data.dealPawnDurations.includes(values.durationInDays) &&
        data.dealPawnDurations[0]
      ) {
        setFieldValue('durationInDays', data.dealPawnDurations[0])
        setLoanDurationError(data.dealPawnDurations.join(', '))
      } else {
        setLoanDurationError('')
      }
    },
  })

  const { email, phone } = customer ?? {}
  useEffect(() => {
    setFieldValue('contactData', {
      email: email ?? '',
      phone: phone ?? '',
    })
  }, [email, phone, setFieldValue])

  const onEditItemIndex = (index: number) =>
    setFieldValue('editedItemIndex', index)

  const onConfirmDeleteItem = (index: number) =>
    setFieldValue('deletedItemIndex', index)

  const checkIsInvalidItemAt = (index: number) => {
    setTouched(setNestedObjectValues(errors, true))
    return Object.keys(errors?.items?.[index] ?? {}).length > 0
  }

  const onSaveItem = async () => {
    if (
      values.editedItemIndex === null ||
      checkIsInvalidItemAt(values.editedItemIndex)
    ) {
      return
    }
    setFieldValue('editedItemIndex', null)
    setFieldValue('newItemIndex', null)
  }

  const onAddNewItem = (push: (item: CustomDealItemFormData) => void) => {
    const { length } = values.items
    push(getDefaultItem())
    const index = length
    setFieldValue('editedItemIndex', index)
    setFieldValue('newItemIndex', index)
  }

  const onRemoveItem = (
    remove: (index: number) => void,
    index: number | null,
  ) => {
    if (index === null) return
    remove(index)
    setFieldValue('editedItemIndex', null)
    setFieldValue('deletedItemIndex', null)
  }

  const shouldShowAddAnotherItemButton =
    !isCommissionSale &&
    (values.editedItemIndex === null || values.items.length === 1)

  const missingInputFields = useMemo(() => {
    const missingFields: string[] = []
    const { items } = formik.values

    if (errors.contactData?.email) missingFields.push(t('customDeal:email'))
    if (errors.contactData?.phone) missingFields.push(t('customDeal:telephone'))

    if (Array.isArray(errors.items)) {
      for (let i = 0; i < errors.items.length; i++) {
        const itemErrors = errors.items[i]
        if (typeof itemErrors === 'string') continue

        if (itemErrors?.uploadedFiles)
          missingFields.push(t('customDeal:photos'))

        if (Array.isArray(itemErrors?.formAnswers)) {
          for (let j = 0; j < itemErrors.formAnswers.length; j++) {
            const questionError = itemErrors.formAnswers[j]
            if (!questionError) continue

            const question = items[i]?.formAnswers?.[j]?.question

            if (!question) continue

            if (question.titleKey === PRICE_QUESTION_TITLE_KEY) {
              missingFields.push(t('customDeal:prices'))
              continue
            }

            const label = question.label?.[0]?.text

            if (label) missingFields.push(label)
          }
        }
      }
    }

    return uniq(missingFields)
  }, [errors, t, formik.values])

  const onClickSubmit = () => {
    if (!dirty) setDirty(true)
    setTimeout(() => {
      document
        .querySelector(`[data-info="error"]`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 0)
  }

  return (
    <Wrapper>
      <FormikProvider value={formik}>
        <Form>
          <FormikOnChange
            delay={500}
            onChange={async (values) => {
              onChangeDebounced(values)
            }}
          />
          <ClientOnly>
            {!customer && (
              <>
                <SmartTextInput
                  formikProps={formik}
                  type="email"
                  name="contactData.email"
                  placeholder={t('customDeal:email')}
                  marginBottom="1rem"
                />
                <SmartTextInput
                  formikProps={formik}
                  type="text"
                  name="contactData.phone"
                  placeholder={t('customDeal:telephone')}
                  marginBottom="1rem"
                />
              </>
            )}
          </ClientOnly>
          <FieldArray name="items">
            {({ remove, push }) => (
              <div>
                {values.items.map((item, index) => (
                  <CustomDealItem
                    isCommissionSale={isCommissionSale}
                    onIsCommissionSaleChange={onIsCommissionSaleChange}
                    key={item.key}
                    index={index}
                    item={item}
                    formikProps={formik}
                    topLevelCategories={topLevelCategories}
                    onRemoveItem={() => onConfirmDeleteItem(index)}
                    numberOfItems={values.items.length}
                    isEdited={values.editedItemIndex === index}
                    isNewItem={values.newItemIndex === index}
                    isError={!!errors?.items?.[index]}
                    onEditItemIndex={onEditItemIndex}
                    onSaveItem={onSaveItem}
                    allowOpenCategorySelection={!!initialCategoryId}
                  />
                ))}

                {shouldShowAddAnotherItemButton && (
                  <AddAnotherItemButton
                    type="button"
                    onClick={() => onAddNewItem(push)}
                  >
                    {t('customDeal:add_item_label')}
                  </AddAnotherItemButton>
                )}

                <DialogStyled open={values.deletedItemIndex !== null}>
                  <DialogContentStyled>
                    <DialogTitle as="p" weight="semibold" uppercase>
                      {t('customDeal:deleteModal.title')}
                    </DialogTitle>
                    <Text.md as="p">
                      {t('customDeal:deleteModal.message', {
                        name:
                          values.items[values.deletedItemIndex as number]
                            ?.title ?? '',
                      })}
                    </Text.md>
                    <ConfirmButton
                      type="button"
                      full
                      onClick={() =>
                        onRemoveItem(remove, values.deletedItemIndex)
                      }
                    >
                      {t('common:remove')}
                    </ConfirmButton>
                    <CancelButton
                      onClick={() => setFieldValue('deletedItemIndex', null)}
                    >
                      {t('common:cancel')}
                    </CancelButton>
                  </DialogContentStyled>
                </DialogStyled>
              </div>
            )}
          </FieldArray>
          {isAllowedPurchase &&
            !isSimplifiedPawnDeal &&
            !isCommissionSale &&
            renderDealTypeRadioGroup()}
          {!isCommissionSale &&
            pawnDurationsData?.dealPawnDurations &&
            pawnDurationsData.dealPawnDurations.length > 1 && (
              <>
                <LoanDurationTitle weight="semibold" as="p" uppercase>
                  {t('common:pick_loan_duration')}
                </LoanDurationTitle>
                <ButtonGroup>
                  {dealType === EDealType.Pawn &&
                    pawnDurationsData.dealPawnDurations.map((duration) => (
                      <Button
                        type="button"
                        id={`CUSTOM_DEAL_DURATION_${duration}_DAYS`}
                        key={duration}
                        appearance={
                          duration === values.durationInDays
                            ? 'primary-outline'
                            : 'whitebg'
                        }
                        onClick={() =>
                          setFieldValue('durationInDays', duration)
                        }
                        style={{ padding: '0.75rem' }}
                      >
                        {duration} {t('common:days')}
                      </Button>
                    ))}
                </ButtonGroup>
              </>
            )}
          {loanDurationError && (
            <ErrorBox
              message={t('common:pick_loan_duration_error').replace(
                '{{value}}',
                loanDurationError,
              )}
            />
          )}
          <DealValuesTableWrapper>
            {!isCommissionSale ? (
              <PayoutDealValues
                dealValuesEntry={dealCalculation?.dealValuesEntry}
                appliedUsedFeeDefinitions={
                  dealCalculation?.appliedUsedFeeDefinitions
                }
                dealItems={dealItems}
                dealType={dealType}
              />
            ) : (
              <CommissionDealValues />
            )}
          </DealValuesTableWrapper>
          <SubmitButton
            primary
            full
            id="CUSTOM_DEAL_SUBMIT_BUTTON"
            type="submit"
            disabled={isSubmitting || customDealCalculationLoading || !company}
            onClick={onClickSubmit}
          >
            {t('common:send')}
          </SubmitButton>
          {dirty && !!missingInputFields.length && (
            <Text.caption
              as="div"
              color="red"
              style={{ marginTop: '0.5rem', textAlign: 'center' }}
            >
              {t('customDeal:missing_input_fields', {
                missingFields: missingInputFields,
              })}
            </Text.caption>
          )}
        </Form>
      </FormikProvider>
      {isAllowedPurchase && isSimplifiedPawnDeal && !isCommissionSale && (
        <SwitchToPurchaseSection>
          {t('common:would_you_rather_sell')}
          <SwitchToPurchaseButton
            onClick={() => {
              onDealTypeChange(EDealType.Purchase)
            }}
          >
            {t('common:switch_to_purchase')}
          </SwitchToPurchaseButton>
        </SwitchToPurchaseSection>
      )}
      <WhatsAppSection />
    </Wrapper>
  )

  function renderDealTypeRadioGroup() {
    return (
      <RadioGroup>
        {[purchaseDeal, pawnDeal].map((type) => {
          return (
            <RadioWrapper
              hidden={
                isHavingLifeInsuranceItem && type.type === EDealType.Purchase
              }
              key={type.type}
            >
              <Label>
                <span>
                  <Text.md as="h2" weight="semibold" color="inherit">
                    {t(type.titleTranslationKey)}
                  </Text.md>

                  <Radio
                    name="dealType"
                    id={`CUSTOM_DEAL_${type.type.toUpperCase()}_OPTION`}
                    checked={
                      isHavingLifeInsuranceItem && type.type === EDealType.Pawn
                        ? true
                        : type.type === dealType
                    }
                    value={type.type}
                    onChange={() => {
                      onDealTypeChange(type.type)
                    }}
                  />
                  <h3>
                    {t(type.noteTranslationKey, {
                      durationInDays: values.durationInDays ?? ' ',
                    })}
                  </h3>
                </span>
              </Label>
            </RadioWrapper>
          )
        })}
      </RadioGroup>
    )
  }
}

const DialogStyled = styled(Dialog)``

const DialogContentStyled = styled.div`
  max-width: 320px;
  padding: 2rem 1.5rem;
  text-align: center;
`

const ConfirmButton = styled(Button)`
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  padding: 6px 14px;
  border: 2px solid #000000;
`

const CancelButton = styled.div`
  text-decoration: underline;
  font-size: 0.875rem;
  cursor: pointer;
  text-align: center;
`

const DialogTitle = styled(Text.md)`
  margin-bottom: 0.5rem;
`

const Wrapper = styled.div`
  margin: 1rem auto 1rem 0;
  max-width: 750px;
`

const SubmitButton = styled(Button)`
  margin-top: 1.5rem;
  margin-bottom: 0.25rem;
  :disabled {
    opacity: 0.2;
  }
`

const AddAnotherItemButton = styled(Button)`
  margin: 2rem auto;
  border: none;
  padding: 0;
  text-decoration: underline;
  font-weight: normal;
  font-size: 0.875rem !important;
  display: block;
`

const SwitchToPurchaseSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.25rem;
  font-size: 14px;
`

const SwitchToPurchaseButton = styled(Button)`
  border: none;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
  font-size: 14px;
  font-weight: bold;
  padding: 0.5rem 0;
`

const RadioWrapper = styled.div<{
  hidden?: boolean
  $disabled?: boolean
}>`
  ${(props) =>
    props.hidden &&
    `
  display: none !important;
`}
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 0.2rem;
  border-radius: ${({ theme }) => theme.radius.normal}px;
  border: 2px solid #dfe1e5;
  ${hoverShadowBox}

  ${(props) => (props.$disabled ? 'border: 2px dashed #DFE1E5;' : '')}

  &:hover {
    border: 2px solid #01ff6c;
  }

  ${media.md} {
    margin: 0.2rem 0.5rem;
  }

  h2 {
    color: ${({ theme }) => theme.colors.primary};
    margin: 0.5rem;
  }

  h3 {
    margin-top: 0.5rem;
    color: #666666;
    font-size: 0.75rem;

    ${media.md} {
      font-size: 0.825rem;
    }
  }
`

const RadioGroup = styled.div`
  margin: 2rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Radio = styled(RadioButton)`
  border-bottom: 1px solid #dfe1e5;
  padding: 0.5rem;
`

const Label = styled.label<{ $disabled?: boolean }>`
  flex: 1;
  ${({ $disabled }) => ($disabled ? '' : 'cursor: pointer;')}
  padding: 1rem;
`

const DealValuesTableWrapper = styled.div`
  margin-top: 1.5rem;

  ${media.md} {
    display: none;
  }
`

const LoanDurationTitle = styled(Text.ms)`
  margin-bottom: 1rem;
  text-align: center;
`

const validateCustomItemAmount = (item: CustomDealItemFormData) => {
  if (!item.payoutAmount) return false

  const numberAnswerWithRestrictions = item.formAnswers.find(
    (c) =>
      c.question.type === ECustomDealFormQuestionType.NumberOptions &&
      c.question.restrictions,
  )

  for (const restriction of numberAnswerWithRestrictions?.question
    .restrictions ?? []) {
    if (
      !checkValidNumberRestriction(
        restriction as CustomDealQuestionNumberRestrictionArgs,
        item.payoutAmount,
      )
    ) {
      return false
    }
  }

  return true
}
