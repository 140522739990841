import { SbBlokData, StoryblokComponent } from '@storyblok/react'
import { PageStoryblok } from '@/types/storyblok-component-types'

export default function Page({ blok }: { blok: PageStoryblok }) {
  return (
    <>
      {blok.body?.map((blok: SbBlokData) => (
        <StoryblokComponent blok={blok} key={blok._uid} />
      )) ?? null}
    </>
  )
}
