const cookieConsentSettings = [
  'ad_storage',
  'analytics_storage',
  'meta_pixel',
  'microsoft_ads',
  'tiktok_pixel',
] as const

export type CookieConsentMode = Record<
  (typeof cookieConsentSettings)[number],
  ConsentValue
>

export function isCookieConsentMode(
  consent: any,
): consent is CookieConsentMode {
  // invalid json
  if (typeof consent !== 'object' || !consent) {
    return false
  }

  // invalid object
  if (
    cookieConsentSettings.some(
      (setting) =>
        !(setting in consent) ||
        ![ConsentValue.Denied, ConsentValue.Granted].includes(consent[setting]),
    )
  ) {
    return false
  }

  return true
}

export enum ConsentValue {
  Granted = 'granted',
  Denied = 'denied',
}
