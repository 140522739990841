import { snakeCase } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { PropsWithChildren, createContext, useContext } from 'react'
import { ELanguageCode, ERegionCode } from '@/types/gql/graphql'

const RegionContext = createContext<ERegionCode | undefined>(undefined)
export const RegionCodeContextProvider = ({
  regionCode,
  ...props
}: PropsWithChildren<{
  regionCode: ERegionCode
}>) => <RegionContext.Provider {...props} value={regionCode} />

const useRegionCode = (): ERegionCode => {
  const regionCode = useContext(RegionContext)
  if (regionCode === undefined) {
    throw new Error('Region code not set')
  }

  return regionCode
}

export const getLanguageCodeFromLocale = (locale: string) => {
  if ((Object.values(ELanguageCode) as string[]).includes(snakeCase(locale))) {
    return snakeCase(locale) as unknown as ELanguageCode
  }

  throw new Error(`Missing language code for locale '${locale}'.`)
}

export const useLanguageCode = (): ELanguageCode => {
  const { lang } = useTranslation()

  return getLanguageCodeFromLocale(lang)
}

export default useRegionCode
