import { media } from '../../helpers'
import styled, { css } from 'styled-components'
import theme from '@/style/themes/default'
import UnstyledButtom from './UnstyledButton'

export interface ButtonProps {
  primary?: boolean
  wide?: boolean
  full?: boolean
  appearance?:
    | 'default'
    | 'white'
    | 'primary'
    | 'primary-outline'
    | 'lightBlue'
    | 'secondary'
    | 'form'
    | 'whitebg'
    | 'lightGreybg'
  weight?: 'normal' | 'medium' | 'semibold' | 'bold'
  visibleFrom?: keyof typeof theme.breakpoints
  visibleUntil?: keyof typeof theme.breakpoints
  children?: React.ReactNode
}

const Button = styled(UnstyledButtom).attrs<ButtonProps>((props) => ({
  wide: props.wide || false,
  full: props.full || false,
  appearance: props.primary ? 'primary' : props.appearance || 'default',
  visibleFrom: props.visibleFrom,
  visibleUntil: props.visibleUntil,
  weight: props.weight || 'semibold',
}))<ButtonProps>`
  display: inline-block;
  border: 2px solid ${({ theme }) => theme.colors.buttonBorder};
  padding: 0.75rem ${({ wide }) => (wide ? 5 : 1.5)}rem;
  border-radius: ${({ theme }) => theme.radius.normal}px;
  transition: border-color 0.2s ease;
  font-size: 0.875rem;

  ${media.sm} {
    font-size: 1rem;
  }

  &:hover {
    border-color: rgba(0, 0, 0, 0.3);
  }

  :disabled {
    opacity: 0.2;
  }

  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}

  ${({ theme, appearance }) =>
    ['primary', 'primary-outline'].includes(appearance || '') &&
    css`
      color: ${theme.colors.black};
      background-color: ${theme.colors.secondary};
      border-color: ${theme.colors.secondary};
    `}

      ${({ appearance }) =>
    appearance === 'primary-outline' &&
    css`
      border-color: rgba(0, 0, 0, 0.1);
    `}

      ${({ theme, appearance }) =>
    appearance === 'white' &&
    css`
      color: ${theme.colors.white};
      border-color: rgba(255, 255, 255, 0.5);

      &:hover {
        border-color: ${theme.colors.white};
      }
    `}

    ${({ theme, appearance }) =>
    (appearance === 'lightBlue' || appearance === 'secondary') &&
    css`
      color: ${theme.colors.white};
      border-color: ${theme.colors[appearance]};

      @media (hover: hover) {
        &:hover {
          border-color: ${theme.colors.white};
        }
      }

      @media (hover: none) {
        &:hover {
          border-color: ${theme.colors[appearance]};
        }
      }
    `}

    ${({ theme, appearance, wide }) =>
    appearance === 'form' &&
    css`
      border-color: ${theme.colors.border};
      padding: 0.8rem ${() => (wide ? 5 : 1.5)}rem;

      &:hover {
        background-color: ${theme.colors.white};
        border-color: ${theme.colors.border};
      }
    `}

      ${({ theme, appearance }) =>
    appearance === 'whitebg' &&
    css`
      border-color: ${theme.colors.border};
      background-color: ${theme.colors.white};

      &:hover {
        background-color: ${theme.colors.white};
        border-color: ${theme.colors.border};
      }
    `}

        ${({ theme, appearance }) =>
    appearance === 'lightGreybg' &&
    css`
      border-color: ${theme.colors.border};
      background-color: ${theme.colors.lightGrey};

      &:hover {
        background-color: ${theme.colors.lightGrey};
        border-color: ${theme.colors.border};
      }
    `}

    ${({ visibleFrom }) =>
    visibleFrom &&
    css`
      display: none;

      ${media[visibleFrom]} {
        display: inline-block;
      }
    `}

    ${({ visibleUntil }) =>
    visibleUntil &&
    css`
      ${media[visibleUntil]} {
        display: none;
      }
    `}

    ${({ weight }) =>
    weight &&
    css`
      font-weight: ${weight === 'bold'
        ? 700
        : weight === 'semibold'
          ? 600
          : weight === 'medium'
            ? 500
            : 400};
    `}
`

export default Button
