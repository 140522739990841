import styled from 'styled-components'
import TypeScales from './typescales'

interface H4Props {
  noMargin?: boolean
}

export default styled(TypeScales.h4).attrs<H4Props>((props) => ({
  as: 'h4',
  noMargin: props.noMargin || false,
}))<H4Props>`
  text-transform: uppercase;
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : 0.75)}rem;
`
