import { useQuery } from '@/app/common/graphql/hooks'
import {
  GetCompanyByRegionDocument,
  GetCompanyByRegionQuery,
  GetCompanyByRegionQueryVariables,
} from '@/types/gql/graphql'
import useRegionCode from './useRegionCode'

const useCompany = () => {
  const regionCode = useRegionCode()
  const companyResult = useQuery<
    GetCompanyByRegionQuery,
    GetCompanyByRegionQueryVariables
  >(GetCompanyByRegionDocument, {
    variables: {
      region: regionCode,
    },
    enableCaching: true,
  })

  return companyResult.data?.getCompanyByRegion
}

export default useCompany
