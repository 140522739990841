import React, { Fragment } from 'react'

export function ClientOnly({
  children,
  ssr,
  ...delegated
}: { children: any[]; ssr?: boolean } & any) {
  const [hasMounted, setHasMounted] = React.useState(false)

  React.useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted && !ssr) {
    return null
  }

  return <Fragment {...delegated}>{children}</Fragment>
}
