import { FeeInfoModal } from '../../../../domains/checkout/components/FeeInfoModal/FeeInfoModal'
import { QuestionMark } from '../../../../domains/checkout/components/QuestionMark'
import useTranslation from 'next-translate/useTranslation'
import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import {
  PREMIUM_SHIPPING_COSTS,
  STANDARD_SHIPPING_COSTS,
  getFeesScheduleLink,
} from '@/config'
import useRegionCode from '@/helpers/useRegionCode'
import Text from '@/style/components/LegacyText'
import { media } from '@/style/helpers'
import { EVehicleCategory, SelectedItemsType } from '@/types'
import {
  DealValuesEntry,
  EBusinessUnit,
  EDealType,
  ETransportMode,
  TransportData,
  UsedFeeDefinition,
} from '@/types/gql/graphql'
import { printLocalAmount } from '@/utils/misc'

const Container = styled.div<{
  embedded?: boolean
}>`
  border-radius: ${({ theme }) => theme.radius.normal}px;
  background: ${(props) => props.theme.colors.buttonBorder};
  padding: 1.15rem;
  padding-bottom: 0.35rem;
  margin-top: 1rem;
  ${media.md} {
    padding: 0.75rem 1.75rem;
  }

  ${(props) =>
    props.embedded &&
    css`
      ${media.md} {
        padding: 0;
      }
    `}
`

const Headline = styled(Text.ms)`
  text-align: center;
`

const CurrencyValue = styled(Text.ms).attrs({
  weight: 'semibold',
})<{
  last?: boolean
}>`
  margin-left: auto;

  ${(props) =>
    props.last &&
    `
    font-weight: bold !important;
    `}

  ${(props) =>
    props.last &&
    css`
      font-size: 1.75rem;
      margin-bottom: -0.25rem;

      ${media.xs} {
        font-size: 1.75rem;
        margin-bottom: -0.25rem;
      }

      ${media.md} {
        font-size: 2.75rem;
        margin-bottom: -0.6rem;
      }
    `}
`

const DealValueRowWrapper = styled.div<{
  last?: boolean
  originalFees?: boolean
}>`
  display: flex;

  padding: 0.65rem 0;
  border-bottom: 1px solid #e5e5e6;

  ${media.md} {
    padding: 1.312rem 0;
  }

  ${(props) =>
    props.last &&
    `
    ${media.md} {
      padding-top: 1.875rem;
      padding-bottom: 1.312rem;
      padding-right: 0;
      padding-left: 0;
    }
  `}

  ${(props) =>
    (props.last || props.originalFees) &&
    `
    border-bottom: none;
    align-items: flex-end;
  `}

${(props) =>
    props.originalFees &&
    `
    border-top: 1px solid #e5e5e6;
  `}
`

const ShippingCostWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const DealValueRow: React.FC<{
  id?: string
  label: string
  value: number
  text?: string
  last?: boolean
  appliedUsedFeeDefinitions?: UsedFeeDefinition[] | undefined
  manipulatedAppliedUsedFeeDefinitions?: UsedFeeDefinition[] | undefined
  hideDecimalPlaces?: boolean
  customItemTitles?: string[]
  className?: string
  hasQuestionMark?: boolean
  dealItems?: Omit<SelectedItemsType, 'answers' | 'itemCategory'>[]
  totalFees?: number
  originalFees?: boolean
  dealType?: EDealType
}> = (props) => {
  const modal = useContext(ModalDialogContext)
  const {
    id,
    label,
    value,
    text,
    last,
    hideDecimalPlaces,
    customItemTitles,
    className,
    dealItems,
    hasQuestionMark,
    appliedUsedFeeDefinitions,
    manipulatedAppliedUsedFeeDefinitions,
    totalFees,
    originalFees,
    dealType,
  } = props
  const regionCode = useRegionCode()

  const businessUnit =
    dealItems &&
    dealItems.length > 0 &&
    Object.values(EVehicleCategory).includes(dealItems[0].itemCategoryId)
      ? EBusinessUnit.Car
      : EBusinessUnit.General

  return (
    <DealValueRowWrapper
      last={last}
      originalFees={originalFees}
      className={className}
    >
      <Text.ms weight={last ? 'semibold' : 'regular'}>
        {label}
        {hasQuestionMark &&
          manipulatedAppliedUsedFeeDefinitions?.length &&
          !text && (
            <QuestionMark
              id={id}
              onClick={() =>
                modal.open(
                  <FeeInfoModal
                    businessUnit={businessUnit}
                    appliedUsedFeeDefinitions={
                      originalFees
                        ? appliedUsedFeeDefinitions
                        : manipulatedAppliedUsedFeeDefinitions
                    }
                    dealItems={dealItems}
                    totalFees={totalFees}
                    customItemTitles={customItemTitles}
                    originalFees={originalFees}
                    dealType={dealType}
                    feeDocumentLink={getFeesScheduleLink(
                      regionCode,
                      businessUnit,
                    )}
                  />,
                  {
                    customStyles: {
                      content: {
                        display: 'inline-block',
                        maxWidth: '80%',
                      },
                    },
                  },
                )
              }
            />
          )}
      </Text.ms>
      {hasQuestionMark && text && (
        <QuestionMark
          onClick={() =>
            modal.open(<FeeInfoModal text={text} />, {
              customStyles: {
                content: {
                  display: 'inline-block',
                  maxWidth: '80%',
                },
              },
            })
          }
        />
      )}
      {!originalFees && (
        <CurrencyValue last={last}>
          {printLocalAmount({
            number: value,
            fractionDigits: hideDecimalPlaces ? 0 : 2,
            removeSpaces: true,
          })}
        </CurrencyValue>
      )}
    </DealValueRowWrapper>
  )
}

export interface DealValues {
  value: number
  label: string
  last: boolean
  hideDecimalPlaces: boolean
  text?: string
  hasQuestionMark: boolean
  originalFees?: boolean
}

interface DealValuesTableProps {
  dealValues: Array<DealValues | undefined>
  appliedUsedFeeDefinitions?: UsedFeeDefinition[] | undefined
  manipulatedAppliedUsedFeeDefinitions?: UsedFeeDefinition[] | undefined
  transportData?: TransportData
  title?: string
  customItemTitles?: string[]
  embedded?: boolean
  dealItems?: Omit<SelectedItemsType, 'answers' | 'itemCategory'>[]
  dealValuesEntry?: DealValuesEntry | undefined
  showKnp?: boolean | undefined
  isDropOff?: boolean
  totalFees?: number
  dealType?: EDealType
}

export const DealValuesTable: React.FC<DealValuesTableProps> = ({
  dealValues,
  appliedUsedFeeDefinitions,
  manipulatedAppliedUsedFeeDefinitions,
  transportData,
  title,
  customItemTitles,
  embedded,
  dealItems,
  isDropOff,
  totalFees,
  dealType,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {title && (
        <Headline weight="semibold" as="p" uppercase>
          {title}
        </Headline>
      )}
      <Container embedded={embedded}>
        <>
          {dealValues
            .filter((c) => Boolean(c))
            .map((dealValue, index) => {
              return (
                <DealValueRow
                  id={`DEAL_VALUE_ROW_BUTTON_${index}`}
                  key={index}
                  value={dealValue?.value ?? 0}
                  label={dealValue?.label ?? ''}
                  last={dealValue?.last}
                  appliedUsedFeeDefinitions={appliedUsedFeeDefinitions}
                  manipulatedAppliedUsedFeeDefinitions={
                    manipulatedAppliedUsedFeeDefinitions
                  }
                  customItemTitles={customItemTitles}
                  hideDecimalPlaces={dealValue?.hideDecimalPlaces}
                  text={dealValue?.text}
                  hasQuestionMark={dealValue?.hasQuestionMark}
                  dealItems={dealItems}
                  totalFees={totalFees}
                  originalFees={dealValue?.originalFees}
                  dealType={dealType}
                />
              )
            })}
          {transportData && !isDropOff && (
            <ShippingCostWrapper>
              {(transportData.transportMode ===
                ETransportMode.StandardShipment ||
                transportData.transportMode ===
                  ETransportMode.PremiumShipment) && (
                <Text.sm weight="semibold">
                  {transportData.transportMode ===
                  ETransportMode.StandardShipment
                    ? t('checkout:shipment_fees_standard', {
                        shippingCosts: printLocalAmount({
                          number: STANDARD_SHIPPING_COSTS,
                          removeSpaces: true,
                          fractionDigits: 0,
                        }),
                      })
                    : t('checkout:shipment_fees_premium', {
                        shippingCosts: printLocalAmount({
                          number: PREMIUM_SHIPPING_COSTS,
                          removeSpaces: true,
                          fractionDigits: 0,
                        }),
                      })}
                </Text.sm>
              )}
            </ShippingCostWrapper>
          )}
        </>
      </Container>
    </>
  )
}
