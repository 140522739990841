import { useEffect, useState } from 'react'
import theme from '@/style/themes/default'
import { useIsSSR } from './useIsSSR'

const breakpoints = Object.keys(theme.breakpoints) as Array<
  keyof typeof theme.breakpoints
>

const ssrWindowDimensions = {
  width: 1920,
  height: 1080,
  activeBreakpoints: ['xs', 'sm', 'md', 'lg'],
}

function getWindowDimensions() {
  if (typeof window === 'undefined') {
    return ssrWindowDimensions
  }

  const { innerWidth: width, innerHeight: height } = window
  const activeBreakpoints = breakpoints.filter(
    (bp) => theme.breakpoints[bp] <= innerWidth,
  )

  return {
    width,
    height,
    activeBreakpoints,
  }
}

/**
 * Before you use this, please consider if you might be able to solve your problem by leveraging media queries.
 * The reason this is explicitly discouraged is because it will cause a layout shift. It has to cause a layout shift
 * because we have to default to rendering the desktop layout on the first client render, because that's what we
 * default to during SSR. If we don't, we most likely end up with hydration issues.
 * As mentioned, media queries to dynamically set display: none; usually solves the problem without a big downside,
 * the only downside being that you will end up with redundant elements in the DOM.
 */
export default function useWindowDimensions_CAN_CAUSE_CLS() {
  const isSSR = useIsSSR()
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return isSSR ? ssrWindowDimensions : windowDimensions
}
