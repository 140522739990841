import {
  AccordionSummary,
  AccordionSummaryProps,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  expanded: {
    minHeight: 40,
  },
  content: {
    '&$expanded': {
      margin: '0 !important',
    },
  },
}))

export const CustomAccordionSummary = (props: AccordionSummaryProps) => {
  const classes = useStyles()
  return (
    <AccordionSummary
      classes={{ content: classes.content, expanded: classes.expanded }}
      {...props}
    />
  )
}
