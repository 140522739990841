import WhatsappBubble from '../WhatsappBubble/WhatsappBubble'
import { createContext, useContext, useState } from 'react'
import styled from 'styled-components'
import { media } from '@/style/helpers'
import Footer from './Footer'
import Header from './Header'

interface LayoutProps {
  children: React.ReactNode
  isProfilePage?: boolean
  isCheckoutPage?: boolean
}

const FlexLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const ContentSpacer = styled.div<{
  $isProfilePage?: boolean
}>`
  flex: 1;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.$isProfilePage &&
    `
    ${media.md} {
      min-height: calc(100vh - 100px) !important; /* 100px (header) */
    }
  `}
`

const LayoutContext = createContext<{
  setWhatsappBubbleExtraOffsetBottom: (value: number) => void
}>({
  setWhatsappBubbleExtraOffsetBottom: () => {},
})

const Layout = ({ children, isProfilePage }: LayoutProps) => {
  const [whatsappBubbleExtraOffsetBottom, setWhatsappBubbleExtraOffsetBottom] =
    useState(0)
  return (
    <LayoutContext.Provider
      value={{
        setWhatsappBubbleExtraOffsetBottom,
      }}
    >
      <FlexLayout>
        <Header />
        <ContentSpacer $isProfilePage={isProfilePage}>{children}</ContentSpacer>
        <WhatsappBubble extraOffsetBottom={whatsappBubbleExtraOffsetBottom} />
        <Footer />
        <div style={{ height: whatsappBubbleExtraOffsetBottom }} />
      </FlexLayout>
    </LayoutContext.Provider>
  )
}

export function useLayoutContext() {
  return useContext(LayoutContext)
}

export default Layout
