import DayjsUtils from '@date-io/dayjs'
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import deLocale from 'dayjs/locale/de'
import enLocale from 'dayjs/locale/en'
import { FormikProps } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import styled from 'styled-components'
import useLocalisedDateFormatting from '@/helpers/useLocalisedDateFormatting'
import { media } from '@/style/helpers'
import { dayjs } from '@/utils/time'

interface DatePickerProps {
  name: string
  value: Date | null
  onChange: (value: Date) => void
  formikProps: FormikProps<any>
  label: string
  maxDate?: Date
  minDate?: Date
  datePickerProps?: KeyboardDatePickerProps
  marginBottom?: string
}

export const DatePicker: React.FC<DatePickerProps> = ({
  name,
  value,
  onChange,
  formikProps,
  label,
  maxDate,
  minDate,
  datePickerProps,
  marginBottom,
}) => {
  const { t, lang } = useTranslation()
  const formatDate = useLocalisedDateFormatting()

  const field = formikProps.getFieldMeta(name)
  const hasError = field.error && (field.touched || formikProps.submitCount > 0)

  return (
    <DatePickerWrapper hasError={!!hasError} marginBottom={marginBottom}>
      <MuiPickersUtilsProvider
        utils={DayjsUtils}
        locale={lang === 'de' ? deLocale : enLocale}
      >
        <KeyboardDatePickerStyled
          {...datePickerProps}
          openTo="year"
          cancelLabel={t('common:cancel')}
          color="primary"
          format="DD.MM.YYYY"
          margin="none"
          id="date-picker-inline"
          label={label}
          name={name}
          value={value}
          maxDate={maxDate}
          minDate={minDate}
          initialFocusedDate={formatDate(dayjs('2000-01-01'), 'Date')}
          onChange={(date: MaterialUiPickersDate) => {
            if (date && date.isValid()) {
              onChange(date.toDate())
            }
          }}
          error={!!hasError}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          invalidDateMessage={t('common:errors.invalid_date_format')}
          maxDateMessage={t('common:errors.under_minimum_age')}
          onBlur={formikProps.handleBlur}
        />
      </MuiPickersUtilsProvider>
    </DatePickerWrapper>
  )
}

const KeyboardDatePickerStyled = styled(KeyboardDatePicker)`
  width: 100%;

  .MuiInput-underline:after,
  .MuiInput-underline:before {
    border-bottom: none;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }

  .MuiInputLabel-formControl {
    transform: translate(0, 5px) scale(1);
  }

  .MuiFormLabel-root.Mui-focused {
    transform: translate(0, 0px) scale(0.65);
    color: rgba(0, 0, 0, 0.54);
  }

  .MuiInputLabel-shrink {
    transform: translate(0, 0px) scale(0.65);
  }

  label + .MuiInput-formControl {
    margin-top: 0px;
  }

  .MuiInputBase-input {
    font-size: 0.75rem;
    margin-top: 5px;

    ${media.sm} {
      font-size: 0.875rem;
    }
  }

  .MuiInputAdornment-positionEnd {
    position: relative;
    top: -3px;
  }

  .MuiFormLabel-root {
    font-size: 0.75rem;
    ${media.sm} {
      font-size: 0.875rem;
    }
  }

  ${media.sm} {
    .MuiInputBase-input {
      font-size: 0.875rem;
    }

    .MuiFormHelperText-root {
      margin-top: 5px !important;
    }
  }
`

const DatePickerWrapper = styled.div<{
  hasError: boolean
  marginBottom?: string
}>`
  width: 100%;
  position: relative;
  margin-bottom: ${(props) => props.marginBottom ?? '25px'};
  border: 2px solid
    ${({ theme, hasError }) =>
      hasError ? theme.colors.error : theme.colors.border} !important;
  border-radius: ${({ theme }) => theme.radius.normal}px;
  height: 2.5rem;

  ${media.sm} {
    height: 3rem;
  }

  ${KeyboardDatePickerStyled} {
    position: absolute;
    left: 10px;
    top: 5px;
    width: calc(100% - 10px);

    ${media.sm} {
      top: 10px;
    }
  }
`
