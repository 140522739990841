import * as Sentry from '@sentry/nextjs'
import { Translate } from 'next-translate'
import { GraphQLOperationError } from '@/app/common/graphql/error'
import { camelToUpperSnake } from './string'

interface UIError {
  title: string
  description: string | string[] | any
  data?: any
}

interface GQLExceptionResponse {
  message?: string
  error: string
  statusCode: number
}

interface GQLException {
  response?: GQLExceptionResponse
}

export function parseApolloErrorPretty(
  apolloError: GraphQLOperationError,
  t: Translate,
) {
  if (apolloError.networkError) {
    return {
      message: t('common:errors.network_error'),
      code: 'NETWORK_ERROR',
    }
  }
  if (!apolloError.graphQLErrors || apolloError.graphQLErrors.length === 0) {
    return {
      message: t('common:errors.unexpected_exception'),
      code: 'UNEXPECTED_EXCEPTION',
    }
  }

  const gqlError = apolloError.graphQLErrors[0]
  const apiResponse =
    (gqlError.extensions?.exception as any)?.response ??
    gqlError.extensions?.originalError

  if (!apiResponse || !apiResponse.message) {
    return {
      message: t('common:errors.unexpected_exception'),
      code: 'UNEXPECTED_EXCEPTION',
    }
  }

  let message = apiResponse.message as string

  if (message.indexOf('{') < 0) {
    return {
      code: message,
      message: t('common:errors.unexpected_exception_with_code', {
        errorCode: message,
      }),
    }
  }

  // api error code
  if (message[0] !== '{') {
    const start = message.indexOf('{')
    message = message.substr(start, message.length - start)
  }

  const parsedError = parseApiError(
    {
      error: apiResponse.error,
      message,
      statusCode: apiResponse.statusCode,
    },
    {
      get: (key: string) => {
        return t('common:errors.' + key)
      },
    },
  )

  return {
    message: parsedError.description,
    code: parsedError.title,
    data: parsedError.data,
  }
}

function parseApiError(
  requestError: { error: string; message?: string; statusCode?: number },
  t: { get: (key: string) => string },
) {
  const error: UIError = {
    title: '',
    description: '',
    data: undefined,
  }

  if (requestError.message) {
    try {
      const errorData = JSON.parse(requestError.message)

      if (typeof errorData.code !== 'undefined') {
        error.title = `Error Code: ${errorData.code}`
        error.description =
          t.get(`ERROR_DESCRIPTION_${errorData.code}`) ?? errorData.message
      } else {
        error.title = t.get('ERROR_TITLE_API_ERROR')
        error.description = errorData.message
      }

      if (typeof errorData.data !== 'undefined') {
        if (
          errorData.data.validationErrors &&
          Array.isArray(errorData.data.validationErrors)
        ) {
          const { validationErrors, ...otherData } = errorData.data
          const validationConstraints: string[] = []

          validationErrors.forEach((validationError: { property: any }) => {
            parseValidationError(
              validationError,
              validationConstraints,
              validationError.property,
              t,
            )
          })

          error.description = validationConstraints
          error.data = otherData
        } else {
          error.data = errorData.data
        }
      }
    } catch (e) {
      console.error('Failed to parse api error.', {
        error: e,
        requestError,
      })
      Sentry.captureException(e)

      // error, message, statusCode
      error.title = `${requestError.statusCode}: ${requestError.error}`
      error.description = requestError.message
    }
  } else {
    error.title = t.get('ERROR_TITLE_API_ERROR')
    error.description = requestError.error
  }

  return error
}

function parseValidationError(
  validationError: any,
  validationConstraints: string[],
  path: string,
  t: { get: (arg0: string) => any },
) {
  if (validationError.children && validationError.children.length > 0) {
    for (const validationErrorChild of validationError.children) {
      parseValidationError(
        validationErrorChild,
        validationConstraints,
        `${path}.${validationErrorChild.property}`,
        t,
      )
    }
  }

  if (validationError.constraints) {
    Object.keys(validationError.constraints).forEach((constraintKey) => {
      const translation = t.get(
        `ERROR_VALIDATION_${camelToUpperSnake(constraintKey)}`,
      )

      if (translation) {
        validationConstraints.push(`"${path}" ${translation}!`)
      } else {
        const constraintSplit =
          validationError.constraints[constraintKey].split(' ')
        constraintSplit.shift()
        const constraint = constraintSplit.join(' ')

        validationConstraints.push(`"${path}" ${constraint}!`)
      }
    })
  }
}

export function getTranslatedValidationError(error: any, t: Translate) {
  if (typeof error === 'object') {
    return t(error.key, error.values)
  } else {
    return t(error)
  }
}

export function isNotFoundError(error: GraphQLOperationError) {
  if (!error.graphQLErrors || error.graphQLErrors.length === 0) {
    return false
  }

  const gqlError = error.graphQLErrors[0]

  const apiResponse: GQLExceptionResponse =
    (gqlError.extensions?.exception as GQLException | undefined)?.response ||
    (gqlError.extensions?.originalError as GQLExceptionResponse)

  if (!apiResponse || !apiResponse.message) {
    return false
  }

  const errorData = JSON.parse(apiResponse.message)
  if (!errorData || typeof errorData !== 'object') {
    return false
  }

  const { code } = errorData
  return typeof code === 'string' && code.endsWith('_NOT_FOUND')
}
