import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useSubscription } from '@/app/common/graphql/hooks'
import { createQueryKey } from '@/app/common/graphql/utils'
import {
  GetDealsForCustomerDocument,
  OnlinePaymentSucceededDocument,
  OnlinePaymentSucceededSubscription,
  OnlinePaymentSucceededSubscriptionVariables,
} from '@/types/gql/graphql'

const OnlinePaymentSubscription: React.FC = () => {
  const client = useQueryClient()
  const router = useRouter()

  useSubscription<
    OnlinePaymentSucceededSubscription,
    OnlinePaymentSucceededSubscriptionVariables
  >(OnlinePaymentSucceededDocument, {
    onData: (data) => {
      if (data.onlinePaymentSucceeded) {
        client.invalidateQueries({
          queryKey: createQueryKey(GetDealsForCustomerDocument),
        })
      }
    },
    skip: !router.pathname.includes('/profile'),
  })

  return null
}

export default OnlinePaymentSubscription
