import { storyblokEditable } from '@storyblok/react'
import { NextPage } from 'next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useCallback, useContext } from 'react'
import styled, { css } from 'styled-components'
import StoryblokRichText from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import {
  ModalDialogContext,
  ModalOptions,
} from '@/app/common/context/modalDialogContext'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import * as tracking from '@/helpers/tracking'
import { media } from '@/style/helpers'
import { CtaButtonStoryblok } from '@/types/storyblok-component-types'

export default function CTAButton({ blok }: { blok: CtaButtonStoryblok }) {
  const {
    caption,
    scroll_to_id: scrollToId,
    wide,
    link,
    modal,
    button_color,
  } = blok
  const modalContext = useContext(ModalDialogContext)
  const router = useRouter()

  const scrollToSection = (element: Element) => {
    if (!element) return

    const top = element.getBoundingClientRect().top + window.scrollY

    window.scrollTo({
      left: 0,
      top,
      behavior: 'smooth',
    })
  }

  const openModal = useCallback(async () => {
    modalContext.setLoading()
    const { Component, modalOptions } = await getModalComponentAndOptions(modal)

    if (!Component) {
      return
    }

    modalContext.open(
      <Component onCompleted={() => modalContext.close()} />,
      modalOptions,
    )
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal])

  const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (blok.id) {
      tracking.trackEvent('cta_click', { id: blok.id })
    }

    if (scrollToId) {
      e.preventDefault()
      const element = document.getElementById(scrollToId)

      if (element) {
        scrollToSection(element)
      } else {
        router.push(href ?? '/')
      }
      return
    }

    if (modal) {
      return openModal()
    }
  }

  const href = modal ? undefined : getStoryBlokLink(link)

  switch (blok.style) {
    case 'outlined':
      return (
        <StyledButton
          primary
          $wide={wide}
          $color={button_color?.color}
          appearance="primary-outline"
          $isOutlined
          id={blok.id}
          href={href}
          onClick={onClick}
          className="cta-button"
          {...storyblokEditable(blok)}
        >
          <StoryblokRichText document={caption} />
        </StyledButton>
      )
    case 'contained':
      return (
        <StyledButton
          primary
          $wide={wide}
          $color={button_color?.color}
          full
          id={blok.id}
          href={href}
          onClick={onClick}
          className="cta-button"
          {...storyblokEditable(blok)}
        >
          <StoryblokRichText document={caption} />
        </StyledButton>
      )
    case 'text':
      return (
        <TextButton
          $wide={wide}
          $color={button_color?.color}
          id={blok.id}
          href={href}
          onClick={onClick}
          className="cta-button"
          {...storyblokEditable(blok)}
        >
          <StoryblokRichText document={caption} />
        </TextButton>
      )
    default:
      return <></>
  }
}

const getModalComponentAndOptions = async (
  modal: CtaButtonStoryblok['modal'],
): Promise<{
  Component: NextPage<{ onCompleted: () => void }> | null
  modalOptions?: ModalOptions
}> => {
  switch (modal) {
    case 'RingBack':
      return {
        Component: await import(
          '@/blocks/CarConfigurator/CallRequest/CallRequestModal'
        ).then((module) => module.default),

        modalOptions: {
          variant: 'full',
          hasWrapper: true,
          fixedMobileFooter: true,
        },
      }

    default:
      return {
        Component: null,
      }
  }
}

function LinkOrAnchor({
  href,
  ...rest
}: React.AnchorHTMLAttributes<HTMLAnchorElement>) {
  if (href) {
    return <Link href={href} {...rest} />
  } else {
    return <a {...rest}>{rest.children}</a>
  }
}

interface ButtonProps {
  primary?: boolean
  wide?: boolean
  full?: boolean
  $color: string | undefined
  appearance?:
    | 'default'
    | 'white'
    | 'primary'
    | 'primary-outline'
    | 'lightBlue'
    | 'secondary'
    | 'form'
    | 'whitebg'
    | 'lightGreybg'
}

const UnstyledButton = styled(LinkOrAnchor)`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal !important;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  outline: none;
  text-decoration: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`

const Button = styled(UnstyledButton).attrs<ButtonProps>((props) => ({
  wide: props.wide || false,
  full: props.full || false,
  appearance: props.primary ? 'primary' : props.appearance || 'default',
  color: props.$color,
}))<ButtonProps>`
  display: inline-block;
  border: 2px solid ${({ theme }) => theme.colors.buttonBorder};
  padding: 0.75rem 0.5rem;
  border-radius: ${({ theme }) => theme.radius.normal}px;
  transition: border-color 0.2s ease;
  font-size: 0.875rem;

  cursor: pointer !important;

  b {
    font-weight: 600;
  }

  & > p {
    line-height: normal !important;
    text-align: center;

    font-size: ${({ wide }) => (wide ? '0.875rem !important' : 'inherit')};
    ${media.sm} {
      font-size: ${({ wide }) => (wide ? '1rem !important' : 'inherit')};
    }
    width: 100%;
  }

  ${media.xs} {
    padding: 0.75rem ${({ wide }) => (wide ? 2 : 0.5)}rem;
    width: ${({ wide }) => (wide ? '21.875rem' : 'fit-content')};
  }

  ${media.sm} {
    font-size: 1rem;
  }

  &:hover {
    border-color: rgba(0, 0, 0, 0.3);
  }

  :disabled {
    opacity: 0.2;
  }

  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}

  ${({ theme, appearance, color }) =>
    ['primary', 'primary-outline'].includes(appearance || '') &&
    css`
      color: ${theme.colors.black};
      // ovo
      background-color: ${color || theme.colors.secondary};
      border-color: ${color || theme.colors.secondary};
    `}

      ${({ appearance }) =>
    appearance === 'primary-outline' &&
    css`
      border-color: rgba(0, 0, 0, 0.1);
    `}

      ${({ theme, appearance }) =>
    appearance === 'white' &&
    css`
      color: ${theme.colors.white};
      border-color: rgba(255, 255, 255, 0.5);

      &:hover {
        border-color: ${theme.colors.white};
      }
    `}

    ${({ theme, appearance }) =>
    (appearance === 'lightBlue' || appearance === 'secondary') &&
    css`
      color: ${theme.colors.white};
      border-color: ${theme.colors[appearance]};

      &:hover {
        border-color: ${theme.colors.white};
      }
    `}

    ${({ theme, appearance, wide }) =>
    appearance === 'form' &&
    css`
      border-color: ${theme.colors.border};
      padding: 0.8rem ${() => (wide ? 5 : 1.5)}rem;

      &:hover {
        background-color: ${theme.colors.white};
        border-color: ${theme.colors.border};
      }
    `}

      ${({ theme, appearance }) =>
    appearance === 'whitebg' &&
    css`
      border-color: ${theme.colors.border};
      background-color: ${theme.colors.white};

      &:hover {
        background-color: ${theme.colors.white};
        border-color: ${theme.colors.border};
      }
    `}

        ${({ theme, appearance }) =>
    appearance === 'lightGreybg' &&
    css`
      border-color: ${theme.colors.border};
      background-color: ${theme.colors.lightGrey};

      &:hover {
        background-color: ${theme.colors.lightGrey};
        border-color: ${theme.colors.border};
      }
    `}
`

const StyledButton = styled(Button)<
  {
    $isOutlined?: boolean
    $wide?: boolean
    $color: string | undefined
  } & ButtonProps
>`
  padding: 0.5rem 1rem;
  ${({ $wide }) =>
    $wide ? 'padding: 0.75rem 0.5rem !important; width: 100%' : 'width: auto'};

  ${({ theme, $isOutlined, $color }) =>
    $isOutlined ? `border-color: ${$color || theme.colors.primary}` : ''};
  ${({ $isOutlined }) =>
    $isOutlined ? 'background-color: transparent; ' : ''};

  & > * {
    font-weight: 600 !important;
  }

  ${media.xs} {
    ${({ $wide }) => ($wide ? 'padding: 0.75rem 2rem !important;' : '')};
    width: ${({ $wide }) => ($wide ? '21.875rem' : 'fit-content')};
  }
`

const TextButton = styled(LinkOrAnchor)<{
  $wide?: boolean
  $color: string | undefined
}>`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p:nth-child(1) {
    display: inline-block;
    border-bottom: 2px solid
      ${({ theme, $color }) => $color || theme.colors.secondary};
    padding-bottom: 5px;
  }

  & > * {
    font-weight: 600 !important;
  }

  ${({ $wide }) =>
    $wide ? 'padding: 0.75rem 0.5rem; width: 100%' : 'width: auto'};

  ${media.xs} {
    ${({ $wide }) => ($wide ? 'padding: 0.75rem 2rem;' : '')};
    width: ${({ $wide }) => ($wide ? '21.875rem' : 'fit-content')};
  }

  display: inline-block;
  cursor: pointer;
  color: inherit !important;
  text-decoration: none;
`
