import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import styled from 'styled-components'
import { TextWithDesktopImagesReferenceStoryblok } from '@/types/storyblok-component-types'

export default function TextWithDesktopImagesReference({
  blok,
}: {
  blok: TextWithDesktopImagesReferenceStoryblok
}) {
  return typeof blok.items !== 'string' && blok.items?.content ? (
    <Container {...storyblokEditable(blok)}>
      <StoryblokComponent blok={blok.items.content} key={blok._uid} />
    </Container>
  ) : null
}

const Container = styled.div``
