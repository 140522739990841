import PhoneIcon from '@material-ui/icons/Phone'
import RoomIcon from '@material-ui/icons/Room'
import { storyblokEditable } from '@storyblok/react'
import Link from 'next/link'
import styled from 'styled-components'
import StoryblokRichText from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import Text from '@/style/components/LegacyText'
import { media } from '@/style/helpers'
import { ShopsMapItemStoryblok } from '@/types/storyblok-component-types'

export default function ShopsMapItem({
  blok,
}: {
  blok: ShopsMapItemStoryblok
}) {
  const pageLink = getStoryBlokLink(blok.page_link)

  return (
    <Wrapper {...storyblokEditable(blok)}>
      {pageLink ? (
        <Link href={pageLink} legacyBehavior>
          <LinkStyle as="a" weight="semibold">
            <Text.lg as="h2" weight="semibold">
              {blok.title}
            </Text.lg>
          </LinkStyle>
        </Link>
      ) : (
        <Text.lg as="h2" weight="semibold">
          {blok.title}
        </Text.lg>
      )}

      <Row>
        <Text.md as="p">{blok.address}</Text.md>
        <IconWrapper>
          <StyledIcon
            href={getStoryBlokLink(blok.google_map_link)}
            target="_blank"
            rel="noreferrer"
          >
            <RoomIcon />
          </StyledIcon>
        </IconWrapper>
      </Row>
      <Row>
        <OpeningHoursWrapper>
          <StoryblokRichText document={blok.opening_hours} />
          {blok.phone_number && (
            <>
              <b>
                <span>Telefon</span>
              </b>
              <span>{blok.phone_number}</span>
            </>
          )}
        </OpeningHoursWrapper>
        {blok.phone_number && (
          <IconWrapper>
            <StyledIcon href={`tel:${blok.phone_number}`} rel="noreferrer">
              <PhoneIcon />
            </StyledIcon>
          </IconWrapper>
        )}
      </Row>
    </Wrapper>
  )
}

const LinkStyle = styled(Text.lg)`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  text-decoration: none;
`

const OpeningHoursWrapper = styled(Text.md)`
  span {
    padding-right: 1rem;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.25rem;
  width: 100%;
`

const Wrapper = styled.div`
  text-align: left;
  padding: 0.75rem 0;
  flex-grow: 0;

  ${media.md} {
    margin: 1rem 1rem 0 1rem;
    flex-basis: 37%;
  }

  ${media.lg} {
    margin: 1rem 4rem 0 4rem;
    flex-basis: 37%;
  }

  border-bottom: 1px solid ${(props) => props.theme.colors.border};

  ${media.md} {
    border-bottom: none;
  }
`

const StyledIcon = styled.a`
  color: inherit;
  border: 2px solid ${(props) => props.theme.colors.border};
  border-radius: 5px;
  padding: 4px;
  padding-bottom: 2px;

  &:hover {
    border-color: rgba(0, 0, 0, 0.3);
  }
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`
