import React, { FunctionComponent } from 'react'
import LazyLoad from 'react-lazyload'
import theme from '@/style/themes/default'
import { UploadFile, UploadFileFormats } from '@/types/gql/graphql'
import { buildSrcSet } from '@/utils/image'
import Image from './Image'

interface UploadedImageProps {
  data: UploadFile
  subset?: Array<keyof Omit<UploadFileFormats, '__typename'>>
  primarySize?: keyof Omit<UploadFileFormats, '__typename'>
  sizes?: Partial<
    Record<
      keyof typeof theme.breakpoints,
      keyof Omit<UploadFileFormats, '__typename'>
    >
  >
  className?: string
}

const UploadedImage: FunctionComponent<UploadedImageProps> = ({
  data,
  subset,
  primarySize,
  sizes,
  className,
}) => {
  const srcData = {
    src: data.url,
    width: data.width,
    height: data.height,
    sizes: '100vw',
  }

  if (primarySize && data.formats) {
    const primarySizeData = data.formats[primarySize]

    if (primarySizeData) {
      srcData.src = primarySizeData.url
      srcData.width = primarySizeData.width
      srcData.height = primarySizeData.height
    }
  }

  if (sizes && data.formats) {
    const definedSizes = Object.keys(sizes) as Array<
      keyof typeof theme.breakpoints
    >
    const mappedSizes = definedSizes.map((size) => {
      const format = sizes[size]

      let width: number | undefined

      if (format && !!data.formats) {
        const uploadFile = data.formats[format]

        if (uploadFile) {
          width = uploadFile.width
        }
      }

      if (typeof width === 'number') {
        return `(min-width: ${theme.breakpoints[size]}px) ${width}px`
      }

      return
    })
    mappedSizes.push(`${srcData.width}px`)

    srcData.sizes = mappedSizes.join(', ')
  }

  return (
    <LazyLoad height={srcData.height}>
      <Image
        {...srcData}
        className={className}
        srcSet={buildSrcSet(data, { sizes: subset })}
        alt={data.alternativeText || data.name.replace(/-/g, ' ')}
      />
    </LazyLoad>
  )
}

export default UploadedImage
