import { StoryblokComponent } from '@storyblok/react'
import styled from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import { media } from '@/style/helpers'
import theme from '@/style/themes/default'
import { PressReleaseStoryblok } from '@/types/storyblok-component-types'

export default function PressRelease({
  blok,
}: {
  blok: PressReleaseStoryblok
}) {
  return (
    <>
      {blok.title && (
        <TitleWrapper>
          <H1Header>{blok.title}</H1Header>
        </TitleWrapper>
      )}
      {blok.image?.filename && (
        <ImageWrapper>
          <StoryblokImage
            sizes={`(max-width: ${theme.breakpoints.md}px) 100vw, (max-width: ${theme.breakpoints.lg}px) 94vw, 1140px`}
            layout="responsive"
            image={blok.image}
          />
        </ImageWrapper>
      )}
      {blok.body &&
        blok.body.map((b) => (
          <ContentWrapper>
            <StoryblokComponent blok={b} />
          </ContentWrapper>
        ))}
    </>
  )
}

const TitleWrapper = styled.div`
  max-width: 792px;
  margin-right: auto;
  margin-left: auto;

  ${media.sm} {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 40px;
  }

  ${media.md} {
    padding-top: 75px;
  }

  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
`

const H1Header = styled.h1`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;

  ${media.sm} {
    margin-bottom: 25px;
    font-size: 39px;
    line-height: 46px;
  }

  ${media.md} {
    margin-bottom: 5rem !important;
    font-size: 52px;
    line-height: 52px;
  }

  margin-bottom: 25px;
`

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding: 0px;
  filter: none;

  img {
    border-radius: 0px !important;
  }

  ${media.md} {
    padding: 0 30px;

    img {
      border-radius: 10px !important;
    }
    filter: drop-shadow(0 26px 34px rgba(0, 0, 0, 0.25));
  }
`

const ContentWrapper = styled.div`
  ul {
    list-style-type: none;
    margin-top: 1rem;
    margin-bottom: 1rem;

    li {
      position: relative;
      padding-left: 25px;
      margin-bottom: 0.5rem;

      :before {
        color: #fff !important;
        font-family: bootstrap-icons;
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-check" viewBox="0 0 16 16"> <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/> </svg>');
        border-radius: 100%;
        background-color: #5016f4;
        position: absolute;
        font-size: 14px;
        line-height: 14px;
        width: 17px;
        height: 17px;
        left: 0;
        top: 0.2rem !important;
      }
    }
  }

  ol {
    counter-reset: ol-counter 0;
    list-style-type: none;
    margin-top: 1rem;
    margin-bottom: 1rem;

    li {
      padding-left: 30px;
      margin-bottom: 0.5rem;
      position: relative;

      &:before {
        counter-increment: ol-counter;
        content: counters(ol-counter, '.') '. ';
        position: absolute;
        left: 0;
        top: 4px;
        color: #5016f4;
        font-weight: 700;
        font-family: GalanoGrotesqueBold, serif;
      }
    }
  }

  h1:not(:first-of-type) {
    margin-top: 4.25rem;
  }

  h2:not(:first-of-type) {
    margin-top: 4.25rem;
  }

  h3:not(:first-of-type) {
    margin-top: 4.25rem;
  }

  img {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  h2 {
    margin-top: 3rem;
    margin-bottom: 0.75rem;
  }

  h3 {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    font-size: 1.2rem !important;
  }

  p {
    margin-bottom: 1rem !important;
  }

  a > p {
    margin-bottom: 0 !important;
  }
`
