import { useMutation } from '@/app/common/graphql/hooks'
import {
  CreateCustomDealDocument,
  CreateCustomDealMutation,
  CreateCustomDealMutationVariables,
} from '@/types/gql/graphql'

export const useCreateCustomDeal = () => {
  const [createCustomDeal] = useMutation<
    CreateCustomDealMutation,
    CreateCustomDealMutationVariables
  >(CreateCustomDealDocument)

  return {
    createCustomDeal,
  }
}
