import { createGlobalStyle } from 'styled-components'
import applyFontFamily from './applyFontFamily'

// styled-components global styles override other global styled imported via css.
// This is a known issue without a clear fix: https://github.com/ben-rogerson/twin.macro/issues/277.
// We used to have a more exhaustive reset, but we should be fine with just relying on the one
// tailwind comes with.
export default createGlobalStyle`
    ${applyFontFamily}

    .unzerSandboxNotify {
        display: none; // Prevent show unwanted notification in dev environment
    }
`
