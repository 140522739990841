import { useSyncExternalStore } from 'react'

function getSnapshot() {
  return false
}

function getServerSnapshot() {
  return true
}

function subscribe() {
  return () => {}
}

export function useIsSSR() {
  return useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot)
}
