import { Translate } from 'next-translate'
import {
  AvailablePropertiesFragmentFragment,
  EOrderBy,
  EProductImageMode,
  EProductPriceMode,
  EQuestionType,
  ItemQuestion,
  ProductImage,
  ProductPriceArgs,
  VariantsFragmentFragment,
} from '@/types/gql/graphql'
import { numberToFraction } from '@/utils/number'

export interface SelectedProductProps {
  propertyDefinitionId?: string
  value: number | string | boolean | undefined
}

const OUNCE_SUFFIX = 'OZ'

const formatVariantProperties = (
  variant: VariantsFragmentFragment | undefined | null,
  t: Translate,
  language = 'de',
) => {
  return variant?.propertiesValues?.map((property) => {
    const title =
      property.propertyDefinition.name.allTranslations.find(
        (translation) => translation.languageCode === language,
      )?.text ?? ''
    const providedSuffix = property.propertyDefinition.valueFormatting?.suffix

    let value
    if ('value' in property) {
      if ('translation' in property.value) {
        value =
          property.value.translation.allTranslations.find(
            (translation) => translation.languageCode === language,
          )?.text ?? ''
      } else if (
        'numberValue' in property.value &&
        providedSuffix === OUNCE_SUFFIX
      ) {
        const fractionValue = numberToFraction(property.value.numberValue)
        value = `${fractionValue} ${title}`
      } else if ('numberValue' in property.value) {
        value = `${property.value.numberValue}${providedSuffix || ''}`
      } else if ('booleanValue' in property.value) {
        value = property.value.booleanValue ? t('common:yes') : t('common:no')
      }
    }

    return { title, value }
  })
}

const formatAvailableProperties = (
  availableProperties: AvailablePropertiesFragmentFragment[],
  t: Translate,
  language = 'de',
) => {
  return availableProperties
    ?.map((property) => {
      const title =
        property.propertyDefinition.name.allTranslations.find(
          (translation) => translation.languageCode === language,
        )?.text ?? ''

      const description =
        property.propertyDefinition.description?.allTranslations.find(
          (translation) => translation.languageCode === language,
        )?.text ?? ''

      if ('values' in property) {
        const values = property.values.map((value) => {
          if ('numberValue' in value) {
            const providedSuffix =
              property.propertyDefinition.valueFormatting?.suffix || ''

            const suffix =
              providedSuffix === OUNCE_SUFFIX ? ` ${title}` : providedSuffix
            const displayValue =
              providedSuffix === OUNCE_SUFFIX
                ? numberToFraction(value.numberValue)
                : value.numberValue

            return {
              text: `${displayValue}${suffix}`,
              value: value.numberValue,
            }
          }
          if ('translation' in value) {
            return {
              text:
                value.translation.allTranslations.find(
                  (translation) => translation.languageCode === language,
                )?.text ?? '',
              value:
                value.translation.allTranslations.find(
                  (translation) => translation.languageCode === language,
                )?.text ?? '',
            }
          }
          // cover the impossible case (typescript narrowing imperfection)
          throw new Error(
            `Unknown product available property value: ${JSON.stringify(value)}`,
          )
        })

        const propertyDefinitionId = property.propertyDefinitionId

        const orderBy = property.propertyDefinition.valueFormatting?.orderBy
        if (orderBy) {
          const sortedValues = [...values].sort((a, b) => {
            if (orderBy === EOrderBy.Desc) return a.value > b.value ? -1 : 1
            return a.value > b.value ? 1 : -1
          })
          return {
            title,
            values: sortedValues,
            propertyDefinitionId,
            description,
          }
        }

        return { title, values, propertyDefinitionId, description }
      } else {
        const propertyDefinitionId = property.propertyDefinitionId
        return {
          title: title,
          values: [
            { text: t('common:yes'), value: true },
            { text: t('common:no'), value: false },
          ],
          propertyDefinitionId,
          description,
        }
      }
    })
    .filter((property) => (property?.values.length ?? 0) > 1)
}

const findDefaultProperties = (
  variants: VariantsFragmentFragment[] | undefined,
  language = 'de',
) => {
  const defaultVariant = variants?.[0]
  return defaultVariant?.propertiesValues.map((pv) => {
    let value

    if ('numberValue' in pv.value) {
      value = pv.value.numberValue
    } else if ('translation' in pv.value) {
      value = pv.value.translation.allTranslations.find(
        (translation) => translation.languageCode === language,
      )?.text
    } else if ('booleanValue' in pv.value) {
      value = pv.value.booleanValue
    }

    return {
      propertyDefinitionId: pv.propertyDefinitionId,
      value,
    }
  })
}

const findValidVariant = (
  selectedProperties: SelectedProductProps[],
  variants: VariantsFragmentFragment[],
) => {
  return variants?.find((v) =>
    selectedProperties.every((sp) =>
      v.propertiesValues.some((pv) => {
        let value
        if (pv.propertyDefinitionId === sp.propertyDefinitionId) {
          if ('numberValue' in pv.value) {
            value = pv.value.numberValue === sp.value
          } else if ('translation' in pv.value) {
            value = pv.value.translation.allTranslations.some(
              (translation) => translation.text === sp.value,
            )
          } else {
            value = pv.value.booleanValue === sp.value
          }
        }
        return value
      }),
    ),
  )
}

const adjustToValidVariant = (
  newSelectedProperties: SelectedProductProps[],
  variants: VariantsFragmentFragment[],
  changedPropertyDefinitionId: string,
  changedValue: number | string | boolean,
  price: ProductPriceArgs | undefined | null,
  language = 'de',
) => {
  let validVariant = variants?.find((v) =>
    v.propertiesValues.some(
      (pv) =>
        (pv.propertyDefinitionId === changedPropertyDefinitionId &&
          (('numberValue' in pv.value &&
            pv.value.numberValue === changedValue) ||
            ('translation' in pv.value &&
              pv.value.translation.allTranslations.some(
                (translation) => translation.text === changedValue,
              )))) ||
        ('booleanValue' in pv.value && pv.value.booleanValue === changedValue),
    ),
  )

  let variantPrice = validVariant
    ? findPriceBySelectedVariant(price, validVariant.id)
    : null

  if (!variantPrice) {
    validVariant = variants?.find((v) => {
      const hasMatchingProperty = v.propertiesValues.some(
        (pv) =>
          (pv.propertyDefinitionId === changedPropertyDefinitionId &&
            (('numberValue' in pv.value &&
              pv.value.numberValue === changedValue) ||
              ('translation' in pv.value &&
                pv.value.translation.allTranslations.some(
                  (translation) => translation.text === changedValue,
                )))) ||
          ('booleanValue' in pv.value &&
            pv.value.booleanValue === changedValue),
      )

      const hasPrice = findPriceBySelectedVariant(price, v.id)
      return hasMatchingProperty && hasPrice
    })

    if (validVariant) {
      variantPrice = findPriceBySelectedVariant(price, validVariant.id)
    }
  }

  if (!validVariant || !variantPrice) {
    return null
  }

  const adjustedProperties = newSelectedProperties.map((prop) => {
    const matchingPropertyValue = validVariant.propertiesValues.find(
      (propertyValue) =>
        propertyValue.propertyDefinitionId === prop.propertyDefinitionId,
    )

    if (!matchingPropertyValue) {
      return {
        propertyDefinitionId: prop.propertyDefinitionId,
        value: undefined, // TODO - add some default value if props doesn't match
      }
    }

    let value

    if ('numberValue' in matchingPropertyValue.value) {
      value = matchingPropertyValue.value.numberValue
    } else if ('translation' in matchingPropertyValue.value) {
      value = matchingPropertyValue.value.translation.allTranslations.find(
        (translation) => translation.languageCode === language,
      )?.text
    } else if ('booleanValue' in matchingPropertyValue.value) {
      value = matchingPropertyValue.value.booleanValue
    }

    return {
      propertyDefinitionId: prop.propertyDefinitionId,
      value,
    }
  })

  return adjustedProperties
}

const findVariantByProperties = (
  variants: VariantsFragmentFragment[],
  selectedProperties: SelectedProductProps[],
) => {
  if ((variants?.length ?? 0) > 0) {
    return variants?.find((v) =>
      selectedProperties.every((sp) =>
        v.propertiesValues.some(
          (pv) =>
            pv.propertyDefinitionId === sp.propertyDefinitionId &&
            (('numberValue' in pv.value && pv.value.numberValue === sp.value) ||
              ('translation' in pv.value &&
                pv.value.translation.allTranslations.some(
                  (t) => t.text === sp.value,
                )) ||
              ('booleanValue' in pv.value &&
                pv.value.booleanValue === sp.value)),
        ),
      ),
    )
  }
}

interface SelectedProductImageProps {
  selectedProperties: SelectedProductProps[]
  imageMode: EProductImageMode
  variants: VariantsFragmentFragment[]
  image: ProductImage | undefined | null
}

const getSelectedProductImage = (data: SelectedProductImageProps) => {
  const { selectedProperties, imageMode, variants, image } = data

  if (imageMode === EProductImageMode.OneImageForAllProducts) {
    if (image) {
      return image
    }
  } else if (imageMode === EProductImageMode.ImagePerVariant) {
    const variant = findVariantByProperties(variants, selectedProperties)
    if (variant && variant.image) {
      return variant.image
    }
  }
}

const buildItemAnswers = (
  itemQuestions: Omit<ItemQuestion, 'validFrom' | 'validTo'>[] | undefined,
) => {
  const answers = (itemQuestions ?? []).map((question) => {
    return {
      questionId: question._id,
      rangeValue:
        question.questionType === EQuestionType.Range && question.rangeData
          ? question.rangeData.defaultValue
          : undefined,
      selectedOptionIndex:
        question.questionType === EQuestionType.SingleChoice &&
        question.singleChoiceOptions
          ? question.singleChoiceOptions.findIndex(
              (option) => option.isDefaultValue,
            )
          : undefined,
    }
  })

  return answers
}

const findPriceBySelectedVariant = (
  price: ProductPriceArgs | undefined | null,
  variantId: string,
) => {
  if (price?.mode === EProductPriceMode.ManualProperty) {
    return price.basePrice
  }
  if ((price?.variants?.length ?? 0) > 0 && variantId) {
    return price?.variants?.find((v) => v.variantId === variantId)?.price
  }
}

export default {
  formatAvailableProperties,
  findDefaultProperties,
  findValidVariant,
  adjustToValidVariant,
  findVariantByProperties,
  buildItemAnswers,
  getSelectedProductImage,
  formatVariantProperties,
  findPriceBySelectedVariant,
}
