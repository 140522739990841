import { CustomDealFormData } from '../ItemList'
import { Field, FieldValidator, FormikProps } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { InputErrorMessage } from '@/app/common/components/Form'
import SmartSelectButton from '@/app/common/components/Form/SmartSelectButton'
import { getTranslatedValidationError } from '@/utils/error'

interface Props {
  questionIndex: number
  formikProps: FormikProps<CustomDealFormData>
  name: string
  label: string
  itemIndex: number
  info: string
  validate?: FieldValidator
  onTouched: (questionIndex: number) => void
}

export const BooleanQuestion = ({
  formikProps,
  label,
  name,
  info,
  validate,
  itemIndex,
  questionIndex,
  onTouched,
}: Props) => {
  const { t } = useTranslation()

  const field = formikProps.getFieldMeta(name)
  const error =
    field.error && (field.touched || formikProps.submitCount > 0)
      ? field.error
      : undefined
  const ref = useRef({ touched: false })

  useEffect(() => {
    if (!ref.current.touched && field.touched) {
      ref.current.touched = true
      onTouched(questionIndex)
    }

    return () => {
      if (!field.touched) {
        // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ref.current.touched = false
      }
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.touched, questionIndex])

  return (
    <Wrapper>
      <Field name={name} validate={validate}>
        {() => (
          <SmartSelectButton
            id={`CUSTOM_DEAL_ITEM_${itemIndex}_QUESTION_${questionIndex}`}
            formikProps={formikProps}
            name={name}
            options={[
              { name: t('common:yes'), value: true },
              { name: t('common:no'), value: false },
            ]}
            headline={label}
            subline={info}
          />
        )}
      </Field>

      {error && (
        <InputErrorMessage message={getTranslatedValidationError(error, t)} />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
  margin: 1.5rem 0;
`
